import { getPlanFeature, type PLAN_NAME } from '@readme/iso';
import React from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import PurpleBox from '../PurpleBox';

import classes from './index.module.scss';

interface PlanAccessBannerProps {
  className?: string;
  /**
   * Type of plan access messaging to display
   */
  kind: 'paygate' | 'trial';
  /**
   * Indicates which plan the feature being gated/trialed belongs to.
   */
  plan: `${PLAN_NAME}`;
}

/**
 * A banner we can display on features that are gated or in trial.
 * Usually used as tooltip content in conjunction with the PlanAccessBadge component, but can be used standalone.
 */
export default function PlanAccessBanner({ className, kind, plan }: PlanAccessBannerProps) {
  const bem = useClassy(classes, 'PlanAccessBanner');
  const planName = getPlanFeature(plan, 'name');

  // Prevent click events from bubbling up to the parent element
  // This is necessary when the banner is used as a tooltip within a dropdown to prevent the dropdown from closing
  // when the banner is clicked.
  const handlePropagation = (e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation();

  return (
    <div
      onClick={handlePropagation}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          handlePropagation(e);
        }
      }}
      role="tooltip"
    >
      <PurpleBox className={bem('&', className)}>
        <p className={bem('-description')}>
          {kind === 'paygate' ? (
            <>
              You&apos;ll need to upgrade to the <strong>{planName}</strong> plan to access this feature.
            </>
          ) : (
            <>
              This is a <strong>{planName}</strong> plan feature, you&apos;ll have access during your trial.
            </>
          )}
        </p>
        <Button href={PLAN_UPGRADE_URL} kind="contrast" size="xs" target="_blank" text>
          {kind === 'paygate' ? 'Upgrade' : 'Plan Details'} <Icon name="arrow-up-right" />
        </Button>
      </PurpleBox>
    </div>
  );
}
