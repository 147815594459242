import type { Placement } from 'tippy.js';

import { getPlanFeature, type PLAN_NAME } from '@readme/iso';
import Tippy from '@tippyjs/react';
import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';

import type { DropdownProps } from '@ui/Dropdown';
import Icon from '@ui/Icon';

import PlanAccessBanner from '../Banner';
import PurpleBox from '../PurpleBox';

import classes from './index.module.scss';

interface PlanAccessBadgeProps {
  /**
   * Type of plan access to display
   * - `locked` - User does not have access to the feature.
   * - `open` - User has access to the feature.
   * - `trial` - User currently has trial access to the feature.
   */
  access: 'locked' | 'open' | 'trial';
  className?: string;
  /**
   * Display only the icon
   */
  iconOnly?: boolean;
  placement?: Placement;
  /**
   * Indicates which plan to display in the badge.
   */
  plan: `${PLAN_NAME}`;
  /**
   * Whether to display a tooltip on hover
   */
  tooltip?: boolean;
}

/**
 * Indicates the access level of a feature based on the user's plan.
 */
export default function PlanAccessBadge({
  access,
  className,
  iconOnly = false,
  placement = 'bottom',
  plan,
  tooltip = false,
}: Pick<DropdownProps, 'align' | 'justify'> & PlanAccessBadgeProps) {
  const bem = useClassy(classes, 'PlanAccessBadge');
  const planName = getPlanFeature(plan, 'name');

  const renderBadge = useCallback(
    (iconName: 'lock' | 'unlock') => (
      <PurpleBox className={bem('&', iconOnly && '_iconOnly', className)}>
        <Icon name={iconName} />
        {!iconOnly && planName}
      </PurpleBox>
    ),
    [bem, className, iconOnly, planName],
  );

  if (access === 'open') return null;

  const badge = renderBadge(access === 'trial' ? 'unlock' : 'lock');

  return tooltip ? (
    <Tippy
      appendTo={typeof document !== 'undefined' ? document.body : 'parent'}
      content={
        <PlanAccessBanner className={bem('-banner')} kind={access === 'trial' ? 'trial' : 'paygate'} plan={plan} />
      }
      duration={150}
      interactive
      offset={[0, 5]}
      placement={placement}
      zIndex={9999999}
    >
      {badge}
    </Tippy>
  ) : (
    badge
  );
}
