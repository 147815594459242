import type { Node } from 'slate';

import { Element } from 'slate';

import type { MarkdownCharInline } from '@ui/MarkdownEditor/types';

export const type = 'markdown-char' as MarkdownCharInline['type'];
export const isMarkdownChar = (node: Node): node is MarkdownCharInline => Element.isElementType(node, type);
export const isMarkdownCharOf = (node: Node, char: string): node is MarkdownCharInline =>
  isMarkdownChar(node) && node.char === char;

export const create = (char: string): MarkdownCharInline => ({
  type,
  char,
  children: [{ text: '' }],
});
