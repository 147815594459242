import type { Node, NodeEntry } from 'slate';

import { Editor, Element } from 'slate';

import type {
  JsxCommentEndTokenElement,
  JsxCommentStartTokenElement,
  JsxCommentTokenElement,
} from '@ui/MarkdownEditor/types';

import { isJsxComment } from '../JsxComment/shared';

export const type = 'jsx-comment-token';
export const isJsxCommentToken = (node: Node): node is JsxCommentTokenElement => Element.isElementType(node, type);

export const defaultStartToken = (): JsxCommentStartTokenElement => ({
  type,
  edge: 'start',
  children: [{ text: '{/*' }],
});

export const defaultEndToken = (): JsxCommentEndTokenElement => ({
  type,
  edge: 'end',
  children: [{ text: '*/}' }],
});

export const isLonelyJsxCommentToken = (
  editor: Editor,
  nodeEntry: NodeEntry,
  { edge }: { edge: 'end' | 'start' },
): nodeEntry is NodeEntry<JsxCommentTokenElement> => {
  const [node, path] = nodeEntry;
  return isJsxCommentToken(node) && node.edge === edge && !Editor.above(editor, { at: path, match: isJsxComment });
};
