import Component from './Component';
import normalizeNode from './normalizeNode';
import { serialize } from './serialize';
import { type, isParagraph } from './shared';

const Paragraph = {
  is: isParagraph,
  isParagraph,
  Component,
  normalizeNode,
  serialize,
  type: type as 'paragraph',
};

export default Paragraph;
