import PropTypes from 'prop-types';
import React from 'react';
import { useSelected } from 'slate-react';

import useClassName from '@ui/MarkdownEditor/useClassName';

import classes from './style.module.scss';

const HorizontalRule = ({ attributes, children }) => {
  const className = useClassName(classes.HorizontalRule, useSelected() && classes['HorizontalRule-selected']);

  return (
    <div className={className}>
      <hr {...attributes} contentEditable={false} />
      {children}
    </div>
  );
};

HorizontalRule.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
};

export default HorizontalRule;
