export const offsetToCodeEditorSelection = (offset: number, text: string) => {
  const lines = text.split('\n');
  let line = 0;
  let ch = 0;

  for (const [i, l] of lines.entries()) {
    if (offset <= l.length) {
      line = i;
      ch = offset;
      break;
    }

    // eslint-disable-next-line no-param-reassign
    offset -= l.length + 1;
  }

  return { line, ch };
};
