/* eslint-disable consistent-return */
import type { BasePoint } from 'slate';

import { detect } from 'detect-browser';
import { Path, Editor, Node, Range, Transforms } from 'slate';

import { offsetToPoint, pointToOffset } from '@ui/MarkdownEditor/editor/utils';
import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { OnKeyDown, ReadmeEditor, ReadmeElement } from '@ui/MarkdownEditor/types';

import { insertRow } from '../Table/operations';
import { isTable } from '../Table/shared';

import { isTableCell } from './shared';

const isOSX = detect()?.os === 'Mac OS';

type coord = [number, number];
const coordOps = {
  up: ([row, col]: coord) => [row - 1, col],
  right: ([row, col]: coord) => [row, col + 1],
  down: ([row, col]: coord) => [row + 1, col],
  left: ([row, col]: coord) => [row, col - 1],
};

interface GetNextLocationOpts {
  exit?: boolean;
  offset?: number;
  reverse?: boolean;
  vertical?: boolean;
}

const getNextLocation = (
  editor: ReadmeEditor,
  { exit = true, reverse = false, vertical = false, offset = 0 }: GetNextLocationOpts = {},
) => {
  if (!editor.selection) return;

  const cellEntry = Editor.above(editor, { match: isTableCell });
  const tableEntry = Editor.above(editor, { match: isTable });
  if (!cellEntry || !tableEntry) return;

  const [table, tablePath] = tableEntry;
  const [, cellPath] = cellEntry;
  const cellCoords = cellPath.slice(-2) as coord;

  const dir = vertical ? (reverse ? 'up' : 'down') : reverse ? 'left' : 'right';

  const nextPath = coordOps[dir](cellCoords);
  if (Node.has(table, nextPath)) {
    const path = [...tablePath, ...nextPath];
    const cell = Node.get(editor, path);

    return offsetToPoint([cell, path], offset);
  }

  if (!vertical) {
    const cellIdx = reverse ? table.children[0].children.length - 1 : 0;
    const wrappedPath = [coordOps[reverse ? 'up' : 'down'](cellCoords)[0], cellIdx];

    if (Node.has(table, wrappedPath)) {
      return Editor.start(editor, [...tablePath, ...wrappedPath]);
    }
  }

  if (exit) {
    if (reverse && !Path.hasPrevious(tablePath)) return;

    const path = Path[reverse ? 'previous' : 'next'](tablePath);
    if (!Node.has(editor, path)) return;
    const block = Node.get(editor, path);

    return offsetToPoint([block, path], offset);
  }
};

type GetNextLocationFn = (editor: ReadmeEditor, opts?: GetNextLocationOpts) => BasePoint | undefined;

const getUp: GetNextLocationFn = (editor, opts) => getNextLocation(editor, { ...opts, reverse: true, vertical: true });
const getRight: GetNextLocationFn = (editor, opts) => getNextLocation(editor, opts);
const getDown: GetNextLocationFn = (editor, opts) => getNextLocation(editor, { ...opts, vertical: true });
const getLeft: GetNextLocationFn = (editor, opts) => getNextLocation(editor, { ...opts, reverse: true });

const isInTableCell = (editor: ReadmeEditor) => Editor.above(editor, { match: isTableCell });

const fallback = (editor: ReadmeEditor, { reverse }: { reverse?: boolean } = {}) => {
  const tableEntry = Editor.above(editor, { match: isTable });
  if (!tableEntry) return;

  const [, tablePath] = tableEntry;
  const at = reverse ? tablePath : Path.next(tablePath);

  Transforms.insertNodes(editor, emptyNode(), { at });

  return Editor.start(editor, at);
};

const arrowUp: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey) || !editor.selection || !isInTableCell(editor)) return;

  const at = getUp(editor, { offset: editor.cursorOffset });
  if (!at) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, at);
};
const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey) || !editor.selection || !isInTableCell(editor)) return;

  const at = getDown(editor, { offset: editor.cursorOffset });
  if (!at) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, at);
};

const enter: OnKeyDown = (event, editor) => {
  if (
    !(event.key === 'Enter' && !(event.shiftKey || event.metaKey || event.ctrlKey)) ||
    !editor.selection ||
    !isInTableCell(editor)
  )
    return;

  const at = getDown(editor) ?? fallback(editor);
  if (!at) return;

  event.preventDefault();
  event.stopPropagation();

  const [, currentTablePath] = Editor.above(editor, { at: editor.selection, match: isTable }) || [];
  const [, nextTablePath] = Editor.above(editor, { at, match: isTable }) || [];

  if (currentTablePath && nextTablePath && !Path.equals(currentTablePath, nextTablePath)) {
    Transforms.insertNodes(editor, [emptyNode()], { at: nextTablePath, select: true });
  } else {
    Transforms.select(editor, at);
  }
};

const ctrlCmdEnter: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Enter' && (isOSX ? event.metaKey : event.ctrlKey)) || !editor.selection) return;

  const cellEntry = Editor.above(editor, { match: isTableCell });
  if (!cellEntry) return;

  event.preventDefault();
  event.stopPropagation();

  const [, path] = cellEntry;
  insertRow(editor, path);

  const at = getDown(editor);
  if (!at) return;
  Transforms.select(editor, at);
};

const tab: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Tab' && !event.shiftKey) || !editor.selection || !isInTableCell(editor)) return;

  const at = getRight(editor);
  if (!at) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, at);
};

const shiftTab: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Tab' && event.shiftKey) || !editor.selection || !isInTableCell(editor)) return;

  const at = getLeft(editor);
  if (!at) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, at);
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && editor.selection && isInTableCell(editor))) return;

  const cellEntry = Editor.above(editor, { match: isTableCell });
  if (!cellEntry) return;

  const [, cellPath] = cellEntry;
  if (!(Range.isCollapsed(editor.selection) && Editor.isStart(editor, editor.selection.anchor, cellPath))) return;

  event.preventDefault();
  event.stopPropagation();
};

const arrowDownOrUp: OnKeyDown = (event, editor) => {
  if (!(['ArrowDown', 'ArrowUp'].includes(event.key) && !event.shiftKey && editor.selection)) {
    delete editor.cursorOffset;
    return;
  }

  const blockEntry = Editor.above(editor, { match: n => Editor.isBlock(editor, n as ReadmeElement) });
  if (!blockEntry) {
    // eslint-disable-next-line no-console
    console.warn("Can't find a top level block?!");
    return;
  }

  if (!editor.cursorOffset) {
    editor.cursorOffset = pointToOffset(blockEntry, editor.selection.anchor);
  }
};

const arrowUpIntoTable: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey && editor.selection && Range.isCollapsed(editor.selection))) return;

  const rootPath = editor.selection.anchor.path.slice(0, 1);
  if (!Path.hasPrevious(rootPath)) return;

  const nextRootPath = Path.previous(rootPath);
  const nextRoot = Node.get(editor, nextRootPath);
  if (!isTable(nextRoot)) return;

  const nextRowPath = [...nextRootPath, nextRoot.children.length - 1];
  const nextRow = Node.get(editor, nextRowPath);
  const point = offsetToPoint([nextRow, nextRowPath], editor.cursorOffset);

  event.preventDefault();
  event.stopPropagation();

  editor.cursorOffset = point.offset;

  Transforms.select(editor, point);
};

const onDelete: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Delete' && editor.selection && isInTableCell(editor))) return;

  const cellEntry = Editor.above(editor, { match: isTableCell });
  if (!cellEntry) return;

  const [, cellPath] = cellEntry;
  if (!(Range.isCollapsed(editor.selection) && Editor.isEnd(editor, editor.selection.anchor, cellPath))) return;

  event.preventDefault();
  event.stopPropagation();
};

export default [
  enter,
  ctrlCmdEnter,
  tab,
  shiftTab,
  arrowDownOrUp,
  arrowUpIntoTable,
  arrowUp,
  arrowDown,
  backspace,
  onDelete,
];
