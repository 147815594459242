import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

import { offsetToCodeEditorSelection } from '../../utils';
import { insertJsxFlow } from '../JsxFlow/operations';

const getCodeEditorSelection = (component: string, startToken: string, endToken: string) => {
  const start = offsetToCodeEditorSelection(component.match(startToken)?.index || 0, component);
  const endTokenIndex = component.match(endToken)?.index;
  const end = offsetToCodeEditorSelection(endTokenIndex ? endTokenIndex + endToken.length : 0, component);

  return [start, end];
};

export const insertComponentType =
  (typeOfComponent: string) =>
  (editor: ReadmeEditor, { at = editor.selection } = {}) => {
    if (!at) {
      // eslint-disable-next-line no-console
      console.error('No selection!');
      return;
    }

    let component = '';
    let codeEditorSelection;

    if (typeOfComponent === 'accordion') {
      component = `<Accordion title="My Accordion Title" icon="fa-info-circle">
  Lorem ipsum dolor sit amet, **consectetur adipiscing elit.** Ut enim
  ad minim veniam, quis nostrud exercitation ullamco. Excepteur sint
  occaecat cupidatat non proident!
</Accordion>`;
      codeEditorSelection = getCodeEditorSelection(component, 'Lorem', 'proident!');
    }

    if (typeOfComponent === 'cards') {
      component = `<Cards columns={4}>
  <Card title="First Card" href="https://readme.com" icon="fa-home" target="_blank">
    Neque porro quisquam est qui dolorem ipsum quia
  </Card>

  <Card title="Second Card" icon="fa-user">
    *Lorem ipsum dolor sit amet, consectetur adipiscing elit*
  </Card>

  <Card title="Third Card" icon="fa-star">
    > Ut enim ad minim veniam, quis nostrud ullamco
  </Card>

  <Card title="Fourth Card" icon="fa-question">
    **Excepteur sint occaecat cupidatat non proident**
  </Card>
</Cards>`;
      codeEditorSelection = getCodeEditorSelection(component, 'Neque', 'quia');
    }

    if (typeOfComponent === 'columns') {
      component = `<Columns layout="auto">
  <Column>
    Neque porro quisquam est qui dolorem ipsum quia
  </Column>

  <Column>
    *Lorem ipsum dolor sit amet, consectetur adipiscing elit*
  </Column>
  
  <Column>
    > Ut enim ad minim veniam, quis nostrud ullamco
  </Column>
</Columns>`;
      codeEditorSelection = getCodeEditorSelection(component, 'Neque', 'quia');
    }

    if (typeOfComponent === 'tabs') {
      component = `<Tabs>
  <Tab title="First Tab">
    Welcome to the content that you can only see inside the first Tab.
  </Tab>

  <Tab title="Second Tab">
    Here's content that's only inside the second Tab.
  </Tab>

  <Tab title="Third Tab">
    Here's content that's only inside the third Tab.
  </Tab>
</Tabs>`;
      codeEditorSelection = getCodeEditorSelection(component, 'Welcome', 'first Tab.');
    }

    insertJsxFlow(editor, component, { codeEditorSelection });
  };

export const insertAccordion = insertComponentType('accordion');
export const insertCards = insertComponentType('cards');
export const insertColumns = insertComponentType('columns');
export const insertTabs = insertComponentType('tabs');

export default insertComponentType;
