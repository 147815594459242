import React, { useMemo, useState, useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import { ImageMenuDropdowns, ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { ImageBlock, ImageElement } from '@ui/MarkdownEditor/types';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import { FileInputCache } from '../blocks/shared';
import { Header } from '../MenuDropdown';
import menuClasses from '../style.module.scss';

import classes from './style.module.scss';
import useImageMenu from './useImageMenu';

interface Props {
  close?: () => void;
  node: ImageBlock | ImageElement | null;
}

export const UploadEditor = ({ close, node }: Props) => {
  const editor = useSlateStatic();
  const [{ uploads }, dispatch] = useImageMenu();

  const [imageUrl, setUrl] = useState<string>(node?.url || '');
  const { urlValidationMessage, isImageLoading, imageSizeError } = (node && uploads.get(node)) || {};

  const categories = useMemo(
    () => (editor.props.imageUpload ? [ImageMenuDropdowns.upload, ImageMenuDropdowns.url] : [ImageMenuDropdowns.url]),
    [editor.props.imageUpload],
  );
  const [selectedCategory, setSelectedCategory] = useState<ImageMenuDropdowns>(categories[0]);

  // Category click handler
  const onClickCategory = useCallback(event => {
    const { category } = event.target.dataset;

    setSelectedCategory(category);
  }, []);

  const removeImage = useCallback(() => {
    if (node) {
      const path = ReactEditor.findPath(editor, node);
      Transforms.delete(editor, { at: path });
    }

    dispatch({ type: ImageMenuActionTypes.close });
  }, [dispatch, editor, node]);

  const onChangeAlt = useCallback(
    event => {
      const alt = event.target.value;
      if (!node) return;

      const path = ReactEditor.findPath(editor, node);
      Transforms.setNodes(editor, { alt }, { at: path });
    },
    [editor, node],
  );

  const onChangeUrl = useCallback(event => {
    setUrl(event.target.value);
  }, []);

  const onKeyDownInput = useCallback(
    event => {
      if (event.key !== 'Enter') return;

      // If we don't yet have an image or we are replacing the image,
      // insert a new one; otherwise we pressed enter on the alt text
      // and that's already been saved.
      if (node && (!node.url || imageUrl !== node.url)) {
        try {
          new URL(imageUrl); // eslint-disable-line no-new
        } catch (err) {
          dispatch({
            type: ImageMenuActionTypes.updateUpload,
            payload: { node, upload: { urlValidationMessage: 'Invalid URL' } },
          });
          return;
        }

        const path = ReactEditor.findPath(editor, node);
        Transforms.setNodes(editor, { url: imageUrl }, { at: path });
      }

      event.stopPropagation();
      event.preventDefault();

      if (close) {
        close();
      } else {
        dispatch({ type: ImageMenuActionTypes.close });
      }
    },
    [node, imageUrl, close, editor, dispatch],
  );

  const openImageUpload = useCallback(() => {
    if (!node) return;

    FileInputCache.get(node)?.current?.click();
  }, [node]);

  return (
    <Menu className={classes['Settings-Menu']} data-testid="upload-editor" role="menu">
      <Header
        categories={categories}
        className={classes['Settings-Header']}
        highlightedCategory={selectedCategory}
        onClickCategory={onClickCategory}
      />
      <MenuItem focusable={false} TagName="div">
        <Flex align="stretch" className={menuClasses['EditorMenu-label']} gap="sm" layout="col" tag="label">
          {/* If the Image Upload tab is open, display the file upload. */}
          {selectedCategory === ImageMenuDropdowns.upload && (
            <>
              <Button
                className={classes['Settings-Button']}
                kind="minimum"
                loading={isImageLoading}
                onClick={openImageUpload}
                outline
                size="sm"
              >
                <i className="icon-upload-cloud" />
                {node?.url ? 'Replace File' : 'Choose File'}
              </Button>
              {imageSizeError ? (
                <div className={classes['Settings-Error']}>{imageSizeError}</div>
              ) : (
                <div className={classes['Settings-Disclaimer']}>
                  Files cannot be larger than 30MB or wider than 2500px.
                </div>
              )}
            </>
          )}
          {selectedCategory === ImageMenuDropdowns.url && (
            <>
              <Input
                className={classes['Settings-URLUpload']}
                data-testid="editor-image-url"
                onChange={onChangeUrl}
                onKeyDown={onKeyDownInput}
                placeholder="https://readme.com/owlbert.png"
                size="sm"
                type="url"
                value={node?.url || ''}
              />
              {!!urlValidationMessage && <div className={classes['Settings-Validation']}>{urlValidationMessage}</div>}
            </>
          )}
        </Flex>
      </MenuItem>
      <MenuDivider />
      <MenuItem focusable={false} TagName="div">
        <Flex align="stretch" className={menuClasses['EditorMenu-label']} gap="sm" layout="col" tag="label">
          <div data-testid="editor-image-alt-text-label">Alt Text</div>
          <Input
            className={classes['Settings-AltTextInput']}
            data-testid="editor-image-alt-text"
            onChange={onChangeAlt}
            onKeyDown={onKeyDownInput}
            size="sm"
            value={node?.alt || ''}
          />
        </Flex>
      </MenuItem>
      <MenuDivider />
      <MenuItem color="red" icon="icon icon-trash1" onClick={removeImage}>
        Delete
      </MenuItem>
    </Menu>
  );
};

export default UploadEditor;
