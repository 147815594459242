import React, { memo, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useFocused } from 'slate-react';

import useEnvInfo from '@core/hooks/useEnvInfo';
import useEventListener from '@core/hooks/useEventListener';

import useClassName from '@ui/MarkdownEditor/useClassName';
import Menu from '@ui/Menu';

import classes from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  handleClose?: () => void;
  hidden?: boolean;
  open?: boolean;
  setPosition: (ref: React.RefObject<HTMLElement>) => void;
}

const parents = (node: Element | null, matcher: (n: Element) => boolean): Element | null => {
  if (!node) return null;
  if (matcher(node)) return node;
  const parent = node.parentNode;

  return parent ? parents(parent as Element, matcher) : null;
};

const IconMenu = ({
  children,
  className,
  handleClose,
  hidden = false,
  open,
  setPosition: setPositionProp,
  ...props
}: Props) => {
  const ref = useRef<HTMLElement>(null);
  const focused = useFocused();
  const { isClient } = useEnvInfo();

  const setPosition = useCallback(() => setPositionProp(ref), [setPositionProp]);

  useEventListener('resize', setPosition);
  useEventListener('scroll', setPosition, { capture: true });

  useEffect(() => {
    if (ref.current && open) setPosition();
  }, [open, setPosition]);

  useEffect(() => {
    if (!focused && open && handleClose) {
      const isMenuFocused = parents(document.activeElement, node => node === ref.current);
      if (!isMenuFocused) handleClose();
    }
  }, [focused, handleClose, open]);

  const menuClassName = useClassName(classes.IconMenu, (open && !hidden) || classes['IconMenu-closed'], className);

  return isClient
    ? createPortal(
        <Menu ref={ref} className={menuClassName} {...props} role="menubar">
          {children}
        </Menu>,
        document.body,
      )
    : null;
};

export { default as Button } from './Button';
export { default as Divider } from './Divider';
export default memo(IconMenu);
