import type { NodeEntry, Node } from 'slate';

import { Editor, Text, Transforms } from 'slate';

import type { JsxCommentElement, Normalizer } from '@ui/MarkdownEditor/types';

import lastParentSiblingPath from '../../utils/lastParentSiblingPath';
import { isJsxCommentToken } from '../JsxCommentToken/shared';
import { isParagraph } from '../Paragraph/shared';

import { expandComment } from './operations';
import { isJsxComment } from './shared';

/* @func validCommentTokens
 * Find a matching pair of comment tags
 */
const validCommentTokens = (editor: Editor, [, path]: NodeEntry<JsxCommentElement>) => {
  const tokens = [...Editor.nodes(editor, { at: path, match: isJsxCommentToken })];
  if (tokens.length === 0) return false;

  return !!(tokens[0][0].edge === 'start' && tokens.find(([t]) => t.edge === 'end'));
};

/* @func unwrap
 * Validate that the comment has matching comment tags. If it doesn't have a
 * leading one, unwrap it. If it doesn't have a trailing one, look for a
 * trailing one.
 */
const unwrap: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isJsxComment(node) || validCommentTokens(editor, [node, path])) return next();

    // Search from this comment to the end of the current block level. This
    // means if we were in a top level paragraph, we'll search the whole doc.
    // If we are in a blockquote, list, or table cell, we'll only search within
    // that block.
    const at = {
      anchor: Editor.start(editor, path),
      focus: Editor.end(editor, lastParentSiblingPath(editor, path)),
    };

    // Starting token, could be the current one, or if the starting one was deleted, the next one.
    const startToken = Editor.nodes(editor, {
      at,
      match: n => isJsxCommentToken(n) && n.edge === 'start',
    }).next().value;

    // End token, could be the current one, or some trailing one.
    const endToken = Editor.nodes(editor, {
      at,
      match: n => isJsxCommentToken(n) && n.edge === 'end',
    }).next().value;

    if (!startToken || !endToken) {
      Transforms.unwrapNodes(editor, { at: path, match: isJsxComment });
      // eslint-disable-next-line consistent-return
      return;
    }

    const success = expandComment(editor, [node, path], {
      at: {
        anchor: Editor.start(editor, path),
        focus: Editor.end(editor, endToken[1]),
      },
    });

    return success ? null : next();
  };

/* @func splitEdges
 * If content is inserted before a leading comment tag, or after a trailing
 * one, split it out onto a new line.
 */
const splitEdges: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!isJsxComment(node)) return next();

    const found = ['start', 'end'].find(edge => {
      const match = (n: Node) => isJsxCommentToken(n) && n.edge === edge;
      const token = Editor.nodes(editor, {
        at: path,
        match,
        reverse: edge === 'end',
      }).next().value;
      if (!token) {
        return false;
      }

      const outerPoint = Editor[edge === 'start' ? 'before' : 'after'](editor, token[1]);
      if (!outerPoint) {
        return false;
      }

      const at = Editor.rangeRef(
        editor,
        edge === 'start'
          ? {
              anchor: Editor.start(editor, path),
              focus: outerPoint,
            }
          : {
              anchor: outerPoint,
              focus: Editor.end(editor, path),
            },
        { affinity: 'inward' },
      );
      if (!at.current) return false;

      try {
        const string = Editor.string(editor, at.current);
        if (string === '') return false;

        // eslint-disable-next-line consistent-return
        Editor.withoutNormalizing(editor, () => {
          if (!at.current) return false;
          Transforms.unwrapNodes(editor, {
            at: at.current,
            match: isJsxComment,
            split: true,
          });
          Transforms.splitNodes(editor, { at: Editor[edge === 'start' ? 'end' : 'start'](editor, at.current) });
          Transforms.select(editor, at.current.focus);
        });
      } finally {
        at.unref();
      }

      return true;
    });

    if (!found) return next();
  };

const plainText: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!isJsxComment(node)) return next();

    const found = node.children.find((child, idx) => {
      if (Text.isText(child) || isJsxCommentToken(child)) return false;

      if (isParagraph(child)) {
        Transforms.unwrapNodes(editor, { at: [...path, idx], match: isParagraph });
        Transforms.insertText(editor, '\n\n', { at: Editor.end(editor, [...path, idx]) });

        return true;
      }

      const string = editor.serialize(child);
      Transforms.removeNodes(editor, { at: [...path, idx] });
      Transforms.insertText(editor, string, { at: [...path, idx] });

      return true;
    });

    if (!found) next();
  };

export default [unwrap, splitEdges, plainText];
