import React, { useCallback, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import { ImageAlignValues, DragItems } from '@ui/MarkdownEditor/enums';
import type { BlockDragItem, ReadmeElement } from '@ui/MarkdownEditor/types';

import { Image, Table } from '../blocks';

import Menu from './Menu';
import classes from './style.module.scss';

export const tooltipId = 'BlockMenu';

interface CollectedDragProps {
  isDragging: boolean;
}

const Tooltip = ({ align, node }: { align: ImageAlignValues | null; node: ReadmeElement | null }) => {
  const bem = useClassy(classes, 'BlockMenu_Tooltip');
  const editor = useSlateStatic();
  const { id: dndId } = useDragAndDropContext();

  const ref = useRef<HTMLDivElement>(null);
  const open = !!node;

  const [{ isDragging }, drag, preview] = useDrag<BlockDragItem, void, CollectedDragProps>(
    () => ({
      type: DragItems.Block,
      item: { align, element: node, dndId },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [align, dndId, node],
  );

  const setPosition = useCallback(() => {
    if (!open || !ref.current) return;

    const element = ReactEditor.toDOMNode(editor, node);
    const tooltipBox = ref.current.getBoundingClientRect();

    let attachmentElement = element;
    if (Image.is(node) && align === ImageAlignValues.right) {
      const imageElement = element.querySelector('img[data-image-node]') as HTMLElement | null;

      if (imageElement) {
        attachmentElement = imageElement;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Unable to find image element!');
      }
    } else if (!Table.is(node)) {
      const leafElement = element.querySelector('[data-slate-leaf]') as HTMLElement | null;

      if (leafElement) {
        attachmentElement = leafElement;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Unable to fine leaf element!');
      }
    }

    const horizontal = attachmentElement.offsetLeft - tooltipBox.width;
    const top = element.offsetTop;

    ref.current.style.transform = `translate(${horizontal}px, ${top}px)`;
  }, [align, editor, node, open]);

  useEffect(setPosition, [setPosition]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return (
    node && (
      <div ref={ref} className={bem('&', open && '--open', isDragging && '--dragging')}>
        <Menu drag={drag} element={node} />
      </div>
    )
  );
};

export default Tooltip;
