import type { Editor } from 'slate';
import type { RenderElementProps } from 'slate-react';

import React from 'react';
import { ReactEditor } from 'slate-react';

import BlockMenu from './BlockMenu';
import { blocksByType } from './byType';

const Paragraph = blocksByType.paragraph.Component;

const renderNode = (editor: Editor, props: RenderElementProps) => {
  const { element } = props;
  const block = blocksByType[element.type];
  const Component = block ? block.Component : Paragraph;

  if (!editor.props.basic && BlockMenu.menuable(editor, ReactEditor.findPath(editor, element))) {
    const { attributes, ...rest } = props;

    return (
      <BlockMenu attributes={attributes} element={element}>
        <Component {...rest} />
      </BlockMenu>
    );
  }

  return <Component {...props} />;
};

export default renderNode;
