import type { RenderingLibrary } from './RenderingLibraryProvider';
import type { CustomEditor, ReadmeEditor, Props } from '../types';
import type { $TSFixMe } from '@readme/iso';

import * as RDMD from '@readme/markdown';

import { safelyStringifyJSON } from '@core/utils/json';

import { toggle } from './decorations';
// @todo:
import deserialize from './deserialize';
import plugins from './plugins';
import serialize from './serialize';

export interface Opts {
  components?: Record<string, string>;
  renderingLibrary: RenderingLibrary;
  reusableContent?: { tags: Record<string, string> };
}

const withReadme = (
  editor: CustomEditor,
  props: Props,
  { reusableContent = { tags: {} }, components = {}, renderingLibrary = RDMD }: Opts = {
    reusableContent: { tags: {} },
    components: {},
    renderingLibrary: RDMD,
  },
) => {
  const readmeEditor = editor as ReadmeEditor;

  readmeEditor.codeEditorSelection = new WeakMap();

  (Object.keys(plugins) as (keyof typeof plugins)[]).forEach(name => {
    // @todo: I have no idea why TS is getting tripped up by normalizeNode.
    readmeEditor[name] = plugins[name](readmeEditor) as $TSFixMe;
  });

  readmeEditor.deserialize = ((md, options) => {
    const rdmdOpts = { ...options, reusableContent, components, renderingLibrary, useMDX: props.useMDX };

    return deserialize(md, rdmdOpts);
  }) as ReadmeEditor['deserialize'];
  readmeEditor.renderingLibrary = renderingLibrary;
  readmeEditor.serialize = node => serialize(node, { renderingLibrary });
  readmeEditor.toJSON = () => safelyStringifyJSON({ children: editor.children }) || '';
  readmeEditor.toString = () => serialize(editor, { renderingLibrary });
  readmeEditor.toggleFormat = type => toggle(editor, type);
  readmeEditor.props = props || {};

  return readmeEditor;
};

export default withReadme;
