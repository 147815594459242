import React from 'react';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import useClassName from '@ui/MarkdownEditor/useClassName';

import classes from './style.module.scss';

interface Props extends ButtonProps {
  active?: boolean;
  icon: string;
}

const IconMenuButton = ({ active, icon, ...props }: Props) => {
  const className = useClassName(classes.Button, active && classes['Button-active']);

  return (
    <Button className={className} kind="minimum" size="sm" text {...props}>
      <Icon name={icon} />
    </Button>
  );
};

export default React.memo(IconMenuButton);
