import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { CustomBlockPageCollectionType } from '@readme/api/src/mappings/customblock/page/types';
import type { ReadType } from '@readme/api/src/mappings/customblock/types';
import type { Path } from 'react-hook-form';

import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useConfirmBeforeUnload from '@core/hooks/useConfirmBeforeUnload';
import useEventListener from '@core/hooks/useEventListener';
import useIsTrialActive from '@core/hooks/useIsTrialActive';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import { fetcher, useReadmeApiNext } from '@core/hooks/useReadmeApi';
import useUniqueId from '@core/hooks/useUniqueId';
import type { HTTPError } from '@core/utils/types/errors';

import type { CustomBlockType } from '@routes/Dash/CustomBlocks/types';

import Button from '@ui/Button';
import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import Notification, { notify } from '@ui/Notification';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';

import PageUsageMenu from '../PageUsageMenu';

import ComponentEditor from './ComponentEditor';
import CustomBlocksFormContext from './Context';
import NameInput from './NameInput';
import ReusableContentEditor from './ReusableContentEditor';
import classes from './style.module.scss';

export interface CustomBlockFormProps {
  className?: string;

  /**
   * The default values to populate the form with.
   */
  item: CustomBlockType;

  /**
   * Called when the cancel button is clicked.
   */
  onCancel?: () => void;

  /**
   * Called when the form's value changes.
   */
  onChange?: (data: { isDirty: boolean; value: { name?: string; source?: string } }) => void;

  /**
   * Called after a successful delete.
   */
  onDeleteSuccess?: () => null | void;

  /**
   * Called after a successful save.
   */
  onSaveSuccess?: ((data: ReadType) => void) | null;

  /**
   * Whether or not this form is dismissed when the cancel button is clicked.
   * This happens when the form is in a modal, or when creating a new Custom Block in the mgmt view
   */
  willDismissOnCancel?: boolean;
}

function FormContent({
  className,
  onCancel,
  onSaveSuccess,
  onDeleteSuccess,
  item,
  onChange,
  willDismissOnCancel = true,
}: CustomBlockFormProps) {
  const tagName = 'CustomBlockForm';
  const uid = useUniqueId(tagName);
  const bem = useClassy(classes, tagName);
  const formRef = useRef<HTMLFormElement>(null);
  const dropdownRef = useRef<DropdownRef>(null);

  const {
    project: { subdomain, flags, _parent, childrenProjects, planOverride, plan },
  } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [saveError, setSaveError] = useState<ErrorType | null>(null);
  const [editorKey, setEditorKey] = useState(0);
  const [isRenaming, setIsRenaming] = useState(false);

  const type = item?.type;
  const isGroup = !!childrenProjects?.length;
  const isStagingEnabled = _parent ? _parent.flags?.staging : flags?.staging || false;

  const apiV2Path = `/${subdomain}/api-next/v2`;
  const versionParam = isGroup ? 'stable' : version;

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    setError,
    formState: { isDirty },
  } = useFormContext();

  const nameValue = useWatch({ control, name: 'name' });
  const sourceValue = useWatch({ control, name: 'source' });

  const {
    data: pageUsageData,
    isLoading: isPageUsageLoading,
    isValidating: isPageUsageValidating,
  } = useReadmeApiNext<CustomBlockPageCollectionType>(
    item?.tag ? `/versions/${versionParam}/custom_blocks/${item.tag}/pages` : null,
  );

  // Flag to determine if we're creating a new Custom Block or editing an existing one
  const isCreateForm = !(item && item.tag);
  const canSave = isDirty;
  const canDelete = pageUsageData && pageUsageData.total === 0;
  const isCanDeleteLoading = isPageUsageLoading || isPageUsageValidating;

  const isTrialActive = useIsTrialActive();
  const hasReusableContentPermissions = usePlanPermissions(planOverride || plan, 'reusableContent');
  const isReusableContentEnabled = isTrialActive || hasReusableContentPermissions;

  useConfirmBeforeUnload(isDirty);

  useEffect(() => {
    setEditorKey(prev => prev + 1);
  }, [item?.tag]);

  useEffect(() => {
    onChange?.({ isDirty, value: { name: nameValue, source: sourceValue } });
  }, [isDirty, nameValue, sourceValue, onChange]);

  const onSubmit = handleSubmit(async data => {
    const tag = item?.tag;
    const url = `${apiV2Path}/versions/${versionParam}/custom_blocks${tag ? `/${tag}` : ''}`;

    try {
      setIsSaving(true);
      const response = await fetcher<ReadType>(url, {
        method: isCreateForm ? 'POST' : 'PATCH',
        body: JSON.stringify({ ...data }),
      });
      reset(data);
      setIsSaving(false);
      setIsRenaming(false);
      onSaveSuccess?.(response);
    } catch (error) {
      // Our fetcher stores the initial response data on `error.info`
      const { info } = error as HTTPError;
      setIsSaving(false);
      setSaveError(info as ErrorType);
      throw error;
    }
  });

  useEffect(() => {
    if (!saveError) return;

    // Manually set errors from the API response to the form state.
    saveError?.errors?.forEach(({ key, message }) => {
      const name = key as unknown as Path<ReadType['data']>;
      setError(name, { type: 'manual', message });
    });
  }, [saveError, saveError?.errors, setError]);

  const handleCancel = useCallback(() => {
    if (willDismissOnCancel && isDirty) {
      // eslint-disable-next-line no-alert
      const result = window.confirm('Are you sure you want to leave? Changes you made may not be saved.');
      if (!result) return;
    }
    reset();
    setEditorKey(prev => prev + 1);
    onCancel?.();
  }, [isDirty, onCancel, reset, willDismissOnCancel]);

  const initRenaming = useCallback(() => {
    dropdownRef.current?.toggle();
    setIsRenaming(true);
  }, []);

  const handleDelete = useCallback(async () => {
    const tag = item?.tag;
    if (!tag) return;

    const url = `${apiV2Path}/versions/${versionParam}/custom_blocks/${tag}`;

    try {
      setIsDeleting(true);
      await fetcher<ReadType>(url, {
        method: 'DELETE',
      });
      setIsDeleting(false);
      onDeleteSuccess?.();
    } catch (error) {
      // Our fetcher stores the initial response data on `error.info`
      setIsDeleting(false);
      notify(<Notification kind="error" title={`Something went wrong deleting <${item?.tag} />.`} />);
      throw error;
    }
  }, [apiV2Path, item?.tag, onDeleteSuccess, versionParam]);

  useEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        e.stopPropagation(); // prevent parent form with this same key binding from submitting
        formRef.current?.dispatchEvent(new Event('submit'));
      }
    },
    {
      target: formRef.current,
    },
  );

  const usageData = useMemo(
    () => ({
      pageCount: item?.usage?.page_count,
      projectCount: item?.usage?.project_count,
    }),
    [item],
  );

  return (
    <form ref={formRef} className={bem('&', className)} id={uid('form')}>
      <div className={bem('-controls')}>
        <div className={bem('-controls-inline')}>
          {!!(isCreateForm || isRenaming) && (
            <NameInput
              isFocused={isReusableContentEnabled}
              onCancel={() => {
                setIsRenaming(false);
                resetField('name');
              }}
            />
          )}
          {!isCreateForm && (
            <Dropdown ref={dropdownRef} appendTo={document.body} justify="start" sticky={true}>
              <Button className={bem('-controls-config-btn')} kind="secondary" size="sm" text>
                {!isRenaming && (
                  <Tooltip arrow={false} content={nameValue} delay={[800, 200]} placement="top-start">
                    <span
                      className={bem(
                        '-controls-config-btn-name',
                        type === 'component' && '-controls-config-btn-name_tag',
                      )}
                    >
                      {nameValue}
                    </span>
                  </Tooltip>
                )}
                <Icon color="color-text-minimum-icon" name="more-vertical" />
              </Button>
              <Menu>
                <MenuItem disabled={isRenaming} icon={<Icon name="edit-2" />} onClick={initRenaming}>
                  Rename
                </MenuItem>
                {isCanDeleteLoading ? (
                  <MenuItem focusable={false}>
                    <Spinner size="sm" />
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem
                      color="red"
                      disabled={!canDelete || isDeleting}
                      icon={<Icon name="skull" strokeWeight={2.75} />}
                      onClick={handleDelete}
                    >
                      Delete
                    </MenuItem>
                    {!canDelete && (
                      <>
                        <MenuDivider />
                        <MenuItem
                          description="You must remove this content from all pages before deleting it."
                          focusable={false}
                        />
                      </>
                    )}
                  </>
                )}
              </Menu>
            </Dropdown>
          )}
        </div>

        {!isCreateForm && (
          <PageUsageMenu
            blockId={item.tag || null}
            isGlobal={false}
            showProjectUsage={!!isGroup}
            usageData={usageData}
          />
        )}
      </div>

      {type === 'component' ? (
        <ComponentEditor />
      ) : (
        // The key is used to force the MarkdownEditor within ReusableContentEditor to re-render when the content changes.
        // This is necessary because the MarkdownEditor doesn't update its internal state when its `doc` prop changes.
        <ReusableContentEditor key={editorKey} />
      )}

      <div className={bem('-footer')}>
        <Button disabled={willDismissOnCancel ? false : !isDirty} kind="secondary" onClick={handleCancel} text>
          Cancel
        </Button>
        <Button disabled={!canSave} form={uid('form')} loading={isSaving} onClick={() => onSubmit()} type="button">
          {isStagingEnabled ? 'Save to Staging' : isCreateForm ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
}

/**
 * A form for creating or editing Custom Blocks.
 */
export default function CustomBlockForm(props: CustomBlockFormProps) {
  return (
    <CustomBlocksFormContext defaultValues={props.item}>
      <FormContent {...props} />
    </CustomBlocksFormContext>
  );
}
