import type { Node } from 'slate';

import { Element } from 'slate';

import type { TableCellElement, TableHeaderCellElement } from '@ui/MarkdownEditor/types';

export const rdmdType = 'tableCell';
export const rdmdHeaderType = 'tableHead';
export const type = 'table-cell';
export const isTableCell = (node: Node): node is TableCellElement | TableHeaderCellElement =>
  Element.isElementType(node, type);
export const isTableHeaderCell = (node: Node): node is TableHeaderCellElement =>
  isTableCell(node) && 'header' in node && node.header;
