import type { EditorMouseEventHandler, ReadmeEditor } from '../types';
import type { MouseEventHandler } from 'react';

import * as blocks from './blocks';
import { onMouseOver as imageMenuHandler } from './ImageMenu';

const blockHandlers = Object.values(blocks).reduce((memo, block) => {
  if ('onMouseOver' in block) {
    memo.push(block.onMouseOver as EditorMouseEventHandler);
  }
  return memo;
}, [] as EditorMouseEventHandler[]);

const onMouseOver =
  ({ editor }: { editor: ReadmeEditor }): MouseEventHandler<HTMLDivElement> =>
  event => {
    [imageMenuHandler, ...blockHandlers].find(handler => {
      handler(editor, event);
      return event.isPropagationStopped();
    });
  };

export default onMouseOver;
