import { isCmdOrCtrl } from '@ui/MarkdownEditor/editor/eventUtils';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { toggleFormatOnKeyDown } from '../shared';

import { deserialize } from './serialize';

const type = 'emphasis';

const isCmdI: OnKeyDown = event => isCmdOrCtrl(event) && !event.shiftKey && event.key === 'i';
const cmdI = toggleFormatOnKeyDown(type, isCmdI);

const Emphasis = {
  decorator: '_',
  decoratorAlternate: '*',
  deserialize,
  icon: 'italic',
  onKeyDown: cmdI,
  type,
};

export default Emphasis;
