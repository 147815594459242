import { Editor, Range, Transforms } from 'slate';

import { type as listItemType, isFirstLineOfListItem } from '../ListItem/shared';

import { type } from './shared';

const convertToList = name => editor => {
  const listProps = { type };
  const listItemProps = { type: listItemType };

  if (name === 'check') {
    listItemProps.checked = false;
  } else if (name === 'ordered') {
    listProps.ordered = true;
    listProps.start = 1;
  }

  const startPath = Range.start(editor.selection).path;

  Editor.withoutNormalizing(editor, () => {
    if (isFirstLineOfListItem(editor, startPath)) {
      Transforms.splitNodes(editor, { always: true });
    }

    Transforms.wrapNodes(editor, listProps);
    Transforms.wrapNodes(editor, listItemProps);
  });
};

export const convertToOrderedList = convertToList('ordered');
export const convertToUnorderedList = convertToList('unordered');
export const convertToCheckList = convertToList('check');

export default { convertToOrderedList, convertToUnorderedList, convertToCheckList };
