import type * as rmdxType from '@readme/mdx';

import loadable from '@loadable/component';
import * as rdmd from '@readme/markdown';
import React, { createContext, useContext } from 'react';

import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

const RMDX = loadable.lib(() => import('@readme/mdx'), { ssr: false });

export type RenderingLibrary = typeof rdmd | typeof rmdxType;

interface Props {
  useMDX?: boolean;
}

const RenderingLibraryContext = createContext<RenderingLibrary>(null);

const Fallback = () => (
  <Flex layout="col" style={{ fontSize: '1.5em', height: '4em', marginBottom: 'var(--sm)', paddingTop: 'var(--xs)' }}>
    <Skeleton width="60%" />
  </Flex>
);

const RenderingLibraryProvider = ({ children, useMDX }: React.PropsWithChildren<Props>) => {
  return useMDX ? (
    <RMDX fallback={<Fallback />}>
      {rmdx => <RenderingLibraryContext.Provider value={rmdx}>{children}</RenderingLibraryContext.Provider>}
    </RMDX>
  ) : (
    <RenderingLibraryContext.Provider value={rdmd}>{children}</RenderingLibraryContext.Provider>
  );
};

export const useRenderingLibrary = () => useContext(RenderingLibraryContext);
export default RenderingLibraryProvider;
