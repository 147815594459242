import PropTypes from 'prop-types';
import React from 'react';

import useClassName from '@ui/MarkdownEditor/useClassName';

import leaves from './leaves';
import { Leaf as SlashMenuLeaf } from './slashMenu';
import classes from './style.module.scss';
import { remap } from './utils';

const leafMap = remap(leaves, 'type', { to: 'render' });

const Decoration = ({ leaf, ...props }) => (
  <span className={useClassName(classes.Decoration, leaf.link && classes.Decoration_Link)} {...props} />
);

Decoration.propTypes = {
  leaf: PropTypes.object,
};

const types = {
  ...leafMap,
  emphasis: 'em',
  strong: 'strong',
  decoration: Decoration,
  slashMenu: SlashMenuLeaf,
};

const decorations = leaf => {
  const decorationTypes = Object.entries(types).filter(
    ([type]) => type in leaf && (type === 'decoration' || !leaf.decoration),
  );

  if (decorationTypes.length === 0) {
    decorationTypes.push([null, 'span']);
  }

  return decorationTypes;
};

const renderLeaf = ({ attributes, children, leaf }) => {
  return decorations(leaf).reduce((element, [, Element], idx, array) => {
    const props = {
      ...(typeof Element !== 'string' && { leaf }),
      ...(idx === array.length - 1 && attributes),
    };

    return <Element {...props}>{element}</Element>;
  }, children);
};

export default renderLeaf;
