import startCase from 'lodash/startCase';

import DeleteLeaf from './Delete';
import Emphasis from './Emphasis';
import InlineCode from './InlineCode';
import Link from './Link';
import MdxFlowExpression from './MdxFlowExpression';
import MdxJsxTextElement from './MdxJsxTextElement';
import MdxTextExpression from './MdxTextExpression';
import Strong from './Strong';

const leaves = {
  Delete: DeleteLeaf,
  Emphasis,
  InlineCode,
  MdxFlowExpression,
  MdxJsxTextElement,
  MdxTextExpression,
  Link,
  Strong,
};

Object.entries(leaves).forEach(([name, leaf]) => {
  leaf.type ||= name.charAt(0).toLowerCase() + name.slice(1);
  leaf.name ||= startCase(name);
});

export const Delete = DeleteLeaf;

export { Emphasis, InlineCode, Link, MdxFlowExpression, MdxJsxTextElement, MdxTextExpression, Strong };

export default leaves;
