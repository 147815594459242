import { Editor, Node, Range } from 'slate';

import { isCmdOrCtrl, isShiftKey } from '@ui/MarkdownEditor/editor/eventUtils';
import { toggleFormatOnKeyDown } from '@ui/MarkdownEditor/editor/leaves/shared';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { pointToOffset } from '../../utils';

import { isLink, type } from './shared';

const isCmdShiftK: OnKeyDown = event => isCmdOrCtrl(event) && isShiftKey(event, 'k');
const cmdShiftK = toggleFormatOnKeyDown(type, isCmdShiftK);

const openLinkEditor: OnKeyDown = (event, editor) => {
  if (!editor.selection || Range.isExpanded(editor.selection)) return;
  if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) return;

  const linkEntry = Editor.above(editor, { match: isLink });
  if (!linkEntry) return;

  const offset = pointToOffset(linkEntry, editor.selection.anchor);
  const [link, path] = linkEntry;

  if (offset === Node.string(link).length - 1) {
    event.stopPropagation();
    event.preventDefault();

    const [, dispatch] = editor.linkEditor;
    const ref = Editor.pathRef(editor, path);

    dispatch({ type: 'open', payload: { link, ref, selection: editor.selection } });
  }
};

const onKeyDown = [cmdShiftK, openLinkEditor];

export default onKeyDown;
