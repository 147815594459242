import { Editor, Node, Range, Path, Transforms } from 'slate';

import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { isImage } from '../Image/shared';

import { isFigure } from './shared';

const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey && editor.selection)) return;

  const [, figurePath] = Editor.above(editor, { at: editor.selection.anchor, match: isFigure }) || [];
  if (!figurePath) return;

  // If the caption is currently selected, we handle this behavior in the caption keydown handler
  const [, imagePath] = Editor.above(editor, { at: editor.selection.anchor, match: isImage }) || [];
  if (!imagePath) return;

  // If there's no caption, we handle this behavior in the image keydown handler
  // Note: This only happens if a user has added a captioned image and then
  // removed the caption and hasn't yet saved.
  if (!Node.has(editor, [...figurePath, 1])) return;

  event.preventDefault();
  event.stopPropagation();

  // If image is selected, we move focus to the caption (the node after the image).
  // If the figure is selected, we move focus to the image
  const at = Range.isCollapsed(editor.selection) ? Editor.after(editor, imagePath) || [...figurePath, 1] : imagePath;
  Transforms.select(editor, at);
};

const arrowUp: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey && editor.selection)) return;

  const [, figurePath] = Editor.above(editor, { at: editor.selection.anchor, match: isFigure }) || [];
  const [, imagePath] = Editor.above(editor, { at: editor.selection.anchor, match: isImage }) || [];

  if (!(figurePath && imagePath)) return;

  // If there's no caption, we handle this behavior in the image keydown handler
  // This only happens if a user has added a captioned image and then removed the caption
  // and hasn't yet saved.
  if (!Node.has(editor, [...figurePath, 1])) return;

  event.preventDefault();
  event.stopPropagation();

  // If the figure itself it currently selected and there is a node before, move selection up
  // If the image is selected, select the figure
  const rootPath = editor.selection.anchor.path.slice(0, 1);
  const at =
    Path.hasPrevious(rootPath) && !Range.isCollapsed(editor.selection)
      ? Editor.end(editor, Path.previous(rootPath))
      : figurePath;

  Transforms.select(editor, at);
};

export default [arrowDown, arrowUp];
