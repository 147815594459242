/* eslint-disable block-scoped-var */
// This file is shared between the front and backend

/* Tiny color stuff */
if (!tinycolor) {
  // eslint-disable-next-line global-require, no-var, vars-on-top
  var tinycolor = require('tinycolor2');
}

const { codes: httpStatusCodes } = require('@readme/http-status-codes');
const lodashTransform = require('lodash/transform');

tinycolor.prototype.getLuminosity = function () {
  // https://github.com/harthur/color/blob/master/color.js#L177
  const rgbRaw = this.toRgb();
  const rgb = [rgbRaw.r, rgbRaw.g, rgbRaw.b];

  const lum = [];
  for (let i = 0; i < rgb.length; i += 1) {
    const chan = rgb[i] / 255;
    // eslint-disable-next-line no-restricted-properties, prefer-exponentiation-operator
    lum[i] = chan <= 0.03928 ? chan / 12.92 : Math.pow((chan + 0.055) / 1.055, 2.4);
  }
  return 0.2126 * lum[0] + 0.7152 * lum[1] + 0.0722 * lum[2];
};

(function (exports) {
  // eslint-disable-next-line no-param-reassign
  exports.code_types = function () {
    return [
      ['text', 'Text'],
      ['asp', 'ASP'],
      ['c', 'C'],
      ['coffeescript', 'CoffeeScript'],
      ['clojure', 'Clojure'],
      ['cplusplus', 'C++'],
      ['csharp', 'C#'],
      ['css', 'CSS'],
      ['curl', 'cURL'],
      ['cypher', 'Cypher'],
      ['d', 'D'],
      ['diff', 'Diff'],
      ['dockerfile', 'Dockerfile'],
      ['erlang', 'Erlang'],
      ['go', 'Go'],
      ['graphql', 'GraphQL'],
      ['groovy', 'Groovy'],
      ['handlebars', 'Handlebars'],
      ['haml', 'HAML'],
      ['haxe', 'Haxe'],
      ['html', 'HTML'],
      ['http', 'HTTP'],
      ['java', 'Java'],
      ['javascript', 'JavaScript'],
      ['jinja2', 'Jinja2'],
      ['json', 'JSON'],
      ['jsx', 'JSX'],
      ['julia', 'Julia'],
      ['kotlin', 'Kotlin'],
      ['less', 'LESS'],
      ['liquid', 'Liquid'],
      ['lua', 'Lua'],
      ['markdown', 'Markdown'],
      ['mermaid', 'Mermaid'],
      ['mysql', 'MySQL'],
      ['node', 'Node.js'],
      ['objectivec', 'Objective-C'],
      ['ocaml', 'OCaml'],
      ['perl', 'Perl'],
      ['php', 'PHP'],
      ['pgsql', 'Postgres'],
      ['powershell', 'PowerShell'],
      ['python', 'Python'],
      ['r', 'R'],
      ['ruby', 'Ruby'],
      ['rust', 'Rust'],
      ['sass', 'SASS'],
      ['scala', 'Scala'],
      ['scss', 'SCSS'],
      ['shell', 'Shell'],
      ['smarty', 'Smarty'],
      ['sol', 'Solidity'],
      ['sql', 'SQL'],
      ['stylus', 'Stylus'],
      ['swift', 'Swift'],
      ['toml', 'TOML'],
      ['twig', 'Twig'],
      ['typescript', 'TypeScript'],
      ['xml', 'XML'],
      ['yaml', 'YAML'],
    ];
  };

  // eslint-disable-next-line no-param-reassign
  exports.code_types_obj = () => {
    return Object.fromEntries(exports.code_types());
  };

  // eslint-disable-next-line no-param-reassign
  exports.enterprise_features = {
    js: 'Custom JavaScript',
    landing: 'Landing page design',
    metrics: 'API Metrics',
    migration: 'Content migration',
    multiple: 'Combining multiple projects',
    oauth: 'Customer OAuth 2 Login',
    sso: 'Employee SSO',
    staging: 'Staging Environment',
    translate: 'Translations',
    writing: 'Technical writing',
  };

  // eslint-disable-next-line no-param-reassign
  exports.statusCodes = function (code) {
    const codes = {};

    Object.entries(httpStatusCodes).forEach(([statusCode, [message, success]]) => {
      const status = statusCode.includes('XX') || statusCode === 'default' ? statusCode : parseInt(statusCode, 10);

      codes[status] = [status, message, success ? 'success' : 'error'];
      if (statusCode.includes('XX')) {
        // @todo it's unclear why we're adding this into the object for `*XX` status codes
        codes[status].push(true);
      }
    });

    if (code) return codes[code];
    return Object.values(codes);
  };

  const errorCode = {};

  exports.statusCodes().forEach(status => {
    errorCode[status[1]] = status[0];
  });

  // eslint-disable-next-line no-param-reassign
  exports.statusMessages = function (message) {
    if (message) return errorCode[message];
    return errorCode;
  };

  // new error class to handle valid custom errors that we don't want to send to Sentry
  class ValidError extends Error {
    constructor(message) {
      super(message);
      this.name = 'ValidError';
    }
  }

  // eslint-disable-next-line no-param-reassign
  exports.ValidError = ValidError;

  // eslint-disable-next-line no-param-reassign
  exports.url_admin = function (req) {
    return function () {
      const args = Array.prototype.slice.call(arguments);

      // redirect to enterprise group from a global landing page
      if (req.project?.childrenProjects?.length) {
        return `${req.domainFull}/group/${req.project.subdomain}/settings`;
      }

      if (!args[0].match(/^v[.0-9ab]*$/)) {
        if (req.version) {
          args.unshift(`v${req.version.version}`);
        } else {
          args.unshift(`v${req.project.stable.version}`);
        }
      }
      return `${req.domainFull}/project/${req.project.subdomain}/${args.join('/')}`;
    };
  };

  // Will figure out if things are equal whether they're an OID, or a string
  // eslint-disable-next-line no-param-reassign
  exports.equalOIDs = function (oid1, oid2) {
    const toString = oid => {
      if (typeof oid === 'object' && oid !== null) {
        if (oid._id) {
          return oid._id.toString();
        }
        return oid.toString();
      }
      return oid;
    };

    return toString(oid1) === toString(oid2);
  };

  /**
   * This returns what features the project is using currently (in which plan type)
   *
   * This method is duplicated in `@readme/legacy-angular-tooling/shared` as that package
   * unfortunately cannot import code that has been written in TypeScript.
   *
   * @param {Object} $$project
   * @param {Object} $$versions
   * @param {Object} $$members An object containing the amount of users a project has.
   * @return {Object}
   */
  // eslint-disable-next-line no-param-reassign
  exports.using = function ({ $$project, $$versions, $$members }) {
    const admins = $$members.admin;
    const readonlyUsers = $$members.readOnly;
    return {
      freelaunch: [
        ['API Reference', true],
        ['OAS/Swagger Support', true], // we always support swagger
        ['Up to 3 API Versions', $$versions.length >= 3],
        ['Up to 5 Admin Users', admins >= 5],
        ['24 Hours of Request History', false],
      ],
      developerhub: [
        ['Custom Landing Page & Domain', !!$$project.modules.landing_page || !!$$project.custom_domain],
        ['Guides & Recipes', !!$$project.modules.docs || !!$$project.modules.tutorials],
        ['Changelog & Discussion Forum', !!$$project.modules.changelog || !!$$project.modules.discuss],
        // ['Logo and Color Customization', !!$$project.appearance.colors.highlight],
        ['5 API Versions', $$versions.length >= 5],
        ['10 Admin Users', admins >= 10],
        ['24 Hours of Request History', false],
      ],
      whitelabel: [
        ['Custom CSS', !!$$project.appearance.stylesheet],
        ['Custom HTML', !!$$project.appearance.html_body],
        ['404 URL Redirects', !!$$project.redirects],
        ['Reusable Content', false],
        ['Remove ReadMe Logo', !!$$project.appearance.hide_logo],
        ['Statuspage Integration', false],
        ['Up to 20 API Versions', $$project.versions.length >= 20],
        ['Up to 50 Admin Users', admins >= 50],
        ['Export Metrics Data', false],
        ['24 Hours of Request History', false],
      ],
      enterprise: [
        ['Public and Private APIs', $$project.flags.enterprise],
        ['Custom JavaScript', $$project.flags.enterprise],
        ['Multi Project Management', !!$$project._parent],
        ['Global Search & Navigation', $$project.flags.enterprise],
        ['Staging Environment', !!$$project._parent],
        ['Documentation PDF Exports', $$project.flags.enterprise],
        ['SSO & User Permissions', !!($$project.samlIdps && $$project.samlIdps.length) || readonlyUsers],
        ['Onboarding & Dedicated CSM', $$project.flags.enterprise],
        ['Export Metrics Data', false],
        ['Custom User & Version Limits', $$project.flags.enterprise],
        ['Custom API History', false],
      ],
    };
  };

  /**
   * This function specifies what features each plan has access to.
   *
   * This method is duplicated in `@readme/legacy-angular-tooling/shared` as that package
   * unfortunately cannot import code that has been written in TypeScript.
   *
   * @deprecated Use `getPlanFeature` in `@readme/iso` instead.
   * @param  {String} plan       A type of plan. One of free|startup|business|startup2018.
   * @param  {String} [permission] A feature. One of members|landing_page|stylesheet|javascript.
   * @return {?}                 Varies depending on how many parameters are passed down.
   */
  // eslint-disable-next-line no-param-reassign
  exports.permissions = function (plan, permission) {
    /**
     * IF YOU NEED TO MAKE CHANGES TO THIS METHOD,
     * PLEASE MAKE SURE TO UPDATE THE ALTERNATE TOO:
     * @alternate packages/iso/src/project/index.ts
     * @alternate packages/legacy-angular-tooling/shared.js
     */
    const permissions = {
      free: {
        // NOTE: If we add this tier back in, make sure we only
        // allow trial accounts to have teammates (see getAllRegularProjects)
        name: 'Inactive Project',
        members: 0,
        is_active: true,
        cost: 0,
        createdAt: new Date('January 1, 2010'),
        endDate: undefined,

        versions: 1,
        api_access: true,
        custom_domain: false,
        custom_pages: false,
        whitelabel: false,
        errors: false,
        password: false,
        glossary: false,
        landing_page: false,
        pdf: false,

        usability: false,
        stylesheet: false,
        javascript: false,
        html: false,
        extra_html: false,
        user_analytics: false,
        doc_metrics: false,
        variables: false,
        jwt: true,

        changelogs: false,
        reference: true,
        guides: false,

        admins: false,
        healthCheck: false,

        metricsExports: false,
        owlbotExports: false,
        // enabled during free trial, disabled at freelaunch
        reusableContent: true,
      },
      startup: {
        name: 'Startup',
        members: 10,
        is_active: true,
        cost: 59,
        createdAt: new Date('January 1, 2010'),
        endDate: new Date('August 28, 2018'),

        versions: 10000,
        api_access: true,
        custom_domain: true,
        custom_pages: true,
        whitelabel: false,
        errors: false,
        password: true,
        glossary: true,
        landing_page: true,
        pdf: false,

        usability: true,
        stylesheet: true,
        javascript: false,
        html: true,
        extra_html: false,
        user_analytics: false,
        doc_metrics: true,
        variables: true,
        jwt: true,

        changelogs: true,
        reference: true,
        guides: true,

        admins: true,
        healthCheck: false,

        metricsExports: false,
        owlbotExports: false,
        reusableContent: false,
      },
      business: {
        name: 'Business',
        is_active: true,
        members: 1000,
        cost: 199,
        createdAt: new Date('January 1, 2010'),
        endDate: new Date('August 28, 2018'),

        versions: 10000,
        api_access: true,
        custom_domain: true,
        custom_pages: true,
        whitelabel: true,
        errors: true,
        password: true,
        glossary: true,
        landing_page: true,
        pdf: true,

        usability: true,
        stylesheet: true,
        javascript: true,
        html: true,
        extra_html: true,
        user_analytics: true,
        doc_metrics: true,
        variables: true,
        jwt: true,

        changelogs: true,
        reference: true,
        guides: true,

        admins: true,
        healthCheck: true,
        metricsExports: true,
        owlbotExports: true,
        reusableContent: true,
      },
    };

    permissions.freelaunch = {
      ...permissions.free,
      admins: true,
      cost: 0,
      createdAt: new Date('May 4, 2020'),
      endDate: undefined,
      members: 5,
      name: 'Free',
      reusableContent: false,
      stylesheet: false,
      versions: 3,
    };

    permissions.startup2018 = {
      ...permissions.startup,
      cost: 99,
      createdAt: new Date('August 28, 2018'),
      endDate: undefined,
      stylesheet: false,
    };

    permissions['startup-annual-2024'] = {
      ...permissions.startup,
      cost: 948,
      createdAt: new Date('March 27, 2024'),
      endDate: undefined,
      stylesheet: false,
    };

    permissions.business2018 = {
      ...permissions.business,
      cost: 399,
      createdAt: new Date('August 28, 2018'),
      endDate: undefined,
      javascript: false,
      metricsExports: true,
      owlbotExports: true,
    };

    permissions['business-annual-2024'] = {
      ...permissions.business,
      cost: 4188,
      createdAt: new Date('March 27, 2024'),
      endDate: undefined,
      javascript: false,
      metricsExports: true,
      owlbotExports: true,
    };

    permissions.enterprise = {
      ...permissions.business,
      cost: 0,
      createdAt: new Date('January 1, 2010'),
      endDate: undefined,
      javascript: true,
      members: 10000,
      metricsExports: true,
      name: 'Enterprise',
      owlbotExports: true,
    };

    permissions.opensource = {
      ...permissions.startup,
      cost: 0,
      createdAt: new Date('January 1, 2010'),
      custom_domain: false,
      endDate: undefined,
      is_active: true,
      name: 'Open Source',
      password: false,
    };

    if (permission) {
      return permissions[plan][permission];
    } else if (plan) {
      return permissions[plan];
    }

    return permissions;
  };

  // eslint-disable-next-line no-param-reassign
  exports.hasCommonEmailDomain = domains => {
    const commonEmailDomains = [
      'gmail.com',
      'yahoo.com',
      'live.com',
      'hotmail.com',
      'aol.com',
      'icloud.com',
      'baidu.com',
      'yandex.com',
    ];
    const matches = domains.filter(domain => !commonEmailDomains.includes(domain));
    return matches.length !== domains.length;
  };

  (function () {
    // slug, full name, site display
    // Not JSON to preserve order
    const links = [
      ['home', 'Home', 'Home'],
      ['docs', 'Guides', 'Guides'],
      ['discuss', 'Discussions', 'Discussions'],
      ['changelog', 'Changelog', 'Changelog'],
      ['search', 'Search Box', ''],
      ['url', 'Link URL', ''],
      ['custompage', 'Custom Page', ''],
      ['user', 'User Controls', ''],
      ['reference', 'API Reference', 'API Reference'],
      ['recipes', 'Recipes', 'Recipes'],
    ];

    const linksJson = lodashTransform(links, (result, data) => {
      // eslint-disable-next-line no-param-reassign
      result[data[0]] = [data[1], data[2]];
    });

    // eslint-disable-next-line no-param-reassign
    exports.links = function (key) {
      if (key) return linksJson[key];
      return links;
    };
  })();

  (function () {
    // slug, full name, site display
    // Not JSON to preserve order
    const buttons = [
      ['', 'No Button', 'No Button'],
      ['get-started', 'Get Started', 'Get Started'],
      ['docs', 'Guides', 'Guides'],
      ['discuss', 'Discussions', 'Discussions'],
      ['changelog', 'Changelog', 'Changelog'],
      ['reference', 'API Reference', 'API Reference'],
      ['recipes', 'Recipes', 'Recipes'],
    ];

    const buttonsJson = lodashTransform(buttons, (result, data) => {
      // eslint-disable-next-line no-param-reassign
      result[data[0]] = [data[1], data[2]];
    });

    // eslint-disable-next-line no-param-reassign
    exports.buttons = function (key) {
      if (key) return buttonsJson[key];
      return buttons;
    };
  })();

  /* Generate Header CSS */
  (function () {
    // eslint-disable-next-line no-param-reassign
    exports.getLuminosity = function (appearance) {
      const main = appearance.colors.main || appearance.logo[4];
      const lum = tinycolor(main).getLuminosity();
      if (lum > 0.6) {
        return 'light';
      }
      if (lum < 0.03) {
        return 'dark';
      }
      return 'normal';
    };
  })();
})(typeof exports === 'undefined' ? (this.shared = {}) : exports);
