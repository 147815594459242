import apply from './apply';
import isInline from './isInline';
import isVoid from './isVoid';
import normalizeNode from './normalizeNode';
import standardHandler from './standardHandler';

const plugins = {
  apply,
  deleteBackward: standardHandler('deleteBackward'),
  deleteFragment: standardHandler('deleteFragment'),
  deleteForward: standardHandler('deleteForward'),
  insertText: standardHandler('insertText'),
  isInline,
  isVoid,
  normalizeNode,
};

export default plugins;
