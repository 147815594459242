import type { RenderElementProps } from 'slate-react';

import React, { useCallback, useEffect } from 'react';
import { Node, Transforms } from 'slate';
import { useSelected, useSlateStatic, ReactEditor } from 'slate-react';

const Figcaption = ({ children, attributes, element }: RenderElementProps) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const empty = Node.string(element) === '';

  const close = useCallback(() => {
    // @xxx: I don't know what's going on, it seems like there's a race
    // condition?! Calling `removeNodes` was updating `editor.children` correctly,
    // but the final `editor.children` that gets renderd doesn't include the
    // change?!
    window.requestAnimationFrame(() => {
      if (!selected && empty) {
        Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor, element) });
      }
    });
  }, [editor, element, empty, selected]);

  useEffect(close, [close]);

  return (
    <figcaption {...attributes}>
      {!!empty && (
        <span
          data-slate-placeholder={true}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            width: '100%',
            maxWidth: '100%',
            display: 'block',
            opacity: '0.333',
            userSelect: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            paddingTop: '2px',
          }}
        >
          Write a caption…
        </span>
      )}
      {children}
    </figcaption>
  );
};

export default Figcaption;
