import { Editor, Element, Node, Path } from 'slate';

import type {
  ListItemElement,
  ListItemMdNode,
  OrderedListElement,
  TaskListElement,
  UnorderedListElement,
} from '@ui/MarkdownEditor/types';

import { isParagraph } from '../Paragraph/shared';

export const rdmdType = 'listItem' as ListItemMdNode['type'];
export const type = 'list-item' as ListItemElement['type'];
export const isListItem = (node: Node): node is ListItemElement => Element.isElementType(node, type);
export const isTaskListItem = (node: Node): node is TaskListElement => isListItem(node) && 'checked' in node;
export const isOnlyListItem = (node: Node): node is OrderedListElement | UnorderedListElement =>
  isListItem(node) && !isTaskListItem(node);
export const defaultListItemProps = {
  type,
  spread: false,
};

export const isFirstLineOfListItem = (editor: Editor, path: Path) => {
  const blockEntry = Editor.above(editor, { at: path, match: n => Editor.isBlock(editor, n) });
  if (!blockEntry) return false;
  const [block, blockPath] = blockEntry;

  const aboveThat = Node.parent(editor, blockPath);

  return isParagraph(block) && isListItem(aboveThat) && !Path.hasPrevious(blockPath);
};
