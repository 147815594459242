import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

const Footer = ({ onClick, text }) => (
  <MenuItem className={classes['MenuDropdown-Footer']} color="red" icon="icon-trash-2" onClick={onClick}>
    {text}
  </MenuItem>
);

Footer.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Footer;
