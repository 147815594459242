import TableCell from '../TableCell';
import TableHeader from '../TableHeader';

import { type } from './shared';

export const deserialize = ({ position, ...node }, deeper) => {
  const cols = Math.max(...node.children.map(r => r.children.length));
  const align =
    node.align.length < cols
      ? [...new Array(cols)].map(i => (i < node.align.length ? node.align[i] : 'left'))
      : node.align.slice(0, cols);

  const n = {
    ...node,
    type,
    align,
    children: node.children.map((row, i) => {
      return {
        ...row,
        ...(i === 0 && { type: TableHeader.type }),
        children: Array.from({ length: cols }, (_, j) => {
          const cell = node.children[i].children[j] || {};

          return {
            type: TableCell.type,
            align: align[j],
            children: [{ type: 'text', value: '' }],
            ...cell,
          };
        }),
      };
    }),
  };

  return {
    ...n,
    children: deeper(n),
  };
};

export const serialize = (node, deeper) => {
  const table = {
    ...node,
    type: 'table',
    children: deeper(node),
  };

  return table;
};
