import type { XYCoord } from 'react-dnd';
import type { Node } from 'slate';

import { Element } from 'slate';

import type { CodeElement, CodeTabsElement } from '@ui/MarkdownEditor/types';

export const type = 'code-tabs';
export const isCodeTabs = (node: Node): node is CodeTabsElement => Element.isElementType(node, type);
export const tabId = 'code-tab';

export const IDS = new WeakMap<CodeElement, string>();

// Largely copied from the examples: https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/04-sortable/simple?from-embed=&file=/src/Card.tsx
export const isPastThreshold = (
  clientOffset: XYCoord | null,
  hoverBoundingRect: DOMRect | undefined,
  { left }: { left: boolean },
) => {
  if (!clientOffset || !hoverBoundingRect) return false;

  const hoverWidth = hoverBoundingRect.right - hoverBoundingRect.left;
  const hoverClientX = clientOffset.x - hoverBoundingRect.left;

  return left ? hoverClientX < hoverWidth * 0.75 : hoverClientX > hoverWidth * 0.25;
};
