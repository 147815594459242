import type { BaseSelection, Location } from 'slate';

import { Transforms, Editor } from 'slate';

import { type, isGlossaryTerm } from './shared';

export const insertGlossaryTerm = (
  editor: Editor,
  term: string,
  { at = editor.selection }: { at?: BaseSelection | Location } = {},
) => {
  if (!at) return;

  const existingGlossaryTermEntry = Editor.above(editor, { at, match: isGlossaryTerm });

  if (existingGlossaryTermEntry) {
    const [, path] = existingGlossaryTermEntry;

    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, { at: path });
      Transforms.insertNodes(editor, { type, children: [{ text: term }] }, { at: path });
      Transforms.select(editor, Editor.end(editor, path));
    });
  } else {
    Transforms.insertNodes(editor, { type, children: [{ text: term }] }, { at, select: true });
  }
};
