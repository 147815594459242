import Component from './Component';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { rdmdType, type, isRecipe } from './shared';
import slashMenu from './slashMenu';

export default {
  deserialize,
  isRecipe,
  isVoid: true,
  operations,
  Component,
  serialize,
  slashMenu,
  rdmdType,
  type,
};
