import { Editor, Point, Range, Transforms } from 'slate';

import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { isTable } from './shared';

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && editor.selection && Range.isCollapsed(editor.selection))) return;

  const before = Editor.before(editor, editor.selection.anchor);
  const tableEntry = Editor.above(editor, { at: before, match: isTable });
  if (!before || !tableEntry) return;

  const [, tablePath] = tableEntry;
  if (!Point.equals(before, Editor.end(editor, tablePath))) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, before);
};

export default [backspace];
