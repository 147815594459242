import type { ReadmeEditor } from '../types';
import type { Node, Editor } from 'slate';

import { Transforms } from 'slate';

export const remove = (editor: Editor) => {
  // eslint-disable-next-line no-plusplus
  for (let i = editor.children.length - 1; i >= 0; i--) {
    Transforms.removeNodes(editor, { at: [i] });
  }
};

export const replace = (editor: ReadmeEditor, next: Node[]) => {
  remove(editor);
  Transforms.insertFragment(editor, next, { at: [] });
};
