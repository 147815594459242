import type { Path } from 'slate';

import * as RDMD from '@readme/markdown';
import { Editor, Text, Transforms } from 'slate';

import type { ReadmeEditor, ReadmeElement } from '@ui/MarkdownEditor/types';

import { isListItem } from '../ListItem/shared';
import { isParagraph, type as paragraphType } from '../Paragraph/shared';

import { type } from './shared';

export const convertToBlockquote = (editor: ReadmeEditor, attrs = {}) => {
  const entry = Editor.above(editor, {
    match: node => Editor.isBlock(editor, node as ReadmeElement),
  });

  if (!entry) return;
  const [block, path] = entry;
  const node = { type, ...attrs } as ReadmeElement;

  if (isListItem(block)) {
    Editor.withoutNormalizing(editor, () => {
      Transforms.wrapNodes(editor, { type: paragraphType } as ReadmeElement, {
        match: n => Text.isText(n) || (!Editor.isEditor(n) && editor.isInline(n)),
      });
      Transforms.wrapNodes(editor, node, { match: isParagraph });
    });
  } else {
    Transforms.wrapNodes(editor, node, { at: path });
  }
};

type calloutTypes = 'error' | 'info' | 'okay' | 'warn';

export const convertToCallout = (editor: ReadmeEditor, calloutType: calloutTypes) => {
  const icon = RDMD.utils.calloutIcons[calloutType][0];

  convertToBlockquote(editor, { icon, empty: false });
};

export const convertCalloutIcon = (editor: ReadmeEditor, nameOrIcon: string, path: Path) => {
  const icon = RDMD.Owlmoji.nameToEmoji[nameOrIcon] ?? nameOrIcon;

  Transforms.setNodes(editor, { icon }, { at: path });
};

export const convertToCalloutError = (editor: ReadmeEditor) => convertToCallout(editor, 'error');
export const convertToCalloutInfo = (editor: ReadmeEditor) => convertToCallout(editor, 'info');
export const convertToCalloutSuccess = (editor: ReadmeEditor) => convertToCallout(editor, 'okay');
export const convertToCalloutWarning = (editor: ReadmeEditor) => convertToCallout(editor, 'warn');

export default {
  convertToBlockquote,
  convertToCallout,
  convertToCalloutError,
  convertToCalloutInfo,
  convertToCalloutSuccess,
  convertToCalloutWarning,
  convertCalloutIcon,
};
