import type { $TSFixMe } from '@readme/iso';
import type { Link } from 'mdast';
import type { NodeEntry } from 'slate';

import { Editor, Element, Path, Node, Text } from 'slate';

import { offsetToPoint } from '@ui/MarkdownEditor/editor/utils';
import type { LinkElement, ReadmeEditor, ReadmeNode } from '@ui/MarkdownEditor/types';

import { create as mdCharCreate, isMarkdownCharOf } from '../MarkdownChar/shared';

export const LINK_REF_TYPE = 'linkReference';
export const LINK_TYPE = 'link';
export const MDAST_TYPES = [LINK_REF_TYPE, LINK_TYPE];
export const LINK_ICON = '🔗';
export const LINK_ICON_REGEX = new RegExp(`\\(${LINK_ICON}\\)?$`);

export const type = LINK_TYPE as LinkElement['type'];

export const isLink = (node: ReadmeNode): node is LinkElement => Element.isElementType(node, type);
export const isLinkEntry = (entry: NodeEntry<ReadmeNode>): entry is NodeEntry<LinkElement> => {
  return isLink(entry[0]) && Path.isPath(entry[1]);
};

export const toString = (node: ReadmeNode, { title = false } = {}) => {
  if (!isLink(node)) return Node.string(node);

  let string = [...Node.nodes(node)].reduce((str, [n]) => {
    if (Text.isText(n)) {
      return str + n.text;
    } else if (isMarkdownCharOf(n, LINK_ICON)) {
      return str + node.url;
    }
    return str;
  }, '');

  if (title && node.title) {
    string = string.replace(/\)$/, ` "${node.title}")`);
  }

  return string;
};

export const lengthOf = (node: LinkElement, offset = -1) => {
  const string = Node.string(node).slice(0, offset);

  return string.match(LINK_ICON_REGEX) ? string.length + node.url.length - LINK_ICON.length : string.length;
};

export const getUrlLocation = (nodeEntry: NodeEntry<LinkElement>) => {
  const [node] = nodeEntry;

  return {
    anchor: offsetToPoint(nodeEntry, (node?.label || '').length + 3),
    focus: offsetToPoint(nodeEntry, Node.string(node).length - 1),
  };
};

export const getUrlPortion = (editor: ReadmeEditor, nodeEntry: NodeEntry<LinkElement>) => {
  const at = getUrlLocation(nodeEntry);

  return Editor.string(editor, at);
};

export const defaultLink = ({ url = '', title, ...rest }: Link | Partial<LinkElement>): LinkElement => {
  const label = 'label' in rest && rest.label ? rest.label : '';
  const labelChildren = rest.children ?? [{ text: label }];

  const children = [
    { text: '[' },
    ...labelChildren,
    { text: '](' },
    url ? mdCharCreate(LINK_ICON) : [],
    { text: ')' },
  ].flat() as $TSFixMe;

  const link: LinkElement = {
    type,
    url,
    label,
    title,
    children,
  };

  return link;
};
