import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import { Editor, Path, Point, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { Link } from '@ui/MarkdownEditor/editor/blocks';
import leaves from '@ui/MarkdownEditor/editor/leaves';
import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';
import { contains, offsetToRange, remap } from '@ui/MarkdownEditor/editor/utils';

import decorate from './decorate';

const decorators = remap(leaves, 'type', { to: 'decorator' });

const nonInclusiveIncludes = (range, location) => {
  if (Point.isPoint(location)) {
    return Point.isAfter(location, Range.start(range)) && Point.isBefore(location, Range.end(range));
  } else if (Range.isRange(location)) {
    return nonInclusiveIncludes(range, location.anchor) && nonInclusiveIncludes(range, location.focus);
  }

  return false;
};

const isContiguous = (editor, decorations) => {
  return decorations.every((prevDec, i) => {
    const nextDec = decorations[i + 1];

    if (!nextDec) return true;
    if (Point.equals(prevDec.focus, nextDec.anchor)) return true;
    if (
      !Path.equals(prevDec.focus.path, nextDec.anchor.path) &&
      Point.equals(Editor.after(editor, prevDec.focus), nextDec.anchor)
    ) {
      return true;
    }
    return false;
  });
};

const linkRange = editor => {
  const link = Editor.above(editor, { match: Link.isLink });
  if (!link) return;

  const range = Editor.range(editor, link[1]);

  if (contains(range, editor.selection)) {
    // eslint-disable-next-line consistent-return
    return {
      ...range,
      link: true,
    };
  }
};

const decorations = editor => {
  if (acrossBlocks(editor.selection)) return [];

  const block = Editor.above(editor, {
    at: editor.selection.anchor,
    match: b => Editor.isBlock(editor, b),
  });

  if (!block) return [];

  const decs = decorate(editor, block, { at: editor.selection });
  const range = linkRange(editor);
  const textOnlyDecs = Range.isCollapsed(editor.selection)
    ? decorate(editor, Editor.leaf(editor, editor.selection))
    : [];

  return [...decs, range, ...textOnlyDecs].filter(x => x);
};

export const getActiveFormats = (editor, ranges = decorations(editor)) => {
  const decorationsByType = ranges.reduce((chunked, decoration) => {
    if (decoration.decoration) return chunked;

    Object.keys(decorators).forEach(decorator => {
      if (!(decorator in decoration)) return;

      if (chunked[decorator]) {
        chunked[decorator].push(decoration);
      } else {
        chunked[decorator] = [decoration];
      }
    });

    return chunked;
  }, {});

  const byType = Object.entries(decorationsByType).reduce((acc, [type, decs]) => {
    if (!isContiguous(editor, decs)) return acc;

    const fullRange = { anchor: decs[0].anchor, focus: decs[decs.length - 1].focus };

    return contains(fullRange, editor.selection) ? { ...acc, [type]: decs } : acc;
  }, {});

  return byType;
};

const focusEditor = editor => {
  try {
    ReactEditor.focus(editor);
  } catch (e) {
    // @note: for testing with jsdom 🙁
  }
};

const typeOf = decoration => Object.keys(decorators).find(d => decoration[d]);

// @todo: the following are known issues
//
// * https://linear.app/readme-io/issue/RM-2452/handle-intra-word-italics-via-the-inline-toolbar
// * https://linear.app/readme-io/issue/RM-2462/when-inserting-formatting-via-the-inline-toolbar-it-should-handle
//
// For refactoring I want to try leaning on RDMD more. Instead of calculating
// what strings to edit ourselves, we could transform the mdast, serialize that
// to markdown, then calculate the string diffs from that.
const setFormat = (editor, type) => {
  if (type === Link.type) {
    Link.toggleLink(editor);
    return;
  }

  const { selection } = editor;
  const ranges = decorations(editor)
    .reverse()
    .map(offset => ({ ...offset, ...offsetToRange(editor, offset) }))
    .filter(range => Range.includes(selection, range));

  const [ofType, others] = partition(ranges, range => range[type]);

  const intersectsStart = ofType.some(range => Range.includes(range, Range.start(selection)));
  const intersectsEnd = ofType.some(range => Range.includes(range, Range.end(selection)));

  const otherIntersectsStart = others.filter(range => nonInclusiveIncludes(range, Range.start(selection)));
  const otherIntersectsEnd = others.filter(range => nonInclusiveIncludes(range, Range.end(selection)));

  const otherTypesToRemove = keyBy([...otherIntersectsStart, ...otherIntersectsEnd], typeOf);
  const toRemove = [...ofType, ...others.filter(range => typeOf(range) in otherTypesToRemove)]
    .filter(range => range.decoration)
    .sort((left, right) => Point.compare(right.anchor, left.anchor));

  const toInsertEnd = otherIntersectsEnd.filter(range => !range.decoration).map(range => decorators[typeOf(range)]);
  if (!intersectsEnd) toInsertEnd.push(decorators[type]);

  const toInsertStart = otherIntersectsStart.filter(range => !range.decoration).map(range => decorators[typeOf(range)]);
  if (!intersectsStart) toInsertStart.unshift(decorators[type]);

  const distance = toInsertEnd.reduce((sum, decorator) => sum + decorator.length, 0);

  Editor.withoutNormalizing(editor, () => {
    toInsertEnd.forEach(decorator => Transforms.insertText(editor, decorator, { at: Range.end(selection) }));
    toRemove.forEach(range => Transforms.delete(editor, { at: range }));
    toInsertStart.forEach(decorator => Transforms.insertText(editor, decorator, { at: Range.start(selection) }));

    if (Range.isCollapsed(editor.selection)) {
      Transforms.move(editor, { distance, reverse: true });
    } else {
      Transforms.move(editor, { distance, edge: 'focus', reverse: true });
    }
  });

  focusEditor(editor);
};

const unsetFormat = (editor, type) => {
  let ranges = decorations(editor);
  if (!getActiveFormats(editor, ranges)[type]) return;

  if (type === Link.type && ranges[0]?.[Link.type]) {
    const [, dispatch] = editor.linkEditor;
    const [link, path] = Editor.above(editor, { match: Link.isLink });
    const ref = Editor.pathRef(editor, path);

    dispatch({ type: 'open', payload: { link, ref, selection: editor.selection } });
    return;
  }

  ranges = ranges.reverse();
  const { selection } = editor;

  const startInsideDecorator = ranges.find(range => range.decoration && Range.includes(range, Range.start(selection)));
  const endInsideDecorator = ranges.find(range => range.decoration && Range.includes(range, Range.end(selection)));

  let distance = 0;

  if (!endInsideDecorator) {
    distance += decorators[type].length;
    Transforms.insertText(editor, decorators[type], { at: Range.end(selection) });
  } else {
    Transforms.delete(editor, { at: ranges.find(range => range.decoration && range[type]) });
  }

  if (!startInsideDecorator) {
    Transforms.insertText(editor, decorators[type], { at: Range.start(selection) });
  } else {
    Transforms.delete(editor, { at: ranges.reverse().find(range => range.decoration && range[type]) });
  }

  Transforms.move(editor, { distance, edge: 'focus', reverse: true });

  focusEditor(editor);
};

const toggle = (editor, type, active = getActiveFormats(editor, decorations(editor))[type]) =>
  active ? unsetFormat(editor, type) : setFormat(editor, type);

export default toggle;
