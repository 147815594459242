import React, { memo, useEffect, useCallback, useRef, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';
import { useSlateStatic, ReactEditor } from 'slate-react';

import Box from '@ui/Box';
import { Emoji } from '@ui/MarkdownEditor/editor/blocks';
import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import Menu from '@ui/Menu';

import EmojiMenuItems from './EmojiMenuItems';
import classes from './style.module.scss';
import { useEmojiMenu } from './useEmojiMenu';

const EmojiMenu = () => {
  const editor = useSlateStatic();
  const [{ filtered, open, rangeRef, selected, target }, dispatch] = useEmojiMenu();
  const results = filtered;
  const menuRef = useRef();
  const listRef = useRef();

  // calculate menu list height
  const listItemSize = 30;
  const listHeight = (results.length > 5 ? 5 : results.length) * listItemSize;

  // insert emoji and close menu
  const onClick = useCallback(
    ({ name }) => {
      ReactEditor.focus(editor);
      if (rangeRef) {
        Emoji.operations.insertEmoji(editor, name, { at: rangeRef.current });
      }

      dispatch({ type: MenuActionTypes.close });
    },
    [dispatch, editor, rangeRef],
  );

  // scroll to item on keyboard up/down
  useEffect(() => {
    if (!listRef?.current || !open) return;

    listRef.current.scrollToItem(selected);
  }, [listRef, open, selected]);

  // scroll to top of menu
  useEffect(() => {
    if (!menuRef?.current || !open) return;

    listRef.current.scrollTo(0);
  }, [listRef, open]);

  // close menu on no results
  useEffect(() => {
    if (!results.length) dispatch({ type: MenuActionTypes.close });
  }, [dispatch, open, results.length]);

  const VirtualListItem = useMemo(
    () =>
      // eslint-disable-next-line react/display-name, react/prop-types
      ({ index, style }) => (
        <EmojiMenuItems name={results[index].name} onClick={onClick} selected={selected === index} style={style} />
      ),
    [onClick, results, selected],
  );

  return results.length ? (
    <MenuDropdown className={`${classes.EmojiMenu} EmojiMenu`} open={open} target={target}>
      <Box className={classes['EmojiMenu-Container']} kind="pop">
        <Menu ref={menuRef} className={classes['EmojiMenu-Menu']} data-testid="emoji-menu" role="menu">
          <VirtualList
            ref={listRef}
            height={listHeight}
            itemCount={results.length}
            itemSize={listItemSize}
            overscanCount={20}
            width="100%"
          >
            {VirtualListItem}
          </VirtualList>
        </Menu>
      </Box>
    </MenuDropdown>
  ) : null;
};

export default memo(EmojiMenu);
