import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Editor } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const openLinkEditor = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const [, dispatch] = editor.linkEditor;
      const path = ReactEditor.findPath(editor, element);
      const ref = Editor.pathRef(editor, path);

      dispatch({ type: 'open', payload: { link: element, ref, selection: editor.selection } });
    },
    [editor, element],
  );

  const props = {};
  if (element.url) props.href = element.url;
  if (element.title) props.title = element.title;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <span {...attributes} onClick={openLinkEditor} role="button">
      {children}
    </span>
  );
};

Link.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
};

export default Link;
