import React from 'react';

import Box from '@ui/Box';
import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';

import classes from './style.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  icon: JSX.Element | string;
  id?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  testId?: string;
  text?: string;
};

export const SettingsButton = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  return (
    <Button ref={ref} className={classes['EmptyBlock-settings-button']} kind="minimum" outline size="sm" {...props}>
      <i className="icon-settings" />
    </Button>
  );
});
SettingsButton.displayName = 'SettingsButton';

const EmptyBlock = ({ icon, text, testId, children, ...props }: Props) => {
  const iconComponent = typeof icon === 'string' ? <i className={icon} /> : icon;

  return (
    <Box className={classes.EmptyBlock} contentEditable={false} data-testid={testId} kind="rule" {...props}>
      {children || <SettingsButton />}
      <span className={classes.EmptyBlock_message}>
        {text}
        {iconComponent}
      </span>
    </Box>
  );
};

export default EmptyBlock;
