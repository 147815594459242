import * as RDMD from '@readme/markdown';
import * as RMDX from '@readme/mdx';

const blocks = [
  'atxHeading',
  'blockquote',
  // 'definition',
  'fencedCode',
  'footnote',
  'html',
  'indentedCode',
  'list',
  'setextHeading',
  'recipe',
  'table',
  'thematicBreak',
];
const rdmdBlocks = ['compactHeading', 'magicBlocks', 'callout', 'CodeTabs', 'embed'];

const inlines = [
  // 'autoLink',
  // 'code', <- required so we don't parse other inlines within it
  'deletion',
  'emphasis',
  // 'escape', <- enabling this, as we should always be parsing escapes, duh
  'figure',
  // 'html',
  'link',
  // 'reference',
  'strong',
  // 'url',
];
const rdmdInlines = ['gemoji'];

const inlinesAsNode = ['link', 'variable', 'emoji'];
const disabledBlocks = ['embed', 'footnote'];
// @note: We special case these so we can read the original doc to see what
// formatting mark ('*' or '_') was used. `remark` doesn't have this
// information.
const emphasis = ['emphasis', 'strong'];

export const disableTokenizers = {
  decorator: {
    disableTokenizers: {
      inline: [...inlinesAsNode, ...rdmdInlines, 'html'],
      block: [...blocks, ...rdmdBlocks],
    },
  },
  deserializer: {
    disableTokenizers: {
      inline: ['url', 'autoLink', ...[...inlines].filter(i => !inlinesAsNode.includes(i) && !emphasis.includes(i))],
      block: disabledBlocks,
    },
  },
  serializer: {
    disableTokenizers: {
      block: [...blocks, rdmdBlocks],
    },
  },
};

export const decorator = (md, useMDX = false) => {
  if (!useMDX) {
    return RDMD.mdast(md, { ...disableTokenizers.decorator });
  }

  try {
    return RMDX.mdast(md);
  } catch (e) {
    return null;
  }
};

export const deserializer = (md, opts = {}, useMDX = false) => {
  if (!useMDX) {
    return RDMD.mdast(md, {
      ...opts,
      alwaysThrow: true,
      ...disableTokenizers.deserializer,
      normalize: false,
    });
  }

  try {
    return RMDX.mdast(md, opts);
  } catch (e) {
    return null;
  }
};

function* mdastNodes(node) {
  yield node;
  if (!node?.children) return;

  for (const child of node.children) {
    yield* mdastNodes(child);
  }
}

export function* getNodes(ast, fn) {
  for (const node of mdastNodes(ast)) {
    if (node && fn(node)) {
      yield node;
    }
  }
}

export const getNode = (ast, fn) => {
  for (const node of mdastNodes(ast)) {
    if (node && fn(node)) {
      return node;
    }
  }

  return null;
};
