import type { RenderLeafProps } from 'slate-react';

import React from 'react';

import type { FormattedText } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

const type = 'mdxFlowExpression';

type Props = RenderLeafProps & {
  leaf: FormattedText & {
    range: Range;
    skipMenu?: boolean;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MdxFlowExpression = ({ leaf, ...props }: Props) => (
  <code className={classes.MdxExpression} spellCheck={false} {...props} />
);

const mdxFlowExpression = {
  type,
  render: MdxFlowExpression,
};

export default mdxFlowExpression;
