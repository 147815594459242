import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isReusableContent } from './shared';
import slashMenu from './slashMenu';

export default {
  Component,
  deserialize,
  is: isReusableContent,
  isReusableContent,
  isVoid: true,
  normalizeNode,
  onKeyDown,
  operations,
  serialize,
  slashMenu,
  type,
};
