import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Transforms, Editor } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Flex from '@ui/Flex';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';
import Select from '@ui/Select';

import { isFigure } from '../blocks/Figure/shared';
import menuClasses from '../style.module.scss';

import classes from './style.module.scss';
import useImageMenu from './useImageMenu';

const SIZE_OPTIONS = [
  { label: 'px', value: 1 },
  { label: '% ', value: 2 },
  { label: 'em', value: 3 },
];

const ALIGNMENT_OPTIONS = [
  { label: 'left', value: 1 },
  { label: 'center', value: 2 },
  { label: 'right', value: 3 },
];

export const ResizeEditor = () => {
  const editor = useSlateStatic();
  const [{ node }, dispatch] = useImageMenu();

  const [size, setSize] = useState(node?.width?.slice(0, (node.width?.length || 0) - 2) || '');
  const [sizeUnit, setSizeUnit] = useState(node?.width?.slice(-2) || 'px');
  const [align, setAlignment] = useState(node?.align || (node?.border || node?.width ? 'center' : 'left'));

  const defaultSizeValue = useMemo(
    () => SIZE_OPTIONS.find(opt => opt.label === sizeUnit)?.value?.toString(),
    [sizeUnit],
  );

  const defaultAlignValue = useMemo(
    () => ALIGNMENT_OPTIONS.find(opt => opt.label === align)?.value?.toString(),
    [align],
  );

  // Make sure sizes are appropriately reset when we move back and forth between images
  useEffect(() => {
    if (node) {
      setSize(node.width?.slice(0, (node.width?.length || 0) - 2) || '');
      setSizeUnit(node.width?.slice(-2) || 'px');
      setAlignment(node.align || (node.border || node.width ? 'center' : 'left'));
    }
  }, [node]);

  const submit = useCallback(
    ({ inputUnit = null, inputSize = null }) => {
      if (!node) return;

      const path = ReactEditor.findPath(editor, node);
      if (!path) return;

      if (inputSize === '') {
        Transforms.unsetNodes(editor, 'width', { at: path });
        return;
      }

      const s = inputSize || size || '';
      const u = inputUnit || sizeUnit;

      // @ts-ignore `width` doesn't exist. fixme?
      Transforms.setNodes(editor, { width: `${s}${u}`, align: node.align || 'center' }, { at: path });
    },
    [editor, size, sizeUnit, node],
  );

  const submitAlignment = useCallback(
    alignment => {
      if (!node) return;

      const path = ReactEditor.findPath(editor, node);

      if (alignment && path) {
        Transforms.setNodes(editor, { align: alignment }, { at: path });
      }
    },
    [editor, node],
  );

  const onChangeSize = useCallback(
    event => {
      setSize(event.target.value);
      submit({ inputSize: event.target.value });
    },
    [submit],
  );

  const onChangeUnit = useCallback(
    event => {
      const unit = SIZE_OPTIONS[event.target.value - 1].label;

      setSizeUnit(unit);
      submit({ inputUnit: unit });
      dispatch({ type: ImageMenuActionTypes.close });
    },
    [dispatch, submit],
  );

  const onChangeAlignment = useCallback(
    event => {
      const alignment = ALIGNMENT_OPTIONS[event.target.value - 1].label;

      setAlignment(alignment);
      submitAlignment(alignment);
      dispatch({ type: ImageMenuActionTypes.close });
    },
    [dispatch, submitAlignment],
  );

  const onKeyDown = useCallback(
    event => {
      if (event.key !== 'Enter') return;

      event.stopPropagation();
      event.preventDefault();

      dispatch({ type: ImageMenuActionTypes.close });
    },
    [dispatch],
  );

  const hasCaption = () => {
    if (!node) return false;

    const path = ReactEditor.findPath(editor, node);
    if (!path) return false;

    const figure = Editor.above(editor, { at: path, match: isFigure });
    const figcaption = figure && figure[0].children[1];

    return !!figcaption;
  };

  return (
    <Menu className={classes['Settings-Menu']} role="menu">
      <MenuItem focusable={false} TagName="div">
        <Flex gap="sm" layout="col" tag="label">
          <div className={menuClasses['EditorMenu-label']}>Max Width</div>
          <InputGroup columnLayout="1fr auto" separators size="sm">
            <Input onChange={onChangeSize} onKeyDown={onKeyDown} type="number" value={size} />
            <Select defaultValue={defaultSizeValue} onChange={onChangeUnit} options={SIZE_OPTIONS} />
          </InputGroup>
          {!hasCaption() && (
            <>
              <div className={menuClasses['EditorMenu-label']}>Alignment</div>
              <Select
                defaultValue={defaultAlignValue}
                onChange={onChangeAlignment}
                options={ALIGNMENT_OPTIONS}
                size={'sm'}
                style={{ width: 'inherit' }}
              />
            </>
          )}
        </Flex>
      </MenuItem>
    </Menu>
  );
};

export default ResizeEditor;
