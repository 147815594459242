import React, { useState, useMemo, createContext, useContext } from 'react';

import type { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

interface State {
  node: EmbedBlock | EmbedElement | null;
  type: EmbedTypes | null;
}

interface Actions {
  close: () => void;
  open: (newNode: EmbedBlock | EmbedElement, newType: EmbedTypes) => void;
}

type Value = [State, Actions];

const useEmbedMenuContextLess = () => {
  const [{ node, type }, setState] = useState<State>({ node: null, type: null });

  return useMemo<Value>(() => {
    return [
      { node, type },
      {
        open: (newNode, newType) => {
          setState({ node: newNode, type: newType });
        },
        close: () => {
          setState({ node: null, type: null });
        },
      },
    ];
  }, [node, type]);
};

const EmbedMenuContext = createContext([
  { node: null, type: null },
  { open: () => {}, close: () => {} },
] as Value);

export const EmbedMenuProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useEmbedMenuContextLess();

  return <EmbedMenuContext.Provider value={value}>{children}</EmbedMenuContext.Provider>;
};

export const useEmbedMenu = () => useContext(EmbedMenuContext);
export default useEmbedMenu;
