import Figure from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import { deserialize, serialize } from './serialize';
import { type, isFigure } from './shared';

export default {
  Component: Figure,
  deserialize,
  onKeyDown,
  is: isFigure,
  isFigure,
  normalizeNode,
  serialize,
  type,
};
