import TableRow from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize } from './serialize';
import { isTableRow, isTableRowEntry, rdmdType, type } from './shared';

export default {
  deserialize,
  isTableRow,
  isTableRowEntry,
  normalizeNode,
  onKeyDown,
  operations,
  rdmdType,
  Component: TableRow,
  type,
};
