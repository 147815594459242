/* eslint-disable consistent-return */
import type {
  BaseInsertTextOperation,
  BasePoint,
  Operation,
  Point,
  SetSelectionOperation,
  RemoveTextOperation,
} from 'slate';

import { Editor } from 'slate';

import { blocksByType } from '@ui/MarkdownEditor/editor/byType';
import type { ReadmeNode } from '@ui/MarkdownEditor/types';

import { TableCell, Variable } from '../../blocks';

// @note: This is a bit of a hack to workaround standard text editing behavior.
// This applies to any node that has markdown delimiters, links, variables,
// etc. Assume you have a link and an empty text node next to it:
//
// [
//   {
//     "type": "paragraph",
//     "children": [
//       { "text": "" },
//       {
//         "type": "link",
//         "title": null,
//         "url": "http://readme.com",
//         "children": [{ "text": "[this is alink](🔗)" }],
//         "label": "this is alink"
//       },
//       { "text": "" },
//     ]
//   }
// ]
//
// If you click on the line anywhere after the link, the cursor will be placed
// at the end of the link node. In this case the selection would be at:
//
// { path: [0, 1, 0], offset: 18 }
//
// This is typically what you want if you have some formatted text. The way
// that we've designed our markdown editor, this is almost never what we want.

const hasInlineMd = (n: ReadmeNode) => 'type' in n && blocksByType[n.type]?.hasInlineMd;

const adjustPoint = (editor: Editor, point: BasePoint | undefined): BasePoint | void => {
  if (!point) return;

  const [, path] = Editor.above(editor, { at: point, match: hasInlineMd }) || [];
  if (!path) return;

  if (Editor.isStart(editor, point, path)) {
    return Editor.before(editor, point);
  } else if (Editor.isEnd(editor, point, path)) {
    return Editor.after(editor, point);
  }
};

const coerceSelection = (editor: Editor, op: Operation) => {
  if (op.type !== 'set_selection' || !op.newProperties) return op;

  const anchor = adjustPoint(editor, op.newProperties.anchor);
  const focus = adjustPoint(editor, op.newProperties.focus);

  if (anchor) op.newProperties.anchor = anchor;
  if (focus) op.newProperties.focus = focus;

  return op;
};

const doubleEntryWorkaround = (
  editor: Editor,
  op: Operation,
  { point, affinity }: { affinity: 'end' | 'start' | undefined; point: Point },
) => {
  if (op.type !== 'insert_text') return op;

  const newPoint = {
    path: point.path,
    offset: affinity === 'end' ? op.text.length : point.offset - op.text.length,
  };

  const undo: RemoveTextOperation = {
    ...op,
    type: 'remove_text',
    text: 'or',
  };

  const reinsert: BaseInsertTextOperation = {
    ...op,
    path: point.path,
    offset: point.offset,
  };

  const select: SetSelectionOperation = {
    type: 'set_selection',
    properties: editor.selection,
    newProperties: {
      anchor: newPoint,
      focus: newPoint,
    },
  };

  return [op, undo, reinsert, select];
};

// @note: This is a bit of a hack to workaround standard text editing behavior.
// This applies to any node that has markdown delimiters, links, variables,
// etc. Assume you're inserting a variable:
//
// [
//   {
//     "type": "paragraph",
//     "children": [
//       { "text": "{user.name" },
//     ]
//   }
// ]
//
// As soon as you type '}', it will convert to the variable, and if the
// operation doesn't move the selection you could end up at the end of the
// variable. By default, you'll be inserting characters into the end of the
// variable, but that's not good.
//
// {
//   "type": "paragraph",
//   "children": [
//     { "text": "" },
//     {
//       "type": "variable",
//       "name": "name",
//       "children": [
//         { "text": "{user.email}a" }
//       ]
//     },
//     { "text": "" },
//   ]
// }
//
// So this hijacks the 'insert_text' operation to move the insertion to the
// next node.
const affinityHack = (editor: Editor, op: Operation) => {
  if (op.type !== 'insert_text') return op;

  const { path, offset } = op;
  const [node, inlineMdPath] = Editor.above(editor, { at: { path, offset }, match: hasInlineMd }) || [];
  if (!inlineMdPath) return op;

  let point: Point | undefined;
  let affinity: 'end' | 'start' | undefined;
  if (Editor.isStart(editor, { path, offset }, inlineMdPath)) {
    affinity = 'start';
    point = Editor.before(editor, { path, offset });
  } else if (Editor.isEnd(editor, { path, offset }, inlineMdPath)) {
    affinity = 'end';
    point = Editor.after(editor, { path, offset });
  }

  if (!point || !node) {
    return op;
  }

  // Something changed about slate's implementation and for certain nodes,
  // we're still getting double entry. Luckily, I found a workaround that
  // involves leaving the original insert text operation, then undoing it, and
  // re-inserting the text in the correct place. :shrug-screaming:
  if (editor.props.useMDX && (Variable.is(node) || Editor.above(editor, { at: path, match: TableCell.is }))) {
    return doubleEntryWorkaround(editor, op, { point, affinity });
  }

  return {
    ...op,
    ...point,
  };
};

export default [coerceSelection, affinityHack];
