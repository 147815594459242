import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

import { isVariable, openVariableMenu } from './shared';

const onKeyDown = (event, editor) => {
  switch (event.key) {
    case 'ArrowLeft':
    case 'ArrowRight': {
      const point = Editor[event.key === 'ArrowLeft' ? 'before' : 'after'](editor, editor.selection);

      if (Editor.above(editor, { match: isVariable })) return;

      const variableEntry = Editor.above(editor, { at: point, match: isVariable });
      if (variableEntry) {
        event.preventDefault();
        event.stopPropagation();

        const [element] = variableEntry;
        const node = ReactEditor.toDOMNode(editor, element);
        openVariableMenu(editor, element, { current: node });
      }

      break;
    }
    default:
      break;
  }
};

export default onKeyDown;
