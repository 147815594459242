import type { $TSFixMe } from '@readme/iso';
import type { Dispatch, ReactNode, RefObject } from 'react';
import type { RangeRef } from 'slate';

import { Owlmoji } from '@readme/markdown';
import fuzzysort from 'fuzzysort';
import React, { createContext, useContext, useReducer, useMemo } from 'react';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';

import { bounded } from '../utils';

interface InitAction {
  payload: { rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

type EmojiMenuAction = CloseAction | DownAction | InitAction | OpenAction | SearchAction | UpAction;

interface Emoji {
  aliases: string;
  name: string;
}

interface EmojiMenuState {
  emojis: Emoji[];
  filtered: Emoji[];
  open: boolean;
  rangeRef: RangeRef | null;
  search: string | null;
  selected: number;
  target: RefObject<HTMLElement> | null;
}

interface EmojiMenuReducer {
  (state: EmojiMenuState, action: EmojiMenuAction): EmojiMenuState;
}

const _initial: EmojiMenuState = {
  emojis: [],
  filtered: [],
  open: false,
  search: null,
  selected: 0,
  rangeRef: null,
  target: null,
};

const byScoreThenAlpha = (left: Fuzzysort.KeysResult<Emoji>, right: Fuzzysort.KeysResult<Emoji>) => {
  // right[0] or left[0] is null when we match against the category instead of the name
  if (!right[0] || !left[0]) {
    return 0;
  }
  const byScore = right[0].score - left[0].score;
  return byScore !== 0 ? byScore : left[0].target.localeCompare(right[0].target);
};

const emojis = Owlmoji.owlmoji.map((emoji: { names: string[] }) => {
  const [name, ...aliases] = emoji.names;
  return { name, aliases: aliases.join(', ').replace(/_/g, ' ') };
});

const useEmojiMenuReducer = () => {
  const memoized: [EmojiMenuReducer, EmojiMenuState] = useMemo(() => {
    const initial = { ..._initial, filtered: emojis, emojis };

    const reducer: EmojiMenuReducer = (state, action) => {
      switch (action.type) {
        case 'init':
          return { ...initial, rangeRef: action.payload.rangeRef };
        case 'open':
          if (state.open) return state;
          return { ...state, target: action.payload.target, open: true };
        case 'up':
          return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
        case 'down':
          return { ...state, selected: bounded(state.selected + 1, state.filtered.length) };
        case 'search': {
          const search = action.payload ? action.payload.replace(/^:?/, '') : '';
          return search === state.search
            ? state
            : {
                ...state,
                search,
                selected: 0,
                filtered: search
                  ? fuzzysort
                      .go(search, state.emojis, { keys: ['name', 'aliases'] })
                      // @ts-ignore
                      .sort(byScoreThenAlpha)
                      .map((r: $TSFixMe) => r.obj)
                  : state.emojis,
              };
        }
        case 'close':
          state.rangeRef?.unref();
          return {
            ...state,
            open: false,
            target: null,
            rangeRef: null,
          };
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useEmojiMenu');
          return state;
      }
    };

    return [reducer, initial];
  }, []);

  const [emojiMenuState, dispatch] = useReducer(...memoized);
  return useMemo<[EmojiMenuState, Dispatch<EmojiMenuAction>]>(() => [emojiMenuState, dispatch], [emojiMenuState]);
};

const EmojiMenuContext = createContext([_initial, () => {}] as [EmojiMenuState, Dispatch<EmojiMenuAction>]);

export const EmojiMenuProvider = ({ children }: { children: ReactNode }) => {
  const value = useEmojiMenuReducer();

  return <EmojiMenuContext.Provider value={value}>{children}</EmojiMenuContext.Provider>;
};

export const useEmojiMenu = () => useContext(EmojiMenuContext);
export default useEmojiMenu;
