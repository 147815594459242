import { Element } from 'slate';

import type { JsxFlowElement, ReadmeNode } from '@ui/MarkdownEditor/types';

export const type = 'mdxJsxFlowElement' as JsxFlowElement['type'];

export const isJsxFlowElement = (node: ReadmeNode): node is JsxFlowElement => Element.isElementType(node, type);

export const tagRegex = /<[A-z].*?>/;
export const startingTagRegex = new RegExp(`^(${tagRegex.source})\\s*\n`, 'm');
