import type { XYCoord } from 'react-dnd';

import React, { memo, useEffect, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { createPortal } from 'react-dom';
import { Element } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';
import useEnvInfo from '@core/hooks/useEnvInfo';

import { ReusableContent } from '@ui/MarkdownEditor/editor/blocks';
import { DragItems, ImageAlignValues } from '@ui/MarkdownEditor/enums';
import type { BlockDragItem, DragItem, ReusableContentElement } from '@ui/MarkdownEditor/types';

import Handle from './Handle';
import classes from './style.module.scss';
import { BLOCKMENU_WIDTH } from './utils';

interface CollectDragLayerProps {
  currentOffset: XYCoord | null;
  initialOffset: XYCoord | null;
  initialSourceOffset: XYCoord | null;
  item: DragItem;
  type: DragItems | null;
}

const isReusableContentItem = (item: DragItem): item is BlockDragItem<ReusableContentElement> =>
  'element' in item && Element.isElement(item.element) && ReusableContent.is(item.element);

const getPreviewStyle = (
  currentOffset: CollectDragLayerProps['currentOffset'],
  initialOffset: CollectDragLayerProps['initialOffset'],
  initialSourceOffset: CollectDragLayerProps['initialSourceOffset'],
  item: DragItem,
) => {
  if (!currentOffset || !initialOffset || !initialSourceOffset || !item) return { display: 'none' };

  // @note: The `initialSourceOffset` includes the BlockMenu in it's width, and
  // we want to calculate the distance between the initial cursor and the edge
  // of the content.
  const rcOffset = isReusableContentItem(item) ? 10 : 0;
  const x = currentOffset.x + (initialSourceOffset.x + BLOCKMENU_WIDTH + rcOffset - initialOffset.x);
  const y = currentOffset.y - (initialOffset.y - initialSourceOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return { transform };
};

interface DragPreviewProps {
  item: DragItem;
  style: React.CSSProperties;
  type: DragItems | null;
}

// eslint-disable-next-line react/display-name
const DragPreview = React.memo(({ item, style, type }: DragPreviewProps) => {
  const editor = useSlateStatic();
  const ref = useRef<HTMLDivElement>(null);
  const bem = useClassy(classes, 'DragLayer');
  const isReusableContent = isReusableContentItem(item);
  const { isClient } = useEnvInfo();

  useEffect(() => {
    if (!ref.current || !isReusableContent) return;

    const original = ReactEditor.toDOMNode(editor, item.element);

    let { width } = original.getBoundingClientRect();
    const node = original.cloneNode(true);

    if ('align' in item && item.align === ImageAlignValues.right) {
      const imageEl = original.querySelector('img[data-image-node]');
      if (imageEl) {
        width = imageEl.clientWidth;
      }
    }

    // @todo: whitespace is being normalized on the clones???
    // This following two spaces  would get collapsed to one.
    ref.current.replaceChildren(node);
    ref.current.style.width = `${width}px`;
    // ref.current.style.transform =
    // 'align' in item && item.align === ImageAlignValues.right ? `translateX(-${width + BLOCKMENU_WIDTH}px)` : 'unset';
  }, [editor, isReusableContent, item, type]);

  return isClient
    ? createPortal(
        <div className={bem('&')}>
          <div className={bem('_Item')} style={style}>
            <div className={bem('_Handle')}>
              <Handle isReusableContent={!!isReusableContent} />
            </div>
            <div ref={ref} className="markdown-body" />
          </div>
        </div>,
        document.body,
      )
    : null;
});

/* DragLayer renders a custom preview when dragging blocks. It needs to be
 * especially minimal or they might introduce jank when dragging.
 */
const DragLayer = () => {
  const bem = useClassy(classes, 'DragLayer');
  const { id: dndProviderId } = useDragAndDropContext();
  const { item, type, currentOffset, initialOffset, initialSourceOffset } = useDragLayer<CollectDragLayerProps>(
    monitor => ({
      item: monitor.getItem(),
      type: monitor.getItemType() as DragItems | null,
      initialOffset: monitor.getInitialClientOffset(),
      initialSourceOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getClientOffset(),
    }),
  );
  const isSameDndProvider = (item as BlockDragItem)?.dndId === dndProviderId;
  const isDragging = !!(item && 'element' in item && DragItems.Block === type && currentOffset);
  const style = getPreviewStyle(currentOffset, initialOffset, initialSourceOffset, item);

  useEffect(() => {
    document.body.classList.toggle(bem('--dragging'), isDragging);

    return () => document.body.classList.remove(bem('--dragging'));
  }, [bem, isDragging]);

  return isSameDndProvider && isDragging ? <DragPreview item={item} style={style} type={type} /> : null;
};

export default memo(DragLayer);
