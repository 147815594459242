import { Editor, Node, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { HtmlBlock, ReadmeEditor, ReadmeElement } from '@ui/MarkdownEditor/types';

import { type } from './shared';

const defaultAttributes: Partial<HtmlBlock> = {
  type,
  value: '',
  runScripts: undefined,
  children: [{ text: '' }],
};

export const insertHtmlBlock = (editor: ReadmeEditor) => {
  const entry = Editor.above(editor, { match: n => Editor.isBlock(editor, n as ReadmeElement) });

  if (!entry) {
    // eslint-disable-next-line no-console
    console.warn("There's no blocks!");
    return;
  }

  const [block, path] = entry;

  const at = Node.string(block) === '' ? path : Path.next(path);
  Transforms.insertNodes(editor, emptyNode(defaultAttributes), {
    at,
    select: true,
  });
};

export default { insertHtmlBlock };
