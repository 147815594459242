import React, { useContext } from 'react';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import MarkdownEditor from '@ui/MarkdownEditor';
import { RHFGroup } from '@ui/RHF';

import { useCustomBlockFormContext } from '../Context';

import classes from './style.module.scss';

export default function ReusableContentEditor() {
  const bem = useClassy(classes, 'ReusableContentEditor');

  const {
    project: { fullBaseUrl, childrenProjects, glossaryTerms, subdomain, variableDefaults, _parent, flags },
  } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const {
    control,
    formState: { errors },
  } = useCustomBlockFormContext();

  const useMDX = _parent ? _parent.flags?.mdx : flags?.mdx || false;
  const isGroup = !!childrenProjects?.length;

  return (
    <div className={bem('&', errors?.source && '_error')}>
      <RHFGroup className={bem('-formgroup')} control={control} name="source" required>
        {({ field }) => (
          <MarkdownEditor
            data-testid="ReusableContentEditor"
            disallowRecipes={isGroup}
            disallowReusableContent
            doc={field.value}
            glossaryTerms={glossaryTerms}
            onChange={(_, editor) => {
              const md = editor.toString();
              field.onChange(md);
            }}
            projectBaseUrl={fullBaseUrl}
            subdomain={subdomain}
            useAPIv2={isSuperHub}
            useMDX={useMDX}
            variableDefaults={variableDefaults}
            version={version}
          />
        )}
      </RHFGroup>
    </div>
  );
}
