import PropTypes from 'prop-types';
import React from 'react';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

const Header = ({ categories, className, highlightedCategory, onClickCategory, testId = '', text }) => {
  return categories ? (
    <Flex className={`${classes['MenuDropdown-Header']} ${className}`} data-testid={testId} gap="xs" justify="start">
      {categories.map((category, index) => {
        return (
          <Button
            key={`${category}-${index}`}
            data-category={category}
            data-testid={`button-${category}`}
            ghost={category !== highlightedCategory}
            kind={category === highlightedCategory ? 'primary' : 'minimum'}
            name={category}
            onClick={onClickCategory}
            size="xs"
          >
            {category}
          </Button>
        );
      })}
    </Flex>
  ) : (
    <MenuItem className={`${classes['MenuDropdown-Header']} ${className}`} focusable={false}>
      {text}
    </MenuItem>
  );
};

Header.propTypes = {
  categories: PropTypes.array,
  className: PropTypes.string,
  highlightedCategory: PropTypes.string,
  onClickCategory: PropTypes.func,
  testId: PropTypes.string,
  text: PropTypes.string,
};

export default Header;
