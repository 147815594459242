/* eslint-disable consistent-return */
import type { $TSFixMe } from '@readme/iso';
import type { Node as MdNode } from 'mdast';

import { Editor, Node, Text, Transforms } from 'slate';

import { deserializer, getNode } from '@ui/MarkdownEditor/editor/parser';
import type { VariableInline, Normalizer } from '@ui/MarkdownEditor/types';

import { deserialize } from './serialize';
import { isVariable, isVariableEntry } from './shared';

const getVariable = (mdast: MdNode) =>
  getNode(
    mdast,
    (node: MdNode): node is MdNode => 'type' in node && ['readme-glossary-item', 'readme-variable'].includes(node.type),
  );

const convertToVariable: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!(Text.isText(node) && !Editor.above(editor, { at: path, match: isVariable }))) {
      return next();
    }
    const { useMDX } = editor.props;

    const string = Node.string(node);
    const regex = useMDX ? /{.*}/ : /<<.*>>/;
    // @perf: we can short circuit the deserializer with a quick regex
    if (!string.match(regex)) return next();

    const { renderingLibrary } = editor;

    try {
      const mdast = renderingLibrary.mdast(string);

      // doesn't look like markdown formatting
      if (!mdast) return next();

      const mdVariable = getVariable(mdast);
      if (!mdVariable) return next();

      const at = {
        anchor: { path, offset: mdVariable.position.start.offset },
        focus: { path, offset: mdVariable.position.end.offset },
      };

      // @ts-ignore
      const variable = deserialize(mdVariable, null as $TSFixMe, { useMDX: !!useMDX });

      Transforms.wrapNodes(editor, variable, { at, split: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);

      return next();
    }
  };

const convertBackToText: Normalizer = next => (editor, nodeEntry) => {
  if (!isVariableEntry(nodeEntry)) return next();
  const [node, path] = nodeEntry;

  const string = Node.string(node);
  if (string === '<<>>') return next();
  const { useMDX } = editor.props;

  try {
    const mdast = useMDX ? editor.renderingLibrary.mdast(string) : deserializer(string);

    const variable = getVariable(mdast);
    if (variable) return next();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }

  Transforms.unwrapNodes(editor, { at: path });
};

const syncName: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isVariable(node)) return next();

    const regex = editor.props.useMDX ? /^{user.(.*)}$/ : /^<<(?:glossary:)?(.*)>>$/;
    const name = Node.string(node).replace(regex, '$1');
    if (node.name === name) return next();

    Transforms.setNodes(editor, { name } as Partial<VariableInline>, { at: path });
  };

export default [convertToVariable, convertBackToText, syncName];
