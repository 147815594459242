import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

export const extractSize = (value?: string) => value?.match(/^\d+/)?.[0];
export const extractUnit = (value?: string) => value?.match(/(px|%|em)/)?.[0];

export const DEFAULTS = {
  height: '100',
  width: '300',
  heightUnit: 'px',
  widthUnit: '%',
  heightFull: '100px',
  widthFull: '300%',
};

export const sizeOptions = [
  { label: 'px', value: 1 },
  { label: '%', value: 2 },
  { label: 'em', value: 3 },
];

const labelAndPlaceholders = {
  [EmbedTypes.youtube]: ['YouTube URL', 'https://www.youtube.com/'],
  [EmbedTypes.github]: ['GitHub Gist URL', 'https://gist.github.com/'],
  [EmbedTypes.pdf]: ['PDF URL', 'https://pdf-sample.pdf'],
  [EmbedTypes.jsfiddle]: ['JSFiddle URL', 'https://jsfiddle.net/'],
  [EmbedTypes.iframe]: ['Iframe URL', 'https://readme.com/'],
};

export const labelAndPlaceholder = (element: EmbedBlock | EmbedElement) => {
  return element.typeOfEmbed
    ? labelAndPlaceholders[element.typeOfEmbed]
    : element.iframe
      ? labelAndPlaceholders.iframe
      : ['Embed URL', 'https://readme.com/'];
};
