import type { $TSFixMe } from '@readme/iso';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useEventListener from '@core/hooks/useEventListener';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  close?: (event: React.MouseEvent) => void;
  delay?: boolean;
  open?: boolean;
  overlay?: boolean;
  target?: React.RefObject<HTMLElement> | null;
}

const MenuDropdown = ({
  children,
  className = '',
  delay = false,
  open,
  target,
  close,
  overlay = false,
  ...props
}: Props) => {
  const { isClient } = useEnvInfo();
  const [style, setStyle] = useState<React.CSSProperties>({});
  const ref = useRef<HTMLDialogElement>(null);

  const setPosition = useCallback(() => {
    if (!target?.current || !ref.current) return;

    const targetRect = target.current.getBoundingClientRect();
    const dropdownRect = ref.current.getBoundingClientRect();

    const targetLeft = targetRect.left;
    const newStyle: React.CSSProperties = {
      display: 'initial',
      top: overlay ? `${targetRect.top}px` : `${targetRect.bottom}px`,
      left: overlay ? `calc(${targetLeft}px + 0.3em)` : `${targetLeft}px`,
    };

    if (window.innerHeight < dropdownRect.height + (overlay ? targetRect.top : targetRect.bottom)) {
      delete newStyle.top;
      newStyle.bottom = `${window.innerHeight - targetRect.top}px`;
    }

    if (Object.entries(newStyle).some(([key, value]) => value !== style?.[key as keyof React.CSSProperties])) {
      setStyle(newStyle);
    }
  }, [style, target, overlay]);

  useEffect(setPosition, [setPosition]);

  useEventListener('resize', setPosition);
  useEventListener('scroll', setPosition, { capture: true });

  useEventListener('pointerup', (event: MouseEvent) => {
    if (open && ref.current && !ref.current.contains(event.target as HTMLElement) && close) {
      close(event as $TSFixMe);
    }
  });

  const bem = useClassy(classes, 'MenuDropdown');

  return isClient
    ? createPortal(
        <dialog
          ref={ref}
          className={bem('&', className, open || '&-closed', delay && '&-delay')}
          hidden={!open}
          style={style}
          {...props}
        >
          {children}
        </dialog>,
        document.body,
      )
    : null;
};

export { default as Header } from './Header';
export { default as Footer } from './Footer';

export default React.memo(MenuDropdown);
