import Component from './Component';
import * as operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isGlossaryTerm } from './shared';

export default {
  Component,
  deserialize,
  is: isGlossaryTerm,
  isGlossaryTerm,
  isInline: true,
  hasInlineMd: true,
  operations,
  serialize,
  type,
};
