import type { Node } from 'slate';

import { Element, Editor, Point, Range } from 'slate';

import type { BlockquoteBlock, CalloutBlock } from '@ui/MarkdownEditor/types';

import Paragraph from '../Paragraph';

export const type = 'blockquote';
export const calloutType = 'rdme-callout';

export const isBlockquote = (node: Node): node is BlockquoteBlock | CalloutBlock => Element.isElementType(node, type);
export const isCallout = (node: Node): node is CalloutBlock => isBlockquote(node) && 'icon' in node;
export const isOnlyBlockquote = (node: Node): node is BlockquoteBlock =>
  isBlockquote(node) && !('icon' in node) && !('theme' in node);

export const isAtEdgeOfBlockquote = (editor: Editor, { edge }: { edge: 'end' | 'start' }) => {
  if (!editor.selection) return false;

  const blockquoteAbove = Editor.above(editor, {
    match: node => Editor.isBlock(editor, node) && !Paragraph.isParagraph(node),
  });

  if (!blockquoteAbove) return false;

  const [blockquote, blockquotePath] = blockquoteAbove;

  return (
    isBlockquote(blockquote) &&
    Range.isCollapsed(editor.selection) &&
    Point.equals(editor.selection.anchor, Editor[edge](editor, blockquotePath))
  );
};
