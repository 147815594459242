import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import { deserialize, serialize } from './serialize';
import { defaultListItemProps, isListItem, isOnlyListItem, rdmdType, type } from './shared';

const ListItem = {
  deserialize,
  defaultProps: defaultListItemProps,
  is: isListItem,
  isOnlyListItem,
  normalizeNode,
  onKeyDown,
  rdmdType,
  Component,
  serialize,
  type,
};

export default ListItem;
