import { Text } from 'slate';

export const deserialize = ({ position, start, ...node }, deeper) => {
  const children = deeper(node);

  children.forEach(item => {
    item.children.forEach((child, idx) => {
      if (Text.isText(child)) {
        item.children[idx] = { type: 'paragraph', children: [child] };
      }
    });
  });

  // @note: slate removes null props. For testing, this makes it consistent.
  if (start !== null) node.start = start;

  return {
    ...node,
    children,
  };
};

export const serialize = (node, deeper) => {
  return {
    ...node,
    children: deeper(node),
  };
};
