export const sampleRecipes = [
  {
    title: 'Recipe 1',
    emoji: '🦉',
    backgroundColor: '#018ef5',
    slug: 'recipe-title-1',
    id: '1',
    link: 'https://readme.com/',
    align: 'default',
  },
  {
    title: 'Recipe 2',
    emoji: '📘',
    backgroundColor: '#8470be',
    slug: 'recipe-title-2',
    id: '2',
    link: 'https://readme.com/',
  },
  {
    title: 'Recipe 3',
    emoji: '🤡',
    backgroundColor: '#12ca93',
    slug: 'recipe-title-3',
    id: '3',
    link: 'https://readme.com/',
  },
];
