import * as rdmd from '@readme/markdown';

import type { ReadmeElement } from '@ui/MarkdownEditor/types';

const serializeForNow = (node: ReadmeElement, { inline = false, renderingLibrary = rdmd } = {}) => {
  const text = 'mdx' in renderingLibrary ? renderingLibrary.mdx(node).trim() : renderingLibrary.md(node).trim();

  return inline ? { text } : { type: 'paragraph', children: [{ text }] };
};

export default serializeForNow;
