import type { UpdateUploadAction } from '../../ImageMenu/useImageMenu';
import type { $TSFixMe } from '@readme/iso';

import React, { useCallback, useEffect, useRef } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import ImageUploader from '@ui/ImageUploader';
import Input from '@ui/Input';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { ImageElement } from '@ui/MarkdownEditor/types';

import { useImageMenu } from '../../ImageMenu';
import { FileInputCache } from '../shared';

import classes from './style.module.scss';

interface FileInputProps {
  element: ImageElement;
}

const FileInput = ({ element }: FileInputProps) => {
  const editor = useSlateStatic();
  const { isTest } = useEnvInfo();
  const [, dispatch] = useImageMenu();
  const ref = useRef<HTMLInputElement>(null);

  const onStart = useCallback(() => {
    dispatch({ type: ImageMenuActionTypes.updateUpload, payload: { node: element, upload: { isImageLoading: true } } });
  }, [dispatch, element]);

  const onFinish = useCallback(
    response => {
      // If response is an object, get the url from the response object (APIv2)
      // otherwise get the first array element (legacy API)
      const url = 'url' in response ? response.url : response[0];
      const path = ReactEditor.findPath(editor, element);
      const align = element.align || 'center';

      Transforms.setNodes(editor, { src: url, url, align } as Partial<ImageElement>, { at: path });

      dispatch({
        type: ImageMenuActionTypes.finishUpload,
        payload: { node: element },
      });
    },
    [dispatch, editor, element],
  );

  const onFileUploadTest = useCallback(() => {
    (onStart as $TSFixMe)();
    const files = (document.getElementById('editor-image-upload') as $TSFixMe)?.files;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.addEventListener('load', e => onFinish((e.target as $TSFixMe).result as string));
  }, [onFinish, onStart]);

  const onUploadError = useCallback(
    err => {
      const payload: UpdateUploadAction['payload'] = {
        node: element,
        upload: {
          isImageLoading: false,
        },
      };

      if (err.message.match('413')) {
        payload.upload.imageSizeError = 'Maximum file size exceeded';
      } else if (err.message.match('503')) {
        payload.upload.imageSizeError = 'Maximum dimensions exceeded';
      }

      dispatch({ type: ImageMenuActionTypes.updateUpload, payload });
    },
    [dispatch, element],
  );

  useEffect(() => {
    FileInputCache.set(element, ref);
  }, [element]);

  // If we're running things locally or in the testing library, don't save the image to our db
  if (isTest) {
    return (
      <Input
        ref={ref}
        accept="image/*"
        className={classes['Settings-input']}
        data-testid="editor-image-upload"
        hidden
        id="editor-image-upload"
        onChange={onFileUploadTest}
        type="file"
      />
    );
  }

  return (
    <ImageUploader
      accept="image/*"
      baseUrl={editor.props.domainFull}
      data-testid="editor-image-upload"
      hidden
      id="editor-image-upload"
      inputRef={ref}
      isLegacyApi={!editor.props.useAPIv2 as $TSFixMe}
      onFinish={onFinish}
      onStart={onStart}
      onUploadError={onUploadError}
    />
  );
};

export default FileInput;
