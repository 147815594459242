import Table from './Component';
import deleteFragment from './deleteFragment';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import * as operations from './operations';
import { deserialize, serialize } from './serialize';
import { defaultTable, isInTable, isTable, isTableEntry, isTableRow, type } from './shared';
import slashMenu from './slashMenu';

export default {
  defaultTable,
  deleteFragment,
  deserialize,
  is: isTable,
  isInTable,
  isTable,
  isTableEntry,
  isTableRow,
  normalizeNode,
  onKeyDown,
  operations,
  Component: Table,
  serialize,
  slashMenu,
  type,
};
