/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

/*
 * @note from easings.net
 */
const easeOutSine = x => Math.sin((x * Math.PI) / 2);
const easeInSine = x => 1 - Math.cos((x * Math.PI) / 2);

const toRadians = deg => (deg * Math.PI) / 180;
const pseudoNormalRand = (n = 1) => Array.from({ length: 4 }).reduce(sum => sum + (Math.random() * n) / 4, 0);

const explosionDistance = 400;
const fallDistance = 600;
const spread = 160;

const explosionTime = 200;
const fallTime = 1600;
const maxTime = explosionTime + fallTime;
const fadeDelay = explosionTime + 500;
const rotationPeriod = 2000;

const position = (time, vector) => {
  const dist = vector.magnitude * explosionDistance * easeOutSine(time >= explosionTime ? 1 : time / explosionTime);
  const x = dist * Math.sin(vector.angle);
  let y = dist * Math.cos(vector.angle);

  if (time > explosionTime) {
    y += easeInSine((time - explosionTime) / maxTime) * fallDistance;
  }

  return [x, y];
};

const opacity = time => {
  if (time < fadeDelay) return 1;
  if (time > maxTime) return 0;

  const interval = fallTime - fadeDelay;
  return (interval - (time - fadeDelay)) / interval;
};

const Confetto = ({ kind, start, time, x, y }) => {
  const bem = useClassy(classes, 'Confetto');
  const colors = kind
    ? [`${kind}30`, `${kind}40`, `${kind}50`, `${kind}60`, `${kind}70`]
    : ['red', 'yellow', 'green', 'blue', 'purple'];
  const color = useMemo(() => colors[Math.floor(Math.random() * 6)], [start]);
  const vector = useMemo(
    () => ({
      angle: toRadians(pseudoNormalRand(spread) + (180 - spread / 2)),
      magnitude: pseudoNormalRand(),
    }),
    [start],
  );
  const rotate = useMemo(
    () => ({ x: Math.random() * 2 - 1, y: Math.random() * 2 - 1, z: Math.random() > 0.5 ? -1 : 1 }),
    [start],
  );
  const scale = useMemo(() => Math.random() / 2 + 0.5, [start]);
  const translate = useMemo(() => ({ x: Math.random() * 100, y: Math.random() * 100 }), [start]);
  const rotation = useMemo(() => {
    const startPosition = Math.random();
    const period = pseudoNormalRand(rotationPeriod / 2) + rotationPeriod * 0.75;
    const direction = Math.random() > 0.5 ? -1 : 1;

    return t => (((t % period) / period + startPosition) % 1) * direction;
  }, [start]);
  const tall = useMemo(() => Math.random() > 0.5, [start]);

  const style = useMemo(() => {
    const coords = position(time, vector);
    const xOffset = x ? `${x}px` : '50vw';
    const yOffset = y ? `${y}px` : '50vh';
    const rotate3d = `rotate3d(${rotate.x}, ${rotate.y}, ${rotate.x}, ${rotation(time)}turn)`;
    const scale3d = `scale3d(${scale}, ${scale}, ${scale})`;
    const translate3d = `translate3d(${translate.x}%, ${translate.y}%, 0)`;

    return {
      borderRadius: '1.5px',
      height: tall ? '10px' : '8px',
      width: tall ? '8px' : '10px',
      backgroundColor: `var(--${color})`,
      left: `calc(${xOffset} + ${coords[0]}px)`,
      top: `calc(${yOffset} + ${coords[1]}px)`,
      opacity: opacity(time),
      transform: `${rotate3d} ${scale3d} ${translate3d}`,
    };
  }, [color, rotation, scale, time, vector, x, y]);

  return <div className={bem('&')} style={style} />;
};

Confetto.propTypes = {
  kind: PropTypes.oneOf(['red', 'yellow', 'green', 'blue', 'purple']),
  start: PropTypes.number,
  time: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
};

export { maxTime };
export default Confetto;
