import { Editor, Transforms } from 'slate';

import { Variable, MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import { MenuHandleTypes } from '@ui/MarkdownEditor/enums';

const afterAngleBracket = editor => {
  if (editor.selection.anchor.offset === 0) return false;

  const anchor = editor.selection.anchor;
  const focus = { path: anchor.path, offset: anchor.offset - 1 };
  const char = Editor.string(editor, { anchor, focus });

  return char === '<';
};

const onKeyDown = (event, editor) => {
  const [{ open, filtered, rangeRef, selected }, dispatch] = editor.variableMenu;
  const { useMDX } = editor.props;

  const menuType = useMDX ? MenuHandleTypes.mdxVariable : MenuHandleTypes.variable;
  const prefix = MenuHandle.prefixes[menuType];

  if (open) {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();
        event.stopPropagation();

        const { name, prefix: legacyPrefix } = filtered[selected] || {};

        if (name) {
          const value = useMDX ? name : `${legacyPrefix}${name}`;

          Variable.operations.insertVariable(editor, value, { at: rangeRef.current });
        }

        dispatch({ type: 'close' });
        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: event.key === 'ArrowDown' ? 'down' : 'up' });

        break;
      }
      case '*':
      case 'Escape': {
        event.preventDefault();
        event.stopPropagation();

        const entry = Editor.above(editor, { at: rangeRef.current, match: MenuHandle.is });
        dispatch({ type: 'close' });
        if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

        if (event.key !== 'Escape') {
          Transforms.insertText(editor, event.key);
        }

        break;
      }
      default:
        break;
    }
  }

  if (event.key === prefix && (useMDX || afterAngleBracket(editor))) {
    event.preventDefault();
    event.stopPropagation();

    // @note Though this is the default behavior, by calling it ourselves,
    // we are able to dispatch an initialize action after the < insertion.
    Transforms.insertText(editor, prefix);
    Transforms.wrapNodes(
      editor,
      { type: MenuHandle.type, menuType },
      {
        at: {
          anchor: {
            path: editor.selection.anchor.path,
            offset: editor.selection.anchor.offset - prefix.length - (useMDX ? 0 : 1),
          },
          focus: editor.selection.focus,
        },
        split: true,
      },
    );

    // if there's text after, slate moves the cursor into it
    // this moves the selection back to the menu handle
    if (!Editor.above(editor, { match: MenuHandle.isMenuHandle }))
      Transforms.select(editor, Editor.before(editor, editor.selection));
  }
};

export default onKeyDown;
