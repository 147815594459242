import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';

import { confetti } from '@ui/Confetti';
import Flex from '@ui/Flex';
import Modal from '@ui/Modal';

import Owlbert from './party-owlbert.png';
import classes from './style.module.scss';

interface Props {
  /** content to be displayed in the status card. If `null` or missing, will not display card */
  content?: string | null;
  /** number of seconds to display Party Owlbert. If `null` or missing, will only hide on click */
  disappear?: number | null;
  partyRef: React.RefObject<Modal>;
}

const PartyOwlbert = ({ partyRef, content = null, disappear = null }: Props) => {
  const { isClient } = useEnvInfo();
  const [popState, setPopState] = useState(false);
  const bem = useClassy(classes, 'PartyOwlbert');

  const makeParty = useCallback(() => {
    const x = window && window.innerWidth / 2;
    const y = window && window.innerHeight / 2 - 50;
    confetti({ kind: '', x, y });
  }, []);

  useEffect(() => {
    if (popState) {
      makeParty();

      if (disappear) {
        setTimeout(() => partyRef?.current?.toggle(false), disappear * 1000);
      }
    }
  }, [popState, disappear, partyRef, makeParty]);

  // Prevent rendering the portal during SSR.
  if (!isClient) return null;

  return ReactDOM.createPortal(
    <>
      <Modal
        ref={partyRef}
        className={bem('-modal')}
        onClose={() => setPopState(false)}
        onOpen={() => setPopState(true)}
        size="sm"
        target="#partyowlbert-modal"
        verticalCenter
      >
        <Flex align="center" className={bem('-container')} justify="center" layout="col">
          <Flex justify="center">
            <img alt="Owlbert and confetti" className={bem(`-image${popState ? '-pop' : ''}`)} src={Owlbert} />
          </Flex>
          {!!content && (
            <Flex className={bem('-content')}>
              <div className={bem('-status')}>
                <code className={bem('-httpStatus')}>
                  <span aria-label={'hooray status code'} className={bem('-httpStatus-chit')} role="img" />
                </code>
                {content}
              </div>
            </Flex>
          )}
        </Flex>
      </Modal>
      <div className={`ModalWrapper ${bem('-wrapper')}`} id="partyowlbert-modal" />
    </>,
    document.body,
  );
};

export default PartyOwlbert;
