export const deserialize = ({ position, ...node }) => {
  return {
    ...node,
    children: [{ text: '' }],
  };
};

export const serialize = ({ children, ...node }) => ({
  ...node,
});
