import { isCallout, calloutType, type } from './shared';

export const deserialize = ({ data, position, ...node }, deeper) => {
  const { hName } = data || {};
  const { icon, title, empty = false } = data?.hProperties || {};
  const children = deeper(node);

  if (icon && !title?.length && hName !== 'Callout') {
    children.unshift({ type: 'paragraph', children: [{ text: title || '' }] });
  }

  return icon ? { ...node, icon, type, empty, children } : { ...node, type, children };
};

export const serialize = (node, deeper) => {
  const { icon } = node;
  const nodeType = isCallout(node) ? calloutType : type;

  return {
    ...node,
    type: nodeType,
    children: deeper(node),
    data: {
      hName: nodeType,
      hProperties: {
        icon,
      },
    },
  };
};
