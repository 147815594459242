import type { Node, NodeEntry } from 'slate';

import { Element, Path } from 'slate';

import type { TableRowElement } from '@ui/MarkdownEditor/types';

export const rdmdType = 'tableRow';
export const type = 'table-row';
export const isTableRow = (node: Node): node is TableRowElement => Element.isElementType(node, type);
export const isTableRowEntry = (entry: NodeEntry | undefined): entry is NodeEntry<TableRowElement> =>
  typeof entry !== 'undefined' && isTableRow(entry[0]) && Path.isPath(entry[1]);
