import { isEmpty } from '@ui/MarkdownEditor/editor/utils';
import type {
  BlockMdNode,
  HtmlMdNode,
  InlineMdNode,
  ParagraphElement,
  ParagraphMdNode,
  Serializer,
} from '@ui/MarkdownEditor/types';

import { type as htmlType } from '../Html/shared';
import { isParagraphPlaceholderParent } from '../shared';

import { type } from './shared';

export const serialize: Serializer<ParagraphElement, HtmlMdNode | ParagraphMdNode, BlockMdNode[] | InlineMdNode[]> = (
  node,
  deeper,
  { parent },
) => {
  if (isEmpty(node) && isParagraphPlaceholderParent(parent)) {
    return {
      type: htmlType,
      value: '<br />',
    };
  }

  let children = deeper(node);

  if (children.length === 1 && children[0].type === type) {
    children = children[0].children;
  }

  return {
    type,
    children,
  };
};
