import { detect } from 'detect-browser';

import { OSX_OPTION_CHARACTERS_MAP } from '@ui/MarkdownEditor/editor/eventUtils';

import { convertToHeading } from './operations';

const isOSX = detect()?.os === 'Mac OS';

const upToThree = [...new Array(3).keys()].map(n => (n + 1).toString());
const isSingleLine = editor => editor.selection.anchor.path[0] === editor.selection.focus.path[0];

// eslint-disable-next-line consistent-return
const parseMetaAltN = event => {
  if (!isOSX && event.ctrlKey && event.altKey && upToThree.includes(event.key)) {
    return parseInt(event.key, 10);
  } else if (isOSX && event.metaKey && event.altKey && event.key in OSX_OPTION_CHARACTERS_MAP) {
    const digit = OSX_OPTION_CHARACTERS_MAP[event.key];
    return digit >= 1 && digit <= 3 ? digit : false;
  }
};

const onKeyDown = (event, editor) => {
  const depth = parseMetaAltN(event);
  if (!depth || !isSingleLine(editor)) return;
  event.stopPropagation();
  event.preventDefault();

  convertToHeading(editor, depth);
};

export default onKeyDown;
