import { useEffect } from 'react';

const useConfirmBeforeUnload = (shouldFire: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      ev.returnValue = '';
    };

    if (shouldFire) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldFire]);
};

export default useConfirmBeforeUnload;
