import React, { memo } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

import EmbedEditor from './component';
import classes from './style.module.scss';

interface Props {
  className?: string;
  element: EmbedBlock | EmbedElement;
  menuRef: React.RefObject<DropdownRef>;
}

const EmbedMenu = ({ element, className, menuRef }: Props) => {
  const embedDefined = !!element?.url;
  const bem = useClassy(classes, 'Embed');

  return (
    <Dropdown
      ref={menuRef}
      className={`${bem(embedDefined ? '&_Toolbar' : '&_Upload')}${className ? ` ${className}` : ''}`}
      data-testid="embed-toolbar"
      justify="start"
    >
      <Button className={bem('&-toggle')} data-testid="embed-toolbar-button" kind="secondary" outline size="sm">
        <Icon name="settings" />
      </Button>
      <EmbedEditor element={element} />
    </Dropdown>
  );
};

export { default as useEmbedMenu } from './hooks/useEmbedMenu';

export default memo(EmbedMenu);
