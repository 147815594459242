import { insertAccordion, insertCards, insertColumns, insertTabs } from './operations';

const slashMenu = {
  Accordion: { icon: 'accordion', label: '<Accordion/>', transform: insertAccordion },
  Cards: { icon: 'cards', label: '<Cards/>', transform: insertCards },
  Columns: { icon: 'columns', label: '<Columns/>', transform: insertColumns },
  Tabs: { icon: 'tabs', label: '<Tabs/>', transform: insertTabs },
};

export default slashMenu;
