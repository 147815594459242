import type { Node } from 'slate';

import { Element } from 'slate';

import type { ImageElement } from '@ui/MarkdownEditor/types';

export const type = 'image';
export const isImage = (node: Node): node is ImageElement => Element.isElementType(node, type);

export const defaultImage = ({
  alt = '',
  title = '',
  url = undefined,
  ...props
}: {
  alt?: string;
  title?: string;
  url?: string;
}): ImageElement => ({
  alt,
  children: [{ text: '' }],
  title,
  type,
  url,
  ...props,
});

export const toString = (image: ImageElement) => {
  const { alt, title, url } = image;

  return `![${alt || ''}](${url}${title ? ` "${title}"` : ''})`;
};

export const toInline = (image: ImageElement) => {
  const { alt, title, url } = image;

  return defaultImage({ alt, title, url });
};
