/* eslint-disable consistent-return */
import { Node, Transforms } from 'slate';

import { MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { MenuHandle, Normalizer } from '@ui/MarkdownEditor/types';

import { isLink } from '../Link/shared';

import { isMenuHandle, prefixes } from './shared';

const syncSearch: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isMenuHandle(node)) return next();
    const search = Node.string(node).replace(`^${prefixes[node.menuType]}(.*)`, '$1');

    if (node.search !== search) {
      Transforms.setNodes(editor, { search } as Partial<MenuHandle>, { at: path });
    } else {
      next();
    }
  };

const checkOpeningBrackets: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isMenuHandle(node) || node.menuType === MenuHandleTypes.glossary) return next();
    const match = Node.string(node).match(`^${prefixes[node.menuType]}`);

    if (!match) {
      Transforms.unwrapNodes(editor, { at: path });
    } else {
      next();
    }
  };

const checkClosingBrackets: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isMenuHandle(node)) return next();
    let shouldUnwrap;

    if (node.menuType === MenuHandleTypes.variable) {
      shouldUnwrap = Node.string(node).match(/^<<.*>>/);
    } else if (node.menuType === MenuHandleTypes.link) {
      shouldUnwrap = [...Node.descendants(node)].find(([n]) => isLink(n));
    } else if (node.menuType === MenuHandleTypes.emoji) {
      shouldUnwrap = Node.string(node).match(/^:.*:/);
    } else if (node.menuType === MenuHandleTypes.reusableContent) {
      shouldUnwrap = Node.string(node).match(/^<.*>/);
    } else if (node.menuType === MenuHandleTypes.mdxVariable) {
      shouldUnwrap = Node.string(node).match(/^{.*}/);
    }

    if (shouldUnwrap) {
      Transforms.unwrapNodes(editor, { at: path });
    } else {
      next();
    }
  };

export default [syncSearch, checkOpeningBrackets, checkClosingBrackets];
