import { deserialize as codeDeserialize } from '../Code/serialize';

import { type } from './shared';

export const deserialize = ({ position, children, ...node }) => {
  return {
    type,
    tabs: (node.type === type ? [...children] : [node]).map(codeDeserialize),
    active: 0,
    children: [{ text: '' }],
  };
};

export const serialize = (node, deeper) => ({
  ...node,
  className: 'tabs',
  children: deeper({ children: node.tabs }),
  data: {
    hName: type,
  },
});
