import type { BlockquoteHTMLAttributes } from 'react';

import * as RDMD from '@readme/markdown';
import React, { useMemo, useCallback, useState } from 'react';
import { Node } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Box from '@ui/Box';
import Dropdown from '@ui/Dropdown';
import EmojiPicker from '@ui/EmojiPicker';
import type { BlockquoteBlock, CalloutBlock } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Owlmoji from '@ui/Owlmoji';

import { convertCalloutIcon } from './operations';
import { isCallout } from './shared';
import classes from './style.module.scss';

interface Props {
  attributes: BlockquoteHTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: BlockquoteBlock | CalloutBlock;
}

interface CalloutProps extends Props {
  element: CalloutBlock;
}

const Callout = ({ attributes, children, element }: CalloutProps) => {
  const editor = useSlateStatic();
  const { icon } = element;
  const theme =
    element.theme ||
    Object.keys(RDMD.utils.calloutIcons).find(key => RDMD.utils.calloutIcons[key].includes(icon)) ||
    'default';
  const title = useMemo(() => Node.string(element.children[0]), [element]);
  const noTitle = title.length === 0;
  const noBody = useMemo(
    () => element.children.slice(1).filter(child => Node.string(child).length > 0).length === 0,
    [element.children],
  );
  const path = ReactEditor.findPath(editor, element);
  const [isIconMenuOpen, setIsIconMenuOpen] = useState(false);

  const toggleIconMenu = useCallback(
    event => {
      setIsIconMenuOpen(!isIconMenuOpen);
      event.preventDefault();
    },
    [isIconMenuOpen],
  );

  const toggleEmojiMenu = useCallback(() => {}, []);

  const pickIcon = useCallback(
    (event: React.MouseEvent) => {
      if (event === null || !(event.target instanceof HTMLElement)) return;

      event.preventDefault();

      const calloutType = event.target.dataset.calloutType as string;
      const calloutIcon = RDMD.utils.calloutIcons[calloutType][0];

      convertCalloutIcon(editor, calloutIcon, path);
      setIsIconMenuOpen(false);
    },
    [editor, path],
  );

  const pickEmoji = useCallback(
    (name: string, event: React.MouseEvent) => {
      event.preventDefault();

      convertCalloutIcon(editor, name, path);
      setIsIconMenuOpen(false);
    },
    [editor, path],
  );

  const className = useClassName(
    'callout',
    `callout_${theme}`,
    classes.Callout,
    noTitle && classes.Callout_blank_title,
    noBody && classes.Callout_blank_body,
  );

  const tippyOptions = useMemo(() => {
    return {
      visible: isIconMenuOpen,
      onClickOutside: () => setIsIconMenuOpen(false),
      onHide: () => setIsIconMenuOpen(false),
      onShow: () => setIsIconMenuOpen(true),
    };
  }, [isIconMenuOpen]);

  return (
    <blockquote className={className} {...attributes}>
      {!!children &&
        React.Children.map(children, (child, index) => {
          if (index === 0) {
            return (
              <h3 key="title" className={classes['Callout-heading']}>
                <span className={classes['Callout-icon']} contentEditable={false}>
                  <Dropdown
                    align="bottom"
                    appendTo={document.body}
                    justify="start"
                    offset={[-12, 4]}
                    sticky
                    tippyOptions={tippyOptions}
                    trigger="click"
                  >
                    <button className={classes['Callout-EmojiPicker-Toggle']} onClick={toggleIconMenu}>
                      <Owlmoji className={classes['Callout-icon']}>{icon}</Owlmoji>
                    </button>
                    <div className={classes['Callout-icon-menu']}>
                      <Dropdown
                        align="bottom"
                        justify="start"
                        offset={[-4, 12]}
                        onToggle={toggleEmojiMenu}
                        sticky
                        trigger="click"
                      >
                        <button className={classes['Callout-icon-menu-item']}>
                          <i className="icon-smile" />
                        </button>
                        <Box className={classes['Callout-EmojiPicker']} kind="pop">
                          <EmojiPicker onClick={pickEmoji} owlmoji={false} />
                        </Box>
                      </Dropdown>
                      <span className={classes['Callout-icon-menu-divider']}></span>
                      {Object.keys(RDMD.utils.calloutIcons).map(calloutType => {
                        return (
                          <button
                            key={calloutType}
                            className={classes['Callout-icon-menu-item']}
                            data-callout-type={calloutType}
                            onClick={pickIcon}
                          >
                            {RDMD.utils.calloutIcons[calloutType][0]}
                          </button>
                        );
                      })}
                    </div>
                  </Dropdown>
                </span>
                {child}
              </h3>
            );
          }

          return child;
        })}
    </blockquote>
  );
};

const Blockquote = ({ attributes, children, element }: Props) => {
  if (isCallout(element)) {
    return <Callout {...{ attributes, children, element }} />;
  }

  return <blockquote {...attributes}>{children}</blockquote>;
};

export default Blockquote;
