import type { $TSFixMe } from '@readme/iso';
import type { Dispatch } from 'react';

import React, { createContext, useContext, useReducer, useMemo } from 'react';

import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { ImageElement, ImageBlock } from '@ui/MarkdownEditor/types';

interface OpenAction {
  payload: {
    node: ImageBlock | ImageElement;
  };
  type: ImageMenuActionTypes.open;
}

interface CloseAction {
  type: ImageMenuActionTypes.close;
}

export interface UpdateUploadAction {
  payload: { node: ImageBlock | ImageElement; upload: Upload };
  type: ImageMenuActionTypes.updateUpload;
}

interface FinishUploadAction {
  payload: { node: ImageBlock | ImageElement };
  type: ImageMenuActionTypes.finishUpload;
}

type ImageMenuAction = CloseAction | FinishUploadAction | OpenAction | UpdateUploadAction;

interface Upload {
  imageSizeError?: string;
  isImageLoading?: boolean;
  urlValidationMessage?: string;
}

interface ImageMenuState {
  fileSelect: boolean;
  node: ImageBlock | ImageElement | null;
  uploads: WeakMap<ImageBlock | ImageElement, Upload>;
}

interface ImageMenuReducer {
  (state: ImageMenuState, action: ImageMenuAction): ImageMenuState;
}

const initial: ImageMenuState = {
  node: null,
  fileSelect: false,
  uploads: new WeakMap<ImageBlock | ImageElement, Upload>(),
};

const useImageMenuReducer = () => {
  const memoized: [ImageMenuReducer, ImageMenuState] = useMemo(() => {
    const reducer: ImageMenuReducer = (state, action) => {
      switch (action.type) {
        case ImageMenuActionTypes.open:
          return { ...state, node: action.payload.node };
        case ImageMenuActionTypes.close:
          return { ...initial };
        case ImageMenuActionTypes.updateUpload: {
          const { node, upload } = action.payload;

          state.uploads.set(node, { ...upload });

          return { ...state };
        }
        case ImageMenuActionTypes.finishUpload: {
          state.uploads.delete(action.payload.node);

          return { ...state };
        }
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useImageMenu:', (action as $TSFixMe).type);
          return state;
      }
    };

    return [reducer, initial];
  }, []);

  const [linkMenuState, dispatch] = useReducer(...memoized);
  return useMemo<[ImageMenuState, Dispatch<ImageMenuAction>]>(
    () => [linkMenuState, dispatch],
    [linkMenuState, dispatch],
  );
};

const ImageMenuContext = createContext([initial, () => {}] as [ImageMenuState, Dispatch<ImageMenuAction>]);

export const ImageMenuProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useImageMenuReducer();

  return <ImageMenuContext.Provider value={value}>{children}</ImageMenuContext.Provider>;
};

export const useImageMenu = () => useContext(ImageMenuContext);
export default useImageMenu;
