import { detect } from 'detect-browser';

const isOSX = detect()?.os === 'Mac OS';

export const OSX_OPTION_CHARACTERS_MAP = {
  º: 0,
  '¡': 1,
  '™': 2,
  '£': 3,
  '¢': 4,
  '∞': 5,
  '§': 6,
  '¶': 7,
  '•': 8,
  ª: 9,
};

export const isCmdOrCtrl = event => (isOSX ? event.metaKey : event.ctrlKey);
export const isShiftKey = (event, key) => {
  return event.shiftKey && (key ? event.key === key || event.key === key.toUpperCase() : true);
};
