import { Editor, Node, Path, Point, Range, Text } from 'slate';

import { type as paragraphType } from './blocks/Paragraph/shared';
import _log from './log';

const log = _log.extend('normalizers');

export const remap = (map, prop, { to } = {}) => {
  return Object.values(map).reduce((memo, value) => {
    if (typeof value[prop] !== 'undefined') memo[value[prop]] = to ? value[to] : value;
    return memo;
  }, {});
};

export const queueHandlers =
  (...funcs) =>
  (...args) => {
    let shouldContinue = false;
    const next = () => {
      shouldContinue = true;
    };

    for (const fn of funcs) {
      shouldContinue = false;
      fn(next)(...args);

      if (!shouldContinue) {
        if (fn.name !== 'baseHandler') {
          log(`'${fn.name}' triggered an operation`, args[1]);
        }

        return;
      }
    }
  };

export const bounded = (number, max) => {
  if (number <= 0) return 0;
  if (typeof max !== 'undefined' && number >= max) return max;
  return number;
};

export function* trailingBlankChildren(editor, path) {
  for (const [node, p] of Node.children(editor, path, { reverse: true })) {
    if (Node.string(node) !== '') break;
    yield [node, p];
  }
}

export const offsetToPoint = (nodeEntry, offset, { affinity = 'backward' } = {}) => {
  const [node, parentPath] = nodeEntry;
  let text;
  let path;
  let found = false;
  let resolvedOffset = offset;

  for ([text, path] of Node.texts(node)) {
    if (text.text.length === resolvedOffset && affinity === 'forward') {
      found = true;
    } else if (found) {
      return {
        offset: 0,
        path: [...parentPath, ...path],
      };
    } else if (resolvedOffset <= text.text.length) {
      return {
        offset: resolvedOffset,
        path: [...parentPath, ...path],
      };
    }

    resolvedOffset -= text.text.length;
  }

  return {
    offset: text.text.length,
    path: [...parentPath, ...path],
  };
};

export const offsetToRange = (editor, { anchor, focus }) => ({
  anchor: offsetToPoint(Editor.node(editor, anchor.path), anchor.offset),
  focus: offsetToPoint(Editor.node(editor, focus.path), focus.offset),
});

export const pointToOffset = (nodeEntry, point) => {
  const [node, rootPath] = nodeEntry;
  let offset = 0;

  for (const [text, path] of Node.texts(node)) {
    if (Path.equals([...rootPath, ...path], point.path)) {
      offset += point.offset;
      break;
    }

    offset += text.text.length;
  }

  return offset;
};

export const contains = (outer, inner) =>
  Point.compare(Range.start(outer), Range.start(inner)) <= 0 && Point.compare(Range.end(outer), Range.end(inner)) >= 0;

// eslint-disable-next-line consistent-return
const findFirst = (node, fn) => {
  if ('children' in node) {
    return findFirst(node.children[0], fn);
  } else if (Text.isText(node)) {
    fn(node);
    return node;
  }
};

// eslint-disable-next-line consistent-return
const findLast = (node, fn) => {
  if ('children' in node) {
    return findLast(node.children[node.children.length - 1], fn);
  } else if (Text.isText(node)) {
    fn(node);
    return node;
  }
};

export const protoInsertText = (node, text, { reverse = false } = {}) => {
  if (!reverse) {
    const found = findFirst(node, n => {
      n.text = `${text}${n.text}`;
    });
    if (!found) node.children.unshift({ text });
  } else {
    const found = findLast(node, n => {
      n.text = `${n.text}${text}`;
    });
    if (!found) node.children.push({ text });
  }

  return node;
};

export const isEmpty = node =>
  node.children.length === 1 && Text.isText(node.children[0]) && node.children[0].text === '';

export const isEmptyEditor = node =>
  Editor.isEditor(node) &&
  node.children.length === 1 &&
  node.children[0].type === paragraphType &&
  isEmpty(node.children[0]);

export * from './utils/codeEditorSelection';
