import { Editor, Transforms } from 'slate';

import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { isEmoji } from './shared';

export const leftOrRightArrow: OnKeyDown = (event, editor) => {
  if (!['ArrowLeft', 'ArrowRight'].includes(event.key) || !editor.selection) return;
  const method = event.key === 'ArrowLeft' ? 'before' : 'after';

  const nextPoint = Editor[method](editor, editor.selection.focus);
  if (!nextPoint || !Editor.above(editor, { at: nextPoint, match: isEmoji })) return;

  const aroundEmojiPoint = Editor[method](editor, nextPoint);
  if (!aroundEmojiPoint) return;

  event.preventDefault();
  event.stopPropagation();

  if (event.shiftKey) {
    Transforms.setSelection(editor, { focus: aroundEmojiPoint });
  } else {
    Transforms.select(editor, aroundEmojiPoint);
  }
};

export default [leftOrRightArrow];
