import { Editor, Node, Point, Range } from 'slate';

import { isMarkdownCharOf } from '../MarkdownChar/shared';

import { isLink, LINK_ICON } from './shared';

// eslint-disable-next-line consistent-return
const openLinkEditor = when => next => editor => {
  const { selection } = editor;
  if (!(selection && Range.isCollapsed(selection))) return next();

  const [node, path] = Editor.above(editor, { match: isLink }) || [];
  if (!node) return next();

  if (!when(editor, [node, path])) return next();
  const ref = Editor.pathRef(editor, path);

  setTimeout(() => {
    const [, dispatch] = editor.linkEditor;
    const link = Node.get(editor, ref.current);

    dispatch({ type: 'open', payload: { link, ref, selection } });
  });
};

export const deleteBackward = openLinkEditor((editor, [, path]) => {
  const mdChar = Editor.nodes(editor, { at: path, mode: 'lowest', match: n => isMarkdownCharOf(n, LINK_ICON) }).next()
    .value;
  if (!mdChar) return false;
  const afterLinkIcon = Editor.after(editor, mdChar[1]);

  return Point.equals(editor.selection.anchor, afterLinkIcon);
});

export const deleteForward = openLinkEditor((editor, [, path]) => {
  const mdChar = Editor.nodes(editor, { at: path, mode: 'lowest', match: n => isMarkdownCharOf(n, LINK_ICON) }).next()
    .value;
  if (!mdChar) return false;
  const beforeLinkIcon = Editor.before(editor, mdChar[1]);

  return Point.equals(editor.selection.anchor, beforeLinkIcon);
});
