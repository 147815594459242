import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';

export const toggleFormatOnKeyDown = (type, matcher) => (event, editor) => {
  if (!matcher(event, editor) || acrossBlocks(editor)) return;

  event.preventDefault();
  event.stopPropagation();

  editor.toggleFormat(type);
};
