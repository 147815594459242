import type { ReadmeNode } from '@ui/MarkdownEditor/types';

const traverse = (
  node: ReadmeNode,
  fn: (node: ReadmeNode, idx: number, parent?: ReadmeNode | null) => void,
  index = 0,
  parent: ReadmeNode | null = null,
) => {
  if ('children' in node) {
    node.children.forEach((child, idx) => {
      traverse(child, fn, idx, node);
    });
  }

  fn(node, index, parent);
};

export default traverse;
