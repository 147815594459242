import * as blocks from '../blocks';
import { queueHandlers } from '../utils';

const standardHandler =
  (name, additional = []) =>
  editor => {
    const base = editor[name];

    return queueHandlers(
      ...additional,
      ...Object.values(blocks)
        .filter(b => b[name])
        .map(b => b[name]),
      () =>
        (_, ...args) =>
          base(...args),
    ).bind(null, editor);
  };

export default standardHandler;
