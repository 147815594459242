import type { RenderLeafProps } from 'slate-react';

import React from 'react';

import type { FormattedText } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

const type = 'mdxJsxTextElement';

type Props = RenderLeafProps & {
  leaf: FormattedText & {
    range: Range;
    skipMenu?: boolean;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MdxJsxTextElement = ({ leaf, ...props }: Props) => (
  <code className={classes.MdxJsxTextElement} spellCheck={false} {...props} />
);

const mdxJsxTextElement = {
  type,
  render: MdxJsxTextElement,
};

export default mdxJsxTextElement;
