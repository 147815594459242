import type { $TSFixMe } from '@readme/iso';

import { Editor, Text } from 'slate';

import type { ReadmeElement, ReadmeInline, ReadmeNode, ReadmeText } from '@ui/MarkdownEditor/types';

import { Image } from '../blocks';

import traverse from './traverse';

const mergeTexts = (_: Editor, node: ReadmeNode) => {
  if (!('children' in node)) return;
  if (node.children.length === 1) return;
  if (!node.children.some(child => Text.isText(child))) return;

  node.children = (node.children as (ReadmeInline | ReadmeText)[]).reduce(
    (memo, child) => {
      const last = memo[memo.length - 1];

      if (
        last &&
        Text.isText(last) &&
        Text.isText(child) &&
        Object.keys(last).length === Object.keys(child).length &&
        Object.getOwnPropertyNames(last).every(x => x in child)
      ) {
        memo[memo.length - 1] = { text: last.text + child.text };
      } else {
        memo.push(child);
      }

      return memo;
    },
    [] as (ReadmeInline | ReadmeText)[],
  );
};

const wrapInlines = (editor: Editor, node: ReadmeNode) => {
  if (!('children' in node)) return;
  if (!node.children.some(child => Editor.isInline(editor, child))) return;

  node.children = (node.children as (ReadmeInline | ReadmeText)[]).reduce(
    (memo, child, idx) => {
      const younger = node.children[idx - 1];
      const isInline = Editor.isInline(editor, child);

      if (isInline && (!younger || !Text.isText(younger))) {
        memo.push({ text: '' });
      }

      memo.push(child);

      if (isInline && idx === node.children.length - 1) {
        memo.push({ text: '' });
      }

      return memo;
    },
    [] as (ReadmeInline | ReadmeText)[],
  );
};

const addLeaves = (_: Editor, node: ReadmeNode) => {
  if (Text.isText(node)) return;
  if ('children' in node && node.children.length) return;

  node.children = [{ text: '' }];
};

const setIsInline = (editor: Editor, node: ReadmeNode, parent: ReadmeElement) => {
  if (!Image.is(node)) return;
  if (!Editor.hasInlines(editor, parent) && !Editor.hasTexts(editor, parent)) return;

  node.isInline = true;
};

/* @note: These are helpful normalizations that can prevent a cascade of
 * re-normalizations. Sometimes they get missed in the deserializes, or they
 * aren't easily known until the doc is built up.
 * https://docs.slatejs.org/concepts/11-normalizing#multi-pass-normalizing
 */
const preNormalize = (editor: Editor): Editor => {
  traverse(editor, (node, _, parent) => {
    mergeTexts(editor, node);
    wrapInlines(editor, node);
    addLeaves(editor, node);
    setIsInline(editor, node, parent as $TSFixMe);
  });

  return editor;
};

export default preNormalize;
