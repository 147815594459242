import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { Editor, Node, Path, Range, Transforms } from 'slate';
import isURL from 'validator/lib/isURL';

import useEnvInfo from '@core/hooks/useEnvInfo';

import { uploadImage } from '@ui/ImageUploader';
import { insertLink, unwrapLink } from '@ui/MarkdownEditor/editor/blocks/Link/operations';
import { isLink } from '@ui/MarkdownEditor/editor/blocks/Link/shared';

import { Paragraph, Table } from '../blocks';
import { acrossBlocks } from '../selection';
import deblockify from '../utils/deblockify';

const pasteUrlAsLink = (editor, url) => {
  // Unwrap any existing links in the selection
  const links = [...Editor.nodes(editor, { match: isLink, reverse: true })];
  links.forEach(link => unwrapLink(editor, link));

  const selectedText = Editor.string(editor, editor.selection);
  // If the selection is across blocks, we don't want to use the selected text as the label
  const useSelectedTextForLabel = selectedText && !acrossBlocks(editor);
  const label = useSelectedTextForLabel ? selectedText : url;

  insertLink(
    editor,
    {
      label,
      url,
    },
    {
      menu: false,
    },
  );
};

const pasteImage = (baseUrl, editor, file, isDev, isTest) => {
  const insertImage = url => {
    // If the current node is empty, insert on this line: otherwise, insert on the next one!
    const path = editor.selection?.anchor?.path;
    const isCurrentNodeEmpty = !Node.get(editor, path)?.text?.length;
    const at = isCurrentNodeEmpty ? Path.parent(path) : Path.next(Path.parent(path));

    const image = [{ type: 'image', url, children: [{ text: '' }] }];
    Transforms.insertNodes(editor, image, { at });
  };

  if (isDev || isTest) {
    const reader = new FileReader();
    reader.onload = e => insertImage(e.target.result);
    reader.readAsDataURL(file);
  } else {
    uploadImage({ file, baseUrl }).then(res => insertImage(res[0]));
  }
};

const pasteTable = (editor, pasted) => {
  const isTable = pasted.length === 1 && Table.isTable(pasted[0]);
  const isOneCell = isTable && pasted[0].children.length === 1 && pasted[0].children[0].children.length === 1;

  if (isTable && !isOneCell) {
    Table.operations.mergeTable(editor, pasted[0]);
  } else {
    // Serialize everything else back into a string and insert it
    const string = editor.serialize({
      children: isOneCell ? pasted[0].children[0].children[0].children : deblockify(editor, pasted),
    });

    // If we've selected across table cells, paste into the
    // first selected cell and remove existing content there
    Editor.withoutNormalizing(editor, () => {
      let at = editor.selection;
      if (acrossBlocks(editor)) {
        at = { path: Editor.start(editor, editor.selection.anchor).path, offset: 0 };
        const distance = Node.get(editor, editor.selection.anchor.path)?.text?.length;

        Transforms.delete(editor, { at, distance });
      }

      Transforms.insertText(editor, string, { at, select: true });

      const anchor = Editor.start(editor, at);
      Transforms.select(editor, {
        anchor,
        focus: {
          path: anchor.path,
          offset: anchor.offset + string.length,
        },
      });
    });
  }
};

export default function usePasteHandler(editor, baseUrl) {
  const { isDev, isTest } = useEnvInfo();

  const isElementBlock = useCallback(
    element =>
      (!Paragraph.isParagraph(element) && Editor.isBlock(editor, element)) ||
      element.children?.forEach(child => isElementBlock(child)),
    [editor],
  );

  return useCallback(
    event => {
      event.preventDefault();

      // Images copied from outside ReadMe docs are found in files
      const file = event.clipboardData.files[0];
      if (file) {
        pasteImage(baseUrl, editor, file, isDev, isTest);
        return;
      }

      let pasted;
      // pasted = editor.deserialize(event.clipboardData.getData('text'));

      try {
        pasted = editor.deserialize(event.clipboardData.getData('text'));
      } catch (e) {
        // If there's a syntax error, the parser could throw. In that case,
        // lets just insert the text.
        Transforms.insertText(editor, event.clipboardData.getData('text'));
        return;
      }

      const { anchor, focus } = editor.selection;

      // If we're pasting a URL, we want to paste it as a link wrapped around the selected text
      const isSelectionInline = !isElementBlock(editor.selection.anchor.path);
      const pastedText = event.clipboardData.getData('text');
      if (isSelectionInline && isURL(pastedText)) {
        if (Range.isCollapsed(editor.selection)) {
          Transforms.insertText(editor, pastedText);
        } else {
          pasteUrlAsLink(editor, pastedText);
        }
        return;
      }

      // Deal with tables when the selection is entirely within the same one one:
      const [, anchorTablePath] = Editor.above(editor, { at: anchor, match: Table.isTable }) || [];
      const [, focusTablePath] = Editor.above(editor, { at: focus, match: Table.isTable }) || [];
      if (anchorTablePath && focusTablePath && isEqual(anchorTablePath, focusTablePath)) {
        pasteTable(editor, pasted);
        return;
      }

      // Make sure we are not adding an unnecessary paragraph
      if (pasted && pasted.length === 1 && Paragraph.isParagraph(pasted[0])) pasted = pasted[0].children;

      if (pasted) {
        Editor.withoutNormalizing(editor, () => {
          // If we have selected across tables and other blocks,
          // remove the table(s) entirely before inserting text correctly.
          if (!isEqual(anchorTablePath, focusTablePath)) editor.deleteFragment();
          Transforms.insertNodes(editor, pasted, { at: editor.selection, select: true });
        });
      }
    },
    [baseUrl, editor, isDev, isElementBlock, isTest],
  );
}
