export enum NodeTypes {
  blockquote = 'blockquote',
  code = 'code',
  codeTabs = 'code-tabs',
  embed = 'embed',
  embedBlock = 'embed-block',
  emoji = 'emoji',
  emphasis = 'emphasis',
  faEmoji = 'fa-emoji',
  figcaption = 'figcaption',
  figure = 'figure',
  html = 'html',
  htmlBlock = 'html-block',
  i = 'i',
  image = 'image',
  imageBlock = 'image-block',
  link = 'link',
  list = 'list',
  listItem = 'list-item',
  markdown_char = 'markdown-char',
  menuHandle = 'menu-handle',
  paragraph = 'paragraph',
  readmeGlossaryItem = 'readme-glossary-item',
  readmeVariable = 'readme-variable',
  recipe = 'recipe',
  reusableContent = 'reusable-content',
  root = 'root',
  strong = 'strong',
  table = 'table',
  tableCell = 'table-cell',
  tableHeader = 'table-header',
  tableRow = 'table-row',
  text = 'text',
  variable = 'variable',
}

export enum MenuHandleTypes {
  emoji = 'emoji',
  glossary = 'glossary',
  link = 'link',
  mdxVariable = 'mdx-variable',
  reusableContent = 'reusable-content',
  variable = 'variable',
}

export enum MenuActionTypes {
  close = 'close',
  down = 'down',
  init = 'init',
  open = 'open',
  search = 'search',
  up = 'up',
}

export enum MenuConfigActionTypes {
  dataLoaded = 'dataLoaded',
  loadNextPage = 'loadNextPage',
  revalidate = 'revalidate',
  shortcut = 'shortcut',
}

export enum DragItems {
  Block = 'block',
  CodeTab = 'code-tab',
  // @note: This should come from react-dnd-html5-backend, but TS doesn't
  // support 'computed values' in enums. If this [PR][pr] is ever merged and we've
  // updated typescript, then we can change this.
  //
  // [pr]: https://github.com/microsoft/TypeScript/pull/50528
  File = '__NATIVE_FILE__',
}

export enum ImageMenuActionTypes {
  close = 'close',
  finishUpload = 'finishUpload',
  init = 'init',
  open = 'open',
  updateUpload = 'updateUpload',
}

export enum ImageMenuDropdowns {
  size = 'Size',
  upload = 'Upload',
  url = 'URL',
}

export enum ImageAlignValues {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum TableAlignment {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum EmbedTypes {
  default = 'default',
  github = 'github',
  iframe = 'iframe',
  jsfiddle = 'jsfiddle',
  pdf = 'pdf',
  youtube = 'youtube',
}
