import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { Editor, Node, Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import useEventListener from '@core/hooks/useEventListener';

import LinkEditorUI from '@ui/LinkEditor';
import { Link } from '@ui/MarkdownEditor/editor/blocks';

import MenuDropdown from '../MenuDropdown';

const LinkEditor = ({ projectBaseUrl, state: [{ ref: pathRef, selection }, dispatch] }) => {
  const editor = useSlateStatic();
  const [target, setTarget] = useState({ current: null });
  const ref = useRef();
  const path = pathRef?.current;

  // @note sadly, link can be stale at this point
  const node = path ? Node.get(editor, path) : null;
  const open = !!(node && target.current);
  const [attrs, setAttrs] = useState({});

  const onChange = useCallback(event => {
    const { name, value } = event.target;
    setAttrs(old => ({ ...old, [name]: value }));
  }, []);

  const updateLink = useCallback(() => {
    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, { at: path });
      Link.insertLink(editor, attrs, { at: path });
    });
  }, [attrs, editor, path]);

  const close = useCallback(
    event => {
      event?.preventDefault();

      updateLink();

      dispatch({ type: 'close' });
      setTarget({ current: null });

      Transforms.select(editor, Editor.after(editor, Editor.end(editor, path)));
      ReactEditor.focus(editor);
    },
    [dispatch, editor, path, updateLink],
  );

  useEventListener(
    'keydown',
    useCallback(event => open && event.key === 'Escape' && close(), [close, open]),
  );

  useEffect(() => {
    if (!node || target.current) return;

    const el = ReactEditor.toDOMNode(editor, node);

    setTarget({ current: el });
    setAttrs({ label: node.label, url: node.url });
  }, [editor, node, target]);

  useEffect(() => {
    if (!open || !ref.current) return;
    // @todo: I can't figure out why this doesn't get focused the first time
    // you click on a link! Deferring it till after a render appears to be a
    // workaround?
    window.requestAnimationFrame(() => {
      const el = ref.current.querySelector(`input[name="${selection.node}"]`);

      el.setSelectionRange(selection.startOffset, selection.endOffset);
      el.focus();
    });
  }, [ref, open, selection]);

  return (
    <MenuDropdown close={close} open={open} target={target}>
      <LinkEditorUI
        ref={ref}
        baseUrl={projectBaseUrl}
        label={attrs.label}
        onChange={onChange}
        onSubmit={close}
        url={attrs.url}
      />
    </MenuDropdown>
  );
};

LinkEditor.propTypes = {
  projectBaseUrl: PropTypes.string,
  state: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        link: PropTypes.object,
        ref: PropTypes.shape({
          current: PropTypes.array,
          unref: PropTypes.func,
        }),
        selection: PropTypes.shape({
          endOffset: PropTypes.number,
          node: PropTypes.string,
          startOffset: PropTypes.number,
        }),
      }),
      PropTypes.func,
    ]),
  ),
};

export default memo(LinkEditor);
