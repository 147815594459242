import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Range } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { getActiveFormats } from '@ui/MarkdownEditor/editor/decorations';

import { Link } from '../blocks';
import IconMenu, { Divider } from '../IconMenu';
import { Strong, Emphasis, Delete, InlineCode } from '../leaves';
import { useReusableContentMenu } from '../ReusableContentMenu';
import { acrossBlocks } from '../selection';
import { useVariableMenu } from '../VariableMenu';

import FormatButton from './FormatButton';

const getScrollParent = node => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
};

const InlineToolbar = ({ linkEditorOpen }) => {
  const editor = useSlateStatic();
  const [{ open: variableMenuOpen }] = useVariableMenu();
  const [{ open: reusableContentMenuOpen }] = useReusableContentMenu();
  const [activeFormats, setActiveFormats] = useState(null);
  const isOpen = !!activeFormats && !variableMenuOpen && !reusableContentMenuOpen;
  const [hidden, setHidden] = useState(false);

  const setPosition = useCallback(
    ref => {
      if (!ref.current || !window || !isOpen) return;

      const domSelection = window.getSelection();
      if (!domSelection?.rangeCount) return;

      /*
       * The InlineToolbar attaches to the selection, so we calculate
       * positional values relative to that.
       */
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      const menuRect = ref.current.getBoundingClientRect();

      const top = `calc(${rect.top - menuRect.height}px - 0.25em)`;
      const left = `${(rect.left + rect.right) / 2 - menuRect.width / 2}px`;

      ref.current.style.top = top;
      ref.current.style.left = left;

      const editorEl = ReactEditor.toDOMNode(editor, editor);

      const container = getScrollParent(editorEl)?.parentNode;
      if (!container || container.tagName === 'HTML' || container instanceof Document) return;

      /*
       * When within a modal, or any scroll container, we want the toolbar to
       * disappear if we exit the boundaries.
       */
      const containerBox = container.getBoundingClientRect();
      const topOffset = rect.top - containerBox.top;
      const bottomOffset = rect.bottom - containerBox.bottom;

      if (topOffset < 0 || bottomOffset > 0) {
        ref.current.style.opacity = 0;
        ref.current.style.transition = 'initial';
        setHidden(true);
      } else {
        ref.current.style.opacity = 1;
        ref.current.style.transition = null;
        setHidden(false);
      }
    },
    [editor, isOpen],
  );

  const open = useCallback(() => {
    if (!linkEditorOpen && editor.selection && !Range.isCollapsed(editor.selection) && !acrossBlocks(editor)) {
      setActiveFormats(getActiveFormats(editor));
    }
  }, [editor, linkEditorOpen]);

  const close = useCallback(() => setActiveFormats(null), []);

  useEffect(() => {
    editor.inlineToolbar = {
      isOpen,
      open,
      close,
    };
  }, [close, editor, isOpen, open]);

  return (
    <IconMenu
      className="InlineToolbar"
      data-testid="inline-toolbar"
      handleClose={close}
      hidden={hidden}
      open={isOpen}
      setPosition={setPosition}
    >
      {[Strong, Emphasis, Delete].map(leaf => (
        <FormatButton key={leaf.type} active={!!activeFormats?.[leaf.type]} icon={leaf.icon} type={leaf.type} />
      ))}
      <Divider />
      {[Link, InlineCode].map(leaf => (
        <FormatButton key={leaf.type} active={!!activeFormats?.[leaf.type]} icon={leaf.icon} type={leaf.type} />
      ))}
    </IconMenu>
  );
};

InlineToolbar.propTypes = {
  linkEditorOpen: PropTypes.bool,
};

export default memo(InlineToolbar);
