import React, { useCallback } from 'react';
import { Node, Path } from 'slate';
import { useSlateStatic } from 'slate-react';

import { Table } from '../blocks';
import { Button } from '../IconMenu';

import useTableToolbar, { TableToolbarActionTypes } from './useTableToolbar';

interface Props {
  isColumn: boolean;
  path: Path;
  remove?: boolean;
}

const AddButton = ({ isColumn, remove = false, path }: Props) => {
  const editor = useSlateStatic();
  const [, dispatch] = useTableToolbar();

  const onPointerUp = useCallback(() => {
    Table.operations.addOrRemove(editor, path, { isColumn, remove });

    if (remove && !Node.has(editor, path)) {
      if (!(isColumn ? Path.hasPrevious(path) : Path.hasPrevious(Path.parent(path)))) return;

      const currentCell = path[path.length - 1];
      const next = isColumn ? Path.previous(path) : [...Path.previous(Path.parent(path)), currentCell];

      dispatch({ type: TableToolbarActionTypes.SetPath, path: next });
    }
  }, [editor, path, isColumn, remove, dispatch]);

  return (
    <Button
      data-testid={`${remove ? 'remove' : 'add'}-${isColumn ? 'column' : 'row'}-button`}
      icon={remove ? 'minus-circle' : 'plus-circle'}
      onPointerUp={onPointerUp}
    ></Button>
  );
};

export default AddButton;
