import type { RenderElementProps } from 'slate-react';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Editor, Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import classy from '@core/utils/classy';

import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { ImageAlignValues } from '@ui/MarkdownEditor/enums';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { ImageElement } from '@ui/MarkdownEditor/types';

import { isFigure } from '../Figure/shared';

import FileInput from './FileInput';
import Placeholder from './Placeholder';
import classes from './style.module.scss';

interface ImageProps extends RenderElementProps {
  element: ImageElement;
}

// eslint-disable-next-line jsx-a11y/alt-text
const Img = (props: JSX.IntrinsicElements['img'] & { align?: ImageAlignValues }) => <img {...props} />;

const Image = ({ attributes, children, element }: ImageProps) => {
  const editor = useSlateStatic();
  const [, dispatch] = editor.imageMenu;
  const { isInline, url, alt, title, align, width, border } = element;

  const Tag = isInline ? 'span' : 'div';
  const tagStyles = isInline ? { display: 'inline-block' } : {};

  // Block images are not wrapped in a paragraph in the editor, but
  // they are when rendered in rdmd, so add the .p class
  // here to make sure the styles are have parity.
  const tagClass = !isInline ? 'p' : '';

  const fileInput = <FileInput element={element} />;

  const onClick = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    const figure = Editor.above(editor, { at: path, match: isFigure });

    Transforms.select(editor, figure ? figure[1] : path);
    dispatch({ type: ImageMenuActionTypes.open, payload: { node: element } });
  }, [dispatch, editor, element]);

  return url ? (
    <Tag {...attributes} className={tagClass} style={tagStyles}>
      <SelectionWrapper element={element} onClick={onClick}>
        <Img
          align={align}
          alt={alt}
          className={classy(classes.Image, border && 'border', isInline && classes.Image_inline)}
          data-image-menu-target
          data-image-node
          data-testid="editor-image-for-test"
          src={url}
          title={title}
          width={width}
        />
        {children}
        {fileInput}
      </SelectionWrapper>
    </Tag>
  ) : (
    <SelectionWrapper blockType={`${element.type}-empty`} {...attributes} element={element}>
      <Placeholder element={element} />
      {children}
      {fileInput}
    </SelectionWrapper>
  );
};

Image.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node.isRequired,
  element: PropTypes.object,
};

export default Image;
