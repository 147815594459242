import { Editor, Transforms } from 'slate';

import { Emoji, MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import { afterWhitespace } from '@ui/MarkdownEditor/editor/onKeyDown/utils';

const onKeyDown = (event, editor) => {
  const [{ open, filtered, rangeRef, selected }, dispatch] = editor.emojiMenu;

  if (open) {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();
        event.stopPropagation();

        const { name } = filtered[selected];

        if (name) {
          Emoji.operations.insertEmoji(editor, name, { at: rangeRef.current });
        }

        dispatch({ type: 'close' });
        break;
      }
      case ':': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: 'close' });
        Transforms.insertText(editor, ':');
        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: event.key === 'ArrowDown' ? 'down' : 'up' });

        break;
      }
      case 'Escape': {
        event.preventDefault();
        event.stopPropagation();

        const entry = Editor.above(editor, { at: rangeRef.current, match: MenuHandle.is });
        dispatch({ type: 'close' });
        if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

        break;
      }
      default:
        break;
    }
  } else if (event.key === ':' && afterWhitespace(editor)) {
    event.preventDefault();
    event.stopPropagation();

    // @note Though this is the default behavior, by calling it ourselves,
    // we are able to dispatch an initialize action after the : insertion.
    Transforms.insertText(editor, ':');
    Transforms.wrapNodes(
      editor,
      { type: MenuHandle.type, menuType: 'emoji' },
      {
        at: {
          anchor: {
            path: editor.selection.anchor.path,
            offset: editor.selection.anchor.offset - 1,
          },
          focus: editor.selection.focus,
        },
        split: true,
      },
    );

    // if there's text after, slate moves the cursor into it
    // this moves the selection back to the menu handle
    if (!Editor.above(editor, { match: MenuHandle.isMenuHandle }))
      Transforms.select(editor, Editor.before(editor, editor.selection));
  }
};

export default onKeyDown;
