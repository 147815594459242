import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Icon from '@ui/Icon';
import useClassName from '@ui/MarkdownEditor/useClassName';
import MenuDivider from '@ui/Menu/Divider';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

const Item = ({ category, icon, label, name, onClick: onClickHandler, selected, shortcut, soon }) => {
  const onClick = useCallback(event => onClickHandler(event, name), [onClickHandler, name]);
  const className = useClassName(
    classes.SlashMenuItem,
    selected && 'selected',
    category === 'Components' && classes.SlashMenuItem_component,
  );

  return (
    <MenuItem
      key={name}
      aria-current={selected}
      className={className}
      data-category={category}
      data-name={name}
      disabled={soon}
      icon={<Icon name={icon} />}
      onClick={onClick}
      role="menuitem"
    >
      <span>
        {label || name}
        {!!soon && <span style={{ float: 'right' }}>🚧</span>}
        <span className={classes['SlashMenuItem-shortcut']}>{shortcut}</span>
      </span>
    </MenuItem>
  );
};

Item.propTypes = {
  category: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  shortcut: PropTypes.string,
  soon: PropTypes.bool,
};

const SlashMenuItems = ({ onClick, results, selectedName }) => {
  if (results.length === 0) {
    return (
      <MenuItem className={classes.NoResults} disabled>
        No results
      </MenuItem>
    );
  }

  return results.map(([category, ...subset], idx) => (
    <React.Fragment key={category}>
      {idx > 0 && <MenuDivider />}
      <MenuHeader data-category={category} data-header>
        {category}
      </MenuHeader>
      {subset.map(({ icon, label, name, shortcut, soon }) => (
        <Item
          key={name}
          category={category}
          icon={icon}
          label={label}
          name={name}
          onClick={onClick}
          selected={selectedName === name}
          shortcut={shortcut}
          soon={soon}
        />
      ))}
    </React.Fragment>
  ));
};

SlashMenuItems.propTypes = {
  onClick: PropTypes.func.isRequired,
  results: PropTypes.array,
  selectedName: PropTypes.string,
};

export default React.memo(SlashMenuItems);
