import { Editor, Path, Transforms } from 'slate';

import { isEmptyNode } from '@ui/MarkdownEditor/emptyNode';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';

import { defaultImage, isImage } from './shared';

export const insertImage = (editor, { at = editor.selection } = {}) => {
  const [, dispatch] = editor.imageMenu;
  const [node, path] = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) }) || [];

  // If the node is empty, insert the image here: otherwise, insert on the next line
  const isEmpty = isEmptyNode(node);
  const imageAt = isEmpty ? path : Path.next(path);

  Editor.withoutNormalizing(editor, () => {
    if (isEmpty) Transforms.delete(editor, { at: imageAt });
    Transforms.insertNodes(editor, defaultImage({}), { at: imageAt, select: true });
  });

  const [newImage] = Editor.nodes(editor, { at: imageAt, match: isImage }).next().value || [];
  if (newImage) {
    dispatch({ type: ImageMenuActionTypes.open, payload: { node: newImage } });
  }
};

export default { insertImage };
