import type { RenderElementProps } from 'slate-react';

import React from 'react';

import type { MarkdownCharInline } from '@ui/MarkdownEditor/types';

const Component = ({ attributes, children, element }: RenderElementProps & { element: MarkdownCharInline }) => {
  return (
    <span {...attributes} contentEditable={false}>
      {element.char}
      {children}
    </span>
  );
};

export default Component;
