import type { Editor } from 'slate';

import { Node, Path } from 'slate';

import { Paragraph } from '../blocks';

const isInsertTrailingParagraph = (editor: Editor) => {
  const [insertOp] = editor.operations;

  return (
    editor.operations.length === 2 &&
    insertOp.type === 'insert_node' &&
    editor.operations[1].type === 'set_selection' &&
    Path.equals(insertOp.path, [editor.children.length - 1]) &&
    Paragraph.is(insertOp.node) &&
    Node.string(insertOp.node) === ''
  );
};

export default isInsertTrailingParagraph;
