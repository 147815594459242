import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import { deserialize, serialize } from './serialize';
import { isJsxFlowElement, type } from './shared';

const JsxFlow = {
  Component,
  deserialize,
  normalizeNode,
  onKeyDown,
  serialize,
  type,
  is: isJsxFlowElement,
  isJsxFlowElement,
  isVoid: true,
};

export default JsxFlow;
