import { daysRemaining } from '@readme/iso/src/utils/date';
import { useContext, useMemo } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';

/**
 * Client-side util that mirrors the Angular factory logic for determining if a project is in an active trial
 * @see https://github.com/readmeio/readme/blob/56ff6db54170e2dd40402b118512023082efbb0a/public/js/factories/Project.js#L37-L45
 * @returns if project is in active trial
 */
function useIsTrialActive() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { is_active: isActive, trial, gracePeriod, plan, planOverride } = project;

  const isFreePlan = useMemo(() => !planOverride && ['free', 'freelaunch'].includes(plan), [plan, planOverride]);

  const isTrialExpired = useMemo(() => {
    if (gracePeriod && gracePeriod.enabled) return false;
    return isFreePlan && trial && trial.trialDeadlineEnabled && daysRemaining(trial?.trialEndsAt) === 0;
  }, [gracePeriod, isFreePlan, trial]);

  return !!isFreePlan && !isActive && !isTrialExpired;
}

export default useIsTrialActive;
