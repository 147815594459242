import { type } from './shared';

export const deserialize = ({ position, ...node }, deeper) => {
  node.children.forEach(cell => {
    cell.header = true;
  });

  return {
    ...node,
    type,
    children: deeper(node),
  };
};
