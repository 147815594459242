export const sampleData = [
  {
    category: 'Documentation',
    deprecated: undefined,
    name: 'Getting Started',
    slug: 'getting-started',
    type: 'doc',
  },
  {
    category: 'Documentation',
    deprecated: undefined,
    name: 'Test Page',
    slug: 'test-page',
    type: 'doc',
  },
  {
    category: 'Documentation',
    deprecated: undefined,
    name: 'Examples',
    slug: 'examples',
    type: 'doc',
  },
  {
    category: 'Custom Page',
    deprecated: undefined,
    name: 'My Custom Page',
    slug: 'custom-page',
    type: 'custom',
  },
  {
    category: 'Custom Page',
    deprecated: undefined,
    name: 'Owl Facts',
    slug: 'owl-facts',
    type: 'custom',
  },
  {
    category: 'Changelog',
    deprecated: undefined,
    name: 'Weekly Changelog',
    slug: 'weekly-changelog',
    type: 'changelog',
  },
  {
    category: 'Changelog',
    deprecated: undefined,
    name: 'Owlet Update',
    slug: 'owlet-update',
    type: 'changelog',
  },
  {
    category: 'Changelog',
    deprecated: undefined,
    name: 'New Changes',
    slug: 'new-changes',
    type: 'changelog',
  },
];
