import React, { useCallback } from 'react';
import { Editor } from 'slate';
import { useSlateStatic } from 'slate-react';

import Icon from '@ui/Icon';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';

import { Link } from '../blocks';
import MenuDropdown from '../MenuDropdown';

import classes from './style.module.scss';
import useUrlMenu from './useUrlMenu';

const UrlMenu = () => {
  const editor = useSlateStatic();
  const [{ open, range, target }, dispatch] = useUrlMenu();
  const text = range ? Editor.string(editor, range) : '';

  const close = useCallback(() => dispatch({ type: MenuActionTypes.close }), [dispatch]);

  const customizeLabel = useCallback(() => {
    if (!range) return;

    close();
    Link.insertLink(editor, { label: text, url: text }, { at: range });
  }, [close, editor, range, text]);

  return (
    <MenuDropdown close={close} open={open} target={target}>
      <Menu className={classes.UrlMenu}>
        <MenuItem onClick={customizeLabel}>
          <Icon name="edit-2" />
          Customize label…
        </MenuItem>
        <MenuDivider />
        <MenuItem color="blue" href={text} TagName="a" target="_blank" title={text}>
          <span className={classes.UrlMenu_Link}>{text}</span>
        </MenuItem>
      </Menu>
    </MenuDropdown>
  );
};

export default React.memo(UrlMenu);
export { default as useUrlMenu } from './useUrlMenu';
