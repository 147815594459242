import React from 'react';

import { HeadingPropTypes } from '../types';

import classes from './style.module.scss';

const HeadingElement = ({ attributes, children, element }) => {
  const Tag = `h${element.depth || 2}`;

  return (
    <Tag {...attributes} className={classes.Heading}>
      {children}
    </Tag>
  );
};

HeadingElement.propTypes = HeadingPropTypes;

export default HeadingElement;
