import { Editor, Node, Path, Text, Transforms } from 'slate';

import { deserializer, getNode } from '@ui/MarkdownEditor/editor/parser';
import type { Normalizer, ReadmeElement } from '@ui/MarkdownEditor/types';

import { isInJsxComment } from '../JsxComment/shared';

import { isInTable, type } from './shared';

const convertToTable: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!Text.isText(node) || isInTable(editor, path) || isInJsxComment(editor, path)) return next();

    const string = Node.string(node);
    // @perf: we can short circuit the deserializer with a quick regex
    // (check it out here: https://regexr.com/6m8td)
    if (!string.match(/\|.*\n(\s*\||\s*:?-+:?\s*\|)/)) return next();

    const mdast = deserializer(string);
    // doesn't look like markdown formatting
    if (!mdast) return next();

    const table = getNode(mdast, (n: ReadmeElement) => n.type === type);
    if (!table) return next();

    Editor.withoutNormalizing(editor, () => {
      const at = Path.parent(path);
      Transforms.removeNodes(editor, { at });
      Transforms.insertNodes(editor, editor.deserialize(string, { mdast }), { at, select: true });
    });
  };

export default [convertToTable];
