import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import { deserialize, serialize } from './serialize';
import { isJsxComment, type } from './shared';

const JsxComment = {
  Component,
  deserialize,
  hasInlineMd: true,
  is: isJsxComment,
  isInline: true,
  isJsxComment,
  normalizeNode,
  onKeyDown,
  serialize,
  type,
};

export default JsxComment;
