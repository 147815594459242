import type { BaseSelection, Node, NodeEntry } from 'slate';

import { Editor, Element, Path } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type {
  ReadmeEditor,
  TableCellElement,
  TableElement,
  TableHeaderCellElement,
  TableHeaderElement,
  TableRowElement,
} from '@ui/MarkdownEditor/types';

import TableCell from '../TableCell';
import TableHeader from '../TableHeader';
import TableRow from '../TableRow';

export const type = 'table';
export const isTable = (node: Node): node is TableElement => Element.isElementType(node, type);
export const isTableEntry = (entry: NodeEntry | undefined): entry is [TableElement, Path] =>
  typeof entry !== 'undefined' && isTable(entry[0]) && Path.isPath(entry[1]);
export const isTableRow = (node: Node): node is TableHeaderElement | TableRowElement =>
  TableHeader.isTableHeader(node) || TableRow.isTableRow(node);
export const isTableRowEntry = (
  entry: NodeEntry | undefined,
): entry is NodeEntry<TableHeaderElement | TableRowElement> =>
  typeof entry !== 'undefined' && isTableRow(entry[0]) && Path.isPath(entry[1]);

export const isInTable = (editor: ReadmeEditor, at: BaseSelection | Path = editor.selection) =>
  at && !!Editor.above(editor, { at, match: isTable });

export const defaultTable = (): TableElement => ({
  type,
  children: [
    {
      type: TableHeader.type as 'table-header',
      children: Array.from({ length: 3 }, () =>
        emptyNode({ type: TableCell.type, header: true } as Partial<TableHeaderCellElement>),
      ),
    },
    {
      type: TableRow.type as 'table-row',
      children: Array.from({ length: 3 }, () =>
        emptyNode<TableCellElement>({ type: TableCell.type } as Partial<TableCellElement>),
      ),
    },
    {
      type: TableRow.type as 'table-row',
      children: Array.from({ length: 3 }, () =>
        emptyNode<TableCellElement>({ type: TableCell.type } as Partial<TableCellElement>),
      ),
    },
  ],
  align: ['left', 'left', 'left'],
});
