import { Editor, Transforms } from 'slate';

import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { trailingBlankChildren } from '../../utils';

import { isReusableContent, isAtEdgeOfReusableContent } from './shared';

const enter: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Enter' && !event.shiftKey && isAtEdgeOfReusableContent(editor, { edge: 'end' }))) {
    return;
  }

  const [block, path] = Editor.above(editor, { match: isReusableContent }) || [];
  const blankLines = [...trailingBlankChildren(editor, path)];
  if (!blankLines.length || !block) return;

  event.preventDefault();
  event.stopPropagation();

  blankLines.slice(1).forEach(() => Transforms.mergeNodes(editor));
  Transforms.unwrapNodes(editor, { match: isReusableContent, split: true });
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && isAtEdgeOfReusableContent(editor, { edge: 'start' }))) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.unwrapNodes(editor, { match: isReusableContent });
};

export default [backspace, enter];
