/* eslint-disable consistent-return */
import { Editor, Node, Transforms } from 'slate';

import { deserializer, getNode } from '@ui/MarkdownEditor/editor/parser';
import { isInside } from '@ui/MarkdownEditor/editor/selection';

import { insertCodeTab } from './operations';
import { isCodeTabs } from './shared';

const convertToCodeTab = (editor, path) => {
  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, { at: path });
    insertCodeTab(editor, path);
  });
};

const parseNewCodeTabs =
  next =>
  (editor, [node, path]) => {
    if (node.type !== 'paragraph') return next();

    // Up to three spaces followed by a minimum of three backticks or tildes OR
    // four spaces
    const string = Node.string(node);
    const match = string.match(/^((?: {0,3}(?:```|~~~))|(?: {4}))/);
    if (!match) return next();

    if (isInside(editor, [node, path])) return next();

    if (match[0].substring(0, 4) === '    ') {
      convertToCodeTab(editor, path);
      return;
    }

    const mdast = deserializer(string);
    if (!mdast) return next();

    const code = getNode(mdast, n => n.type === 'code');
    if (!code) return next();

    convertToCodeTab(editor, path);
  };

const ensureActive =
  next =>
  (editor, [node, path]) => {
    if (!(isCodeTabs(node) && !('active' in node))) return next();

    Transforms.setNodes(editor, { active: 0 }, { at: path });
  };

export default [parseNewCodeTabs, ensureActive];
