/* eslint-disable consistent-return */
import type { Node as MdNode } from 'mdast';
import type { Path } from 'slate';

import { Element, Node, Text, Transforms } from 'slate';

import type { HeadingElement, Normalizer } from '@ui/MarkdownEditor/types';

import { deserializer, getNode } from '../../parser';
import { offsetToPoint } from '../../utils';
import { isInJsxComment } from '../JsxComment/shared';
import { isInTable } from '../Table/shared';

const type = 'heading';

const isHeading = (node: Node): node is HeadingElement => Element.isElementType(node, type);
const isMdastHeading = (node: MdNode) => node.type === type;

const isFirst = (path: Path) => path[path.length - 1] === 0;

const parseNewHeading: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (
      !Text.isText(node) ||
      isHeading(Node.parent(editor, path)) ||
      isInTable(editor, path) ||
      isInJsxComment(editor, path) ||
      !isFirst(path)
    )
      return next();

    const string = Node.string(node);
    // @perf: we can short circuit the deserializer with a quick regex
    if (!string.match(/^ {0,3}[-=#]/m)) return next();

    const mdast = deserializer(string);
    if (!mdast) return next();

    const heading = getNode(mdast, isMdastHeading);
    if (!heading) return next();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, data, position, ...element } = heading;

    const at = {
      anchor: offsetToPoint([node, path], position.start.offset),
      focus: offsetToPoint([node, path], position.end.offset),
    };

    Transforms.setNodes(editor, element, { at, split: true });
  };

const updateDepth: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isHeading(node)) return next();

    let count = -1;
    for (count = 0; count <= 7; count += 1) {
      if (Node.string(node)[count] !== '#') {
        break;
      }
    }

    if ([0, 7].includes(count)) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: path });
    } else if (count && count !== node.depth) {
      Transforms.setNodes(editor, { depth: count }, { at: path });
    } else {
      next();
    }
  };

const normalizeNode = [parseNewHeading, updateDepth];

export default normalizeNode;
