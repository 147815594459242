import React from 'react';

import { isCmdOrCtrl, isShiftKey } from '@ui/MarkdownEditor/editor/eventUtils';

import { toggleFormatOnKeyDown } from './shared';
import { LeafPropTypes } from './types';

const type = 'delete';

const isCmdShiftS = event => isCmdOrCtrl(event) && isShiftKey(event, 's');
const cmdShiftS = toggleFormatOnKeyDown(type, isCmdShiftS);

const DeleteLeaf = ({ leaf, ...props }) => <del {...props} />;

DeleteLeaf.propTypes = LeafPropTypes;

const Delete = {
  decorator: '~~',
  icon: 'strikethrough',
  onKeyDown: cmdShiftS,
  render: DeleteLeaf,
  type,
};

export default Delete;
