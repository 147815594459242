import type { ParagraphElement, ReadmeBlock, ReadmeNode } from './types';

import { Text } from 'slate';

import Paragraph from './editor/blocks/Paragraph';

const emptyNode = <NodeType extends ReadmeBlock = ParagraphElement>(props: Partial<NodeType> = {}): NodeType => {
  const node = {
    ...props,
  };

  node.children ??= [{ text: '' }];
  node.type ??= Paragraph.type;

  return node as NodeType;
};

export const isEmptyNode = (node?: ReadmeNode): node is ParagraphElement =>
  !!node &&
  Paragraph.is(node) &&
  node.children.length === 1 &&
  Text.isText(node.children[0]) &&
  node.children[0].text === '';

export default emptyNode;
