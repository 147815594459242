import type { ReadImageType } from '@readme/api/src/mappings/image/types';

import Cookie from 'js-cookie';
import { useCallback, useContext } from 'react';

// eslint-disable-next-line no-restricted-imports
import type { ProjectContextValue } from '@core/context';
// eslint-disable-next-line no-restricted-imports
import { ProjectContext } from '@core/context';

export type UploadApiNextImageResponse = ReadImageType;

export interface UploadApiNextImageProps {
  /**
   * Image file to upload
   */
  file: File;
  /**
   * When provided, the images API will resize the image to match this height in
   * number of pixels. GIFs are exempt and cannot be resized.
   */
  maxHeight?: number;
  /**
   * Project subdomain is required by the v2 API endpoint and must be provided.
   */
  subdomain: string;
}

async function uploadApiNextImage({ file, maxHeight, subdomain }: UploadApiNextImageProps) {
  const formData = new FormData();
  formData.append('file', file);

  const params = new URLSearchParams();
  if (maxHeight) {
    params.set('resize_height', maxHeight.toString());
  }

  return fetch(`/${subdomain}/api-next/v2/images?${params.toString()}`, {
    headers: {
      'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') || '',
    },
    body: formData,
    method: 'POST',
  }).then(res => {
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return res.json() as Promise<UploadApiNextImageResponse>;
  });
}

/**
 * Returns a bound "upload" function that consumers can call to upload form
 * images to the v2 API endpoint under the current project's subdomain.
 * @todo This is a temporary solution to get a project's `subdomain` from the
 * `ProjectContext` because Angular's `directive` build is running
 * `webpack@1.0.0` and cannot handle Zustand stores. Once that directive is out
 * of our build pipeline, remove this hook and read the project `subdomain`
 * directly from the `ProjectStore` instead inside `uploadApiNext()`.
 */
export default function useUploadApiNextImage() {
  const {
    project: { subdomain },
  } = useContext(ProjectContext) as ProjectContextValue;

  return useCallback(
    (args: Omit<UploadApiNextImageProps, 'subdomain'>) => {
      return uploadApiNextImage({ ...args, subdomain });
    },
    [subdomain],
  );
}
