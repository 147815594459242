import { Editor, Node, Range, Transforms } from 'slate';

import { LINK_ICON, isLink, getUrlLocation, getUrlPortion } from './shared';

// eslint-disable-next-line consistent-return
const openLinkEditor = next => (editor, text) => {
  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) return next();

  const link = Editor.above(editor, { match: isLink });
  if (!link || !isLink(link[0])) return next();

  const range = getUrlLocation(link);

  if (!Range.includes(range, selection.anchor)) return next();

  // @note: There's a fault with the normalizers where they won't allow
  // editing an empty url, so we have to set it manually.
  const url = getUrlPortion(editor, link);
  if (!url) {
    Transforms.setNodes(editor, { url: text }, { at: link[1] });
  }

  const ref = Editor.pathRef(editor, link[1]);
  setTimeout(() => {
    const [, dispatch] = editor.linkEditor;
    const node = Node.get(editor, ref.current);

    if (!url) {
      // @todo: there's some kind of reconciliation bug in slate. We're
      // ignoring the-text-to-be-inserted above so it never makes it into our
      // editor, but it still makes it into the DOM. If we trigger an update,
      // it should clear it out.
      Transforms.insertText(editor, LINK_ICON, { at: getUrlLocation(Editor.node(editor, ref.current)) });
    }

    dispatch({ type: 'open', payload: { link: node, ref, selection: editor.selection } });
  }, 0);
};

export default openLinkEditor;
