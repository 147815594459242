import type { Ref } from 'react';
import type { ConnectDragSource } from 'react-dnd';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

interface Props {
  className?: string;
  drag?: ConnectDragSource;
  isReusableContent?: boolean;
  tippyRef?: Ref<HTMLDivElement>;
}

const BlockMenuHandle = ({ className: _className, drag, isReusableContent, tippyRef }: Props) => {
  const bem = useClassy(classes, 'BlockMenu_Handle');

  return (
    <div ref={tippyRef}>
      <Button
        ref={drag}
        className={bem('&', _className, isReusableContent && '-reusableContent')}
        data-testid={'block-menu-handle'}
        kind="secondary"
        size="sm"
        text
      >
        <Icon color="gray80" name="menu" title={undefined} />
      </Button>
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<HTMLDivElement, Props>((props, ref) => <BlockMenuHandle {...props} tippyRef={ref} />);
