import { useState, useMemo } from 'react';

import type { RecipeBlock } from '@ui/MarkdownEditor/types';

const useRecipeMenu = (): [
  RecipeBlock | null | undefined,
  { close: () => void; open: (newNode: RecipeBlock) => void },
] => {
  const [node, setNode] = useState<RecipeBlock | null>();

  return useMemo(() => {
    return [
      node,
      {
        open: (newNode: RecipeBlock) => window.requestAnimationFrame(() => setNode(newNode)),
        close: () => setNode(null),
      },
    ];
  }, [node]);
};

export default useRecipeMenu;
