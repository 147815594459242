import TableCell from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { isTableCell, rdmdType, type } from './shared';

export default {
  Component: TableCell,
  deserialize,
  is: isTableCell,
  isTableCell,
  normalizeNode,
  onKeyDown,
  operations,
  rdmdType,
  serialize,
  type,
};
