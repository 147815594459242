/* eslint-disable consistent-return */
import type { $TSFixMe } from '@readme/iso';
import type { NodeEntry } from 'slate';

import { Editor, Node, Transforms, Text } from 'slate';

import { offsetToPoint } from '@ui/MarkdownEditor/editor/utils';
import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type {
  BlockquoteBlock,
  ListElement,
  ListItemElement,
  Normalizer,
  ReusableContentElement,
  TaskListItemElement,
} from '@ui/MarkdownEditor/types';

import { isBlockquote } from '../Blockquote/shared';
import { isJsxComment } from '../JsxComment/shared';
import { isListItem, defaultListItemProps } from '../ListItem/shared';
import { isParagraph } from '../Paragraph/shared';
import { isReusableContent } from '../ReusableContent/shared';
import { isTableCell } from '../TableCell/shared';

import { isList, defaultListProps } from './shared';

const isValidListParent = (
  node: Node,
): node is BlockquoteBlock | Editor | ListElement | ListItemElement | ReusableContentElement | TaskListItemElement =>
  Editor.isEditor(node) || isList(node) || isBlockquote(node) || isListItem(node) || isReusableContent(node);

const hasValidListParent = (editor: Editor, [node, path]: NodeEntry) =>
  ('type' in node && node.type === 'paragraph' && isValidListParent(Node.parent(editor, path))) ||
  (editor.props?.useMDX && isTableCell(node));

const listMarkerRegex = /(?<newline>\n|^) {0,3}([-+*]|(?<start>\d{1,9})[.)]) /;

const convertToList: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!hasValidListParent(editor, [node, path])) return next();

    const string = Node.string(node);
    const match = string.match(listMarkerRegex);
    if (!match || !match.groups) return next();

    const props: Partial<ListElement> = match.groups.start
      ? { ...defaultListProps, ordered: true, start: parseInt(match.groups.start, 10) }
      : defaultListProps;

    // @note: affinity: 'forward' is a hack to get outside inline nodes like
    // links. Consider the following markdown:
    // ```
    // - before [label](🔗)
    //   -
    // ```
    // Without affinity: 'forward' our ranges anchor would be at the end of the
    // link's text node. The split operation of the wrapNodes transform does
    // weird stuff in that case.
    const rangeRef = Editor.rangeRef(editor, {
      anchor: offsetToPoint([node, path], match.index, { affinity: 'forward' }),
      focus: offsetToPoint([node, path], string.length),
    });

    if (!rangeRef.current) return next();
    if (Editor.above(editor, { at: rangeRef.current.anchor, match: isJsxComment })) {
      return next();
    }

    try {
      Editor.withoutNormalizing(editor, () => {
        // If they have a blank line (but not a paragraph) before starting a
        // newline, we need to insert our own paragraph. Slate's transforms don't
        // like to insert new nodes if you split at the start of a text node?
        if ((match.groups as $TSFixMe).newline.length && match.index === 0) {
          if (rangeRef.current == null) return next();
          Transforms.insertNodes(editor, emptyNode(), { at: rangeRef.current.anchor });
        }

        if (rangeRef.current == null) return next();

        if (!isParagraph(node)) {
          Transforms.wrapNodes(editor, emptyNode(), {
            at: {
              anchor: Editor.start(editor, path),
              focus: Editor.end(editor, path),
            },
            split: true,
            match: n => Editor.isInline(editor, n) || Text.isText(n),
          });
        }

        Transforms.wrapNodes(editor, props as ListElement, {
          at: rangeRef.current,
          split: true,
        });
        Transforms.wrapNodes(editor, defaultListItemProps as unknown as ListElement, {
          at: rangeRef.current,
        });
        Transforms.delete(editor, { at: rangeRef.current.anchor, distance: match[0].length });
      });
    } finally {
      if (rangeRef) rangeRef.unref();
    }
  };

const setDefaultProps: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isList(node)) return next();

    const updates = (Object.keys(defaultListProps) as (keyof ListElement)[]).reduce<Partial<ListElement>>(
      (acc, prop) => {
        if (node[prop] === undefined) {
          const value = defaultListProps[prop];
          acc[prop] = value as $TSFixMe;
        }

        return acc;
      },
      {} as Partial<ListElement>,
    );

    if (!Object.keys(updates).length) return next();

    Transforms.setNodes(editor, updates, { at: path });
  };

const removeEmpty: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isList(node)) return next();

    const invalid = node.children.some(child => !isListItem(child));
    if (!(invalid || node.children.length === 0)) return next();

    Editor.withoutNormalizing(editor, () => {
      // eslint-disable-next-line no-plusplus
      for (let i = node.children.length - 1; i >= 0; i--) {
        if (!isListItem(node.children[i])) {
          Transforms.removeNodes(editor, { at: [...path, i] });
        }
      }

      if (node.children.length === 0) {
        Transforms.removeNodes(editor, { at: path });
      }
    });
  };

const isSameType = (one: ListElement, other: ListElement) => one.ordered === other.ordered;
const isMergeable = (one: Node, two: Node) => isList(one) && isList(two) && isSameType(one, two);

const mergeAdjacentLists: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isValidListParent(node)) return next();

    let found = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < node.children.length; i++) {
      const previous = node.children[i - 1];
      const child = node.children[i];

      if (isMergeable(previous, child)) {
        found = true;
        Transforms.mergeNodes(editor, { at: [...path, i] });
        break;
      }
    }

    if (!found) return next();
  };

const normalizeNode = [convertToList, setDefaultProps, removeEmpty, mergeAdjacentLists];

export default normalizeNode;
