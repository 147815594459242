import type { Props as MarkdownEditorProps } from '../../types';
import type { Dispatch, ReactNode, RefObject } from 'react';
import type { RangeRef } from 'slate';

import fuzzysort from 'fuzzysort';
import React, { useState, useCallback, useEffect, createContext, useContext, useReducer, useMemo, useRef } from 'react';

import makeFetch from '@core/utils/makeFetch';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';

import { bounded } from '../utils';

import { sampleData } from './sampleData';

interface InitAction {
  payload: { rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

interface ReinitAction {
  payload: {
    links: Link[];
  };
  type: 're-init';
}

type LinkMenuAction = CloseAction | DownAction | InitAction | OpenAction | ReinitAction | SearchAction | UpAction;

interface LinkMenuState {
  filtered: Link[] | [];
  links: Link[] | [];
  open: boolean;
  rangeRef: RangeRef | null;
  search: string | null;
  selected: number;
  target: RefObject<HTMLElement> | null;
}

interface LinkMenuReducer {
  (state: LinkMenuState, action: LinkMenuAction): LinkMenuState;
}

interface ProjectData {
  basic?: boolean;
  subdomain?: string;
  useAPIv2?: boolean;
  useTestData?: boolean;
  version?: string;
}

interface Link {
  category: string;
  deprecated?: boolean;
  name: string;
  slug: string;
  type: string;
}

interface Page {
  category: string;
  children: Page[];
  deprecated: boolean;
  slug: string;
  title: string;
}

interface Category {
  pages: Page[];
  reference: boolean;
  title: string;
}

type Changelog = Omit<Page, 'category' | 'deprecated'>;
type CustomPage = Omit<Page, 'category' | 'deprecated'>;

const mapper = (page: Partial<Page>) => ({
  name: page.title,
  slug: page.slug,
});

const mapCategories = (categories: Category[]) => {
  // Recursive function to flatten nested pages
  const flattenPages = ({
    categoryTitle,
    nestedPages,
    reference,
  }: {
    categoryTitle: string;
    nestedPages: Page[];
    reference: boolean;
  }) => {
    return nestedPages.flatMap(({ title, slug, children }) => {
      const type = reference ? ('ref' as const) : ('doc' as const);
      return [
        {
          category: categoryTitle,
          name: title,
          type,
          slug,
        },
        ...(children ? flattenPages({ nestedPages: children, categoryTitle, reference }) : []),
      ];
    });
  };

  // Call the function with nested data starting at the top level parent-level
  return categories.flatMap(({ pages: nestedPages, title: categoryTitle, reference }) => {
    return flattenPages({ nestedPages, categoryTitle, reference });
  });
};

const mapChangelogs = (changeLogs: Changelog[]) =>
  changeLogs.map(changeLog => ({
    ...mapper(changeLog),
    type: 'changelog',
    category: 'Changelog',
  }));

const mapCustomPages = (customPages: CustomPage[]) =>
  customPages.map(customPage => ({
    ...mapper(customPage),
    type: 'page',
    category: 'Custom Page',
  }));

const _initial: LinkMenuState = {
  links: [],
  filtered: [],
  open: false,
  search: null,
  selected: 0,
  rangeRef: null,
  target: null,
};

const byScoreThenAlpha = (left: Fuzzysort.KeysResult<Link>, right: Fuzzysort.KeysResult<Link>) => {
  // right[0] or left[0] is null when we match against the category instead of the name
  if (!right[0] || !left[0]) {
    return 0;
  }
  const byScore = right[0].score - left[0].score;
  return byScore !== 0 ? byScore : left[0].target.localeCompare(right[0].target);
};

const usePageMenuReducer = ({ basic, subdomain, useAPIv2, useTestData, version }: ProjectData) => {
  const [links, setLinks] = useState<Link[]>([]);

  const getJson = useCallback(
    (type: 'changelogs' | 'custompages' | 'docs', cb) => {
      const v2EndpointsByType = {
        changelogs: `/${subdomain}/api-next/v2/changelogs`,
        custompages: `/${subdomain}/api-next/v2/versions/${version}/custom_pages`,
        docs: `/${subdomain}/api-next/v2/versions/${version}/sidebar?page_type=guide`,
      };
      const endpoint = useAPIv2 ? v2EndpointsByType[type] : `/api/projects/${subdomain}/v${version}/data/${type}`;

      return makeFetch(endpoint)
        .then(res => res.json())
        .then(data => (useAPIv2 ? cb(data?.data ?? data) : cb(data)))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.warn(err);
          return [];
        });
    },
    [subdomain, useAPIv2, version],
  );

  const isMounted = useRef(true);
  const initial = { ..._initial, filtered: links, links };
  const reducer: LinkMenuReducer = (state, action) => {
    switch (action.type) {
      case 'init':
        return { ...initial, rangeRef: action.payload.rangeRef };
      case 're-init': {
        return { ...state, ...action.payload };
      }
      case 'open':
        if (state.open) return state;
        return { ...state, target: action.payload.target, open: true };
      case 'up':
        return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
      case 'down':
        return { ...state, selected: bounded(state.selected + 1, state.filtered.length) };
      case 'search': {
        const search = action.payload ? action.payload.replace(/^\[?/, '') : '';
        return search === state.search
          ? state
          : {
              ...state,
              search,
              selected: 0,
              filtered: search
                ? fuzzysort
                    .go(search, state.links, {
                      keys: ['name'],
                    })
                    // @ts-ignore
                    .sort(byScoreThenAlpha)
                    .map((r: Fuzzysort.KeysResult<Link>) => r.obj)
                : state.links || [],
            };
      }
      case 'close':
        state.rangeRef?.unref();
        return {
          ...state,
          open: false,
          target: null,
          rangeRef: null,
        };
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown action in usePageMenu');
        return state;
    }
  };

  const [linkMenuState, dispatch] = useReducer(reducer, initial);

  useEffect(() => {
    if (useTestData) setLinks(sampleData);
    if (basic || useTestData || !subdomain || !version) return;

    if (isMounted.current && links.length === 0) {
      Promise.all([
        getJson('docs', mapCategories),
        getJson('changelogs', mapChangelogs),
        getJson('custompages', mapCustomPages),
      ]).then(json => {
        if (!isMounted.current) return;

        const newLinks = json.flat();
        setLinks(newLinks);
        dispatch({ type: 're-init', payload: { links: newLinks } });
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      isMounted.current = false;
    };
  }, [basic, getJson, links, subdomain, useTestData, version]);

  return useMemo<[LinkMenuState, Dispatch<LinkMenuAction>]>(() => [linkMenuState, dispatch], [linkMenuState]);
};

const PageMenuContext = createContext([_initial, () => {}] as [LinkMenuState, Dispatch<LinkMenuAction>]);

export const PageMenuProvider = ({
  basic,
  children,
  subdomain,
  useAPIv2 = false,
  useTestData = false,
  version = '1.0',
}: Pick<MarkdownEditorProps, 'basic' | 'subdomain' | 'useAPIv2' | 'useTestData' | 'version'> & {
  children: ReactNode;
}) => {
  const value = usePageMenuReducer({ basic, subdomain, useAPIv2, useTestData, version });

  return <PageMenuContext.Provider value={value}>{children}</PageMenuContext.Provider>;
};

export const usePageMenu = () => useContext(PageMenuContext);
export default usePageMenu;
