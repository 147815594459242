import type { ReadType } from '@readme/api/src/mappings/customblock/types';

import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

interface CustomBlocksFormContextProps {
  children: React.ReactNode;
  defaultValues: Partial<ReadType['data']>;
}

/**
 * Sets up the React Hook Form instance that manages our CustomBlocks editing form.
 *
 * Child components should call `useCustomBlockFormContext()` hook to access the
 * form context that is properly typed.
 */
export default function CustomBlocksFormContext({ defaultValues, children }: CustomBlocksFormContextProps) {
  const formValue = useForm<ReadType['data']>({ defaultValues });

  return <FormProvider {...formValue}>{children}</FormProvider>;
}

/**
 * Convenience hook to get our React Hook Form Context with types that match our
 * Custom Block form fields.
 */
export const useCustomBlockFormContext = () => useFormContext<ReadType['data']>();
