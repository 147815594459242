import Emoji from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import * as operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isEmoji } from './shared';

export default {
  is: isEmoji,
  deserialize,
  isEmoji,
  isInline: true,
  isVoid: true,
  Component: Emoji,
  type,
  normalizeNode,
  onKeyDown,
  operations,
  serialize,
};
