import { Editor, Point, Range, Node } from 'slate';

import { offsetToPoint } from '@ui/MarkdownEditor/editor/utils';

const isBlockOrEditor = (editor, node) => Editor.isEditor(node) || Editor.isBlock(editor, node);

// @todo: memoize this onto editor
export const acrossBlocks = editor => {
  if (!editor.selection) return false;

  const { anchor, focus } = editor.selection;

  let anchorBlock;
  try {
    for (const [node] of Node.ancestors(editor, anchor.path, { reverse: true })) {
      if (isBlockOrEditor(editor, node)) {
        anchorBlock = node;
        break;
      }
    }

    let focusBlock;
    for (const [node] of Node.ancestors(editor, focus.path, { reverse: true })) {
      if (isBlockOrEditor(editor, node)) {
        focusBlock = node;
        break;
      }
    }

    return anchorBlock !== focusBlock;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return false;
  }
};

export const isInside = (editor, nodeEntry) => {
  if (!editor.selection) return false;

  const start = Range.start(editor.selection);
  const end = Range.end(editor.selection);

  const inner = {
    anchor: offsetToPoint(nodeEntry, 0),
    focus: offsetToPoint(nodeEntry, Node.string(nodeEntry[0]).length),
  };

  if (Range.isExpanded(editor.selection)) {
    return Point.isAfter(start, inner.anchor) || Point.isBefore(end, inner.focus);
  }

  return Point.isAfter(start, inner.anchor) && Point.isBefore(end, inner.focus);
};
