import type { RenderElementProps } from 'slate-react';

import React, { useCallback, useRef, useMemo } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { v4 as uuid } from 'uuid';

import Button from '@ui/Button';
import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { CodeTabsElement } from '@ui/MarkdownEditor/types';

import CodeEditor from '../../CodeEditor';

import { newCodeTab, updateCode } from './operations';
import { IDS } from './shared';
import classes from './style.module.scss';
import Tab from './Tab';

interface Props extends RenderElementProps {
  element: CodeTabsElement;
}

const CodeTabs = ({ attributes, element, children }: Props) => {
  const editor = useSlateStatic();
  const ref = useRef<HTMLDivElement>(null);
  const id = useMemo(uuid, [uuid]);
  const { active } = element;
  const tab = element.tabs[active];

  const openCodeSettings = useCallback(() => {
    const [, dispatch] = editor.codeSettings;
    const path = ReactEditor.findPath(editor, element);

    ReactEditor.deselect(editor);
    dispatch({ type: MenuActionTypes.open, payload: { path } });
  }, [editor, element]);

  const tabClick = useCallback(
    event => {
      const index = parseInt(event.target.dataset.index || event.target.parentElement.dataset.index, 10);
      const [{ path }, dispatch] = editor.codeSettings;
      const open = !!path;

      /*
       * if we're the active tab open
       * else close
       */
      if (active === index && !open) {
        openCodeSettings();
      } else {
        const at = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { active: index }, { at });

        dispatch({ type: MenuActionTypes.close });
      }
    },
    [active, editor, element, openCodeSettings],
  );

  const moveTab = useCallback(
    (source, index, { active: originalActiveTab } = {}) => {
      const reordered = [...element.tabs];
      const sourceIndex = reordered.findIndex(el => el === source);
      if (sourceIndex === -1) return;

      const activeEl = reordered[active];
      reordered.splice(sourceIndex, 1);
      reordered.splice(index, 0, source);

      const newActive = originalActiveTab ?? reordered.findIndex(t => t === activeEl);
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { tabs: reordered, active: newActive }, { at: path });
    },
    [active, editor, element],
  );

  const insertCodeTab = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    newCodeTab(editor, path);
  }, [editor, element]);

  const onCodeChange = useCallback(
    value => {
      const path = ReactEditor.findPath(editor, element);
      updateCode(editor, { value }, { at: path, index: active });
    },
    [active, editor, element],
  );

  // @note: We don't have a unique id for the blocks, so lets make some! We
  // need these so react knows how to re-order the tabs.
  element.tabs.forEach(t => {
    if (!IDS.has(t)) IDS.set(t, uuid());
  });

  return (
    <SelectionWrapper element={element} {...attributes}>
      <div className={classes.CodeTabs_container}>
        <div ref={ref} className={classes.CodeTabs} contentEditable={false}>
          {element.tabs.map((t, index) => (
            <Tab
              key={IDS.get(t)}
              active={active}
              data-index={index}
              element={t}
              index={index}
              kind="secondary"
              moveTab={moveTab}
              onClick={tabClick}
              parentId={id}
              size="sm"
              text
            />
          ))}
          <Button
            className={classes['CodeTabs-add']}
            contentEditable={false}
            ghost
            kind="minimum"
            onClick={insertCodeTab}
            size="xs"
            text
          >
            <i className="icon-plus1" />
          </Button>
        </div>
        <CodeEditor code={tab.value} element={element} language={tab.lang || 'javascript'} onChange={onCodeChange} />
        {children}
      </div>
    </SelectionWrapper>
  );
};

export default CodeTabs;
