import type { $TSFixMe } from '@readme/iso';
import type { Node as MdNode } from 'mdast';
import type { Node } from 'slate';

import { Element } from 'slate';

import type { ImageEmojiInline, ImageEmojiMdNode } from '@ui/MarkdownEditor/types';

export const type = 'owlmoji';
export const mdType = 'image';
export const isImageEmoji = (node: Node): node is ImageEmojiInline => Element.isElementType(node, type);
export const isMdImageEmoji = (node: MdNode & { data?: { hProperties?: $TSFixMe } }): node is ImageEmojiMdNode => {
  return !!(
    'data' in node &&
    node.data &&
    'hProperties' in node.data &&
    node.data.hProperties &&
    'className' in node.data.hProperties &&
    node.data?.hProperties?.className === 'emoji'
  );
};
