import { Editor, Path, Node, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { OnKeyDown, ReadmeElement } from '@ui/MarkdownEditor/types';

import { isFigcaption } from '../Figcaption/shared';
import ListItem from '../ListItem';

import { isImage } from './shared';

const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey && editor.selection)) return;

  const [, imagePath] = Editor.above(editor, { match: isImage }) || [];

  let nextPath = [0];
  const [, listItemPath] = Editor.above(editor, { at: imagePath, match: ListItem.is }) || [];
  if (imagePath) {
    nextPath = listItemPath ? Path.next(listItemPath) : Path.next(imagePath);
  } else {
    const rootPath = editor.selection.anchor.path.slice(0, 1);
    nextPath = Path.next(rootPath);
  }

  const hasNextNode = Node.has(editor, nextPath);
  const isImageNext =
    hasNextNode && (isImage(Node.get(editor, nextPath)) || isImage(Node.get(editor, [...nextPath, 0])));

  // If we've selected an image or an image is the next node, we handle it manually
  if (!(imagePath || isImageNext)) return;

  event.preventDefault();
  event.stopPropagation();

  if (hasNextNode) {
    Transforms.select(editor, isImageNext ? nextPath : Editor.end(editor, nextPath));
    return;
  }

  // If there's no next node, insert an empty one on arrow down!
  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, emptyNode(), { at: nextPath, select: true });
    if (listItemPath) Transforms.wrapNodes(editor, ListItem.defaultProps as ReadmeElement, { at: nextPath });
  });
};

const arrowUp: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey && editor.selection)) return;

  const [, imagePath] = Editor.above(editor, { match: isImage }) || [];
  const rootPath = editor.selection.anchor.path.slice(0, 1);
  const prevPath = Path.hasPrevious(rootPath) && Path.previous(rootPath);
  const isPrevImage = prevPath && (isImage(Node.get(editor, prevPath)) || isImage(Node.get(editor, [...prevPath, 0])));

  // If we've selected an image or an image is the node above, we handle it manually
  if (!(imagePath || isPrevImage)) return;

  // We also need to ensure we are not arrowing up into a figure with a caption (handled elsewhere)
  if (prevPath && !(Node.has(editor, [...prevPath, 1]) && isFigcaption(Node.get(editor, [...prevPath, 1])))) {
    event.preventDefault();
    event.stopPropagation();

    Transforms.select(editor, Editor.end(editor, prevPath));
  }
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && editor.selection)) return;

  const [, imagePath] = Editor.above(editor, { match: isImage }) || [];
  if (!imagePath) return;

  const [, dispatch] = editor.imageMenu;
  dispatch({ type: ImageMenuActionTypes.close });
};

export default [arrowDown, arrowUp, backspace];
