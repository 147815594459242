import type { $TSFixMe } from '@readme/iso';

import type {
  ImageBlockMdNode,
  ParagraphElement,
  ParagraphMdNode,
  ImageElement,
  FigureElement,
  FigcaptionElement,
} from '@ui/MarkdownEditor/types';

import { deserialize as figcaptionDeserialize } from '../Figcaption/serialize';
import { type as figureType } from '../Figure/shared';
import { deserialize as imageDeserialize } from '../Image/serialize';

export const deserialize = (
  node: ImageBlockMdNode,
  deeper: (node: ImageBlockMdNode) => [ParagraphElement],
): FigureElement | ImageElement => {
  const image = imageDeserialize(node as $TSFixMe) as $TSFixMe;

  if (node.children.length > 0) {
    return {
      type: figureType,
      children: [image, figcaptionDeserialize<ImageBlockMdNode>(node, deeper)],
    };
  }

  return image;
};

export const serialize = (
  node: FigureElement,
  deeper: (node: { children: FigcaptionElement['children'] }) => [ParagraphMdNode],
  { renderingLibrary },
): ImageBlockMdNode => {
  const [image, figcaption] = node.children;
  const { align, border = false, width, url = '', alt = '', title = '' } = image;

  const proxy = {
    type: 'root',
    children: deeper({ children: figcaption!.children }),
  };
  const caption = renderingLibrary.mdx(proxy).trim();

  const attrs = {
    align,
    alt,
    border,
    caption,
    title,
    src: url,
    width,
  };

  return {
    ...attrs,
    type: 'image-block',
    children: [],
    data: {
      hName: 'img',
      hProperties: attrs,
    },
  };
};
