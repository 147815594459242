import { Editor, Path, Transforms } from 'slate';

import { isInside } from '@ui/MarkdownEditor/editor/selection';
import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { isFirstTextOfParagraph } from '../shared';

import { isHorizontalRule, type } from './shared';

const INDENT_REGEXP =
  // Try it here: https://regexr.com/65vt6
  /^((?:(?:(?: {0,3}\*)(?: *\* *){2,})|(?:(?: {0,3}-)(?: *- *){2,})|(?:(?: {0,3}_)(?: *_ *){2,})))$/;

const convertToHorizontalRule =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (isHorizontalRule(node) || !isFirstTextOfParagraph(editor, [node, path])) return next();
    const match = node.text.match(INDENT_REGEXP);
    if (!match) return next();

    if (isInside(editor, [node, path])) return next();

    const at = Editor.start(editor, path);
    const distance = match[0].length;

    Editor.withoutNormalizing(editor, () => {
      Transforms.delete(editor, { at, distance });
      Transforms.setNodes(editor, { type }, { at: Path.parent(path) });
      Transforms.insertNodes(editor, [emptyNode()], { at: Path.next(Path.parent(path)), select: true });
    });
  };

export default convertToHorizontalRule;
