import React, { memo, useCallback } from 'react';
import { useSlateStatic, ReactEditor } from 'slate-react';

import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';

import MenuDropdown from '../MenuDropdown';

import classes from './style.module.scss';
import Toolbar from './Toolbar';
import useImageMenu from './useImageMenu';

const ImageMenu = () => {
  const editor = useSlateStatic();
  const [{ node }, dispatch] = useImageMenu();

  const getElement = useCallback(() => {
    if (!node) return;

    try {
      const element = ReactEditor.toDOMNode(editor, node);
      // We want to attach directly to the image element instead of the corner
      // the parent container (important since images are centered on their
      // lines and likely to not be the full width of the parent container).
      // eslint-disable-next-line consistent-return
      return element.querySelector(`img[src="${node.url}"]`) ?? element;
    } catch (e) {
      // @note: https://owlbert.io/images/gifs/fine.gif
      // ReactEditor.toDOMNode throws an error when the image is not found, but
      // it's a nice to use, as slate keeps a map of node -> element. But, we
      // trigger an open synchronously, before the element has rendered.
      // eslint-disable-next-line no-console
      console.warn('Image not ready yet?');
    }
  }, [editor, node]);
  const target = { current: getElement() };

  const open = !!(target && node);
  const imageExists = !!node?.url;

  const closeImageMenu = useCallback(() => {
    ReactEditor.focus(editor);

    dispatch({ type: ImageMenuActionTypes.close });
  }, [dispatch, editor]);

  return (
    <MenuDropdown
      className={imageExists ? classes.ImageToolbar : classes.ImageUpload}
      close={closeImageMenu}
      data-testid="image-toolbar"
      delay
      open={open}
      overlay
      target={target}
    >
      {!!imageExists && <Toolbar />}
    </MenuDropdown>
  );
};

export { default as useImageMenu, ImageMenuProvider } from './useImageMenu';
export { default as UploadEditor } from './UploadEditor';
export { default as onMouseOver } from './onMouseOver';

export default memo(ImageMenu);
