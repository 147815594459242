import { customBlockNameRegex } from '@readme/iso';
import React, { useLayoutEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useEventListener from '@core/hooks/useEventListener';
import useUniqueId from '@core/hooks/useUniqueId';

import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import classes from './style.module.scss';

interface Props {
  isFocused?: boolean;
  onCancel: () => void;
}

const NameInput = ({ onCancel, isFocused }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const uid = useUniqueId('NameInput');
  const bem = useClassy(classes, 'ReusableContentForm-controls-inline');

  const { control, setError, clearErrors } = useFormContext();

  const handleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value && !customBlockNameRegex.test(value)) {
      setError('name', {
        type: 'pattern',
        message: 'Name must start with an alphanumeric character.',
      });
    } else {
      clearErrors('name');
    }
  };

  useEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();

        onCancel();
      }
    },
    {
      target: ref.current,
    },
  );

  useLayoutEffect(() => {
    if (isFocused) ref.current?.focus();
  }, [isFocused]);

  // @note: moving the Input outside of Tippy, means that the Input doesn't
  // have to re-render when the Tippy is rendered. When the Input re-rendered,
  // it would lose focus.
  return (
    <RHFGroup control={control} errorStyle="tooltip" id={uid('name')} name="name" required>
      {({ field }) => (
        <>
          <Input
            {...field}
            ref={ref}
            className={bem('-input')}
            data-1p-ignore
            onChange={e => {
              field.onChange(e);
              handleValidation(e);
            }}
            pattern={customBlockNameRegex.source}
            placeholder="Name"
            size="sm"
          />
        </>
      )}
    </RHFGroup>
  );
};

export default NameInput;
