import { ReactEditor } from 'slate-react';

import * as blocks from '../blocks';
import { onKeyDown as confettiHandler } from '../confetti';
import { onKeyDown as emojiMenuHandler } from '../EmojiMenu';
import leaves from '../leaves';
import { onKeyDown as pageMenuHandler } from '../PageMenu';
import { onKeyDown as reusableContentMenuHandler } from '../ReusableContentMenu';
import { onKeyDown as slashMenuHandler } from '../slashMenu';
import { onKeyDown as variableMenuHandler } from '../VariableMenu';

const tabOrShiftTab = event => {
  if (event.key === 'Tab') {
    event.preventDefault();
  }
};

const shiftEnter = (event, editor) => {
  if (event.key === 'Enter' && event.shiftKey) {
    event.preventDefault();
    event.stopPropagation();

    editor.insertText('\n');
  }
};

const blurOnEscape = (event, editor) => {
  if (event.key === 'Escape') {
    event.preventDefault();
    event.stopPropagation();

    ReactEditor.blur(editor);
  }
};

/*
 * onKeyDown expects handlers to have a prototype of:
 *
 * (event, editor) => void
 * @param {React SyntheticEvent} event
 * @param {Editor} editor
 * @returns {void}
 *
 * Calling event.stopPropagation() will halt 'queueEventHandlers'
 */
const onKeyDown = ({ editor }) => {
  const nodeHandlers = [...Object.values(blocks), ...Object.values(leaves)].reduce((memo, node) => {
    return node.onKeyDown ? memo.concat(node.onKeyDown) : memo;
  }, []);

  const funcs = [
    tabOrShiftTab,
    slashMenuHandler,
    confettiHandler,
    pageMenuHandler,
    reusableContentMenuHandler,
    variableMenuHandler,
    emojiMenuHandler,
    ...nodeHandlers,
    shiftEnter,
    blurOnEscape,
  ];

  return event =>
    funcs.find(fn => {
      fn(event, editor);
      return event.isPropagationStopped();
    });
};

export default onKeyDown;
