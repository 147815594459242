import type { HTMLAttributes } from 'react';

import React from 'react';
import { useSelected } from 'slate-react';

import rootClasses from '@ui/MarkdownEditor/style.module.scss';
import type { ImageEmojiInline } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Owlmoji from '@ui/Owlmoji/index';

import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: ImageEmojiInline;
}

const ImageEmoji = ({ attributes, children, element }: Props) => {
  const { data, title } = element;
  const name = title.replaceAll(/^:|:$/g, '');
  const selected = useSelected();
  const className = useClassName(classes.Emoji_Container, selected && rootClasses.selected);

  return (
    <span {...attributes} className={className}>
      <Owlmoji {...data.hProperties}>{name}</Owlmoji>
      {children}
    </span>
  );
};

export default ImageEmoji;
