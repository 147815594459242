import { Editor, Transforms } from 'slate';

import { Link, MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import { afterWhitespace, noModifiers } from '@ui/MarkdownEditor/editor/onKeyDown/utils';

const closeMenu = editor => {
  const [{ rangeRef }, dispatch] = editor.pageMenu;

  const entry = Editor.above(editor, { at: rangeRef.current, match: MenuHandle.is });
  dispatch({ type: 'close' });
  if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });
};

const onKeyDown = (event, editor) => {
  const [{ open, filtered, rangeRef, selected }, dispatch] = editor.pageMenu;

  if (open) {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();
        event.stopPropagation();

        const { name, slug, type } = filtered[selected] || {};

        if (name) {
          Link.insertLinkFromMenu(editor, { label: name, url: `${type}:${slug}` }, { at: rangeRef.current });
        }

        closeMenu(editor);

        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: event.key === 'ArrowDown' ? 'down' : 'up' });

        break;
      }
      case 'Escape': {
        event.preventDefault();
        event.stopPropagation();

        closeMenu(editor);

        break;
      }
      case ']': {
        event.preventDefault();
        event.stopPropagation();

        closeMenu(editor);
        Transforms.insertText(editor, ']');

        break;
      }
      default:
        break;
    }
  }

  if (event.key === '[' && noModifiers(event) && afterWhitespace(editor)) {
    event.preventDefault();
    event.stopPropagation();

    // @note Though this is the default behavior, by calling it ourselves,
    // we are able to dispatch an initialize action after the [ insertion.
    Transforms.insertText(editor, '[');
    Transforms.wrapNodes(
      editor,
      { type: MenuHandle.type, menuType: 'link' },
      {
        at: {
          anchor: {
            path: editor.selection.anchor.path,
            offset: editor.selection.anchor.offset - 1,
          },
          focus: editor.selection.focus,
        },
        split: true,
      },
    );

    // if there's text after, slate moves the cursor into it
    // this moves the selection back to the menu handle
    if (!Editor.above(editor, { match: MenuHandle.isMenuHandle }))
      Transforms.select(editor, Editor.before(editor, editor.selection));
  }
};

export default onKeyDown;
