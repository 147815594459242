import { Node } from 'slate';

import type {
  Deserializer,
  GlossaryTermMdNode,
  Serializer,
  VariableInline,
  VariableMdNode,
} from '@ui/MarkdownEditor/types';

import { isGlossaryTerm, type } from './shared';

export const deserialize: Deserializer<GlossaryTermMdNode | VariableMdNode, VariableInline> = (node, _, { useMDX }) => {
  const name =
    node.type === 'readme-variable'
      ? node.data.hProperties.name || node.data.hProperties.variable
      : node.data.hName === 'readme-glossary-item'
        ? node.data?.hProperties?.term
        : // @ts-expect-error
          node.children[0].value || '';

  return {
    type,
    name,
    children: [
      { text: useMDX ? `{user.${name}}` : `<<${node.type === 'readme-variable' ? '' : 'glossary:'}${name}>>` },
    ],
  };
};

export const serialize: Serializer<VariableInline, GlossaryTermMdNode | VariableMdNode> = node => {
  if (isGlossaryTerm(node)) {
    return {
      type: 'readme-glossary-item',
      data: {
        hName: 'readme-glossary-item',
        hProperties: {
          term: Node.string(node).replace(/^<<glossary:(.*)>>$/, '$1'),
        },
      },
    };
  }

  const text = Node.string(node)
    .replace(/^<<(.*)>>$/, '$1')
    .replace(/^{user\.(.*)}$/, '$1');

  return {
    type: 'readme-variable',
    text,
    data: {
      hName: 'readme-variable',
      hProperties: {
        name: text,
      },
    },
  };
};
