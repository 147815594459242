import type { BaseSelection, Location } from 'slate';

import { Transforms, Editor } from 'slate';

import { type, isVariable } from './shared';

export const insertVariable = (
  editor: Editor,
  name: string,
  { at = editor.selection }: { at?: BaseSelection | Location } = {},
) => {
  if (!at) return;

  const existingVariableEntry = Editor.above(editor, { at, match: isVariable });
  const text = editor.props.useMDX ? `{${name}}` : `<<${name}>>`;

  if (existingVariableEntry) {
    const [, path] = existingVariableEntry;

    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, { at: path });
      Transforms.insertNodes(editor, { type, name, children: [{ text }] }, { at: path });
      Transforms.select(editor, Editor.end(editor, path));
    });
  } else {
    Transforms.insertText(editor, text, { at });

    const after = Editor.after(editor, editor.selection!)!;
    Transforms.select(editor, after);
  }
};
