import Div from './Component';
import { deserialize, serialize } from './serialize';
import { type } from './shared';

export default {
  deserialize,
  isVoid: true,
  Component: Div,
  type,
  serialize,
};
