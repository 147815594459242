import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useClassName from '@ui/MarkdownEditor/useClassName';

import { ListItemPropTypes } from '../types';

import { isTaskListItem } from './shared';
import classes from './style.module.scss';

const ListItemElement = ({ attributes, children, element }) => {
  const isTaskList = isTaskListItem(element);
  const editor = useSlateStatic();
  const onChange = useCallback(() => {
    if (!isTaskList) return;

    const at = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { checked: !element.checked }, { at });
  }, [editor, element, isTaskList]);
  const className = useClassName(classes.ListItem, isTaskList && 'task-list-item');

  return (
    <li {...attributes} className={className}>
      {!!isTaskList && <input checked={element.checked} contentEditable="false" onChange={onChange} type="checkbox" />}
      {children}
    </li>
  );
};

ListItemElement.propTypes = ListItemPropTypes;

export default ListItemElement;
