/* eslint-disable consistent-return */
import { Editor, Node, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { isImage } from '../Image/shared';
import { isTable } from '../Table/shared';

import { isListItem, defaultListItemProps } from './shared';

const CHECKLIST_REGEX = /^\s*\[(\s|[xX])\]( |$)/;

const convertToCheckList: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isListItem(node)) return next();

    const match = Node.string(node).match(CHECKLIST_REGEX);
    if (!match) return next();

    const at = Editor.start(editor, path);
    const distance = match[0].length;
    const checked = !!match[1].match(/[xX]/);

    Transforms.delete(editor, { at, distance });
    Transforms.setNodes(editor, { checked }, { at: path });
  };

const setDefaultProps: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isListItem(node)) return next();

    const updates = Object.keys(defaultListItemProps).reduce((acc, prop) => {
      // @ts-ignore
      if (node[prop] === undefined) {
        // @ts-ignore
        acc[prop] = defaultListItemProps[prop];
      }

      return acc;
    }, {});

    if (!Object.keys(updates).length) return next();

    Transforms.setNodes(editor, updates, { at: path });
  };

const setSpread: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isListItem(node)) return next();
    if (node.spread) return next();

    // @todo: When we upgrade remark-parse, we _may_ be able to remove this. RM-52
    const spread = node.children.some(isTable) || (node.children.some(isImage) && node.children.length > 1);
    if (!spread) return next();

    Transforms.setNodes(editor, { spread: true }, { at: path });
  };

const normalizeNode = [convertToCheckList, setDefaultProps, setSpread];

export default normalizeNode;
