import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { isList, type } from './shared';
import slashMenu from './slashMenu';

const List = {
  deserialize,
  is: isList,
  isList,
  normalizeNode,
  onKeyDown,
  operations,
  Component,
  serialize,
  slashMenu,
  type,
};

export default List;
