import type { RenderElementProps } from 'slate-react';

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Range, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Box from '@ui/Box';
import type { DropdownRef } from '@ui/Dropdown';
import EmbedMenu, { useEmbedMenu } from '@ui/MarkdownEditor/editor/EmbedMenu';
import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { EmbedElement } from '@ui/MarkdownEditor/types';

import EmbedToolbar from '../../EmbedMenu/Toolbar';
import { DEFAULTS } from '../../EmbedMenu/utils';
import EmptyBlock from '../EmptyBlock';

import classes from './style.module.scss';
import YouTube from './YouTube.png';

interface EmbedProps extends RenderElementProps {
  element: EmbedElement;
}

const Embed = ({ attributes, children, element }: EmbedProps) => {
  const editor = useSlateStatic();
  const { typeOfEmbed, url, height, width, openMenu, ...props } = element;
  const [, { open, close }] = useEmbedMenu();
  const menuRef = useRef<DropdownRef>(null);

  const openEmbedMenu = useCallback(
    event => {
      // If we're dragging selection across the emebed in any way, don't open the menu
      // @note: I'd rather use acrossBlocks here, but technically if we've only selected
      // the line just above the image, we're not across blocks when we cursor into the image
      if (editor.selection && !Range.isCollapsed(editor.selection)) return;

      event.preventDefault();
      open(element, element.typeOfEmbed);
    },
    [editor, open, element],
  );

  const closeEmbedToolbar = useCallback(
    event => {
      event.preventDefault();
      const embedEl = ReactEditor.toDOMNode(editor, element);
      const embedRect = embedEl.getBoundingClientRect();
      const isOutsideEmbed =
        event.clientX < embedRect.left ||
        event.clientX > embedRect.right ||
        event.clientY < embedRect.top ||
        event.clientY > embedRect.bottom;
      if (isOutsideEmbed) close();
    },
    [editor, close, element],
  );

  const iframe = useMemo(() => {
    if (!element.html) return null;

    return typeof DOMParser !== 'undefined'
      ? (new DOMParser().parseFromString(element.html, 'text/html').body.children[0] as HTMLIFrameElement)
      : null;
  }, [element.html]);

  useEffect(() => {
    if (!openMenu || !menuRef?.current?.toggle) return;

    menuRef.current.toggle();
    Transforms.setNodes(editor, { openMenu: false }, { at: ReactEditor.findPath(editor, element) });
  }, [editor, element, element.openMenu, openMenu]);

  const icon =
    typeOfEmbed === 'github'
      ? 'icon-github'
      : typeOfEmbed === 'pdf'
        ? 'icon-file'
        : typeOfEmbed === 'jsfiddle'
          ? 'icon-jsfiddle'
          : 'icon-maximize';
  const text =
    typeOfEmbed === 'github'
      ? 'Embed GitHub Gist'
      : typeOfEmbed === 'pdf'
        ? 'Embed PDF'
        : typeOfEmbed === 'jsfiddle'
          ? 'Embed JSFiddle'
          : 'Embed Iframe';

  return (
    <SelectionWrapper
      contentEditable={false}
      element={element}
      {...(url && {
        blockType: typeOfEmbed,
        onMouseEnter: openEmbedMenu,
        onMouseLeave: closeEmbedToolbar,
      })}
      {...attributes}
    >
      {!url && typeOfEmbed !== 'youtube' ? (
        <EmptyBlock icon={icon} testId="embed-menu-button" text={text}>
          <EmbedMenu element={element} menuRef={menuRef} />
          {children}
        </EmptyBlock>
      ) : (
        <>
          <EmbedToolbar element={element} menuRef={menuRef}>
            {!url && typeOfEmbed === 'youtube' ? (
              <Box
                className={classes['Embed-Empty-Youtube']}
                data-testid="embed-menu-button"
                kind="rule"
                onClick={openEmbedMenu}
                {...props}
              >
                <img alt="YouTube icon" className={classes['Embed-Empty-Youtube_icon']} src={YouTube} />
              </Box>
            ) : typeOfEmbed === 'github' ? (
              <div
                dangerouslySetInnerHTML={{ __html: element.html || '' }}
                data-testid="editor-embed-for-test"
                title={iframe?.title}
              />
            ) : (
              <iframe
                allow={iframe?.allow}
                allowFullScreen={iframe?.allowFullscreen}
                className={`${iframe?.className} ${classes.Embed_Iframe}`}
                data-testid="editor-embed-for-test"
                frameBorder={iframe?.frameBorder}
                height={height || iframe?.height || DEFAULTS.heightFull}
                scrolling={iframe?.scrolling}
                src={iframe?.src || url}
                title={iframe?.title}
                width={width || iframe?.width || DEFAULTS.widthFull}
              />
            )}
          </EmbedToolbar>
          {children}
        </>
      )}
    </SelectionWrapper>
  );
};

export default Embed;
