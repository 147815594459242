import Component from './Component';
import { type, isMarkdownChar, create } from './shared';

const MarkdownChar = {
  Component,
  create,
  is: isMarkdownChar,
  isInline: true,
  isMarkdownChar,
  isVoid: true,
  type: type as 'markdownChar',
};

export default MarkdownChar;
