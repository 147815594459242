import FaEmoji from './Component';
import normalizeNode from './normalizeNode';
import { deserialize, serialize } from './serialize';
import { type, isFaEmoji, rdmdType } from './shared';

export default {
  Component: FaEmoji,
  deserialize,
  isFaEmoji,
  isInline: true,
  isVoid: true,
  normalizeNode,
  rdmdType,
  serialize,
  type,
};
