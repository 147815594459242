import { type } from './shared';

export const deserialize = ({ data, position, ...node }) => {
  const { backgroundColor, emoji, id, link, slug, title } = data.hProperties;
  return { ...node, backgroundColor, emoji, id, link, slug, title, children: [{ text: '' }], type };
};

export const serialize = ({ backgroundColor, emoji = '🦉', id, link, slug, title }, _, { renderingLibrary }) => {
  if ('mdx' in renderingLibrary) {
    return {
      type: 'tutorial-tile',
      backgroundColor,
      emoji,
      id,
      link,
      slug,
      title,
    };
  }

  return {
    data: {
      hProperties: { backgroundColor, emoji, id, link, slug, title },
      hName: 'tutorial-tile',
      backgroundColor,
      emoji,
      id,
      link,
      slug,
      title,
    },
    type: 'div',
  };
};
