import type { Node } from 'slate';

import { Editor, Element } from 'slate';

import type { FigcaptionElement } from '@ui/MarkdownEditor/types';

export const type = 'figcaption';
export const isFigcaption = (node: Node): node is FigcaptionElement =>
  Element.isElementType<FigcaptionElement>(node, type);

export const isInCaption = (editor: Editor) => editor.selection && Editor.above(editor, { match: isFigcaption });
