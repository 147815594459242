import { detect } from 'detect-browser';
import { Editor, Node, Path, Point, Range, Transforms } from 'slate';

import { OSX_OPTION_CHARACTERS_MAP } from '@ui/MarkdownEditor/editor/eventUtils';

import { isListItem } from '../ListItem/shared';
import Paragraph from '../Paragraph';
import { isTableCell } from '../TableCell/shared';

import { convertToCheckList, convertToOrderedList, convertToUnorderedList } from './operations';
import { isList, defaultListProps } from './shared';

const isOSX = detect()?.os === 'Mac OS';

const backspace = (event, editor) => {
  if (!(event.key === 'Backspace' && Range.isCollapsed(editor.selection))) return;

  const itemEntry = Editor.above(editor, { match: isListItem });
  if (!itemEntry) return;

  const [, path] = itemEntry;
  if (!Point.equals(Editor.start(editor, path), editor.selection.anchor)) return;

  event.stopPropagation();
  event.preventDefault();

  const list = Node.parent(editor, path);
  const gp = Node.parent(editor, Path.parent(path));

  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, { match: isList, split: list.children.length > 1 });
    Transforms.unwrapNodes(editor, { match: isListItem });
    if (isTableCell(gp)) {
      Transforms.unwrapNodes(editor, { match: Paragraph.isParagraph });
    }
  });
};

const onTrailingBlankLine = (editor, [item, path]) => {
  return (
    Range.isCollapsed(editor.selection) &&
    Point.equals(Editor.end(editor, path), editor.selection.anchor) &&
    Node.string(item.children[item.children.length - 1]) === ''
  );
};

const isPreviousItemBlank = editor => {
  const previousEntry = Editor.previous(editor, { match: isListItem });
  if (!previousEntry) return false;

  const [previousItem] = previousEntry;
  return Node.string(previousItem) === '';
};

const enter = (event, editor) => {
  if (!(event.key === 'Enter' && !event.shiftKey)) return;

  const itemEntry = Editor.above(editor, { match: n => Editor.isBlock(editor, n) && !Paragraph.isParagraph(n) });
  if (!itemEntry || !isListItem(itemEntry[0])) return;

  event.stopPropagation();
  event.preventDefault();

  const [item] = itemEntry;

  if (Node.string(item) === '' || onTrailingBlankLine(editor, itemEntry) || isPreviousItemBlank(editor)) {
    Editor.withoutNormalizing(editor, () => {
      Transforms.unwrapNodes(editor, { split: true, match: isListItem });
      Transforms.unwrapNodes(editor, { split: true, match: isList });

      const listItem = Editor.above(editor, { match: isListItem });
      if (listItem && listItem[0].children.length > 1) {
        const [, at] = Editor.above(editor, { match: Paragraph.isParagraph });
        Transforms.splitNodes(editor, { at });
      }
    });
  } else {
    Transforms.splitNodes(editor, { always: true, match: isListItem });
  }
};

const tab = (event, editor) => {
  if (!(event.key === 'Tab' && !event.shiftKey)) return;

  const item = Editor.above(editor, { match: isListItem });
  if (!item) return;

  const [, path] = item;
  if (!Path.hasPrevious(path)) return;

  event.stopPropagation();
  event.preventDefault();

  const siblingPath = Path.previous(path);
  const sibling = Node.get(editor, siblingPath);
  const previousItemsLastChildPath = [...siblingPath, sibling.children.length];
  const list = Node.parent(editor, path);

  const listProps = { ...defaultListProps, ordered: list.ordered };
  if (list.ordered) listProps.start = 1;

  Editor.withoutNormalizing(editor, () => {
    Transforms.moveNodes(editor, { match: isListItem, to: previousItemsLastChildPath });
    Transforms.wrapNodes(editor, listProps, { match: isListItem });
  });
};

const shiftTab = (event, editor) => {
  if (!(event.key === 'Tab' && event.shiftKey)) return;

  const listEntry = Editor.above(editor, { match: isList });
  if (!listEntry) return;

  const [list, listPath] = listEntry;
  if (!Path.hasPrevious(listPath)) return;

  const grandparent = Editor.above(editor, { at: listPath, match: isListItem });
  if (!grandparent) return;

  event.stopPropagation();
  event.preventDefault();

  const [, itemPath] = Editor.above(editor, { match: isListItem });

  Editor.withoutNormalizing(editor, () => {
    const idx = itemPath[itemPath.length - 1];

    /*
     * If we have a single item in a nested list, we can move it from its list
     * to the ancestor list. If there is any content after the list, the item
     * must adopt it.
     *
     * ul
     *   li
     *     ul
     *       li  <-- the item that is being unindented
     *     p     <-- possible additional text
     *
     * becomes:
     *
     * ul
     *   li      <-- our old buddy
     *     p     <-- our new friends
     */
    if (idx === 0 && !Node.has(editor, Path.next(itemPath))) {
      const listSiblingPath = Path.next(listPath);
      while (Node.has(editor, listSiblingPath)) {
        Transforms.moveNodes(editor, {
          at: listSiblingPath,
          to: [...itemPath, Node.get(editor, itemPath).children.length],
        });
      }

      Transforms.moveNodes(editor, { at: itemPath, to: Path.next(grandparent[1]) });

      /*
       * If we are the last item in a list, we simple get moved to our ancestor
       * list
       *
       * ul
       *   li
       *     ul
       *       li  <-- intrepid traveler
       *
       * becomes:
       *
       * ul
       *   li      <--
       *
       * @note that the list normalizers will clean up any empty lists.
       */
    } else if (idx === list.children.length - 1) {
      Transforms.moveNodes(editor, { at: itemPath, to: Path.next(grandparent[1]) });

      /*
       * For all other cases, we split up our parent list and split up our
       * ancestor list item. We then adopt the trailing list that was split
       * out.
       *
       * ul
       *   li
       *     ul
       *       li  <-- wanderer
       *       li
       *
       * becomes:
       *
       * ul
       *   li
       *   li      <--
       *     ul
       *       li
       *
       */
    } else {
      const itemPathRef = Editor.pathRef(editor, itemPath);

      try {
        Transforms.splitNodes(editor, { at: itemPathRef.current, height: 1 });
        Transforms.splitNodes(editor, { at: Path.next(itemPathRef.current), height: 1 });
        Transforms.unwrapNodes(editor, { at: Path.parent(itemPathRef.current) });
        Transforms.splitNodes(editor, { at: itemPathRef.current, height: 1 });

        const itemSiblingPath = Path.next(itemPathRef.current);
        while (Node.has(editor, itemSiblingPath)) {
          Transforms.moveNodes(editor, {
            at: itemSiblingPath,
            to: [...itemPathRef.current, Node.get(editor, itemPathRef.current).children.length],
          });
        }

        Transforms.unwrapNodes(editor, { at: itemPathRef.current, height: 1 });
      } finally {
        itemPathRef.unref();
      }
    }
  });
};

const shiftMap = {
  $: 4,
  '%': 5,
  '^': 6,
};

const isCmdOption4to6 = event => {
  const digit = isOSX ? OSX_OPTION_CHARACTERS_MAP[event.key] : shiftMap[event.key];
  return (isOSX ? event.metaKey && event.altKey : event.ctrlKey && event.shiftKey) && [4, 5, 6].find(n => n === digit);
};

// or ctrl+shift
const cmdOption4to6 = (event, editor) => {
  const digit = isCmdOption4to6(event);
  if (!digit) return;

  event.preventDefault();
  event.stopPropagation();

  if (digit === 4) {
    convertToCheckList(editor);
  } else if (digit === 5) {
    convertToUnorderedList(editor);
  } else {
    convertToOrderedList(editor);
  }
};

const onKeyDown = [backspace, enter, tab, shiftTab, cmdOption4to6];

export default onKeyDown;
