import type { HTMLAttributes } from 'react';

import React from 'react';
import { useSelected } from 'slate-react';

import rootClasses from '@ui/MarkdownEditor/style.module.scss';
import type { EmojiInline } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Owlmoji from '@ui/Owlmoji/index';

import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: EmojiInline;
}

const Emoji = ({ attributes, children, element }: Props) => {
  const selected = useSelected();
  const className = useClassName(classes.Emoji, selected && rootClasses.selected);

  return (
    <span {...attributes}>
      <Owlmoji className={className}>{element.name}</Owlmoji>
      {children}
    </span>
  );
};

export default Emoji;
