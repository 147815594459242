import { useMemo, useReducer } from 'react';

import { bounded } from '@ui/MarkdownEditor/editor/utils';

export const initialState = {
  link: null,
  ref: null,
  selection: {
    node: 'label',
    startOffset: 0,
    endOffset: 0,
  },
};

const pointFromSlate = (link, selection, edge) => {
  const { label = '', url = '' } = link;

  let node;
  if (!url) {
    node = 'url';
  } else {
    node = selection[edge].offset < (label.length || 0) + 2 ? 'label' : 'url';
  }

  let offset;
  if (node === 'url') {
    offset = url.length;
  } else if (node === 'label') {
    offset = bounded(selection[edge].offset - 1, url.length || 0);
  }

  return { node, offset };
};

export const rangeFromSlate = (link, selection) => {
  const anchor = pointFromSlate(link, selection, 'anchor');
  const focus = pointFromSlate(link, selection, 'focus');

  let node = 'label';
  if ((link.label || link.url) && (anchor.node !== focus.node || anchor.node === 'url')) {
    node = 'url';
  }

  return {
    node,
    startOffset: Math.min(anchor.offset, focus.offset),
    endOffset: Math.max(anchor.offset, focus.offset),
  };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'open': {
      const { link, ref, selection } = payload;

      return {
        link,
        ref,
        selection: selection ? rangeFromSlate(link, selection) : initialState.selection,
      };
    }
    case 'update':
      return {
        ...state,
        ...payload,
      };
    case 'close':
      state.ref.unref();

      return { ...initialState };
    default:
      return state;
  }
};

const useLinkEditor = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return useMemo(() => [state, dispatch], [state, dispatch]);
};

export default useLinkEditor;
