import type { HTMLAttributes } from 'react';

import React from 'react';
import { useSelected } from 'slate-react';

import rootClasses from '@ui/MarkdownEditor/style.module.scss';
import type { FaEmojiInline } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: FaEmojiInline;
}

const Emoji = ({ attributes, children, element }: Props) => {
  const selected = useSelected();
  const containerClassname = useClassName(classes.FaEmoji, selected && rootClasses.selected);
  const className = useClassName('fa', element.className);

  return (
    <span {...attributes} className={containerClassname} contentEditable={false}>
      <i className={className}></i>
      {children}
    </span>
  );
};

export default Emoji;
