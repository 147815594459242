import type { Editor } from 'slate';

import { Range, Transforms } from 'slate';

import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

const chromeTripleClickWorkaround = (editor: ReadmeEditor, event: React.MouseEvent<Element, MouseEvent>) => {
  if (event.detail !== 3 || !editor.selection) return;

  if (
    Object.prototype.isPrototypeOf.call(Element.prototype, event.target) &&
    (event.target as Element).closest('.CodeMirror')
  ) {
    return;
  }

  // If we're the last child, there's no place for chrome to move the
  // selection to.
  if (editor.children.length - 1 === Range.end(editor.selection).path[0]) {
    return;
  }

  Transforms.move(editor, { distance: 1, unit: 'offset', reverse: true, edge: 'end' });
};

const onClick = (editor: Editor) => (event: React.MouseEvent) => {
  event.stopPropagation();

  chromeTripleClickWorkaround(editor, event);
};

export default onClick;
