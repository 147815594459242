import PropTypes from 'prop-types';

const attributes = PropTypes.object;

const element = {};

export const EmojiPropTypes = {
  attributes,
  element: PropTypes.shape({
    ...element,
    title: PropTypes.string.isRequired,
  }),
};

export const HtmlPropTypes = {
  attributes,
  element: PropTypes.shape({
    ...element,
    html: PropTypes.string,
  }),
};

export const HeadingPropTypes = {
  attributes,
  element: PropTypes.shape({
    ...element,
    depth: PropTypes.number,
  }),
};

export const ParagraphPropTypes = {
  attributes,
  element: PropTypes.shape({ ...element }),
};

export const ListPropTypes = {
  attributes,
  element: PropTypes.shape({
    ...element,
    ordered: PropTypes.bool.isRequired,
    spread: PropTypes.bool.isRequired,
    start: PropTypes.number,
  }),
};

export const ListItemPropTypes = {
  attributes,
  element: PropTypes.shape({ ...element, checked: PropTypes.bool }),
};
