import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import useClassName from '@ui/MarkdownEditor/useClassName';
import MenuItem from '@ui/Menu/Item';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const Item = ({ id, name, onClick: onClickHandler, prefix, selected, type }) => {
  const onClick = useCallback(() => onClickHandler({ name, prefix }), [name, onClickHandler, prefix]);
  const className = useClassName(classes.VariableMenuItem, selected && 'selected');

  return (
    <MenuItem key={id} aria-current={selected} className={className} data-name={name} onClick={onClick} role="menuitem">
      <Tooltip content={name} delay={[300, 200]} placement="top-start" title>
        <div className={classes['VariableMenuItem-Container']}>
          <p className={classes['VariableMenuItem-Container-name']}>{name}</p>
          <p className={classes['VariableMenuItem-Container-type']}>{type}</p>
        </div>
      </Tooltip>
    </MenuItem>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

const VariableMenuItems = ({ onClick, results, selectedName }) => {
  if (results.length === 0) {
    return (
      <MenuItem className={classes.NoResults} disabled>
        No results
      </MenuItem>
    );
  }

  return results.map(({ id, name, prefix, type }) => (
    <Item key={id} id={id} name={name} onClick={onClick} prefix={prefix} selected={selectedName === name} type={type} />
  ));
};

VariableMenuItems.propTypes = {
  onClick: PropTypes.func.isRequired,
  results: PropTypes.array,
  selectedName: PropTypes.string,
};

export default React.memo(VariableMenuItems);
