import { Editor, Path } from 'slate';

const lastParentSibling = (editor: Editor, path: Path) => {
  const parentPath = Path.parent(path);
  let siblingPath = parentPath;

  while (Editor.hasPath(editor, Path.next(siblingPath))) {
    siblingPath = Path.next(siblingPath);
  }

  return siblingPath;
};

export default lastParentSibling;
