import { Editor, Range, Transforms } from 'slate';

import { TableCell } from '../blocks';
import { noModifiers } from '../onKeyDown/utils';

import { transforms } from './commands';
import { init } from './useSlashMenu';

const afterWhitespace = editor => {
  if (!editor.selection) return true;

  const start = Range.start(editor.selection);
  const [, blockPath] = Editor.above(editor, { at: start, match: n => Editor.isBlock(editor, n) }) || [];
  if (blockPath && Editor.isStart(editor, start, blockPath)) return true;

  const before = Editor.before(editor, start, { distance: 1, unit: 'character' });
  return !before || Editor.string(editor, { anchor: before, focus: start }).match(/^\s$/);
};

const inTable = editor => Editor.above(editor, { match: TableCell.isTableCell });

const afterAngleBracket = editor => {
  if (editor.selection.anchor.offset === 0) return false;

  const anchor = editor.selection.anchor;
  const focus = { path: anchor.path, offset: anchor.offset - 1 };
  const char = Editor.string(editor, { anchor, focus });

  return char === '<';
};

const onKeyDown = (event, editor) => {
  const [{ open, filtered, selected }, dispatch] = editor.slashMenu;

  if (open) {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();
        event.stopPropagation();

        const { name } = filtered[selected] || {};
        if (name) {
          transforms[name](editor);
        }
        dispatch({ type: 'close' });

        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: event.key === 'ArrowDown' ? 'down' : 'up' });

        break;
      }
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: 'close' });

        break;
      default:
        break;
    }

    return;
  }

  if (
    event.key === '/' &&
    noModifiers(event) &&
    afterWhitespace(editor) &&
    !inTable(editor) &&
    !afterAngleBracket(editor)
  ) {
    event.preventDefault();
    event.stopPropagation();

    /* @note Though this is the default behavior, by calling it ourselves,
     * we are able to dispatch an initialize action after the / insertion.
     */
    Transforms.insertText(editor, '/');

    dispatch(init(editor));
  }
};

export default onKeyDown;
