import type { Node as MdNode } from 'mdast';
import type { Node } from 'slate';

import { Element } from 'slate';

import type { HtmlBareMdNode, HtmlBlock } from '@ui/MarkdownEditor/types';

export const type = 'html';
export const rdmdType = 'html-block';
export const hName = 'HTMLBlock';

export const isHtml = (node: Node): node is HtmlBlock => Element.isElementType(node, type);
export const isMdBareHtml = (node: MdNode): node is HtmlBareMdNode => 'type' in node && node.type === type;
