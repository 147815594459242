import { Editor, Transforms } from 'slate';

const preserveCursor = (editor, fn) => {
  // eslint-disable-next-line consistent-return
  Editor.withoutNormalizing(editor, () => {
    if (!editor.selection) return fn();

    const selectionRef = Editor.rangeRef(editor, editor.selection);

    fn();

    Transforms.select(editor, selectionRef.current);
    selectionRef.unref();
  });
};

export default preserveCursor;
