import type { Deserializer, ReusableContentElement, ReusableContentMdNode, Serializer } from '@ui/MarkdownEditor/types';

import { fromMdNode } from './shared';

export const deserialize: Deserializer<ReusableContentMdNode, ReusableContentElement> = (
  node,
  _,
  { renderingLibrary },
) => {
  return fromMdNode(node, { renderingLibrary });
};

export const serialize: Serializer<ReusableContentElement, ReusableContentMdNode> = node => {
  return {
    children: [],
    type: 'reusable-content',
    tag: node.tag,
  };
};
