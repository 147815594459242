import type { Opts } from './withReadme';

import { useMemo } from 'react';

import type { CustomEditor, Props } from '@ui/MarkdownEditor/types';

import { useUrlMenu } from './UrlMenu';
import withReadme from './withReadme';

import {
  useSlashMenu,
  useEmojiMenu,
  useImageMenu,
  useLinkEditor,
  usePageMenu,
  useRecipeMenu,
  useReusableContentMenu,
  useVariableMenu,
  useReusableContentModal,
} from './index';

const useReadmeEditor = (editor: CustomEditor, props: Props, opts: Opts) => {
  const readmeEditor = useMemo(() => {
    return withReadme(editor, props, opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  readmeEditor.emojiMenu = useEmojiMenu();
  readmeEditor.imageMenu = useImageMenu();
  readmeEditor.linkEditor = useLinkEditor();
  readmeEditor.pageMenu = usePageMenu();
  readmeEditor.recipeMenu = useRecipeMenu();
  readmeEditor.slashMenu = useSlashMenu();
  readmeEditor.variableMenu = useVariableMenu();
  readmeEditor.reusableContentMenu = useReusableContentMenu();
  readmeEditor.urlMenu = useUrlMenu();
  readmeEditor.reusableContentModal = useReusableContentModal();

  return readmeEditor;
};

export default useReadmeEditor;
