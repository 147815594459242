import { Editor, Range, Text, Transforms } from 'slate';

import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

import { pointToOffset } from '../../utils';

import { tagRegex } from './shared';

const enter = (event: KeyboardEvent, editor: ReadmeEditor) => {
  const { selection } = editor;
  if (!selection) return;

  if (!(event.key === 'Enter' && !event.shiftKey) || !Range.isCollapsed(selection)) {
    return;
  }

  const nodeEntry = Editor.node(editor, selection);
  if (!nodeEntry || !Text.isText(nodeEntry[0])) return;

  const match = nodeEntry[0].text.match(tagRegex);
  if (!match) return;

  const offset = pointToOffset(nodeEntry, selection.anchor);
  if (match[0].length !== offset) return;

  event.stopPropagation();
  event.preventDefault();

  Transforms.insertText(editor, '\n');
};

export default [enter];
