import type { EditorValue } from '../types';
import type { Descendant, Editor } from 'slate';

import { useCallback, useEffect, useRef, useState } from 'react';

import log from './log';
import isInsertTrailingParagraph from './utils/isInsertTrailingParagraph';

const debug = log.extend('useOnChange');

const useOnChange = (
  editor: Editor,
  initialValue: EditorValue,
  callback?: (value: EditorValue, editor: Editor) => void,
) => {
  const isLoaded = useRef<boolean>(false);
  const [value, setValue] = useState<EditorValue>(initialValue);

  /* onChange handler
   * Note that slate is now a pseudo-controlled component. It is initialized
   * with a value, but from then on it manages its own state. That's why this
   * onChange doesn't actually return the value of the editor.
   */
  const onChange = useCallback(
    (newValue: Descendant[]) => {
      debug(editor.operations);

      /*
       * onChange will be called when the selection has changes. Nothing else
       * for us to do.
       */
      if (value === newValue) {
        return;
      }

      /* @note: The editor has an issue where it normalizes the document on
       * load, and this can trigger an `onChange` event. This is usually used
       * to mark the editor state as dirty and trigger an alert when someone
       * attempts to navigate away. Let's just skip any onChanges that happen
       * on load.
       *
       * We also skip when inserting a trailing paragraph, because that's more
       * a UX feature than a real change to the document.
       */
      if (isLoaded.current && callback && !isInsertTrailingParagraph(editor)) {
        callback(newValue, editor);
      }

      setValue(newValue);
    },
    [callback, editor, value],
  );

  useEffect(() => {
    isLoaded.current = true;
  }, []);

  return onChange;
};

export default useOnChange;
