import { deserialize, serialize } from './serialize';
import { languages, type } from './shared';

const Code = {
  deserialize,
  languages,
  serialize,
  type,
};

export default Code;
