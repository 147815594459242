import type { ReusableContentMenuItem } from './types';
import type { ReadCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { Dispatch, RefObject } from 'react';
import type { RangeRef } from 'slate';

import fuzzysort from 'fuzzysort';
import React, { createContext, useContext, useReducer, useMemo, useState, useEffect } from 'react';

import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import { MenuConfigActionTypes, MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { Props } from '@ui/MarkdownEditor/types';

import { bounded } from '../utils';
import { sampleData as variableSampleData } from '../VariableMenu/sampleData';

import { sampleData } from './sampleData';

const sampleGlossaryTerms = variableSampleData
  .filter(term => term.type === 'Glossary Term')
  .map(term => ({
    _id: term.id,
    term: term.name,
    definition: term.definition,
  }));

type GlossaryMenuItem = Required<Props>['glossaryTerms'][0] & {
  search: string;
};

type MenuItem = GlossaryMenuItem | ReusableContentMenuItem;

interface InitAction {
  payload: { menuType?: MenuHandleTypes; rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface RevalidateAction {
  type: MenuConfigActionTypes.revalidate;
}

interface LoadNextPageAction {
  type: MenuConfigActionTypes.loadNextPage;
}

interface DataLoadedAction {
  payload: MenuItem[];
  type: MenuConfigActionTypes.dataLoaded;
}

interface ShortcutAction {
  payload: boolean;
  type: MenuConfigActionTypes.shortcut;
}

type ReusableContentMenuAction =
  | CloseAction
  | DataLoadedAction
  | DownAction
  | InitAction
  | LoadNextPageAction
  | OpenAction
  | RevalidateAction
  | SearchAction
  | ShortcutAction
  | UpAction;

interface ReusableContentMenuState {
  filtered: MenuItem[];
  glossaryTerms: GlossaryMenuItem[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  menuType: MenuHandleTypes;
  open: boolean;
  rangeRef: RangeRef | null;
  reusableContent: ReusableContentMenuItem[];
  search: string | null;
  selected: number;
  shortcut: boolean;
  target: RefObject<HTMLElement> | null;
}

interface ReusableContentMenuReducer {
  (state: ReusableContentMenuState, action: ReusableContentMenuAction): ReusableContentMenuState;
}

type ProjectData = Required<
  Pick<Props, 'basic' | 'glossaryTerms' | 'subdomain' | 'useMDX' | 'useTestData' | 'version'>
> & {
  disallowReusableContent: boolean;
  useReusableContent: boolean;
};

const initialState: ReusableContentMenuState = {
  filtered: [],
  glossaryTerms: [],
  hasNextPage: false,
  isNextPageLoading: false,
  menuType: MenuHandleTypes.reusableContent,
  open: false,
  rangeRef: null,
  reusableContent: [],
  search: null,
  selected: 0,
  shortcut: false,
  target: null,
};

/**
 * When we use the `useReadmeApiInfinite` hook to fetch Reusable Content blocks,
 * we get back an array of pages in `state.data`. This function will flatten that
 * array into a single array of items.
 */
const transformDataToItems = (data: ReturnType<typeof useReadmeApiInfinite<ReadCollectionType>>['data']) =>
  data?.flatMap(page => page?.data || []) || [];

const PER_PAGE = 20;

const useReusableContentMenuReducer = ({
  basic,
  disallowReusableContent,
  glossaryTerms,
  subdomain,
  useMDX,
  useReusableContent,
  useTestData,
  version,
}: ProjectData) => {
  const [reusableContent, setReusableContent] = useState<ReusableContentMenuItem[]>([]);

  const {
    data: reusableData,
    isLoading,
    mutate,
    size,
    setSize,
  } = useReadmeApiInfinite<ReadCollectionType>((pageIndex, previousPageData) => {
    if (!useReusableContent || disallowReusableContent) return null;

    if (previousPageData?.paging?.next === null) {
      // Reached the end of the list
      return null;
    }

    return `/${subdomain}/api-next/v2/versions/${version}/custom_blocks?type=content&page=${
      pageIndex + 1
    }&per_page=${PER_PAGE}&sort=name`;
  });

  useEffect(() => {
    if (!useReusableContent || disallowReusableContent) return;

    if (useTestData) {
      setReusableContent(sampleData);
      return;
    }

    if (basic || !subdomain || !version) return;

    if (!isLoading) {
      const content = transformDataToItems(reusableData);
      if (useMDX) {
        setReusableContent([...content]);
      } else {
        setReusableContent(content);
      }
    }
  }, [
    reusableData,
    basic,
    subdomain,
    useTestData,
    version,
    isLoading,
    useMDX,
    useReusableContent,
    disallowReusableContent,
  ]);

  const hasNextPage = useMemo(() => {
    if (!reusableData) return false;
    const endPage = reusableData[reusableData.length - 1];

    return endPage.paging?.next !== null;
  }, [reusableData]);

  const memoized: [ReusableContentMenuReducer, ReusableContentMenuState] = useMemo(() => {
    const terms = (useTestData ? sampleGlossaryTerms : glossaryTerms).map(
      (term: ProjectData['glossaryTerms'][0]): GlossaryMenuItem => ({
        ...term,
        search: `glossary:${term.term}`,
      }),
    );

    const initial = {
      ...initialState,
      glossaryTerms: terms,
      filtered: useMDX ? [...reusableContent, ...terms] : reusableContent,
      reusableContent,
      hasNextPage,
    };

    const reducer: ReusableContentMenuReducer = (state, action) => {
      switch (action.type) {
        case 'init':
          return { ...initial, shortcut: state.shortcut, ...action.payload };
        case 'open':
          if (state.open) return state;
          return { ...state, target: action.payload.target, open: true };
        case 'up':
          return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
        case 'down':
          return { ...state, selected: bounded(state.selected + 1, state.filtered.length - 1) };
        case 'shortcut':
          return { ...state, shortcut: action.payload };
        case 'loadNextPage': {
          setSize(size + 1);
          return state;
        }
        case 'dataLoaded': {
          return {
            ...state,
            reusableContent,
            filtered: state.search
              ? fuzzysort
                  .go(state.search, reusableContent, {
                    keys: ['name'],
                  })
                  .map((r: Fuzzysort.KeysResult<ReusableContentMenuItem>) => r.obj)
              : reusableContent,
            hasNextPage,
          };
        }
        case 'search': {
          const search = action.payload ? action.payload.replace(/^</, '') : '';
          const set = useMDX
            ? state.menuType === MenuHandleTypes.glossary
              ? state.glossaryTerms
              : [...state.reusableContent, ...state.glossaryTerms]
            : state.reusableContent;

          return search === state.search
            ? state
            : {
                ...state,
                search,
                selected: 0,
                filtered: search
                  ? fuzzysort
                      .go(search, set, {
                        keys: ['name', 'search'],
                      })
                      .map((r: Fuzzysort.KeysResult<MenuItem>) => r.obj)
                  : set,
              };
        }
        case 'revalidate':
          mutate();
          return state;
        case 'close':
          state.rangeRef?.unref();
          return {
            ...state,
            open: false,
            shortcut: false,
            target: null,
            rangeRef: null,
          };
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useReusableContent');
          return state;
      }
    };

    return [reducer, initial];
  }, [glossaryTerms, hasNextPage, mutate, reusableContent, setSize, size, useMDX, useTestData]);

  const [reusableContentMenuState, dispatch] = useReducer(...memoized);

  // Update state when reusableContent is loaded.
  // This will occur for paginated requests after the initial data is loaded.
  useEffect(() => {
    dispatch({ type: MenuConfigActionTypes.dataLoaded, payload: reusableContent });
  }, [reusableContent]);

  return useMemo<[ReusableContentMenuState, Dispatch<ReusableContentMenuAction>]>(
    () => [reusableContentMenuState, dispatch],
    [reusableContentMenuState],
  );
};

const ReusableContentMenuContext = createContext([initialState, () => {}] as [
  ReusableContentMenuState,
  Dispatch<ReusableContentMenuAction>,
]);

export const ReusableContentMenuProvider = ({
  basic,
  children,
  glossaryTerms,
  subdomain,
  useMDX = false,
  useReusableContent = true,
  disallowReusableContent = false,
  useTestData = false,
  version = '1.0',
}: ProjectData & { children: React.ReactNode }) => {
  const value = useReusableContentMenuReducer({
    basic,
    disallowReusableContent,
    glossaryTerms,
    subdomain,
    useMDX,
    useReusableContent,
    useTestData,
    version,
  });

  return (useReusableContent && !disallowReusableContent) || useMDX ? (
    <ReusableContentMenuContext.Provider value={value}>{children}</ReusableContentMenuContext.Provider>
  ) : (
    <>{children}</>
  );
};

export const useReusableContentMenu = () => useContext(ReusableContentMenuContext);
export default useReusableContentMenu;
