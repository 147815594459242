import type { RenderElementProps } from 'slate-react';

import React from 'react';

import type { JsxCommentTokenElement } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: JsxCommentTokenElement;
}

const JsxCommentToken = ({ attributes, children }: Props) => {
  return (
    <code className={classes.JsxCommentToken} {...attributes}>
      {children}
    </code>
  );
};

export default JsxCommentToken;
