import type { Dispatch, ReactNode } from 'react';

import React, { useMemo, createContext, useContext, useReducer } from 'react';
import { Path } from 'slate';

export enum TableToolbarActionTypes {
  Close = 'close',
  SetPath = 'set_path',
}

interface SetPath {
  path: Path;
  type: TableToolbarActionTypes.SetPath;
}

interface Close {
  type: TableToolbarActionTypes.Close;
}

type TableToolbarAction = Close | SetPath;

interface State {
  open: boolean;
  path?: Path;
}

const initialState: State = {
  open: false,
};

const reducer = (state: State, action: TableToolbarAction): State => {
  switch (action.type) {
    case TableToolbarActionTypes.SetPath:
      return state.open && state.path && Path.equals(state.path, action.path)
        ? state
        : {
            path: action.path,
            open: !!action.path,
          };
    case TableToolbarActionTypes.Close:
      return {
        ...state,
        open: false,
      };
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown action in useTableToolbar');
      return state;
  }
};

const TableToolbarContext = createContext([initialState, () => {}] as [State, Dispatch<TableToolbarAction>]);

export const useTableToolbar = () => useContext(TableToolbarContext);

export const TableToolbarProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: [State, Dispatch<TableToolbarAction>] = useMemo(() => [state, dispatch], [state, dispatch]);

  return <TableToolbarContext.Provider value={value}>{children}</TableToolbarContext.Provider>;
};

export default useTableToolbar;
