import Blockquote from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isBlockquote, isCallout, isOnlyBlockquote } from './shared';
import slashMenu from './slashMenu';

export default {
  Component: Blockquote,
  deserialize,
  isBlockquote,
  isCallout,
  isOnlyBlockquote,
  normalizeNode,
  onKeyDown,
  operations,
  serialize,
  slashMenu,
  type,
};
