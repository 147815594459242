import type { HTMLAttributes } from 'react';

import React, { useEffect, useMemo, useState } from 'react';
import { useSlateStatic, useSelected, ReactEditor } from 'slate-react';

import type { CMEditor } from '@ui/CodeSnippet';
import CodeSnippet from '@ui/CodeSnippet';
import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';
import type { CodeTabsElement, HtmlBlock, JsxFlowElement } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import { JsxFlow } from '../blocks';

import onKeyDown from './onKeyDown';
import classes from './style.module.scss';

export type CodeEditorProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  code: string;
  element: CodeTabsElement | HtmlBlock | JsxFlowElement;
  id?: string;
  language: string;
  onChange: (value: string) => void;
};

export type CodeEditorSelection = [{ ch: number; line: number }, { ch: number; line: number }];

const CodeEditor = ({ id, className, code = '', element, language, onChange }: CodeEditorProps) => {
  const editor = useSlateStatic();
  const [cmEditor, setCmEditor] = useState<CodeMirror.Editor | null>(null);
  const selected = useSelected();

  useEffect(() => {}, [cmEditor, code, editor.codeEditorSelection, element]);

  useEffect(() => {
    if (selected && !acrossBlocks(editor)) {
      const newSelection = editor.codeEditorSelection.get(element);
      if (cmEditor && newSelection) {
        cmEditor.setSelection(...newSelection);
        editor.codeEditorSelection.delete(element);
      }

      cmEditor?.focus();
      cmEditor?.refresh();
    } else if (!selected) {
      cmEditor?.setSelection({ line: 0, ch: 0 }, { line: 0, ch: 0 });
    }
  }, [selected, cmEditor, editor, element]);

  const options = useMemo(
    () => ({ editable: selected, foldGutter: true, ...(JsxFlow.is(element) && { lineNumbers: false }) }),
    [element, selected],
  );

  const editorProps = useMemo(
    () => ({
      editorDidMount: setCmEditor,
      onChange: (_e: Event, _data: unknown, value: string) => {
        onChange(value);
      },
      onKeyDown: (codeMirrorEditor: CMEditor, event: KeyboardEvent) => {
        const path = ReactEditor.findPath(editor, element);
        onKeyDown(event, codeMirrorEditor, editor, path);
      },
    }),
    [onChange, editor, element],
  );

  const _className = useClassName(classes.CodeEditor, className, JsxFlow.is(element) && classes.CodeEditor_JsxFlow);

  return (
    <CodeSnippet
      className={_className}
      code={code}
      contentEditable={false}
      // We're removing editor control here via contentEditable above so CodeMirror can
      // handle editing, so we have to pass our onChange and onKeyDown handlers along to CodeMirror
      editorProps={editorProps}
      id={id}
      language={language}
      options={options}
    />
  );
};

export default CodeEditor;
