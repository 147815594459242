import Figcaption from './Component';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { isFigcaption, type } from './shared';

export default {
  Component: Figcaption,
  deserialize,
  isFigcaption,
  onKeyDown,
  operations,
  serialize,
  type,
};
