import type { Node } from 'mdast';

import type { FigcaptionElement, FigcaptionMdNode, ParagraphElement, ReadmeElement } from '@ui/MarkdownEditor/types';

import { type } from './shared';

/*
 * @note: We're overloading the node type as ImageBlockMdNode for @readme/mdxv7's
 * `image-block` type. Upstream, the different types have been collapsed into 1.
 */
export const deserialize = <T = FigcaptionMdNode>(
  node: T,
  deeper: (node: T) => [ParagraphElement],
): FigcaptionElement => {
  return {
    type,
    children: deeper(node),
  };
};

export const serialize = (node: FigcaptionElement, deeper: (node: ReadmeElement) => Node[]): FigcaptionMdNode => {
  return {
    type: 'figcaption',
    data: {
      hName: 'figcaption',
    },
    children: deeper(node) as FigcaptionMdNode['children'],
  };
};
