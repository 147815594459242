import type { BaseRange, Location } from 'slate';

import _copy from 'copy-to-clipboard';
import { Path, Editor, Range, Transforms, Node } from 'slate';

import { uploadImage } from '@ui/ImageUploader';
import { Image, List, ListItem, ReusableContent } from '@ui/MarkdownEditor/editor/blocks';
import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';
import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { ImageElement } from '@ui/MarkdownEditor/types';

export const BLOCKMENU_WIDTH = 30;

const getBlock = (editor: Editor) => {
  const fragment = editor.getFragment();

  if (fragment.length > 1 || fragment.length === 0 || !List.isList(fragment[0])) {
    return fragment;
  }

  for (const [node] of [...Node.descendants(fragment[0])].reverse()) {
    if (List.isList(node) && node.children.length === 1) {
      return [node];
    }
  }

  return fragment;
};

const copy = (editor: Editor, at: BaseRange | Location) => {
  // @note: Not sure who's sometimes changing the selection, so let's just
  // re-select before operating.
  Transforms.select(editor, at);
  const root = { children: getBlock(editor) };

  _copy(editor.serialize(root));
};

const remove = (editor: Editor, at: BaseRange) => {
  const commonEntry = Editor.node(editor, at);
  const [commonNode, commonPath] = commonEntry;
  const listItemEntry = ListItem.is(commonEntry[0]) ? commonEntry : Editor.above(editor, { at, match: ListItem.is });

  if (commonPath.length === 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = at.focus.path[0]; i >= at.anchor.path[0]; i--) {
      Transforms.removeNodes(editor, { at: [i] });
    }

    return;
  }

  if (List.is(commonNode)) {
    // eslint-disable-next-line no-plusplus
    for (let i = at.focus.path[commonPath.length + 1]; i >= at.anchor.path[commonPath.length + 1]; i--) {
      Transforms.removeNodes(editor, { at: [...commonPath, i] });
    }

    return;
  }

  const blockPath = listItemEntry ? listItemEntry[1] : commonPath.slice(0, 1);
  Transforms.removeNodes(editor, { at: blockPath });
};

const insertLineBelow = (editor: Editor, at: BaseRange) => {
  const commonEntry = Editor.node(editor, at);
  const listItemEntry = ListItem.is(commonEntry[0]) ? commonEntry : Editor.above(editor, { at, match: ListItem.is });

  if (listItemEntry) {
    const path = Path.next(listItemEntry[1]);

    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, emptyNode(), { at: path });
      Transforms.unwrapNodes(editor, { at: path, split: true, match: List.isList });
    });

    return;
  }

  const path = Path.next(at.focus.path.slice(0, 1));
  Transforms.insertNodes(editor, emptyNode(), { at: path, select: true });
};

const insertLineAbove = (editor: Editor) => {
  const path = [0];
  Transforms.insertNodes(editor, emptyNode(), { at: path, select: true });
};

export const expandSelection = (editor: Editor, path: Path) => {
  let selection;

  if (editor.selection && acrossBlocks(editor) && Range.includes(editor.selection, path)) {
    const commonPathLength = ListItem.is(Node.get(editor, path))
      ? Path.common(editor.selection.anchor.path, editor.selection.focus.path).length + 1
      : 1;

    selection = {
      anchor: Editor.start(editor, Range.start(editor.selection).path.slice(0, commonPathLength)),
      focus: Editor.end(editor, Range.end(editor.selection).path.slice(0, commonPathLength)),
    };
  } else {
    selection = Editor.range(editor, path);
  }

  Transforms.select(editor, selection);
};

const dropImage = (editor: Editor, item: { dataTransfer: DataTransfer }, { to }: { to: Path }) => {
  const insertImage = (url: string) => {
    if (Image.is(Node.get(editor, to))) {
      Transforms.setNodes(editor, { url }, { at: to });
    } else {
      const image: ImageElement = { type: Image.type as ImageElement['type'], url, children: [{ text: '' }] };
      const at = Path.next(to);

      Transforms.insertNodes(editor, image, { at });
    }
  };

  const handleFiles = (files: FileList) => {
    const allowedTypes = /image.*/;
    const file = files[0];
    // TODO: we should trigger some state update or something, to be able to show the right notification in the modal
    if (!file.type.match(allowedTypes)) return;

    if (editor.props.useTestData) {
      const reader = new FileReader();
      /* @ts-ignore */
      reader.onload = (e: ProgressEvent) => insertImage(e?.target?.result);
      reader.readAsDataURL(file);
    } else {
      /* @ts-ignore */
      uploadImage({ file, baseUrl: editor.props.domainFull }).then(res => insertImage(res[0]));
    }
  };

  // If there's a URL, the image was dragged from the browser and we don't need to save it to our db
  const url = item.dataTransfer.getData('text/uri-list');
  if (url) insertImage(url);
  else if (item.dataTransfer.files.length) handleFiles(item.dataTransfer.files);
};

const drop = (editor: Editor, { at, to }: { at: Path; to: Path }) => {
  // @xxx: There's some implicit behavior here that is coupled with the
  // `BlockMenu` component and the `BlockMenu_Hoverboad` styles! It's
  // undocumented, but when moving a node to a later path, it inserts the node
  // after the path, versus when moving a node to an earlier path, it inserts
  // it directly at the path.
  Transforms.moveNodes(editor, { at, to });
  Transforms.select(editor, to);
};

const menuable = (_: Editor, path: Path) => {
  const isTopLevel = path.length === 1;
  return isTopLevel;
};

const containsReusableContent = (editor: Editor) => {
  // Does the current editor.selection contain any reusable content nodes?
  return !![...Editor.nodes(editor, { match: ReusableContent.isReusableContent })].length;
};

const Utils = {
  copy,
  drop,
  dropImage,
  remove,
  insertLineBelow,
  insertLineAbove,
  menuable,
  containsReusableContent,
};

export default Utils;
