import type { Location } from 'slate';

import { Editor, Transforms } from 'slate';

import type { EmojiInline, ReadmeEditor } from '@ui/MarkdownEditor/types';

import { selectAfter } from '../shared';

import { isEmoji } from './shared';

export const insertEmoji = (editor: ReadmeEditor, emoji: EmojiInline | string, { at }: { at: Location }) => {
  if (typeof emoji === 'string') {
    Transforms.insertText(editor, `:${emoji}:`, { at });
  } else {
    Transforms.insertNodes(editor, emoji, { at, select: true });
  }

  if (!editor.selection) return;

  if (!Editor.above(editor, { match: isEmoji })) return;

  selectAfter(editor);
};
