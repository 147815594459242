import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { toggle } from '@ui/MarkdownEditor/editor/decorations';

import { Button } from '../IconMenu';

const FormatButton = ({ active, type, icon = type }) => {
  const editor = useSlateStatic();
  // In Safari, selection gets pulled away when clicking in the inline menu.
  // We use this variable to ensure we have the editor selection saved in the above case.
  const [selection, setSelection] = useState(editor.selection);

  const toggleFormat = useCallback(
    event => {
      // In Safari, selection is pulled from the editor when we click into the inline
      // toolbar. If we're in the middle of toggling and this is the case, restore selection
      // to make sure we can toggle the correct section of text.
      if (!editor.selection) Transforms.select(editor, selection);

      event.stopPropagation();
      event.preventDefault();

      toggle(editor, type, active);
    },
    [active, editor, type, selection],
  );

  const persistSelection = useCallback(() => {
    setSelection(editor.selection);
  }, [editor]);

  return <Button icon={icon} onPointerDown={persistSelection} onPointerUp={toggleFormat}></Button>;
};

FormatButton.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default React.memo(FormatButton);
