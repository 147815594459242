import { ReactEditor } from 'slate-react';

import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { EditorMouseEventHandler, ImageElement, ImageBlock } from '@ui/MarkdownEditor/types';

const onMouseOver: EditorMouseEventHandler = (editor, event) => {
  const [{ node }, dispatch] = editor.imageMenu;
  const elements = document.elementsFromPoint(event.clientX, event.clientY);
  const imageMenuTarget = elements.find(el => el instanceof HTMLElement && el.dataset.imageMenuTarget);

  // @xxx: Stopping at the first `.markdown-body` prevents us from finding an
  // editor below the current one!
  if (!imageMenuTarget || !ReactEditor.hasDOMNode(editor, imageMenuTarget)) {
    if (node) dispatch({ type: ImageMenuActionTypes.close });
    return;
  }

  const image = ReactEditor.toSlateNode(editor, imageMenuTarget) as ImageBlock | ImageElement;
  dispatch({ type: ImageMenuActionTypes.open, payload: { node: image } });
};

export default onMouseOver;
