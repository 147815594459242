import { Editor, Node, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { trailingBlankChildren } from '../../utils';

import { isBlockquote, isAtEdgeOfBlockquote, isCallout } from './shared';

const enter: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Enter' && !event.shiftKey && isAtEdgeOfBlockquote(editor, { edge: 'end' }))) {
    return;
  }

  const [block, path] = Editor.above(editor, { match: isBlockquote }) || [];
  const blankLines = [...trailingBlankChildren(editor, path)];
  if (!blankLines.length || !block) return;

  if (isCallout(block) && blankLines.length === 1 && block.children.length === 1) return;

  event.preventDefault();
  event.stopPropagation();

  blankLines.slice(1).forEach(() => Transforms.mergeNodes(editor));
  Transforms.unwrapNodes(editor, { match: isBlockquote, split: true });
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && isAtEdgeOfBlockquote(editor, { edge: 'start' }))) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.unwrapNodes(editor, { match: isBlockquote });
};

const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && isAtEdgeOfBlockquote(editor, { edge: 'end' }))) return;

  const [, blockquotePath] = Editor.above(editor, { match: isBlockquote }) || [];
  if (!blockquotePath) return;

  const nextPath = Path.next(blockquotePath);
  if (Node.has(editor, nextPath)) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.insertNodes(editor, emptyNode(), { at: nextPath, select: true });
};

export default [arrowDown, backspace, enter, backspace];
