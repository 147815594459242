import { Editor, Range } from 'slate';

import { isJsxComment } from './shared';

const enter = (event: KeyboardEvent, editor: Editor) => {
  if (event.key !== 'Enter') return;

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) return;

  const [comment] = Editor.above(editor, { match: isJsxComment }) || [];
  if (!comment) return;

  event.preventDefault();
  event.stopPropagation();

  editor.insertText('\n');
};

export default [enter];
