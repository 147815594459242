import isEqual from 'lodash/isEqual';
import { Editor, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { isRecipe } from './shared';

export const insertRecipe = (editor, { at = editor.selection } = {}) => {
  const [, { open }] = editor.recipeMenu;
  const [node, path] = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) }) || [];

  const recipeAt = isEqual(emptyNode(), node) ? path : Path.next(path);

  Transforms.insertNodes(editor, [{ type: 'recipe', children: [{ text: '' }] }], {
    at: recipeAt,
    select: true,
  });

  const [newRecipe] = Editor.nodes(editor, { at: recipeAt, match: isRecipe }).next().value || [];
  if (newRecipe) open(newRecipe);
};

export default { insertRecipe };
