import type { DropTargetMonitor, XYCoord } from 'react-dnd';
import type { RenderElementProps } from 'slate-react';

import React from 'react';
import { useDrop } from 'react-dnd';
import { useSlateStatic, ReactEditor } from 'slate-react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import { Image } from '@ui/MarkdownEditor/editor/blocks';
import { DragItems, ImageAlignValues } from '@ui/MarkdownEditor/enums';
import type { BlockDragItem, DragItem, FileDrop } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';
import Utils from './utils';

const getImageFromPoint = (editor: ReactEditor, coords: XYCoord | null) => {
  if (!coords) return null;
  const { x, y } = coords;

  return document
    .elementsFromPoint(x, y)
    .filter(el => el.attributes.getNamedItem('data-slate-node'))
    .map(el => ReactEditor.toSlateNode(editor, el))
    .find(n => Image.is(n));
};

interface CollectedDropProps {
  isOver: boolean;
  item?: DragItem;
}

const BlockMenu = ({ attributes, element, children }: RenderElementProps) => {
  const editor = useSlateStatic();
  const { id: dndId } = useDragAndDropContext();
  const bem = useClassy(classes, 'BlockMenu_Hoverpad');
  const isFloat = Image.is(element) && element.align !== ImageAlignValues.center;

  /* `useDrop` registers a drop target. Each editor's block will accept another
   * block (or file) to be dragged to it.
   */
  const [{ isOver, item }, drop] = useDrop<DragItem, void, CollectedDropProps>(
    () => ({
      accept: [DragItems.Block, DragItems.File],
      canDrop: dragItem => (dragItem as BlockDragItem).dndId === dndId,
      collect: (monitor: DropTargetMonitor<DragItem, void>) => ({
        isOver: monitor.canDrop() && monitor.isOver({ shallow: true }),
        item: monitor.getItem(),
      }),
      drop: (source, monitor) => {
        const to = ReactEditor.findPath(editor, element);

        switch (monitor.getItemType()) {
          case DragItems.Block: {
            if ((source as BlockDragItem).dndId !== dndId) return;

            const { element: sourceEl } = source as BlockDragItem;
            if (!sourceEl) return;

            const at = ReactEditor.findPath(editor, sourceEl);

            Utils.drop(editor, { at, to });
            break;
          }
          case DragItems.File: {
            const image = getImageFromPoint(editor, monitor.getClientOffset());
            const path = image ? ReactEditor.findPath(editor, image) : to;

            Utils.dropImage(editor, source as FileDrop, { to: path });
            break;
          }
          default:
        }
      },
    }),

    [dndId, editor, element],
  );

  drop(attributes.ref);

  /* @warning: Adding components for drag and drop at one point caused issues
   * with `contenteditable`. I have no idea what the precise cause was, but the
   * effect was that if you arrowed up or down from an empty paragraph, it
   * would jump to the start or end of the doc.
   */

  return (
    <div
      {...attributes}
      className={bem(
        '&',
        item && 'element' in item && item.element === element && '--dragging',
        isOver && '--hover',
        isFloat && '--float',
      )}
      data-block-menu={true}
    >
      {children}
    </div>
  );
};

BlockMenu.menuable = Utils.menuable;

export { default as BlockMenuTooltip } from './Tooltip';
export { default as DragLayer } from './DragLayer';
export { default as BlockMenuContainer } from './Container';

export default BlockMenu;
