import type { ImageUploaderData, UploadImageResponse } from '.';

/**
 * Normalizes the provided image data whether it's an Array or Object and
 * returns it in Object form that matches the ImageRepresentation shape that our
 * API defines. If an Array has 0 length, it is assumed to be `undefined`.
 */
export function toImageRepresentation(data?: ImageUploaderData | UploadImageResponse): ImageUploaderData | undefined {
  if (!Array.isArray(data)) return data;
  if (!data.length) return undefined;
  return {
    url: data[0],
    name: data[1],
    width: data[2],
    height: data[3],
    color: data[4],
    links: {
      original_url: data[5] || null,
    },
    uri: data[6],
  };
}
