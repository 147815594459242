import type { Range } from 'slate';
import type { RenderLeafProps } from 'slate-react';

import React, { useRef } from 'react';
import { useSlateStatic } from 'slate-react';

import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { FormattedText } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

type Props = RenderLeafProps & {
  leaf: FormattedText & {
    link: true;
    range: Range;
    skipMenu?: boolean;
  };
};

const LinkLeaf = ({ children, leaf, ...attributes }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);
  const editor = useSlateStatic();
  const [, dispatch] = editor.urlMenu;

  const onClick = () => {
    if (leaf.skipMenu) return;

    dispatch({ type: MenuActionTypes.open, range: leaf.range, target: ref });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <span ref={ref} className={classes.Link} {...attributes} onClick={onClick}>
      {children}
    </span>
  );
};

const Link = {
  render: LinkLeaf,
  type: 'link',
};

export default Link;
