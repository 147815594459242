import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import * as operations from './operations';
import { deserialize, serialize } from './serialize';
import { isCodeTabs, tabId, type } from './shared';
import slashMenu from './slashMenu';

const CodeTabs = {
  Component,
  deserialize,
  is: isCodeTabs,
  isCodeTabs,
  isVoid: true,
  normalizeNode,
  onKeyDown,
  operations,
  serialize,
  slashMenu,
  tabId,
  type,
};

export default CodeTabs;
