import type { ReactNode } from 'react';

import React, { createContext, useContext } from 'react';
import { useSelected, useSlateStatic } from 'slate-react';

import classes from '@ui/MarkdownEditor/style.module.scss';
import useClassName from '@ui/MarkdownEditor/useClassName';

import { acrossBlocks } from './selection';

const SelectedClassNameContext = createContext<string | undefined>('');

export const SelectedClassNameProvider = ({ children }: { children: ReactNode; className?: string }) => {
  const selected = useSelected();
  const editor = useSlateStatic();
  const className = editor.selection && selected && acrossBlocks(editor) ? classes.selected : null;

  return <SelectedClassNameContext.Provider value={className}>{children}</SelectedClassNameContext.Provider>;
};

const useSelectedClassName = (...classNames: (boolean | string | null | undefined)[]) => {
  const contextSelectedClassName = useContext(SelectedClassNameContext);

  return useClassName(contextSelectedClassName, ...classNames);
};

export default useSelectedClassName;
