export const sampleData = [
  {
    type: 'reusable-content',
    source: '# Testing!',
    name: 'Testing',
    tag: 'Testing',
    children: [],
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'reusable-content',
    source: '# I am Reusable!',
    name: 'Reusable',
    tag: 'Reusable',
    children: [],
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'reusable-content',
    source: '# Hey there',
    name: 'Hello',
    tag: 'hello',
    children: [],
    updated_at: '2023-09-22T18:07:41.048Z',
  },
];
