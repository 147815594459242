import type { Dispatch, ReactNode, RefObject } from 'react';
import type { RangeRef } from 'slate';

import fuzzysort from 'fuzzysort';
import React, { createContext, useContext, useReducer, useMemo } from 'react';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { Props } from '@ui/MarkdownEditor/types';

import { log } from '../log';
import { bounded } from '../utils';

import { sampleData } from './sampleData';

interface InitAction {
  payload: { rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

type VariableMenuAction = CloseAction | DownAction | InitAction | OpenAction | SearchAction | UpAction;

interface Term {
  definition: string;
  id: string;
  name: string;
  type: string;
}

interface VariableMenuState {
  filtered: Term[];
  open: boolean;
  rangeRef: RangeRef | null;
  search: string | null;
  selected: number;
  target: RefObject<HTMLElement> | null;
  terms: Term[];
}

interface VariableMenuReducer {
  (state: VariableMenuState, action: VariableMenuAction): VariableMenuState;
}

export interface MenuItem {
  definition: string;
  id: string;
  name: string;
  prefix: string;
  search?: string;
  type: string;
}

type ProjectData = Required<Pick<Props, 'glossaryTerms' | 'useMDX' | 'useTestData' | 'variableDefaults'>>;

const _initial: VariableMenuState = {
  terms: [],
  filtered: [],
  open: false,
  search: null,
  selected: 0,
  rangeRef: null,
  target: null,
};

const byScoreThenAlpha = (left: Fuzzysort.KeysResult<Term>, right: Fuzzysort.KeysResult<Term>) => {
  // right[0] or left[0] is null when we match against the category instead of the name
  if (!right[0] || !left[0]) {
    return 0;
  }
  const byScore = right[0].score - left[0].score;
  return byScore !== 0 ? byScore : left[0].target.localeCompare(right[0].target);
};

const useVariableMenuReducer = ({ glossaryTerms, useTestData, variableDefaults, useMDX }: ProjectData) => {
  const memoized: [VariableMenuReducer, VariableMenuState] = useMemo(() => {
    // combine glossaryTerms and variableDefaults with common attributes
    const projectTerms: { definition: string; id: string; name: string; prefix: string; type: string }[] = [];

    if (!useTestData) {
      for (const v of variableDefaults) {
        if (v)
          projectTerms.push({
            id: v._id,
            name: v.name || '',
            definition: v.default || '',
            prefix: '',
            type: 'Variable',
          });
      }

      if (!useMDX) {
        for (const g of glossaryTerms) {
          if (g)
            projectTerms.push({
              id: g._id,
              name: g.term,
              definition: g.definition,
              prefix: 'glossary:',
              type: 'Glossary Term',
            });
        }
      }
    }

    const terms = (useTestData ? sampleData.filter(term => (useMDX ? term.type === 'Variable' : true)) : projectTerms)
      .map(term => (useMDX ? { ...term, name: `user.${term.name}` } : term))
      .sort((a, b) => a.name.localeCompare(b.name));

    const initial = { ..._initial, filtered: terms, terms };

    const reducer: VariableMenuReducer = (state, action) => {
      log(action.type, action, state);
      switch (action.type) {
        case 'init':
          return { ...initial, rangeRef: action.payload.rangeRef };
        case 'open':
          if (state.open) return state;
          return { ...state, target: action.payload.target, open: true };
        case 'up':
          return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
        case 'down':
          return { ...state, selected: bounded(state.selected + 1, state.filtered.length) };
        case 'search': {
          const regex = useMDX ? /^{/ : /^(<<(glossary:)?|glossary:)/;
          const search = action.payload ? action.payload.replace(regex, '') : '';

          return search === state.search
            ? state
            : {
                ...state,
                search,
                selected: 0,
                filtered: search
                  ? fuzzysort
                      .go(search, state.terms, {
                        keys: ['name'],
                      })
                      // @ts-ignore
                      .sort(byScoreThenAlpha)
                      .map((r: Fuzzysort.KeysResult<Term>) => r.obj)
                  : state.terms,
              };
        }
        case 'close':
          state.rangeRef?.unref();
          return {
            ...state,
            open: false,
            target: null,
            rangeRef: null,
          };
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useVariableMenu');
          return state;
      }
    };

    return [reducer, initial];
  }, [glossaryTerms, useMDX, useTestData, variableDefaults]);

  // @note: Much to my chagrin, the array's returned from react hooks are not
  // guaranteed to be stable. Sometimes I like to pass the whole tuple around,
  // but of course that means it changes every render. For my sanity, let's
  // memoize it.
  const [variableState, dispatch] = useReducer(...memoized);
  return useMemo<[VariableMenuState, Dispatch<VariableMenuAction>]>(() => [variableState, dispatch], [variableState]);
};

const VariableMenuContext = createContext([_initial, () => {}] as [VariableMenuState, Dispatch<VariableMenuAction>]);

export const VariableMenuProvider = ({
  children,
  glossaryTerms = [],
  useTestData = false,
  variableDefaults = [],
  useMDX = false,
}: ProjectData & { children: ReactNode }) => {
  const value = useVariableMenuReducer({ glossaryTerms, useTestData, variableDefaults, useMDX });

  return <VariableMenuContext.Provider value={value}>{children}</VariableMenuContext.Provider>;
};

export const useVariableMenu = () => useContext(VariableMenuContext);
export default useVariableMenu;
