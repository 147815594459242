/* eslint-disable consistent-return */
import { Editor, Text, Transforms, Node, Path } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { isImageBlock } from '../ImageBlock/shared';
import ImageEmoji from '../ImageEmoji';
import { isLink } from '../Link/shared';
import { selectAfter } from '../shared';
import { isInTable } from '../Table/shared';

import { defaultImage, isImage, type } from './shared';

// @todo: replace with unist-util-visit once we can import esm only modules.
const visit = (tree, _type, fn) => {
  const queue = [[tree, 0, null]];

  while (queue.length) {
    const [current, idx, parent] = queue.shift();

    if (_type === current.type) {
      fn(current, idx, parent);
    }

    if (current.children) {
      queue.push(...current.children.map((child, i) => [child, i, current]));
    }
  }
};

const convertToImage =
  next =>
  (editor, [node, path]) => {
    if (!Text.isText(node) || Editor.above(editor, { at: path, match: n => isImage(n) || isLink(n) })) return next();

    const string = Node.string(node);
    // @perf: we can short circuit the deserializer with a quick regex
    if (!string.match(/!\[.*\]\(.*\)|:.*:/)) return next();

    let mdast;
    try {
      mdast = editor.renderingLibrary.mdast(string, { settings: { position: true } });
    } catch {
      // no-op if the string is not valid mdx
    }

    // doesn't look like markdown formatting
    if (!mdast) return next();

    let isInline = false;
    let image;
    visit(mdast, type, (n, _, parent) => {
      image = n;
      isInline = parent?.children.length > 1;
    });
    if (!image) return next();

    const { position, url, alt, title } = image;
    const leadingOffset = Node.string(node).match(/\S/)?.index || 0;

    const imageLocation = {
      anchor: { path, offset: position.start.offset + leadingOffset },
      focus: { path, offset: position.end.offset + leadingOffset },
    };

    if (ImageEmoji.isMdImageEmoji(image)) {
      const emoji = ImageEmoji.deserialize(image);
      Transforms.insertNodes(editor, emoji, { at: imageLocation, select: true });
      selectAfter(editor);
      return;
    }

    const inTable = isInTable(editor, path);
    const props = { alt, title, url };
    if (isInline || inTable) props.isInline = true;

    const imageNode = defaultImage(props);

    Editor.withoutNormalizing(editor, () => {
      if (!inTable && !leadingOffset && mdast.children[0].children.length === 1) {
        const at = Path.parent(path);

        Transforms.removeNodes(editor, { at });
        Transforms.insertNodes(editor, imageNode, { at, select: true });
      } else {
        Transforms.insertNodes(editor, imageNode, { at: imageLocation, select: true });
      }
    });
  };

const textWithBang = (editor, [node, path]) => {
  return (
    Text.isText(node) &&
    !Editor.above(editor, { at: path, match: isImage }) &&
    !Editor.above(editor, { at: path, match: isImageBlock }) &&
    node.text.endsWith('!')
  );
};

const linkAfterBang = (editor, [node, path]) => {
  if (!isLink(node)) return false;
  if (!Path.hasPrevious(path)) return false;

  const linkStart = Editor.start(editor, path);

  const previous = Editor.before(editor, linkStart, { unit: 'character' });
  return previous && Editor.string(editor, { anchor: previous, focus: linkStart }) === '!';
};

const hasInlineContent = (editor, [node, path]) => {
  return Node.string(Node.parent(editor, path)).trim() !== `!${Node.string(node)}`;
};

const convertLinkToImage =
  next =>
  (editor, [node, path]) => {
    let bangPoint;
    let linkEntry;

    if (textWithBang(editor, [node, path])) {
      bangPoint = Editor.before(editor, Editor.end(editor, path), { unit: 'character' });
      linkEntry = Editor.next(editor, { at: path });
      if (!linkEntry || !isLink(linkEntry[0])) return next();
    } else if (linkAfterBang(editor, [node, path])) {
      bangPoint = Editor.before(editor, Editor.start(editor, path), { unit: 'character' });
      linkEntry = [node, path];
    } else {
      return next();
    }

    const { url, label: alt, title } = linkEntry[0];
    const inTable = isInTable(editor);
    const isInline = inTable || hasInlineContent(editor, linkEntry);
    const imageNode = defaultImage({ alt, title, url, isInline });

    Editor.withoutNormalizing(editor, () => {
      let at = Path.parent(path);
      if (!isInline || inTable) {
        // We're making a decision to restrict images in tables such that they
        // have no sibling content.
        //
        // If we're not inline, we also need to remove the parent paragraph so
        // the 'block' image can take it's place.
        Transforms.removeNodes(editor, { at: Path.parent(path) });
      } else {
        Transforms.delete(editor, { at: bangPoint });
        Transforms.removeNodes(editor, { at: linkEntry[1] });
        at = bangPoint;
      }

      Transforms.insertNodes(editor, imageNode, { at, select: true });
    });
  };

const syncIsInline =
  next =>
  (editor, [node, path]) => {
    if (!isImage(node)) return next();

    const isInlineContext = Editor.hasInlines(editor, Node.parent(editor, path));
    const shouldBeInline = isInlineContext || isInTable(editor, path);
    if (node.isInline === shouldBeInline) return next();

    Editor.withoutNormalizing(editor, () => {
      Transforms.setNodes(editor, { isInline: shouldBeInline }, { at: path });

      if (!isInlineContext && shouldBeInline) {
        const charRangeBefore = {
          anchor: Editor.before(editor, path, { distance: 2 }),
          focus: Editor.before(editor, path),
        };

        if (Editor.string(editor, charRangeBefore) === '\n') {
          Transforms.delete(editor, { at: charRangeBefore });
        }

        Transforms.wrapNodes(editor, emptyNode(), { at: path });
      }
    });
  };

export default [convertToImage, convertLinkToImage, syncIsInline];
