import type { ForwardedRef } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './index.module.scss';

interface PurpleBoxProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Used as the purple-themed container for our Trial and Paygate UI components.
 */
function PurpleBox({ children, className }: PurpleBoxProps, ref: ForwardedRef<HTMLDivElement>) {
  const bem = useClassy(classes, 'PurpleBox');

  return (
    <div ref={ref} className={bem('&', className)}>
      {children}
    </div>
  );
}

export default forwardRef(PurpleBox);
