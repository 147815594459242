import { getFeaturePlan } from '@readme/iso';
import React, { useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';

// eslint-disable-next-line no-restricted-imports
import ReusableContentForm from '@routes/Dash/CustomBlocks/Form';
// eslint-disable-next-line no-restricted-imports
import PlanAccessBadge from '@routes/SuperHub/Layout/PlanAccess/Badge';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import InfoPop from '@ui/InfoPop';
import { MenuConfigActionTypes } from '@ui/MarkdownEditor/enums';
import Modal, { ModalHeader } from '@ui/Modal';
import PartyOwlbert from '@ui/PartyOwlbert';

import { useReusableContentMenu } from '../ReusableContentMenu';

import classes from './index.module.scss';
import useReusableContentModal from './useReusableContentModal';

const ReusableContentModal = () => {
  const [{ reusableContent }, reusableContentMenuDispatch] = useReusableContentMenu();

  const party = useRef<Modal>(null);

  const makeParty = () => {
    if (reusableContent.length === 0) party.current?.toggle(true);
  };

  const tagName = 'ReusableContentModal';
  const uid = useUniqueId(tagName);
  const bem = useClassy(classes, tagName);
  const { close, modalRef, currentItem, isOpen, onSaveCallback, onDeleteCallback } = useReusableContentModal();

  const [isSuperHubAdmin] = useSuperHubStore(state => [state.isSuperHubAdmin]);
  const { showTrialIndicator } = useProjectPlan();

  const showTrialBadge = isSuperHubAdmin && showTrialIndicator('reusableContent');
  const upgradePlan = getFeaturePlan('reusableContent');

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem()}
        data-testid="reusable-content-modal"
        noDismiss
        size="xl"
        target={`#${uid('wrapper')}`}
        verticalCenter
      >
        <section className={bem('-content')}>
          <ModalHeader className={bem('-header')} toggleClose={close}>
            <Flex align="center" gap="sm">
              <Icon className={bem('-header-icon')} color="green" name="recycle" />
              <h1 className={bem('-header-title')}>Reusable Content</h1>
              <InfoPop className={bem('-header-infopop')}>
                Reuse the same content across your docs. Update in one place to update everywhere.
              </InfoPop>
              {!!showTrialBadge && (
                <PlanAccessBadge
                  access="trial"
                  align="bottom"
                  className={bem('-trialBadge')}
                  justify="center"
                  plan={upgradePlan}
                  tooltip={true}
                />
              )}
            </Flex>
          </ModalHeader>
          <div className={bem('-body')}>
            {!!isOpen && !!currentItem && (
              <Box className={bem('-form-wrapper')} kind="card">
                <ReusableContentForm
                  item={currentItem}
                  onCancel={close}
                  onDeleteSuccess={() => {
                    reusableContentMenuDispatch({ type: MenuConfigActionTypes.revalidate });
                    onDeleteCallback?.();
                    close();
                  }}
                  onSaveSuccess={data => {
                    reusableContentMenuDispatch({ type: MenuConfigActionTypes.revalidate });
                    onSaveCallback?.(data);
                    makeParty();
                    close();
                  }}
                />
              </Box>
            )}
          </div>
        </section>
      </Modal>
      <div className="ModalWrapper" id={uid('wrapper')}></div>
      <PartyOwlbert content="First Reusable Content Block" disappear={2} partyRef={party} />
    </>
  );
};

export { default as useReusableContentModal, ReusableContentModalProvider } from './useReusableContentModal';
export default ReusableContentModal;
