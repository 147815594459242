import Cookie from 'js-cookie';

export const getDecoratedHeaders = () => {
  return {
    'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
    'Content-Type': 'application/json',
  };
};

const makeFetch = (url: string, packet: Partial<RequestInit> = {}) => {
  const decoratedPacket = { headers: getDecoratedHeaders(), ...packet } as RequestInit;
  return fetch(url, decoratedPacket);
};

export default makeFetch;
