import { Editor } from 'slate';

import Image from '@ui/MarkdownEditor/editor/blocks/Image';
import type { ReadmeEditor, ReadmeElement } from '@ui/MarkdownEditor/types';

import traverse from './traverse';

/*
 * Utility function to convert image blocks into inline images.
 * This is useful for when pasting content into a table cell, which only
 * supports inline content.
 */
const deblockify = (editor: Editor, value: ReadmeElement[]): ReadmeElement[] => {
  traverse({ children: value } as ReadmeEditor, (node, idx, parent) => {
    if (Image.is(node) && Editor.isBlock(editor, node)) {
      if (parent && 'children' in parent) {
        parent.children[idx] = Image.toInline(node);
      }
    }
  });

  return value;
};

export default deblockify;
