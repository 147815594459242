import type { Link } from 'mdast';
import type { Path, NodeEntry } from 'slate';

import { Node } from 'slate';

import type {
  Deserializer,
  FormattedText,
  ImageBlock,
  ImageElement,
  InlineMdNode,
  LinkElement,
  MarkdownCharInline,
  ReadmeText,
  Serializer,
} from '@ui/MarkdownEditor/types';

import { isImage, toString as imageToString } from '../Image/shared';
import { isImageBlock, toString as imageBlockToString } from '../ImageBlock/shared';

import { type, toString, defaultLink } from './shared';

const set = (node: LinkElement, path: Path, element: { text: string }) => {
  const [lastPath, ...paths] = path.reverse();
  let ref: FormattedText | LinkElement | MarkdownCharInline = node;

  paths.forEach(p => {
    if ('children' in ref) ref = ref.children[p];
  });

  ref.children[lastPath] = element;
};

/* @note: this is to workaround https://linear.app/readme-io/issue/RM-4501/link-within-image-not-rendered-in-new-editor
 * An alternative solution might be to allow images as inline and improve the
 * image menu, but that will require considerable more design and architecture
 * work. :shrug:
 */
const imagesToPlainText = (link: LinkElement): LinkElement => {
  const updates: NodeEntry<ImageBlock | ImageElement>[] = [];

  for (const [image, path] of Node.descendants(link)) {
    if (isImage(image) || isImageBlock(image)) {
      updates.push([image, path]);
    }
  }

  updates.forEach(([image, path]) => {
    const imgString = isImageBlock(image) ? imageBlockToString(image) : imageToString(image);
    set(link, path, { text: imgString });
  });

  return link;
};

export const deserialize: Deserializer<Link, LinkElement> = (node, deeper) => {
  const children = deeper(node) as ReadmeText[];

  const link: LinkElement = defaultLink({
    ...node,
    title: node.title ?? undefined,
    type,
    children,
  });

  imagesToPlainText(link);
  link.label = link.children
    .slice(1, link.children.length - 3)
    .map(n => Node.string(n))
    .join('');

  return link;
};

export const serialize: Serializer<LinkElement, InlineMdNode[] | Link> = (node, _, { renderingLibrary }) => {
  return renderingLibrary.mdast(toString(node, { title: true }));
};
