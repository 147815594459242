import PropTypes from 'prop-types';
import React from 'react';

import useSelectedClassName from '@ui/MarkdownEditor/editor/useSelectedClassName';

import classes from './style.module.scss';

const TableHeader = ({ attributes, children }) => {
  return (
    <thead {...attributes}>
      <tr className={useSelectedClassName(classes.TableHeader)}>{children}</tr>
    </thead>
  );
};

TableHeader.propTypes = {
  attributes: PropTypes.object,
};

export default TableHeader;
