import { deserialize, serialize } from './serialize';
import { type } from './shared';
import slashMenu from './slashMenu';

export default {
  deserialize,
  serialize,
  slashMenu,
  type,
};
