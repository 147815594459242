import type { Editor, NodeEntry, Range } from 'slate';

import { DIRTY_NODES_DECORATE } from '../cache';

const CACHE = new WeakMap();

type Decorate = (editor: Editor, nodeEntry: NodeEntry, opts?: { at?: Location }) => Range[];

const memoize =
  (fn: Decorate): Decorate =>
  (editor, [node, path], { at } = {}) => {
    if (at) return fn(editor, [node, path], { at });

    if (CACHE.has(node) && !DIRTY_NODES_DECORATE.has(node)) {
      return CACHE.get(node);
    }

    const result = fn(editor, [node, path], { at });

    DIRTY_NODES_DECORATE.delete(node);
    CACHE.set(node, result);

    return result;
  };

export default memoize;
