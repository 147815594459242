import type {
  HtmlBareMdNode,
  HtmlBlock,
  HtmlBlockMdNodeBase,
  HtmlMdNode,
  ParagraphElement,
} from '@ui/MarkdownEditor/types';

import { type as paragraphType } from '../Paragraph/shared';

import { type, rdmdType } from './shared';

export const deserialize = ({ type: nodeType, ...node }: HtmlMdNode): HtmlBlock | ParagraphElement => {
  const { value } = node as HtmlBareMdNode;
  if (nodeType === type) {
    return {
      type: paragraphType,
      children: [{ text: value }],
    } as ParagraphElement;
  }

  const {
    data: {
      hProperties: { runScripts, html },
    },
  } = node as HtmlBlockMdNodeBase;

  return {
    type,
    ...(runScripts && { runScripts }),
    value: html,
    children: [{ text: '' }],
  } as HtmlBlock;
};

export const serialize = ({ runScripts, value }: HtmlBlock): HtmlMdNode => {
  return {
    type: rdmdType,
    children: [{ type: 'text', value }],
    data: {
      hName: rdmdType,
      hProperties: {
        ...(runScripts && { runScripts }),
        html: value,
      },
    },
  } as HtmlMdNode;
};
