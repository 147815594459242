import Variable from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import * as operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isVariable, rdmdType } from './shared';

export default {
  Component: Variable,
  deserialize,
  hasInlineMd: true,
  is: isVariable,
  isVariable,
  isInline: true,
  normalizeNode,
  operations,
  onKeyDown,
  rdmdType,
  serialize,
  type,
};
