/* eslint-disable consistent-return */
import times from 'lodash/times';
import { Editor, Node, Path, Transforms } from 'slate';

import { deserializer, getNode } from '../../parser';
import { isFirstTextOfParagraph } from '../shared';

import { isBlockquote, isCallout, isOnlyBlockquote, type } from './shared';

// Not really sure why this is tagged as unsafe. I believe the {0,3} should
// prevent it from 'catastrophic backtracking'.
// Try it here: https://regexr.com/60567
const INDENT_REGEXP = /^( {0,3}> ?)+/;

const insertBlockquote =
  next =>
  (editor, [node, path]) => {
    if (!isFirstTextOfParagraph(editor, [node, path])) return next();

    const match = node.text.match(INDENT_REGEXP);
    if (!match) return next();

    const at = { path, offset: 0 };
    const distance = match[0].length;
    const depth = match[0].match(/>/g).length;

    Editor.withoutNormalizing(editor, () => {
      Transforms.delete(editor, { at, distance });
      times(depth, () => {
        Transforms.wrapNodes(editor, { type }, { at: Path.parent(path) });
      });
    });
  };

const mergeBlocks =
  next =>
  (editor, [node, path]) => {
    if (!isOnlyBlockquote(node)) return next();

    const previous = Path.hasPrevious(path) && Node.get(editor, Path.previous(path));
    if (isOnlyBlockquote(previous)) {
      return Transforms.mergeNodes(editor, { at: path });
    }

    const nextNode = Node.has(editor, Path.next(path)) && Node.get(editor, Path.next(path));
    if (isOnlyBlockquote(nextNode)) {
      return Transforms.mergeNodes(editor, { at: Path.next(path) });
    }

    next();
  };

// @todo: import this from @readme/markdown
const regexp = /^> ?(\u00a9|\u00ae|[\u2000-\u3300]|[\u{1f000}-\u{1fbff}])/u;

const toCallout =
  next =>
  (editor, [node, path]) => {
    if (!isBlockquote(node) || isCallout(node)) return next();

    const string = `> ${Node.string(node)}`;
    // @perf: we can short circuit the deserializer with a quick regex
    if (!string.match(regexp)) return next();

    const mdast = deserializer(string);
    const callout = getNode(mdast, n => n.type === 'rdme-callout');

    if (!callout) return next();

    const [, relPath] = Node.texts(node).next().value;
    const textPath = [...path, ...relPath];
    const { icon } = callout.data.hProperties;

    const whitespaceRegexp = `^${icon}(\\s+)`;
    const leadingWhitespace = Node.string(node).match(whitespaceRegexp);
    const whitespaceAndIcon = leadingWhitespace ? (leadingWhitespace[1]?.length || 0) + 1 : 1;

    Editor.withoutNormalizing(editor, () => {
      Transforms.delete(editor, {
        at: { path: textPath, offset: 0 },
        distance: whitespaceAndIcon,
      });
      Transforms.setNodes(editor, { icon }, { at: path });
    });
  };

export default [insertBlockquote, mergeBlocks, toCallout];
