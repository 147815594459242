import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { type } from './shared';

export const deserialize = ({ position, checked, ...node }, deeper) => {
  let children = deeper(node);

  if (!children.length) {
    children = node.spread ? [emptyNode()] : [{ text: '' }];
  }

  // @note: slate removes null props. For testing, this makes it consistent.
  if (checked !== null) node.checked = checked;

  return {
    ...node,
    children,
  };
};

export const serialize = (node, deeper, { renderingLibrary }) => {
  return {
    ...node,
    type: 'mdx' in renderingLibrary ? 'listItem' : type,
    children: deeper(node),
  };
};
