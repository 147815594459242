import type { NodeEntry, BaseRange } from 'slate';

import { Editor, Text, Transforms } from 'slate';

import Debug from '@ui/MarkdownEditor/editor/log';
import type { JsxCommentElement, JsxCommentTokenElement } from '@ui/MarkdownEditor/types';

import { pointToOffset } from '../../utils';
import { isJsxComment } from '../JsxComment/shared';

import { isJsxCommentToken, isLonelyJsxCommentToken, type } from './shared';

const debug = Debug.extend('jsx-comment-token:operations');

/*
 * Contract a comment to a new ending token.
 */
const contractComment = (editor: Editor, comment: NodeEntry<JsxCommentElement>, { at } = { at: editor.selection }) => {
  if (!at) return;

  const uncommentRange = Editor.rangeRef(editor, {
    anchor: Editor.end(editor, at),
    focus: Editor.end(editor, comment[1]),
  });

  try {
    if (!uncommentRange.current) return;

    const nextStartToken = Editor.nodes(editor, {
      at: uncommentRange.current,
      match: n => isJsxCommentToken(n) && n.edge === 'start',
    }).next().value;
    if (nextStartToken) {
      uncommentRange.current.focus = Editor.before(editor, Editor.start(editor, nextStartToken[1]))!;
    }

    const string = Editor.string(editor, uncommentRange.current);

    Transforms.unwrapNodes(editor, { at: uncommentRange.current, match: isJsxComment, split: true });

    if (string.match(/^\n\n/)) {
      Transforms.delete(editor, { at: uncommentRange.current.anchor, distance: 2 });
      Transforms.splitNodes(editor, { at: uncommentRange.current.anchor });
    }

    if (string.match(/\n\n$/)) {
      Transforms.delete(editor, { at: uncommentRange.current.focus, distance: 2, reverse: true });
      Transforms.splitNodes(editor, { at: uncommentRange.current.focus });
    }
  } finally {
    uncommentRange.unref();
  }
};

/*
 * Create a new comment when a new end token is inserted and a start token
 * exists earlier in the doc.
 */
const maybeWrapComment = (editor: Editor, at: BaseRange) => {
  const startTokenEntry = Editor.nodes(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.start(editor, at),
    },
    match: (node, path) => isLonelyJsxCommentToken(editor, [node, path], { edge: 'start' }),
  }).next().value;
  if (!startTokenEntry) return;

  const startTokenPathRef = Editor.pathRef(editor, startTokenEntry[1]);
  const endTokenPathRef = Editor.pathRef(editor, Editor.above(editor, { at, match: isJsxCommentToken })![1]);

  try {
    if (!startTokenPathRef.current || !endTokenPathRef.current) return;

    const startParent = Editor.above(editor, {
      at: startTokenPathRef.current,
      match: n => Editor.isBlock(editor, n),
    });

    const endParent = Editor.above(editor, {
      at: at.focus,
      match: n => Editor.isBlock(editor, n),
    });
    if (!startParent || !endParent) {
      debug('Could not find parents of comment tokens!');
      return;
    }
    const inline = startParent[0] === endParent[0];

    const frag = Editor.fragment(editor, {
      anchor: Editor.end(editor, startTokenPathRef.current),
      focus: Editor.before(editor, endTokenPathRef.current)!,
    });
    let string = editor.serialize({ type: 'root', children: frag });

    if (pointToOffset(endParent, at.anchor) === 0) {
      string += '\n\n';
    }

    /* This is a lot of code for what seems like a simple operation. We want to
     * remove all the content between the two comment tokens, and then
     * re-insert it as plain text. There's probably a few ways to achieve this,
     * but the current method is as follows:
     *
     * 1. remove any siblings of the parents of the comment tokens
     * 2. merge the parents into one
     * 3. wrap the tokens with a `jsx-comment` element
     * 4. re-insert the serialized content as plain text, deleting any
     * remaining content
     */
    Editor.withoutNormalizing(editor, () => {
      if (!startTokenPathRef.current || !endTokenPathRef.current) return;

      const siblings = [
        ...Editor.nodes(editor, {
          at: {
            anchor: Editor.start(editor, startTokenPathRef.current),
            focus: Editor.end(editor, endTokenPathRef.current),
          },
          match: (_, p) => p.length === (inline ? at.anchor.path.length - 1 : endParent[1].length),
          reverse: true,
        }),
      ];

      siblings.slice(1, siblings.length - 1).forEach(([, p]) => {
        Transforms.removeNodes(editor, { at: p });
      });

      if (!inline) {
        const parentEntry = Editor.above(editor, { at: endTokenPathRef.current });
        if (!parentEntry) {
          debug('Could not find parent of end comment token!');
          return;
        }

        Transforms.mergeNodes(editor, { at: parentEntry[1] });
      }

      Transforms.wrapNodes(editor, { type: 'jsx-comment' } as JsxCommentElement, {
        at: {
          anchor: Editor.start(editor, startTokenPathRef.current),
          focus: Editor.end(editor, endTokenPathRef.current),
        },
        split: true,
        match: isJsxCommentToken,
      });

      Transforms.insertText(editor, string, {
        at: {
          anchor: Editor.end(editor, startTokenPathRef.current),
          focus: Editor.before(editor, endTokenPathRef.current)!,
        },
      });
    });
  } finally {
    startTokenPathRef?.unref();
    endTokenPathRef?.unref();
  }
};

/*
 * Create a new comment token and possibly create or modify comments
 */
export const wrapCommentToken = (editor: Editor, { at = editor.selection, edge = 'start' } = {}) => {
  if (!at) return false;

  const atRef = Editor.rangeRef(editor, at);

  try {
    if (!atRef.current) return false;

    const comment = Editor.above(editor, { at: atRef.current, match: isJsxComment });

    Transforms.wrapNodes(editor, { type, edge } as JsxCommentTokenElement, {
      at: atRef.current,
      split: true,
      match: Text.isText,
    });

    Editor.withoutNormalizing(editor, () => {
      if (!atRef.current) return;

      if (edge === 'end') {
        if (!comment) {
          maybeWrapComment(editor, atRef.current);
        } else {
          contractComment(editor, comment, { at: atRef.current });
        }
      }
    });

    return true;
  } finally {
    atRef.unref();
  }
};
