import Component from './Component';
import { deleteBackward, deleteForward } from './delete';
import insertText from './insertText';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize, serialize } from './serialization';
import { LINK_ICON, type, isLink, toString } from './shared';
import slashMenu from './slashMenu';

const Link = {
  Component,
  LINK_ICON,
  decorator: '',
  deleteBackward,
  deleteForward,
  deserialize,
  hasInlineMd: true,
  icon: 'link',
  insertText,
  isInline: true,
  isLink,
  normalizeNode,
  onKeyDown,
  serialize,
  slashMenu,
  toString,
  type,
  ...operations,
};

export default Link;
