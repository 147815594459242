import type { ImageUploaderLegacyProps } from '.';
import type { ImageArray } from '@readme/backend/models/image/types';

import Cookie from 'js-cookie';

export type UploadImageResponse = ImageArray | [];

export interface UploadImageProps extends Pick<ImageUploaderLegacyProps, 'baseUrl' | 'maxHeight'> {
  file: File;
}

export default async function uploadImage({ baseUrl = '', file, maxHeight }: UploadImageProps) {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('name', file.name);
  if (maxHeight) {
    formData.append('resize', maxHeight.toString());
  }

  return fetch(`${baseUrl}/api/images/image-upload`, {
    headers: {
      'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') || '',
    },
    body: formData,
    method: 'POST',
  }).then(res => {
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return res.json() as Promise<UploadImageResponse>;
  });
}
