import type { MdxFlowExpression } from 'mdast-util-mdx';

import type {
  Deserializer,
  FormattedText,
  JsxCommentElement,
  ReadmeInline,
  Serializer,
} from '@ui/MarkdownEditor/types';

import { type as tokenType } from '../JsxCommentToken/shared';

import { type } from './shared';

export const deserialize: Deserializer<MdxFlowExpression, JsxCommentElement> = (node, _, context) => {
  const content = context.renderingLibrary
    .mdx(node)
    .replace(/^{\/\*/, '')
    .replace(/\*\/}\s*$/, '');

  const comment: JsxCommentElement = {
    type,
    children: [
      { type: tokenType, edge: 'start', children: [{ text: '{/*' }] },
      { text: content },
      { type: tokenType, edge: 'end', children: [{ text: '*/}' }] },
    ],
  };

  return comment;
};

export const serialize: Serializer<JsxCommentElement, MdxFlowExpression> = (
  node,
  deeper,
  { parent, renderingLibrary },
) => {
  const subtree = deeper(node);

  if ('mdx' in renderingLibrary) {
    let string = renderingLibrary.mdx({ type: 'root', children: subtree });

    if (parent.children.some((child: FormattedText | ReadmeInline) => 'text' in child && child.text.trim() !== '')) {
      string = string.replaceAll(/\n(?!$)/g, '\\\n');
    }

    const ast = renderingLibrary.mdast(string).children;
    return ast;
  }

  return subtree;
};
