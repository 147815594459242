import { Owlmoji } from '@readme/markdown';
import { Text, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { type } from './shared';

// @todo: import from markdown
const regex = /:(?<name>\+1|[-\w]+):/g;

const parseShortcode: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!Text.isText(node)) return next();

    const match = regex.exec(node.text);
    if (!match) return next();

    const { name } = match.groups as { name: string };
    if (Owlmoji.kind(name) !== 'gemoji') return next();

    const at = {
      anchor: {
        path,
        offset: match.index,
      },
      focus: { path, offset: match.index + match[0].length },
    };

    Transforms.insertNodes(
      editor,
      { name, type, value: Owlmoji.nameToEmoji[name], children: [{ text: '' }] },
      { at, select: true },
    );
  };

export default [parseShortcode];
