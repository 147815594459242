import { Node } from 'slate';

import type {
  Deserializer,
  GlossaryTerm,
  MdxGlossaryTermMdNode,
  Serializer,
  TextMdNode,
} from '@ui/MarkdownEditor/types';

import { type } from './shared';

export const deserialize: Deserializer<MdxGlossaryTermMdNode, GlossaryTerm> = node => {
  const name = node.children[0].value || '';

  return {
    type,
    children: [{ text: name }],
  };
};

export const serialize: Serializer<GlossaryTerm, MdxGlossaryTermMdNode | TextMdNode> = node => {
  const term = Node.string(node);

  return term
    ? {
        type: 'readme-glossary-item',
        data: {
          hName: 'Glossary',
          hProperties: {
            term,
          },
        },
        children: [{ type: 'text', value: '' }],
      }
    : { type: 'text', value: '' };
};
