import React, { useEffect, useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

import useClassy from '@core/hooks/useClassy';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import { DragItems, MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { CodeElement, CodeTabDragItem, CodeTabID } from '@ui/MarkdownEditor/types';

import { useCodeSettings } from '../../CodeSettings';
import { languages } from '../Code/shared';

import { isPastThreshold, tabId } from './shared';
import classes from './style.module.scss';

interface Props extends ButtonProps {
  active: number;
  element: CodeElement;
  index: number;
  moveTab: (source: CodeElement, destination: number, options?: { active: number }) => void;
  parentId: CodeTabID;
}

interface CollectedDragProps {
  isDragging: boolean;
}

const Tab = ({ active, element, index, moveTab, parentId, ...props }: Props) => {
  const { lang, meta } = element;
  const ref = useRef<HTMLButtonElement>(null);
  const bem = useClassy(classes, 'CodeTabs');
  const [{ path }, dispatch] = useCodeSettings();

  const [{ isDragging }, drag] = useDrag<CodeTabDragItem, void, CollectedDragProps>(
    () => ({
      collect: monitor => ({ isDragging: monitor.isDragging() }),
      end: (item, monitor) => {
        if (monitor.didDrop()) return;

        moveTab(item.element, item.originalIndex, { active: item.originalActive });
      },
      isDragging: monitor => monitor.getItem().element === element,
      item: {
        element,
        index,
        originalActive: active,
        originalIndex: index,
        parentId,
      },
      type: DragItems.CodeTab,
    }),
    [active, element, index, moveTab, parentId],
  );

  const [, drop] = useDrop<CodeTabDragItem, void, null>(
    () => ({
      accept: DragItems.CodeTab,
      canDrop: item => item.parentId === parentId,
      hover: (item, monitor) => {
        if (!monitor.canDrop()) return;
        if (item.index === index) return;

        if (
          !isPastThreshold(monitor.getClientOffset(), ref.current?.getBoundingClientRect(), {
            left: item.index > index,
          })
        ) {
          return;
        }

        moveTab(item.element, index);
        item.index = index;
      },
    }),
    [index, moveTab, parentId],
  );

  drag(drop(ref));

  useEffect(() => {
    if (!!path && isDragging) dispatch({ type: MenuActionTypes.close });
  }, [dispatch, isDragging, path]);

  return (
    <Button
      ref={ref}
      className={bem('&', '-button', index === active && '-active', isDragging && '-dragging')}
      data-component="code-tab"
      data-testid={tabId}
      {...props}
    >
      {meta || `${(lang && languages[lang]) || 'Text'}`}
      <i className="icon-chevron-down" />
    </Button>
  );
};

export default Tab;
