import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSlateStatic } from 'slate-react';

import { Table } from '@ui/MarkdownEditor/editor/blocks';

import { Button } from '../IconMenu';

const isDisabled = ([table, path], { isColumn, forward }) => {
  if (!path || !table) return false;

  if (isColumn) {
    const cellIdx = path[path.length - 1];

    return forward ? cellIdx === path.length - 1 : cellIdx === 0;
  }
  const rowIdx = path[path.length - 2];
  const rowCount = table?.children.length;

  return forward ? rowIdx === rowCount - 1 : rowIdx === 1;
};

const MoveButton = ({ isColumn, forward = false, path, table }) => {
  const editor = useSlateStatic();

  const icon = isColumn ? `arrow-${forward ? 'right' : 'left'}` : `arrow-${forward ? 'down' : 'up'}`;
  const disabled = isDisabled([table, path], { isColumn, forward });

  const onPointerUp = useCallback(() => {
    if (disabled) return;

    Table.operations.move(editor, path, { isColumn, forward });
  }, [disabled, isColumn, editor, path, forward]);

  return <Button disabled={disabled} icon={icon} onPointerUp={onPointerUp}></Button>;
};

MoveButton.propTypes = {
  forward: PropTypes.bool,
  isColumn: PropTypes.bool,
  path: PropTypes.array,
  table: PropTypes.object,
};

export default MoveButton;
