import type { Range } from 'slate';
import type { RenderLeafProps } from 'slate-react';

import React from 'react';

import type { FormattedText } from '@ui/MarkdownEditor/types';

import classes from '../style.module.scss';

type MdxTextExpressionType = 'mdxTextExpression';
const type: MdxTextExpressionType = 'mdxTextExpression';

type Props = RenderLeafProps & {
  leaf: FormattedText & {
    range: Range;
    skipMenu?: boolean;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MdxTextExpression = ({ leaf, ...props }: Props) => {
  return <code className={classes.MdxExpression} spellCheck={false} {...props} />;
};

const mdxTextExpression = {
  type,
  render: MdxTextExpression,
};

export default mdxTextExpression;
