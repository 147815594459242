import React, { useEffect, useState } from 'react';

import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';
import Menu, { MenuHeader } from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import { useEmbedHandlers } from '../hooks/useEmbedHandlers';
import classes from '../style.module.scss';
import { DEFAULTS, extractSize, extractUnit, labelAndPlaceholder } from '../utils';

import EmbedSizeInput from './EmbedSizeInput';
import EmbedURLInput from './EmbedURLInput';

interface Props {
  element: EmbedBlock | EmbedElement;
}

export const EmbedEditor = ({ element }: Props) => {
  const { height, width, typeOfEmbed, url } = element;

  const [urlEntered, setUrlEntered] = useState(url || '');
  const [urlValidationMessage, setUrlValidationMessage] = useState('');
  const [urlLabel, placeholder] = labelAndPlaceholder(element);

  const {
    handleOnBlur,
    handleOnKeyDown,
    onChangeHeight,
    onChangeHeightUnit,
    onChangeType,
    onChangeWidth,
    onChangeWidthUnit,
  } = useEmbedHandlers(element, { setUrlEntered, setUrlValidationMessage });

  // reset menu
  useEffect(() => {
    setUrlValidationMessage('');
    setUrlEntered(url || '');
  }, [element, url]);

  return (
    <Menu className={classes['Settings-Menu']} role="menu">
      <MenuItem focusable={false}>
        <EmbedURLInput
          handleOnBlur={handleOnBlur}
          handleOnKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          urlEntered={urlEntered}
          urlLabel={urlLabel}
          urlValidationMessage={urlValidationMessage}
        />
      </MenuItem>
      {element.iframe || typeOfEmbed === 'iframe' ? (
        <>
          <MenuDivider />
          <MenuItem focusable={false}>
            <EmbedSizeInput
              label="Height"
              onChange={onChangeHeight}
              onChangeUnit={onChangeHeightUnit}
              unit={extractUnit(height) || DEFAULTS.heightUnit}
              value={extractSize(height) || DEFAULTS.height}
            />
          </MenuItem>
          <MenuDivider />
          <MenuItem focusable={false}>
            <EmbedSizeInput
              label="Width"
              onChange={onChangeWidth}
              onChangeUnit={onChangeWidthUnit}
              unit={extractUnit(width) || DEFAULTS.widthUnit}
              value={extractSize(width) || DEFAULTS.width}
            />
          </MenuItem>
        </>
      ) : null}
      {typeOfEmbed === 'default' && !element.iframe ? (
        <>
          <MenuDivider />
          <MenuHeader>Convert Embed to:</MenuHeader>
          {[
            { type: EmbedTypes.youtube, label: 'Youtube' },
            { type: EmbedTypes.github, label: 'GitHub Gist' },
            { type: EmbedTypes.pdf, label: 'PDF' },
            { type: EmbedTypes.jsfiddle, label: 'JSFiddle' },
            { type: EmbedTypes.iframe, label: 'Iframe' },
          ].map(({ type: newType, label }) => (
            <MenuItem key={newType} onClick={() => onChangeType(newType)}>
              {label}
            </MenuItem>
          ))}
        </>
      ) : null}
      <MenuDivider />
    </Menu>
  );
};

export default EmbedEditor;
