import type { $TSFixMe } from '@readme/iso';
import type { BaseRange, BaseSelection, Location } from 'slate';

import { Editor, Transforms, Node } from 'slate';

import { MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import { MenuHandleTypes, MenuConfigActionTypes } from '@ui/MarkdownEditor/enums';
import type {
  ReadmeEditor,
  ReadmeElement,
  MenuHandle as MenuHandleType,
  ReusableContentElement,
} from '@ui/MarkdownEditor/types';

import { isMenuHandle } from '../MenuHandle/shared';

import { type } from './shared';

export const insertReusableContent = (
  editor: ReadmeEditor,
  selectedTag: string | null = null,
  { at = editor.selection }: { at?: BaseSelection | Location } = {},
) => {
  if (!at) {
    // eslint-disable-next-line no-console
    console.error('No selection!');
    return;
  }
  const entry = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n as ReadmeElement) });
  if (!entry) {
    // eslint-disable-next-line no-console
    console.warn("There's no blocks!");
    return;
  }
  const [, path] = entry;
  const menu = Node.parent(editor, editor.selection!.anchor.path);

  if (isMenuHandle(menu)) Transforms.removeNodes(editor, { at: editor.selection!, match: MenuHandle.is });

  if (!selectedTag) {
    const { open } = editor.reusableContentModal;

    open({
      item: { type: 'content' },
      onSave: data => {
        if (!data) return;
        const { tag, source, name } = data.data;

        Transforms.insertNodes(
          editor,
          { type, tag, source, name, children: [{ text: '' }] },
          { at: path, select: false },
        );
      },
    });
  } else {
    const [{ filtered }] = editor.reusableContentMenu;
    const block = filtered.find(b => 'tag' in b && b.tag === selectedTag);

    if (block)
      Transforms.insertNodes(editor, { ...block, type, children: [{ text: '' }] } as ReusableContentElement, {
        at: path,
      });
  }
};

export const makeReusable = (editor: ReadmeEditor, at: BaseRange | null = editor.selection) => {
  if (!at) {
    // eslint-disable-next-line no-console
    console.error('No selection!');
    return;
  }

  const [start] = [...at.anchor.path];

  const { open } = editor.reusableContentModal;
  const content = Editor.fragment(editor, at);

  open({
    item: {
      source: editor.serialize({ children: content } as $TSFixMe),
      uri: '',
      name: '',
      tag: '',
      type: 'content',
    },
    onSave: data => {
      if (!data) return;
      const { source, name, tag } = data.data;

      // remove all parent blocks in selection
      Transforms.removeNodes(editor, { at, mode: 'highest' });

      // plop in our new reusable content block in its place
      Transforms.insertNodes(
        editor,
        { tag, type, source, name, children: [{ text: '' }] },
        { at: [start], select: false },
      );
    },
  });
};

export const openReusableContentMenu = (editor: Editor, shortcut: boolean = false) => {
  if (!editor.selection) return;

  Transforms.insertText(editor, '<');
  Transforms.wrapNodes(editor, { type: MenuHandle.type, menuType: MenuHandleTypes.reusableContent } as MenuHandleType, {
    at: {
      anchor: {
        path: editor.selection.anchor.path,
        offset: editor.selection.anchor.offset - 1,
      },
      focus: editor.selection.focus,
    },
    split: true,
  });

  if (!Editor.above(editor, { match: MenuHandle.isMenuHandle }))
    // wtf slate
    // @ts-ignore
    Transforms.select(editor, Editor.before(editor, editor.selection));
  const [, dispatch] = editor.reusableContentMenu;

  dispatch({ type: MenuConfigActionTypes.shortcut, payload: shortcut });
};

export default { insertReusableContent, makeReusable, openReusableContentMenu };
