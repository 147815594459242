import PropTypes from 'prop-types';
import React from 'react';

import useSelectedClassName from '@ui/MarkdownEditor/editor/useSelectedClassName';

const TableRow = ({ attributes, children }) => {
  return (
    <tr {...attributes} className={useSelectedClassName()}>
      {children}
    </tr>
  );
};

TableRow.propTypes = {
  attributes: PropTypes.object,
};

export default TableRow;
