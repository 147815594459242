import type { EmbedLookupResponse } from '@readme/api/src/routes/embeds/operations/embedLookup';

import { useCallback } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import makeFetch from '@core/utils/makeFetch';

import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

import { DEFAULTS, extractSize, extractUnit, sizeOptions } from '../utils';

export const useEmbedHandlers = (element: EmbedBlock | EmbedElement, { setUrlValidationMessage, setUrlEntered }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { height, width } = element;
  const { subdomain } = editor.props;

  const insertEmbed = useCallback(
    (updates, getter = null) => {
      let urlObject: URL | null = null;

      if (updates.url && updates.url === element.url) {
        delete updates.url;
      }

      try {
        if (updates.url) {
          urlObject = new URL(updates.url);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(updates.url, error);
        setUrlValidationMessage('Invalid URL');
        return;
      }

      if (urlObject && (updates.typeOfEmbed === 'iframe' || element.typeOfEmbed === 'iframe')) {
        const isCodepen = urlObject.host === 'codepen.io';

        // eslint-disable-next-line no-param-reassign
        updates = {
          ...updates,
          url: isCodepen ? updates.url.replace('/pen/', '/embed/') : updates.url,
          href: updates.url,
        };
      }

      if (updates.typeOfEmbed === 'iframe') {
        // eslint-disable-next-line no-param-reassign
        updates = {
          ...updates,
          html: false,
          iframe: true,
          title: 'iframe',
        };
      }

      Transforms.setNodes(editor, updates, { at: path });

      if (getter) {
        getter();
      }
    },
    [editor, element.typeOfEmbed, element.url, path, setUrlValidationMessage],
  );

  const getEmbed = useCallback(
    (url: string) => {
      const { typeOfEmbed } = element;

      if (typeOfEmbed === 'iframe' || !url) return;

      makeFetch(`/${subdomain}/api-next/v2/embeds/lookup?url=${url}&type=${typeOfEmbed}`, {
        method: 'GET',
      })
        .then(res => res.json())
        .then((json: EmbedLookupResponse) => {
          const { embed, gist_html: gistHTML } = json.data;

          const update = {
            html: gistHTML || embed.media.html,
            url: embed.url,
            title: embed.title,
            favicon: embed.favicon_url,
            image: embed.images[0]?.url,
            typeOfEmbed,
            href: embed.url,
          };

          Transforms.setNodes(
            editor,
            {
              ...update,
              ...(editor.props.useMDX
                ? {
                    providerUrl: embed.provider_url,
                    providerName: embed.provider_name,
                  }
                : { provider: embed.provider_url }),
            },
            { at: path },
          );
        })
        .catch(() => {
          setUrlValidationMessage("We can't find anything to embed at that URL");
        });
    },
    [editor, element, path, setUrlValidationMessage, subdomain],
  );

  const handleOnBlur = useCallback(
    event => {
      setUrlEntered(event.target.value);

      insertEmbed({ url: event.target.value }, () => {
        getEmbed(event.target.value);
      });
    },
    [getEmbed, insertEmbed, setUrlEntered],
  );

  const handleOnKeyDown = useCallback(
    event => {
      if (!['Enter', 'Escape'].includes(event.key)) {
        setUrlValidationMessage('');
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      insertEmbed({ url: event.target.value }, () => {
        getEmbed(event.target.value);
      });
    },
    [getEmbed, insertEmbed, setUrlValidationMessage],
  );

  const onChangeHeight = useCallback(
    event => {
      const value = event.target.value;
      const unit = extractUnit(height) || DEFAULTS.heightUnit;

      insertEmbed({ height: `${value}${unit}` });
    },
    [height, insertEmbed],
  );

  const onChangeHeightUnit = useCallback(
    event => {
      const unit = sizeOptions[event.target.value - 1].label;
      const _height = extractSize(height) || DEFAULTS.height;

      insertEmbed({ height: `${_height}${unit}` });
    },
    [height, insertEmbed],
  );

  const onChangeType = useCallback(
    newType => {
      insertEmbed({ url: element.url, typeOfEmbed: newType });
    },
    [element.url, insertEmbed],
  );

  const onChangeWidth = useCallback(
    event => {
      const value = event.target.value;
      const unit = extractUnit(width) || DEFAULTS.widthUnit;

      insertEmbed({ width: `${value}${unit}` });
    },
    [insertEmbed, width],
  );

  const onChangeWidthUnit = useCallback(
    event => {
      const unit = sizeOptions[event.target.value - 1].label;
      const _width = extractSize(width) || DEFAULTS.width;

      insertEmbed({ width: `${_width}${unit}` });
    },
    [insertEmbed, width],
  );

  return {
    handleOnBlur,
    handleOnKeyDown,
    onChangeHeight,
    onChangeHeightUnit,
    onChangeType,
    onChangeWidth,
    onChangeWidthUnit,
  };
};
