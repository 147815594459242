import isEqual from 'lodash/isEqual';
import { Editor, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

export const insertEmbedType =
  typeOfEmbed =>
  (editor, { at = editor.selection } = {}) => {
    const [node, path] = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) }) || [];

    const embedAt = isEqual(emptyNode(), node) ? path : Path.next(path);

    Transforms.insertNodes(
      editor,
      [{ type: 'embed', typeOfEmbed, url: '', openMenu: true, children: [{ text: '' }] }],
      {
        at: embedAt,
        select: true,
      },
    );
  };

export const insertYouTube = insertEmbedType('youtube');
export const insertGist = insertEmbedType('github');
export const insertPDF = insertEmbedType('pdf');
export const insertJSFiddle = insertEmbedType('jsfiddle');
export const insertIframe = insertEmbedType('iframe');

export default insertEmbedType;
