import { Editor, Node, Path, Transforms } from 'slate';

import { type as codeType } from '../Code/shared';

import { type, isCodeTabs } from './shared';

export const updateCode = (editor, props, { at = editor.selection, index }) => {
  const codeTabs = Node.get(editor, at);
  if (!isCodeTabs(codeTabs)) {
    // eslint-disable-next-line no-console
    console.warn("Coulnd't find code tabs at", at);
  }

  const tabs = [...codeTabs.tabs];
  tabs[index] = { ...tabs[index], ...props };

  Transforms.setNodes(editor, { tabs }, { at });
};

export const insertCodeTab = (editor, at) => {
  // Valid parents: editor itself, list item, block quote, ~tables~
  const [block, path] = Editor.above(editor, { match: Editor.isBlock.bind(null, editor) }) || [];
  const location = at || (Node.string(block) === '' ? path : Path.next(path));

  Transforms.insertNodes(
    editor,
    { type, tabs: [{ type: codeType, value: '' }], children: [{ text: '' }] },
    { at: location, select: true },
  );
};

export const newCodeTab = (editor, at) => {
  const codeTabs = Node.get(editor, at);
  if (!isCodeTabs(codeTabs)) {
    // eslint-disable-next-line no-console
    console.warn("Coulnd't find code tabs at", at);
  }

  updateCode(editor, { type: codeType, value: '' }, { at, index: codeTabs.tabs.length });
  Transforms.setNodes(editor, { active: codeTabs.tabs.length }, { at });
};

export const setLang = (editor, newLang, { at, index }) => {
  const codeTabs = Node.get(editor, at);
  if (!isCodeTabs(codeTabs)) {
    // eslint-disable-next-line no-console
    console.warn("Coulnd't find code tabs at", at);
  }

  const lang = newLang !== codeTabs.tabs[index].lang ? newLang : null;

  updateCode(editor, { lang }, { at, index });
};

export const removeCodeTab = (editor, at, index) => {
  const codeTabs = Node.get(editor, at);
  if (!isCodeTabs(codeTabs)) {
    // eslint-disable-next-line no-console
    console.warn("Coulnd't find code tabs at", at);
  }

  // If we're deleting the last tab, delete the entire code tab node
  if (codeTabs.tabs.length === 1) Transforms.delete(editor, { at });
  else {
    const attrs = {
      tabs: [...codeTabs.tabs],
      ...(codeTabs.active === codeTabs.tabs.length - 1 && { active: codeTabs.active - 1 }),
    };
    attrs.tabs.splice(index, 1);

    Transforms.setNodes(editor, attrs, { at });
  }
};

export default { insertCodeTab, newCodeTab, updateCode, setLang, removeCodeTab };
