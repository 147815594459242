import isEqual from 'lodash/isEqual';
import { Editor, Transforms, Node, Path } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { isCodeTabs } from './shared';

// When backspacing into a code tabs node, we want to focus within it instead of deleting it
const backspace = (event, editor) => {
  if (
    !(
      event.key === 'Backspace' &&
      editor.selection.anchor.offset === 0 &&
      editor.selection.focus.offset === 0 &&
      Path.hasPrevious(Path.parent(editor.selection.anchor.path)) &&
      isCodeTabs(Node.get(editor, Path.previous(Path.parent(editor.selection.anchor.path))))
    )
  )
    return;

  event.preventDefault();
  event.stopPropagation();

  const currentPath = Path.parent(editor.selection.anchor.path);
  Editor.withoutNormalizing(editor, () => {
    if (isEqual(emptyNode(), Node.get(editor, currentPath))) Transforms.delete(editor, { at: currentPath });
    Transforms.select(editor, { path: Path.previous(currentPath), offset: 0 });
  });
};

const arrowUp = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey)) return;

  const rootPath = editor.selection.anchor.path.slice(0, 1);
  if (!Path.hasPrevious(rootPath)) return;

  const previousPath = Path.previous(rootPath);
  const previousNode = Node.get(editor, previousPath);
  if (!isCodeTabs(previousNode)) return;

  Transforms.select(editor, previousPath);
};

const arrowDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey)) return;

  const rootPath = editor.selection.anchor.path.slice(0, 1);
  const nextPath = Path.next(rootPath);
  if (!Node.has(editor, nextPath)) return;

  const nextNode = Node.get(editor, nextPath);
  if (!isCodeTabs(nextNode)) return;

  Transforms.select(editor, nextPath);
};

export default [backspace, arrowUp, arrowDown];
