import type { RenderLeafProps } from 'slate-react';

import React from 'react';

import { isCmdOrCtrl } from '@ui/MarkdownEditor/editor/eventUtils';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { toggleFormatOnKeyDown } from '../shared';

import { deserialize } from './serialize';

const type = 'strong';

const isCmdB: OnKeyDown = event => isCmdOrCtrl(event) && event.key === 'b';
const cmdB = toggleFormatOnKeyDown(type, isCmdB);

const Component = (props: RenderLeafProps) => {
  const { children, attributes } = props;

  return <strong {...attributes}>{children}</strong>;
};

const Strong = {
  decorator: '**',
  decoratorAlternate: '__',
  deserialize,
  icon: 'bold',
  onKeyDown: cmdB,
  render: Component,
  type,
};

export default Strong;
