import type { Node } from 'slate';

import { Element } from 'slate';

import type { ListElement, OrderedListElement, TaskListElement, UnorderedListElement } from '@ui/MarkdownEditor/types';

import { isTaskListItem } from '../ListItem/shared';

export const type = 'list' as ListElement['type'];
export const isList = (node: Node): node is ListElement => Element.isElementType(node, type);
export const isTaskList = (node: Node): node is TaskListElement => isList(node) && isTaskListItem(node.children[0]);
export const isOrderedList = (node: Node): node is OrderedListElement => isList(node) && node.ordered;
export const isUnorderedList = (node: Node): node is UnorderedListElement =>
  isList(node) && !node.ordered && !isTaskList(node);
export const defaultListProps: Partial<ListElement> = {
  type,
  ordered: false,
  spread: false,
};
