import type { DragDropMonitor } from 'dnd-core';

import React, { createContext, useMemo } from 'react';
import { DndProvider, useDragDropManager } from 'react-dnd';

import useUniqueId from '@core/hooks/useUniqueId';

import useEnvInfo from '../useEnvInfo';

export interface DragAndDropContextValue {
  /**
   * Keeps track of a unique provider ID that internal drag/droppable components
   * can reference to constrain drag/drop operations to a particular provider.
   */
  id: string;

  /**
   * Convenience reference to the drag drop manager's monitor. With this, anyone
   * can subscribe to state changes and collect monitor values.
   */
  monitor: DragDropMonitor | null;
}

export const DragAndDropContext = createContext<DragAndDropContextValue>({
  id: '',
  monitor: null,
});
DragAndDropContext.displayName = 'DragAndDropContext';

/**
 * Wrapper component to declare and provide values for our context. We need this
 * rendered in a separate sub-component of DndProvider so those top-level
 * contexts are rendered before accessing react-dnd's DragDropManager.
 */
function DragAndDropContextWrapper({ children }: { children: React.ReactNode }) {
  const uid = useUniqueId('DragAndDropProvider');
  const contextId = uid();
  const dndManager = useDragDropManager();
  const dndMonitor = dndManager.getMonitor();

  const memoizedValue = useMemo(
    () => ({
      id: contextId,
      monitor: dndMonitor,
    }),
    [contextId, dndMonitor],
  );

  return <DragAndDropContext.Provider value={memoizedValue}>{children}</DragAndDropContext.Provider>;
}

export type DragAndDropProviderProps = Parameters<typeof DndProvider>[0];

/**
 * Wrapper includes both our `DragAndDropContext` with a generated unique ID and
 * the required DndProvider from `react-dnd` with props forwarded. By default,
 * the `HTML5Backend` is used but can be overridden if needed.
 */
export default function DragAndDropProvider({ children, ...props }: DragAndDropProviderProps) {
  const { isClient } = useEnvInfo();
  return (
    <DndProvider {...props} context={isClient ? window : undefined}>
      <DragAndDropContextWrapper>{children}</DragAndDropContextWrapper>
    </DndProvider>
  );
}
