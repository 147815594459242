import React, { memo, useEffect, useCallback, useRef } from 'react';
import { useSlateStatic, ReactEditor } from 'slate-react';

import { Link } from '@ui/MarkdownEditor/editor/blocks';
import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import PageMenu, { PageMenuItem } from '@ui/PageMenu';

import classes from './style.module.scss';
import { usePageMenu } from './usePageMenu';

const EditorPageMenu = () => {
  const editor = useSlateStatic();
  const [{ filtered, open, rangeRef, selected, target }, dispatch] = usePageMenu();

  const selectedName = filtered[selected]?.name;
  const results = filtered;
  const menuRef = useRef();

  const onClick = useCallback(
    ({ name, slug, type }) => {
      ReactEditor.focus(editor);

      if (rangeRef) {
        Link.insertLinkFromMenu(editor, { label: name, url: `${type}:${slug}` }, { at: rangeRef.current });
      }

      dispatch({ type: MenuActionTypes.close });
    },
    [dispatch, editor, rangeRef],
  );

  useEffect(() => {
    if (!menuRef?.current) return;

    const menuItem = [...menuRef.current.children][selected];
    if (!menuItem) return;
    if (!menuItem.scrollIntoView) return;

    menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [selected]);

  useEffect(() => {
    if (!results.length) dispatch({ type: MenuActionTypes.close });
  }, [dispatch, open, results.length]);

  return results.length ? (
    <MenuDropdown className={`${classes.PageMenu} PageMenu`} open={open} target={target}>
      <PageMenu ref={menuRef}>
        {results.map(result => {
          return (
            <PageMenuItem
              {...result}
              key={`${result.name}-${result.type}:${result.slug}`}
              onClick={onClick}
              selected={result.name === selectedName}
            />
          );
        })}
      </PageMenu>
    </MenuDropdown>
  ) : null;
};

export default memo(EditorPageMenu);
