import type { BaseSelection, Node, Path } from 'slate';

import { Editor, Element } from 'slate';

import type { JsxCommentElement, ReadmeEditor } from '@ui/MarkdownEditor/types';

export const type = 'jsx-comment';
export const isJsxComment = (node: Node): node is JsxCommentElement => Element.isElementType(node, type);

export const isInJsxComment = (editor: ReadmeEditor, at: BaseSelection | Path = editor.selection) =>
  at && !!Editor.above(editor, { at, match: isJsxComment });
