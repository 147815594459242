const prefix = depth => Array.from(new Array(depth), () => '#').join('');

export default function deserialize({ position, type, depth, ...node }, deeper) {
  const text = `${prefix(depth)} `;

  return {
    type,
    depth,
    children: [{ text }, ...deeper(node)],
  };
}
