import Component from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import { deserialize, serialize } from './serialize';
import { mdType, type, isImageEmoji, isMdImageEmoji } from './shared';

export default {
  is: isImageEmoji,
  deserialize,
  isImageEmoji,
  isMdImageEmoji,
  isInline: true,
  isVoid: true,
  Component,
  mdType,
  type,
  normalizeNode,
  onKeyDown,
  serialize,
};
