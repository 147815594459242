/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import type { MouseEventHandler, ReactNode } from 'react';

import React, { useState, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { ReactEditor, useSlateStatic } from 'slate-react';

import type { ImageAlignValues } from '@ui/MarkdownEditor/enums';
import type { DragItem, ImageElement, ReadmeElement } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';
import Tooltip from './Tooltip';

interface CollectDragLayerProps {
  item: DragItem;
}

const BlockMenuContainer = ({ children }: { children: ReactNode }) => {
  const ref = useRef(null);
  const editor = useSlateStatic();
  const [node, setNode] = useState<ReadmeElement | null>(null);
  const [align, setAlign] = useState<ImageAlignValues | null>(null);
  const { item } = useDragLayer<CollectDragLayerProps>(monitor => ({
    item: monitor.getItem(),
  }));

  const onMouseEnter: MouseEventHandler<HTMLDivElement> = event => {
    if (item) return;

    const image = document.elementsFromPoint(event.clientX, event.clientY).find(el => el.tagName === 'IMG');
    const element = (image || (event.target as HTMLElement)).closest('[data-block-menu]') as HTMLElement;

    if (!element || !ReactEditor.hasDOMNode(editor, element)) {
      return;
    }

    const newNode = ReactEditor.toSlateNode(editor, element) as ReadmeElement;
    const newAlign = (image && (newNode as ImageElement).align) || null;

    if (node !== newNode) {
      setNode(() => newNode);
      setAlign(() => newAlign);
    }
  };

  const onMouseLeave = () => {
    setNode(null);
    setAlign(null);
  };

  return (
    <div
      ref={ref}
      className={classes.BlockMenu_Container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseEnter}
    >
      {children}
      <Tooltip align={align} node={node} />
    </div>
  );
};

export default BlockMenuContainer;
