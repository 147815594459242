import isEqual from 'lodash/isEqual';
import { Editor, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { type } from './shared';

export const insertEmbedBlockType =
  typeOfEmbed =>
  (editor, { at = editor.selection } = {}) => {
    const [node, path] = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) }) || [];

    const embedAt = isEqual(emptyNode(), node) ? path : Path.next(path);

    Transforms.insertNodes(editor, [{ type, typeOfEmbed, url: '', openMenu: true, children: [{ text: '' }] }], {
      at: embedAt,
      select: true,
    });
  };

export const insertYouTubeEmbed = insertEmbedBlockType('youtube');
export const insertGistEmbed = insertEmbedBlockType('github');
export const insertPDFEmbed = insertEmbedBlockType('pdf');
export const insertJSFiddleEmbed = insertEmbedBlockType('jsfiddle');
export const insertIframeEmbed = insertEmbedBlockType('iframe');

export default insertEmbedBlockType;
