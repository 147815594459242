import { ReactEditor } from 'slate-react';

import { confetti } from '@ui/Confetti';
import { isCmdOrCtrl, isShiftKey } from '@ui/MarkdownEditor/editor/eventUtils';

const getTopPoints = range => {
  if (!range.getClientRects) return null;

  const rects = range.getClientRects();
  if (rects.length <= 0) return null;

  return [...rects].flatMap(({ x, y, width }) => [
    { x, y },
    { x: x + width, y },
  ]);
};

const transform = editor => {
  getTopPoints(ReactEditor.toDOMRange(editor, editor.selection)).forEach((coords, i) => {
    setTimeout(() => confetti(coords), i * 150 + Math.random() * 100);
  });
};

const isCmdShiftP = event => {
  return isCmdOrCtrl(event) && isShiftKey(event, 'p');
};

const cmdShiftP = (event, editor) => {
  if (!isCmdShiftP(event)) return;

  event.preventDefault();
  event.stopPropagation();

  transform(editor);
};

const onKeyDown = cmdShiftP;

export { onKeyDown };
export default { onKeyDown };
