import React, { memo, useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { Range } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Menu from '@ui/Menu';

import { transforms } from './commands';
import SlashMenuItems from './SlashMenuItems';
import SlashMenuTooltip from './SlashMenuTooltip';
import classes from './style.module.scss';
import useSlashMenu from './useSlashMenu';

const chunk = list =>
  list.reduce((acc, item) => {
    const categoryChunk = acc.find(([category]) => item.category === category);

    if (!categoryChunk) {
      acc.push([item.category, item]);
    } else {
      categoryChunk.push(item);
    }

    return acc;
  }, []);

const SlashMenu = () => {
  const editor = useSlateStatic();
  const [{ selected, filtered, rangeRef, target, open }, dispatch] = useSlashMenu();
  const selectedName = filtered[selected]?.name;
  const results = useMemo(() => chunk(filtered), [filtered]);
  const menuRef = useRef();

  const [scrollTop, setScrollTop] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [hover, setHover] = useState(0);

  const onHover = useCallback(() => setHover(hover + 1), [hover, setHover]);

  const onClick = useCallback(
    (_, name) => {
      ReactEditor.focus(editor);
      transforms[name](editor);

      dispatch({ type: 'close' });
    },
    [dispatch, editor],
  );

  const onScroll = useCallback(() => {
    if (!menuRef.current) return;
    setScrollTop(menuRef.current.scrollTop);
  }, []);

  const close = useCallback(() => dispatch({ type: 'close' }), [dispatch]);

  const isVisible = Boolean(menuRef.current && open);

  useEffect(() => {
    if (!isVisible) return;

    menuRef.current.scrollTop = 0;
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    const menuItem = [...menuRef.current.children][selected];
    if (!menuItem) return;
    // @note: not available in jsdom?!
    if (!menuItem.scrollIntoView) return;

    menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [selected, isVisible]);

  // Set the highlighted category based of if we clicked on a category or if we
  // have scrolled to it. We set `selectedCategory` once we click on one, and
  // then unset it when the scroll finished.
  //
  // This is mostly a problem if you have a category with too few items in it,
  // such that scrolling to it, also scrolls another category into view.
  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (!menuRef.current) return;
      if (!isVisible || scrollTop !== menuRef.current?.scrollTop) return;

      if (selectedCategory) {
        setSelectedCategory(null);
      }
    });
    // @note: This hook is only to be called when the scroll changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop, isVisible]);

  useEffect(() => {
    if (rangeRef?.current && Range.isCollapsed(rangeRef.current)) {
      dispatch({ type: 'close' });
    }
    // @note: rangeRef is mutable, so we really need to be checking against rangeRef.current for selection changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rangeRef?.current]);

  const className = useClassName(
    classes['SlashMenu-Menu'],
    filtered.length > 0 && classes['SlashMenu-Menu-results'],
    filtered.length === 1 && classes['SlashMenu-Menu-one_result'],
  );

  return (
    <MenuDropdown className={`${classes.SlashMenu} SlashMenu`} close={close} open={open} target={target}>
      <Menu ref={menuRef} className={className} onMouseOver={onHover} onScroll={onScroll} role="menu">
        <SlashMenuItems onClick={onClick} results={results} selectedName={selectedName} useMdx={editor.props.useMDX} />
      </Menu>
      {!editor.props.basic && <SlashMenuTooltip menuRef={menuRef} />}
    </MenuDropdown>
  );
};

export default memo(SlashMenu);
