import { Editor, Range } from 'slate';

export const noModifiers = event => !['altKey', 'ctrlKey', 'metaKey', 'shiftKey'].includes(event.key);

export const afterWhitespace = editor => {
  if (!editor.selection) return true;

  const start = Range.start(editor.selection);
  const [, blockPath] = Editor.above(editor, { at: start, match: n => Editor.isBlock(editor, n) }) || [];
  if (blockPath && Editor.isStart(editor, start, blockPath)) return true;

  const before = Editor.before(editor, start, { distance: 1, unit: 'character' });
  return !before || Editor.string(editor, { anchor: before, focus: start }).match(/^\s$/);
};
