import type { Path } from 'slate';

import { Editor, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { ParagraphElement, ReadmeElement } from '@ui/MarkdownEditor/types';

import { type as figureType } from '../Figure/shared';

import { isFigcaption, type } from './shared';

export const insertFigcaption = (editor: Editor, imagePath: Path) => {
  const figcaption = Editor.next(editor, { at: imagePath });

  if (figcaption && isFigcaption(figcaption[0])) {
    Transforms.select(editor, Editor.end(editor, figcaption[1]));
    return;
  }

  const at = [...imagePath, 1];

  Editor.withoutNormalizing(editor, () => {
    Transforms.wrapNodes(editor, { type: figureType } as ReadmeElement, { at: imagePath });
    Transforms.insertNodes(editor, { type, children: [emptyNode() as ParagraphElement] }, { at, select: true });
  });
};

export default { insertFigcaption };
