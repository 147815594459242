import type { $TSFixMe } from '@readme/iso';
import type { Ancestor, EditorAboveOptions, Path } from 'slate';

import { useCallback } from 'react';
import { Editor, Node, Range, Transforms } from 'slate';

import { TableCell } from '@ui/MarkdownEditor/editor/blocks';
import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { ReadmeBlock } from '@ui/MarkdownEditor/types';

import { acrossBlocks } from '../selection';

/* @note: Editor.above does not check the commonPath?!
 */
const aboveWithCommonPath = (editor: Editor, options: EditorAboveOptions<Ancestor> = {}) => {
  const { at = editor.selection, match = () => true } = options;
  if (!at) return null;

  const commonPath = Editor.path(editor, at);
  const entry = Editor.node(editor, commonPath);
  if (!entry) return null;

  if (match(...entry)) {
    return entry;
  }

  return Editor.above(editor, options);
};

const wholeBlockSelected = (editor: Editor) => {
  if (!editor.selection) return false;

  const [, blockPath] =
    aboveWithCommonPath(editor, { match: n => Editor.isBlock(editor, n) && !TableCell.is(n) }) || [];
  if (!blockPath) return false;

  return Range.equals(Editor.range(editor, blockPath), {
    anchor: Range.start(editor.selection),
    focus: Range.end(editor.selection),
  });
};

/* useCopyHandler()
 *
 * The complexity in this function is due to `Editor.fragment`. It splits the
 * ancestor nodes and includes them in the fragment. So, if you selected some
 * text in a blockquote and copied it, it would include the blockquote
 * formatting. For our copy handler, we're going to assume if you're partially
 * selecting from within a block, you don't want to include the wrapped blocks
 * in the copy data.
 */
export default function useCopyHandler(editor: Editor, cut = false) {
  return useCallback(
    (event: React.ClipboardEvent) => {
      if (!editor.selection) return;

      event.preventDefault();

      let fragment;

      if (acrossBlocks(editor) || wholeBlockSelected(editor)) {
        fragment = Editor.fragment(editor, editor.selection);
      } else {
        const [block, blockPath] =
          (aboveWithCommonPath(editor, { match: n => Editor.isBlock(editor, n) }) as [ReadmeBlock, Path]) || [];
        if (!block || !blockPath || !('children' in block)) {
          throw new Error('Unable to find a block at the current selection?!');
        }

        const { anchor, focus } = editor.selection;

        const subRange = {
          anchor: {
            path: anchor.path.slice(blockPath.length),
            offset: anchor.offset,
          },
          focus: {
            path: focus.path.slice(blockPath.length),
            offset: focus.offset,
          },
        };

        /*
         * We unwrap the contents from the table and put them into a
         * 'surrogate' so that we don't end up with a 1x1 table in the
         * resultant string.
         */
        const surrogateNode = emptyNode({ children: block.children } as $TSFixMe);
        fragment = Node.fragment(surrogateNode, subRange);
      }

      const copied = editor.serialize({ children: fragment, type: 'root' });
      event.clipboardData.setData('text', copied);

      if (cut) Transforms.delete(editor);
    },
    [editor, cut],
  );
}
