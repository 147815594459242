import type { RenderElementProps } from 'slate-react';

import React, { useEffect, useCallback } from 'react';
import { useSelected, useSlateStatic } from 'slate-react';

import { useVariableMenu } from '@ui/MarkdownEditor/editor/VariableMenu';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { VariableInline } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Tooltip from '@ui/Tooltip';

import { openVariableMenu, isGlossaryTerm } from './shared';
import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: VariableInline;
}

const Variable = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const [{ open, target, terms, rangeRef, search }, dispatch] = useVariableMenu();
  const selectedTerm = terms?.find((term: { name: string }) => term.name === element.name);
  const className = useClassName(isGlossaryTerm(element) && selectedTerm && 'GlossaryItem-trigger', classes.Variable);

  const openMenu = useCallback(
    event => {
      event.preventDefault();
      openVariableMenu(editor, element, attributes.ref);
    },
    [attributes.ref, editor, element],
  );

  useEffect(() => {
    if (open && target?.current === attributes?.ref?.current) {
      dispatch({ type: MenuActionTypes.search, payload: element.name });
    }
  }, [attributes?.ref, dispatch, editor, element, element.name, open, rangeRef, search, target]);

  useEffect(() => {
    if (open && !selected && target?.current === attributes?.ref?.current) {
      dispatch({ type: MenuActionTypes.close });
    }
  }, [attributes.ref, dispatch, open, selected, target]);

  const GlossaryTooltip = () => (
    <>
      <strong>
        <em>{selectedTerm?.name}</em>
      </strong>
      <span> - {selectedTerm?.definition}</span>
    </>
  );

  const variableInner = (
    <code {...attributes} className={className} onClick={openMenu} role="none">
      {children}
    </code>
  );

  return isGlossaryTerm(element) && selectedTerm ? (
    <Tooltip arrow={false} content={<GlossaryTooltip />} offset={[0, 5]} placement="bottom-start">
      {variableInner}
    </Tooltip>
  ) : (
    variableInner
  );
};

export default Variable;
