import { Node, Point, Range, Transforms } from 'slate';

import preserveCursor from '../../preserveCursor';

const ensureCursorAfterPrefix = (editor, path) => {
  const node = Node.get(editor, path);
  const [, leafPath] = Node.texts(node).next().value;
  const depth = Math.max(Node.string(node).search(/([^#]|$)/), 0);
  const edgeOfPrefix = {
    path: [...path, ...leafPath],
    offset: depth,
  };

  if (Range.isCollapsed(editor.selection) && Point.isBefore(editor.selection.anchor, edgeOfPrefix)) {
    Transforms.select(editor, edgeOfPrefix);
  }
};

export const convertToHeading = (editor, newDepth, { at: _at } = {}) => {
  const path = _at || [editor.selection.focus.path[0]];
  const line = Node.get(editor, path);
  const string = Node.string(line);
  const depth = Math.max(string.search(/([^#]|$)/), 0);
  const diff = newDepth - depth;

  // nothing to do!! 🎊
  if (diff === 0) return;

  preserveCursor(editor, () => {
    const [, leafPath] = Node.texts(line).next().value;
    const at = { path: [...path, ...leafPath], offset: 0 };

    if (diff > 0) {
      let prefix = [...new Array(diff)].map(() => '#').join('');
      if (!string.match(/^#/)) {
        prefix += ' ';
      }

      Transforms.insertText(editor, prefix, { at });
    } else {
      Transforms.delete(editor, { at, distance: Math.abs(diff) });
    }
  });

  // When toggling headers, we never really want the cursor before (or inside?)
  // the hashes. If the line is blank, the above will leave the cursor at the
  // beginning of the line.
  ensureCursorAfterPrefix(editor, path);
};

export const convertToH1 = editor => convertToHeading(editor, 1);
export const convertToH2 = editor => convertToHeading(editor, 2);
export const convertToH3 = editor => convertToHeading(editor, 3);

export default { convertToHeading };
