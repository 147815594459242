import type { UploadImageResponse } from './upload';
import type { UploadApiNextImageResponse } from './uploadApiNext';
import type { RefObject } from 'react';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import type { InputProps } from '@ui/Input';
import Input from '@ui/Input';
import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';
import uploadImage from './upload';
import useUploadApiNextImage from './uploadApiNext';
import { toImageRepresentation } from './util';

const isError = (err: unknown): err is Error => {
  return typeof err === 'object' && err !== null && 'message' in err;
};

/**
 * Image data shape that can be provided to the `ImageUploader.data` prop and is
 * what's returned from the `onFinish` handler when a new image is uploaded.
 */
export type ImageUploaderData = Partial<UploadApiNextImageResponse['data']>;

/**
 * Legacy ImageUploader props that uploads images to our old v1 image API
 * endpoint and returns image data in the form of an Array.
 */
export interface ImageUploaderLegacyProps
  extends Omit<ImageUploaderProps, 'baseUrl' | 'data' | 'isLegacyApi' | 'onFinish'> {
  /**
   * Optional base path to prefix onto the API endpoint URL.
   */
  baseUrl?: string;

  /**
   * Useful primarily when `preview` is enabled, it allows the preview panel to
   * initially be rendered and allows consumers to set what image data exists.
   */
  data?: UploadImageResponse;

  /**
   * Determines whether to interface with the old `/api/images` vs new
   * `/api-next/v2/images` API endpoint. When omitted or `false`, images are
   * uploaded to the v2 API endpoint compatible on both Dash and SuperHub. Image
   * `data` is represented as an Object rather than an Array and is what's used
   * during `preview` states and the `onFinish` callback.
   */
  isLegacyApi: true;

  /**
   * Called whenever image data changes or is removed.
   */
  onFinish?: (data: UploadImageResponse) => void;
}

export interface ImageUploaderProps {
  /**
   * Not available when `v2` is enabled.
   */
  baseUrl?: never;
  children?: React.ReactNode;
  className?: string;

  /**
   *  Useful primarily when `preview` is enabled, it allows the preview panel to
   *  initially be rendered and allows consumers to set what image data exists.
   */
  data?: ImageUploaderData;

  /**
   * Hides the button and makes it entirely inaccessible.
   */
  hidden?: boolean;

  /**
   * Optional ref to pass in. Using a different prop than `ref` allows us to
   * restrict it's type to `RefObject`.
   */
  inputRef?: RefObject<HTMLInputElement>;

  /**
   * Determines whether to interface with the old `/api/images` vs new
   * `/api-next/v2/images` API endpoint. When `false`, images are uploaded to the
   * v2 API endpoint compatible on both Dash and SuperHub. Image `data` is
   * represented as an Object rather than an Array and is what's used during
   * `preview` states and the `onFinish` callback.
   */
  isLegacyApi?: false;

  /**
   * Constrains the image by this height when uploaded.
   */
  maxHeight?: number;

  /**
   * Called when the image uploader is clicked. Simply passes through the event
   * object.
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * Called whenever image data changes or is removed. When removed, handler is
   * invoked with an empty or null-ish image object that the API expects.
   */
  onFinish?: (data: ImageUploaderData) => void;

  /**
   * Optional callback for preview image load
   * Useful for referencing preview HTMLImageElement for things like color detection
   */
  onPreviewLoad?: (img: HTMLImageElement) => void;

  /**
   * Called when image upload request is in transit.
   */
  onStart?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Called when an error returns from the image upload request.
   */
  onUploadError?: (error: Error) => void;

  /**
   * When enabled, renders a preview panel of the current image that was either
   * uploaded or set via `data`. Exposes controls to "update" or "remove" the
   * current image.
   */
  preview?: boolean;
}

/**
 * Renders a single button that allows users to upload an image to our image
 * backend and get the image data response via `onFinish` callback handler. By
 * default, images are uploaded to the latest v2 image API endpoint at
 * `/api-next/v2/images` and returns data in an Object shape. Alternatively, the
 * legacy v1 endpoint can be used to return data in an Array instead with the
 * `isLegacyApi` prop.
 *
 * Optionally, image previews can be rendered before or after image uploads by
 * using the `preview` prop and supplying image `data` in either Object or Array
 * shapes. The preview panel shows the image thumbnail along with its name and
 * an interactive control to remove the image.
 *
 * NOTE: As of 2/16/2024, ImageUploader depends on `ProjectStore` for the v2 API
 * interface. If that's not yet available, use `isLegacyApi` instead.
 */
export default (function ImageUploader({
  baseUrl,
  children = 'Choose File…',
  className,
  data: propData,
  disabled,
  ghost,
  hidden,
  inputRef,
  isLegacyApi,
  kind = 'minimum',
  onClick,
  maxHeight,
  onFinish,
  onPreviewLoad,
  onStart,
  onUploadError,
  outline = true,
  preview,
  size,
  ...props
}: Omit<InputProps, 'size'> &
  Pick<ButtonProps, 'disabled' | 'ghost' | 'kind' | 'outline' | 'size'> &
  (ImageUploaderLegacyProps | ImageUploaderProps)) {
  const uid = useUniqueId('ImageUploader');
  const bem = useClassy(styles, 'ImageUploader');
  const [isLoading, setIsLoading] = useState(false);
  const backupRef = useRef<HTMLInputElement>(null);
  const ref = inputRef || backupRef;
  const [data, setData] = useState<ImageUploaderData | undefined>(toImageRepresentation(propData));

  // TODO: Remove this hook to get the bound upload function once the Angular
  // directive build is gone and we replace ProjectContext with ProjectStore
  // inside `uploadApiNextImage.ts`.
  const uploadApiNextImage = useUploadApiNextImage();

  // Allows "data" to be controlled via props.
  useEffect(() => {
    setData(toImageRepresentation(propData));
  }, [propData]);

  const upload = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async e => {
      e.preventDefault();
      if (!e.target.files?.length) return;

      setData(undefined);
      setIsLoading(true);
      onStart?.(e);

      try {
        if (isLegacyApi) {
          const imageData = await uploadImage({
            baseUrl,
            file: e.target.files[0],
            maxHeight,
          });
          setData(toImageRepresentation(imageData));
          onFinish?.(imageData);
        } else {
          const imageData = await uploadApiNextImage({
            file: e.target.files[0],
            maxHeight,
          });
          setData(imageData.data);
          onFinish?.(imageData.data);
        }
      } catch (err) {
        // When error occurs, restore original data.
        setData(data);
        if (isError(err)) {
          onUploadError?.(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [baseUrl, data, isLegacyApi, maxHeight, onFinish, onStart, onUploadError, uploadApiNextImage],
  );

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onClick?.(event);
      ref.current?.click();
    },
    [ref, onClick],
  );

  const handleRemove = useCallback(() => {
    setData(undefined);
    if (isLegacyApi) {
      onFinish?.([]);
    } else {
      onFinish?.({ uri: null });
    }
  }, [onFinish, isLegacyApi]);

  return (
    <>
      {!!preview && data?.url ? (
        <Flex align="center" className={bem('-preview')} data-testid="preview" gap="0" justify="start">
          <Flex
            align="center"
            aria-label="Change"
            className={bem('-preview-control')}
            gap="sm"
            onClick={handleChange}
            tag="button"
          >
            <img
              alt={data.name || undefined}
              className={bem('-preview-image')}
              crossOrigin={onPreviewLoad ? 'anonymous' : undefined}
              height={data.height || undefined}
              onLoad={event => onPreviewLoad?.(event.target as HTMLImageElement)}
              src={data.url || undefined}
              width={data.width || undefined}
            />
            <Tooltip arrow={false} content={data.name} delay={[800, 200]} offset={[0, 5]}>
              <span className={bem('-preview-label')}>{data.name}</span>
            </Tooltip>
          </Flex>
          <button className={bem('-preview-control', '-preview-control_remove')} onClick={handleRemove}>
            Remove
          </button>
        </Flex>
      ) : (
        <Button
          className={bem('&', className)}
          disabled={disabled}
          ghost={ghost}
          hidden={hidden}
          id={uid('upload-button')}
          kind={kind}
          loading={isLoading}
          onClick={handleChange}
          outline={outline}
          size={size}
        >
          {children}
        </Button>
      )}
      <Input
        // Overridable properties for the input field
        accept="image/*"
        {...props}
        // Fixed properties that should never be overridden
        key={data?.url}
        ref={ref}
        aria-labelledby={uid('upload-button')}
        className={bem('-input')}
        data-testid="input"
        disabled={disabled}
        hidden
        onChange={upload}
        type="file"
      />
    </>
  );
});

export * from './upload';
export * from './uploadApiNext';
export { default as uploadImage } from './upload';
