import Component from './Component';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isEmbedBlock } from './shared';
import slashMenu from './slashMenu';

export default {
  deserialize,
  isEmbedBlock,
  isVoid: true,
  Component,
  operations,
  renderNode: Component,
  serialize,
  slashMenu,
  type,
};
