import { type } from './shared';

const extractCode = ({ lang, meta = '', type: codeType, value }) => {
  return { lang, type: codeType, meta, value };
};

export const deserialize = node => {
  return extractCode(node);
};

export const serialize = node => ({
  ...node,
  lang: node.lang || (node.meta ? 'Text' : ''),
  className: 'tab-panel',
  data: {
    hName: type,
    hProperties: {
      lang: node.lang || (node.meta ? 'Text' : ''),
      meta: node.meta || '',
    },
  },
});
