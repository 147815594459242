import { NodeTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedBlockMdNode, Serializer, Deserializer } from '@ui/MarkdownEditor/types';

const hProps: (keyof EmbedBlockMdNode['data']['hProperties'])[] = [
  'height',
  'href',
  'html',
  'iframe',
  'providerName',
  'providerUrl',
  'url',
  'width',
  'typeOfEmbed',
];

export const deserialize: Deserializer<EmbedBlockMdNode, EmbedBlock> = ({ type, label, title, data }) => {
  const props = data?.hProperties;

  const deserialized = {
    ...props,
    children: [{ text: '' }],
    type,
    ...(props.html && { html: decodeURIComponent(props.html) }),
    ...(label && { label }),
    ...(title && { title }),
  } as EmbedBlock;

  return deserialized;
};

export const serialize: Serializer<EmbedBlock, EmbedBlockMdNode> = node => {
  const props = Object.keys(node).reduce(
    (acc, key) => {
      if (hProps.includes(key as keyof EmbedBlockMdNode['data']['hProperties']) && node[key] != null) {
        acc[key] = node[key];
      }
      return acc;
    },
    {} as EmbedBlockMdNode['data']['hProperties'],
  );

  const serialized = {
    ...(node.label && { label: node.label }),
    ...(node.title && { title: node.title }),
    url: node.url,
    children: [],
    data: {
      hName: 'embed',
      hProperties: {
        ...props,
        ...(node.label && node.title === '@embed' && { title: node.label }),
        ...(props.html && { html: encodeURIComponent(props.html) }),
      },
    },
    type: NodeTypes.embedBlock,
  } as EmbedBlockMdNode;

  return serialized;
};
