import React from 'react';

import classes from './style.module.scss';

const Hint = () => (
  <>
    <strong>Drag</strong>
    <span className={classes.BlockMenu_TooltipText}> to move</span>
    <br />
    <strong>Click</strong>
    <span className={classes.BlockMenu_TooltipText}> to open menu</span>
  </>
);

export default React.memo(Hint);
