import * as blocks from '@ui/MarkdownEditor/editor/blocks';

const voidNodes = Object.values(blocks)
  .filter(block => block.isVoid)
  .map(block => block.type);

const isVoid =
  ({ isVoid: isVoidCore }) =>
  element => {
    return voidNodes.includes(element.type) || isVoidCore(element);
  };

export default isVoid;
