import type { RenderElementProps } from 'slate-react';

import React, { useCallback, useMemo } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { v4 as uuid } from 'uuid';

import Box from '@ui/Box';
import CodeEditor from '@ui/MarkdownEditor/editor/CodeEditor';
import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { HtmlBlock } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

type Props = RenderElementProps & {
  element: HtmlBlock;
};

const Html = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const id = useMemo(() => `html-${uuid()}`, []);

  const note = useMemo(
    () => (editor.props.compatibilityMode ? '' : '(Script tags will be stripped)'),
    [editor.props.compatibilityMode],
  );

  const onChange = useCallback(
    value => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { value }, { at: path });
    },
    [editor, element],
  );

  return (
    <SelectionWrapper element={element} {...attributes}>
      <Box className={classes.Html}>
        <div className={classes.HtmlToolbar} contentEditable={false} role="tablist">
          <div aria-controls={id} aria-selected={true} className={classes['HtmlToolbar-Tab']} role="tab">
            Custom HTML
          </div>
          <div className={classes['HtmlToolbar-Note']}>{note}</div>
        </div>
        <CodeEditor
          code={element.value}
          element={element}
          id={id}
          language="html"
          onChange={onChange}
          role="tabpanel"
        />
        {children}
      </Box>
    </SelectionWrapper>
  );
};

export default Html;
