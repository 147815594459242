import type { HTMLAttributes } from 'react';

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Node } from 'slate';

import type { TableHeaderCellElement, TableCellElement } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import { isTableHeaderCell } from './shared';
import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: TableCellElement | TableHeaderCellElement;
}

const TableCell = ({ attributes, children, element }: Props) => {
  const style = { textAlign: element.align ?? (isTableHeaderCell(element) ? ('center' as const) : ('left' as const)) };
  const isHeaderCellEmpty = isTableHeaderCell(element) && isEmpty(Node.string(element));
  const className = useClassName(classes.TableCell, isTableHeaderCell(element) && classes.TableCell_Header);

  return (
    <td {...attributes} className={className} style={style}>
      {!!isHeaderCellEmpty && (
        <span className={classes.TableCell_Placeholder} contentEditable={false} data-slate-placeholder={true}>
          Column Title
        </span>
      )}
      {children}
    </td>
  );
};

export default TableCell;
