import Component from './Component';
import deserialize from './deserialize';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import slashMenu from './slashMenu';

const isHeading = node => node.type === 'heading';

const Heading = {
  Component,
  deserialize,
  isHeading,
  normalizeNode,
  onKeyDown,
  operations,
  slashMenu,
  type: 'heading',
};

export default Heading;
