import type { $TSFixMe } from '@readme/iso';
import type { Node } from 'mdast';

import { ImageAlignValues } from '@ui/MarkdownEditor/enums';
import type { FigureElement, FigureMdNode, ReadmeElement } from '@ui/MarkdownEditor/types';

import { serialize as figcaptionSerialize } from '../Figcaption/serialize';
import { serialize as imageSerialize } from '../Image/serialize';

import { type } from './shared';

export const deserialize = (node: FigureMdNode, deeper: (node: FigureMdNode) => ReadmeElement[]): FigureElement => {
  const children = deeper(node) as FigureElement['children'];
  children[0].align = ImageAlignValues.center;

  return {
    type,
    children,
  };
};

export const serialize = (node: FigureElement, deeper: (node: ReadmeElement) => Node[]): FigureMdNode => {
  const [image, figcaption] = node.children;
  const imageMdNode = imageSerialize(image as $TSFixMe) as $TSFixMe;
  const children: FigureMdNode['children'] = figcaption
    ? [imageMdNode, figcaptionSerialize(figcaption, deeper)]
    : [imageMdNode];

  return {
    type,
    data: {
      hName: type,
    },
    children,
  };
};
