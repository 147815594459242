import React from 'react';

import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import type { EmbedElement, EmbedBlock } from '@ui/MarkdownEditor/types';

import EmbedMenu from '.';

interface EmbedToolbarProps {
  children: React.ReactElement;
  element: EmbedBlock | EmbedElement;
  menuRef: React.RefObject<DropdownRef>;
}

const EmbedToolbar = ({ children, element, menuRef }: EmbedToolbarProps) => {
  return (
    <Dropdown align="top" appendTo={document.body} justify="start" offset={[5, -35]} trigger="hover">
      {children}
      <EmbedMenu element={element} menuRef={menuRef} />
    </Dropdown>
  );
};

export default EmbedToolbar;
