import type { NodeEntry } from 'slate';

import { Editor, Path, Element, Node, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { VariableInline, ReadmeNode } from '@ui/MarkdownEditor/types';

export const type = 'variable';
export const rdmdType = 'readme-glossary-item';
export const isVariable = (node: Node): node is VariableInline => Element.isElementType(node, type);
export const isGlossaryTerm = (node: Node): node is VariableInline =>
  isVariable(node) && !!Node.string(node).match(/^<<glossary:/);
export const isVariableEntry = (entry: NodeEntry<ReadmeNode>): entry is NodeEntry<VariableInline> => {
  return isVariable(entry[0]) && Path.isPath(entry[1]);
};

export const openVariableMenu = (editor: Editor, element: VariableInline, ref: React.RefObject<HTMLSpanElement>) => {
  const [, dispatch] = editor.variableMenu;

  const rangeRef = Editor.rangeRef(editor, Editor.range(editor, ReactEditor.findPath(editor, element)));

  dispatch({ type: MenuActionTypes.init, payload: { rangeRef } });
  dispatch({ type: MenuActionTypes.open, payload: { target: ref } });

  if (!rangeRef.current) return;

  const prefixLength = editor.props.useMDX ? 1 : 2;

  Transforms.select(editor, {
    anchor: { path: rangeRef.current.anchor.path, offset: rangeRef.current.anchor.offset + prefixLength },
    focus: { path: rangeRef.current.focus.path, offset: rangeRef.current.focus.offset - prefixLength },
  });
};
