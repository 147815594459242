import pick from 'lodash/pick';

import { type } from './shared';

const attrs = [
  'html',
  'url',
  'title',
  'favicon',
  'image',
  'provider',
  'href',
  'typeOfEmbed',
  'height',
  'width',
  'iframe',
];

export const deserialize = ({ data }) => {
  return {
    type,
    children: [{ text: '' }],
    ...pick(data.hProperties, attrs),
  };
};

export const serialize = node => {
  return {
    data: {
      hProperties: pick(node, attrs),
      hName: 'embed',
      ...pick(node, attrs),
    },
    type: 'embed',
  };
};
