import type { Dispatch, ReactNode } from 'react';
import type { Path } from 'slate';

import React, { useContext, createContext, useReducer, useMemo } from 'react';
import { useSlateStatic } from 'slate-react';

import { MenuActionTypes } from '@ui/MarkdownEditor/enums';

interface CodeSettingsState {
  path: Path | null;
}

interface CodeSettingsOpenAction {
  payload: {
    path: Path;
  };
  type: MenuActionTypes.open;
}

interface CodeSettingsCloseAction {
  type: MenuActionTypes.close;
}

type CodeSettingsAction = CodeSettingsCloseAction | CodeSettingsOpenAction;

export const initialState: CodeSettingsState = {
  path: null,
};

const reducer = (state: CodeSettingsState, action: CodeSettingsAction) => {
  switch (action.type) {
    case MenuActionTypes.open: {
      const { path } = action.payload;
      return { path };
    }
    case MenuActionTypes.close:
      return { ...initialState };
    default:
      return state;
  }
};

const CodeSettingsContext = createContext([initialState, () => {}] as [
  CodeSettingsState,
  Dispatch<CodeSettingsAction>,
]);

export const CodeSettingsProvider = ({ children }: { children: ReactNode }) => {
  const editor = useSlateStatic();
  const [state, dispatch] = useReducer(reducer, initialState);
  editor.codeSettings = [state, dispatch];

  const value: [CodeSettingsState, Dispatch<CodeSettingsAction>] = useMemo(() => [state, dispatch], [state]);

  return <CodeSettingsContext.Provider value={value}>{children}</CodeSettingsContext.Provider>;
};

export const useCodeSettings = () => useContext(CodeSettingsContext);
export default useCodeSettings;
