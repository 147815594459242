import type { ConnectDragSource } from 'react-dnd';
import type { Range } from 'slate';

import React, { useState, useRef, useCallback } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';

import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import { ReusableContent } from '@ui/MarkdownEditor/editor/blocks';
import type { ReadmeElement } from '@ui/MarkdownEditor/types';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import Handle from './Handle';
import Hint from './Hint';
import classes from './style.module.scss';
import Utils, { expandSelection } from './utils';

const preventDefault = (e: { preventDefault: () => void }) => e.preventDefault();

// prevent block menu from being cut off by page header
const POPPER_OPTIONS = {
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          padding: { top: 100 },
        },
      },
    ],
  },
};

const TOOLTIP_DELAY: [number | null, number | null] = [300, null];

const BlockMenuMenu = ({ element, drag }: { drag: ConnectDragSource; element: ReadmeElement | null }) => {
  const editor = useSlateStatic();
  const dropdownRef = useRef<DropdownRef>(null);
  const [selection, setSelection] = useState<Range | null>(null);
  const path = element && ReactEditor.findPath(editor, element);
  const isFirstElement = path && path[0] === 0;
  const isReusableContent = element && ReusableContent.isReusableContent(element);

  const handleCopy = useCallback(() => {
    if (!selection) {
      // eslint-disable-next-line no-console
      console.warn('No selection!?');
      return;
    }

    Utils.copy(editor, selection);
    dropdownRef?.current?.toggle(false);
  }, [editor, selection]);

  const handleDelete = useCallback(() => {
    if (!selection) {
      // eslint-disable-next-line no-console
      console.warn('No selection!?');
      return;
    }

    Utils.remove(editor, selection);
  }, [editor, selection]);

  const insertLineBelow = useCallback(() => {
    if (!selection) {
      // eslint-disable-next-line no-console
      console.warn('No selection!?');
      return;
    }

    Utils.insertLineBelow(editor, selection);
    dropdownRef?.current?.toggle(false);
  }, [editor, selection]);

  const insertLineAbove = useCallback(() => {
    if (!selection) {
      // eslint-disable-next-line no-console
      console.warn('No selection!?');
      return;
    }

    Utils.insertLineAbove(editor);
    dropdownRef?.current?.toggle(false);
  }, [editor, selection]);

  const makeReusable = useCallback(() => {
    if (!selection) {
      // eslint-disable-next-line no-console
      console.warn('No selection!?');
      return;
    }

    ReusableContent.operations.makeReusable(editor, selection);
    dropdownRef?.current?.toggle(false);
  }, [editor, selection]);

  const onToggle = useCallback(
    (value: boolean) => {
      if (!element) return;

      if (value === false || !path) return;

      expandSelection(editor, path);
      ReactEditor.focus(editor);
      setSelection(editor.selection);

      // @note: we defer the close till after a full render-cycle so the
      // toolbar has a chance to respond to the selection change. Maybe there's
      // a more deterministic way to do this?
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          editor.inlineToolbar.close();
        });
      });
    },
    [editor, element, path],
  );

  return (
    <Dropdown ref={dropdownRef} align="top" justify="start" onToggle={onToggle} tippyOptions={POPPER_OPTIONS}>
      {/* @note: Adding a div here to accept a ref from the Dropdown, so we don't need to forward that through the Tooltip */}
      <div>
        <Tooltip content={<Hint />} delay={TOOLTIP_DELAY} placement="bottom">
          <Handle drag={drag} isReusableContent={!!isReusableContent} />
        </Tooltip>
      </div>
      <Menu className={classes.BlockMenu_Menu}>
        <MenuItem
          icon={<Icon name="copy" />}
          onClick={handleCopy}
          /* @ts-ignore */
          onMouseDown={preventDefault}
        >
          Copy
        </MenuItem>
        {!!isFirstElement && (
          <MenuItem
            icon={<Icon name="corner-up-right" />}
            onClick={insertLineAbove}
            /* @ts-ignore */
            onMouseDown={preventDefault}
          >
            Add Line Above
          </MenuItem>
        )}
        <MenuItem
          icon={<Icon name="corner-down-right" />}
          onClick={insertLineBelow}
          /* @ts-ignore */
          onMouseDown={preventDefault}
        >
          Add Line Below
        </MenuItem>
        {!!editor.props.useReusableContent && !isReusableContent && !Utils.containsReusableContent(editor) && (
          <MenuItem
            icon={<Icon name="recycle" />}
            onClick={makeReusable}
            /* @ts-ignore */
            onMouseDown={preventDefault}
          >
            Make Reusable
          </MenuItem>
        )}
        <MenuDivider />
        <MenuItem
          color="red"
          icon={<Icon name="trash" />}
          onClick={handleDelete}
          /* @ts-ignore */
          onMouseDown={preventDefault}
        >
          Delete
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default BlockMenuMenu;
