import isEqual from 'lodash/isEqual';
import { Editor, Path, Node, Range, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { isFigure } from '../Figure/shared';

import { isFigcaption } from './shared';

const enter: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Enter' && !event.shiftKey && editor.selection)) return;

  const captionEntry = Editor.above(editor, { match: isFigcaption });
  if (!captionEntry) return;
  const [caption, path] = captionEntry;

  if (
    !(
      Range.isCollapsed(editor.selection) &&
      Editor.isEnd(editor, editor.selection.anchor, path) &&
      isEqual(emptyNode(), caption.children[caption.children.length - 1])
    )
  )
    return;

  event.preventDefault();
  event.stopPropagation();

  const lastLineOrCaptionPath = caption.children.length === 1 ? path : [...path, caption.children.length - 1];
  const nextFigureSiblingPath = Path.next(Path.parent(path));
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: lastLineOrCaptionPath });
    Transforms.insertNodes(editor, [emptyNode()], { at: nextFigureSiblingPath, select: true });
  });
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && editor.selection)) return;

  const captionEntry = Editor.above(editor, { match: isFigcaption });
  if (!captionEntry) return;
  const [caption, path] = captionEntry;

  if (Node.string(caption) !== '') return;

  event.preventDefault();
  event.stopPropagation();

  const nextEntry = Editor.next(editor, { at: path });

  if (nextEntry && isEqual(nextEntry[0], emptyNode())) {
    Transforms.select(editor, Editor.start(editor, nextEntry[1]));
  } else {
    const [, figurePath] = Editor.above(editor, { match: isFigure }) || [];
    if (figurePath) {
      Transforms.insertNodes(editor, emptyNode(), { at: Path.next(figurePath), select: true });
    } else {
      // eslint-disable-next-line no-console
      console.warn("Couldn't find a figure above a figcaption?!");
      Transforms.insertNodes(editor, emptyNode(), { at: Path.next(path), select: true });
    }
  }
};

const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey && editor.selection)) return;

  const captionEntry = Editor.above(editor, { match: isFigcaption });
  if (!captionEntry) return;

  const [, figurePath] = Editor.above(editor, { match: isFigure }) || [];
  const nextPath = figurePath && Path.next(figurePath);
  if (nextPath && Node.has(editor, nextPath)) return;

  // If we are not at the end of the caption, do nothing
  if (!isEqual(editor.selection.anchor.path, Editor.end(editor, captionEntry[1]).path)) return;

  event.preventDefault();
  event.stopPropagation();

  // If there's no next node, insert an empty one on arrow down!
  Transforms.insertNodes(editor, emptyNode(), { at: nextPath, select: true });
};

const arrowUp: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey && editor.selection)) return;

  const captionEntry = Editor.above(editor, { match: isFigcaption });
  // const rootPath = editor.selection.anchor.path.slice(0, 1);

  if (!captionEntry) return;
  const [, path] = captionEntry;

  // If we are not at the beginning of the caption, do nothing
  if (!isEqual(editor.selection.anchor.path, Editor.start(editor, path).path)) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, Path.previous(path));
};

export default [enter, backspace, arrowDown, arrowUp];
