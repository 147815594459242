import type { NodeEntry } from 'slate';

import { Editor, Range, Point, Transforms } from 'slate';

import type { LinkElement } from '@ui/MarkdownEditor/types';

import { isLink, defaultLink } from './shared';

export const unwrapLink = (editor: Editor, [node, path]: NodeEntry<LinkElement>) => {
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertText(editor, node.label, { at: { path, offset: 0 } });
  });
};

export const insertLink = (
  editor: Editor,
  { label, url, title }: Partial<LinkElement> = {},
  { at = editor.selection, menu = true } = {},
) => {
  if (!at) return;

  Transforms.insertNodes(editor, defaultLink({ label, url, title }), {
    at,
    select: true,
  });

  if (!menu || !editor.selection) return;

  const afterLink = Editor.after(editor, editor.selection);
  if (!afterLink) return;

  Transforms.select(editor, afterLink);

  const linkEntry = Editor.previous(editor, { match: isLink });

  if (linkEntry) {
    const [link, path] = linkEntry;
    const ref = Editor.pathRef(editor, path);

    editor.linkEditor[1]({ type: 'open', payload: { link, ref, selection: editor.selection } });
  } else {
    throw new Error('Unable to find link?!');
  }
};

export const insertLinkFromMenu = (
  editor: Editor,
  { label, url, title }: Partial<LinkElement> = {},
  { at = editor.selection } = {},
) => {
  if (!at) return;

  Transforms.insertNodes(editor, defaultLink({ label, url, title }), {
    at,
    select: true,
  });
};

export const toggleLink = (editor: Editor) => {
  if (!editor.selection) return;

  const links = [...Editor.nodes(editor, { match: isLink, reverse: true })];
  let rangeRef;

  if (links.length) {
    /* @note: We're trying to exand selection to the edge of any links that may
     * be partially selected. `Editor.rangeRef` has limitations, and seems to
     * nullify the ref if one of the paths is removed, so we push the point to
     * the outside of a links edge.
     */
    const firstLinkEdge = Editor.before(editor, Editor.start(editor, links[links.length - 1][1]));
    const lastLinkEdge = Editor.after(editor, Editor.end(editor, links[0][1]));
    rangeRef = Editor.rangeRef(editor, {
      anchor:
        firstLinkEdge && Point.isBefore(firstLinkEdge, Range.start(editor.selection))
          ? firstLinkEdge
          : Range.start(editor.selection),
      focus:
        lastLinkEdge && Point.isAfter(lastLinkEdge, Range.end(editor.selection))
          ? lastLinkEdge
          : Range.end(editor.selection),
    });

    links.forEach(link => unwrapLink(editor, link));
  }

  const at = rangeRef?.current || editor.selection;

  const label = Editor.string(editor, at);
  const url = links.length ? links[links.length - 1][0].url : '';

  insertLink(editor, { label, url }, { at });
  rangeRef?.unref();
};

export default { insertLink, insertLinkFromMenu, toggleLink, unwrapLink };
