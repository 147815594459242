import type { FaEmojiInline, FaEmojiMdNode } from '@ui/MarkdownEditor/types';

import { rdmdType, type } from './shared';

export const deserialize = (node: FaEmojiMdNode): FaEmojiInline => {
  return {
    type,
    className: node.data.hProperties.className[1],
    children: [{ text: '' }],
  };
};

export const serialize = (node: FaEmojiInline): FaEmojiMdNode => ({
  type: rdmdType,
  data: {
    hName: rdmdType,
    hProperties: {
      className: ['fa', node.className],
    },
  },
});
