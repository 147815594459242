import React from 'react';

import useClassName from '@ui/MarkdownEditor/useClassName';

import { isTaskListItem } from '../ListItem/shared';
import { ListPropTypes } from '../types';

const ListElement = ({ attributes, children, element }) => {
  const Tag = element.ordered ? 'ol' : 'ul';
  const isTaskList = isTaskListItem(element.children[0]);
  const className = useClassName(isTaskList && 'contains-task-list');

  return (
    <Tag {...attributes} className={className} start={element.start}>
      {children}
    </Tag>
  );
};

ListElement.propTypes = ListPropTypes;

export default ListElement;
