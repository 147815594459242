import React from 'react';

import { isCmdOrCtrl, isShiftKey } from '@ui/MarkdownEditor/editor/eventUtils';

import { toggleFormatOnKeyDown } from './shared';
import classes from './style.module.scss';
import { LeafPropTypes } from './types';

const type = 'inlineCode';

const isCmdShiftI = event => isCmdOrCtrl(event) && isShiftKey(event, 'i');
const cmdShiftI = toggleFormatOnKeyDown(type, isCmdShiftI);

const InlineCode = ({ leaf, ...props }) => <code className={classes.Code} spellCheck={false} {...props} />;

InlineCode.propTypes = LeafPropTypes;

const inlineCode = {
  decorator: '`',
  icon: 'code',
  onKeyDown: cmdShiftI,
  render: InlineCode,
};

export default inlineCode;
