import TableHeader from './Component';
import normalizeNode from './normalizeNode';
import onKeyDown from './onKeyDown';
import operations from './operations';
import { deserialize } from './serialize';
import { rdmdType, type, isTableHeader } from './shared';

export default {
  deserialize,
  isTableHeader,
  normalizeNode,
  onKeyDown,
  operations,
  rdmdType,
  Component: TableHeader,
  type,
};
