import type { BasePoint, NodeMatch, Operation, Node } from 'slate';

import { Path, Editor, Point, Range } from 'slate';

import { isTable, isTableRow } from '@ui/MarkdownEditor/editor/blocks/Table/shared';
import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

import { acrossBlocks } from './utils';

type RangeKey = keyof Range;
type PointSpan = [BasePoint, BasePoint];

const adjustToLevel = (editor: ReadmeEditor, [start, end]: PointSpan, match: NodeMatch<Node>): PointSpan => {
  const startEntry = Editor.above(editor, { at: start, match });
  const endEntry = Editor.above(editor, { at: end, match });

  if (startEntry && endEntry) {
    return Path.equals(startEntry[1], endEntry[1])
      ? [start, end]
      : [Editor.start(editor, startEntry[1]), Editor.end(editor, endEntry[1])];
  } else if (startEntry) {
    return [Editor.start(editor, startEntry[1]), end];
  } else if (endEntry) {
    return [start, Editor.end(editor, endEntry[1])];
  }

  return [start, end];
};

const apply = (editor: Editor, op: Operation) => {
  if (op.type !== 'set_selection' || !editor.selection || op.newProperties === null) return op;

  const newSelection = { ...editor.selection, ...op.newProperties };

  const previouslyBlockLevel = acrossBlocks(editor);
  const currentlyBlockLevel = acrossBlocks({ ...editor, selection: newSelection });

  // Not block level at all
  if (!currentlyBlockLevel && !previouslyBlockLevel) {
    return op;
  }

  // Handle reversed selections
  const [startProp, endProp]: [RangeKey, RangeKey] = Range.isForward(newSelection)
    ? ['anchor', 'focus']
    : ['focus', 'anchor'];

  const [start, end] = adjustToLevel(
    editor,
    adjustToLevel(editor, [newSelection[startProp], newSelection[endProp]], isTableRow),
    isTable,
  );

  // If the start or end points are not at the start or end of a
  // line/block/table, lets update the 'set_selection' so they are.
  if (!Point.equals(start, newSelection[startProp])) {
    Object.assign(op.newProperties, { [startProp]: start });
  }

  if (!Point.equals(end, newSelection[endProp])) {
    Object.assign(op.newProperties, { [endProp]: end });
  }

  return op;
};

export default [apply];
