/* eslint-disable consistent-return */
import { Editor, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { isTable } from '../Table/shared';

const syncHeaderAttribute: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isTable(node)) return next();

    let modified = false;

    Editor.withoutNormalizing(editor, () => {
      node.children[0].children.forEach((headerCell, index) => {
        if (!headerCell.header) {
          modified = true;
          Transforms.setNodes(editor, { header: true }, { at: [...path, 0, index] });
        }
      });
    });

    if (!modified) return next();
  };

export default [syncHeaderAttribute];
