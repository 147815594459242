import type { NodeEntry } from 'slate';

import { Range, Editor, Transforms } from 'slate';

import type { JsxCommentElement } from '@ui/MarkdownEditor/types';

import { contains, offsetToPoint, pointToOffset } from '../../utils';
import { defaultEndToken } from '../JsxCommentToken/shared';

import { type } from './shared';

export const insertJsxComment = (editor: Editor, at: Range | null = editor.selection) => {
  if (!at) return;

  Transforms.wrapNodes(editor, { type } as JsxCommentElement, {
    at,
    split: true,
  });
};

export const expandComment = (
  editor: Editor,
  [node, path]: NodeEntry<JsxCommentElement>,
  { at }: { at?: Range | null } = {},
) => {
  // eslint-disable-next-line no-param-reassign
  at = at || editor.selection;
  if (!at) return false;

  const startRef = Editor.pointRef(editor, Range.start(at));

  try {
    if (!startRef.current) return false;

    const nonCommentStart = Editor.after(editor, path)!;
    const nonCommentEnd = at.focus;
    const nonCommentRange = Editor.rangeRef(
      editor,
      {
        anchor: nonCommentStart,
        focus: nonCommentEnd,
      },
      { affinity: 'inward' },
    );

    if (!nonCommentRange.current) return false;

    const fragment = Editor.fragment(editor, nonCommentRange.current);
    const string = editor.serialize({ type: 'root', children: fragment }).replace(/\*\/}$/, '');
    let selectionOffset: number | undefined;

    if (editor.selection && contains(at, editor.selection)) {
      selectionOffset = pointToOffset([node, path], editor.selection.anchor);
    }

    Editor.withoutNormalizing(editor, () => {
      if (!nonCommentRange.current) return;

      // Split the parent nodes of the start and end comments and then remove
      // everything, including the end comment, after the start comment
      Transforms.splitNodes(editor, { always: true, at: nonCommentRange.current.anchor, height: 0 });
      Transforms.splitNodes(editor, { always: true, at: nonCommentRange.current.focus, height: 0 });

      // Re-insert the content that was deleted as plain text. We remove the
      // trailing comment token `*/}` and insert a proper token element to get
      // around affinity hacks that happen in the apply plugin.
      const endOfComment = Editor.end(editor, path);
      Transforms.insertNodes(editor, defaultEndToken(), {
        at: endOfComment,
      });
      Transforms.insertText(editor, string, { at: endOfComment });
      Transforms.removeNodes(editor, { at: nonCommentRange.current });

      if (selectionOffset) {
        const point = offsetToPoint([node, path], selectionOffset);
        Transforms.select(editor, point);
      }
    });

    return true;
  } finally {
    startRef.unref();
  }
};
