import type { Node as MdNode } from 'mdast';

import { Node, Path, Point, Range, Text, Transforms } from 'slate';

import { deserializer, getNode } from '@ui/MarkdownEditor/editor/parser';
import type { Normalizer } from '@ui/MarkdownEditor/types';

import { deserialize } from './serialize';
import { rdmdType } from './shared';

const getFaEmoji = (mdast: MdNode) =>
  getNode(mdast, (node: MdNode): node is MdNode => 'type' in node && node.type === rdmdType);

const convertToFaEmoji: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!Text.isText(node)) return next();

    const string = Node.string(node);
    // @perf: we can short circuit the deserializer with a quick regex
    if (!string.match(/:[^:]*:/)) return next();

    const mdast = deserializer(string, { settings: { position: true } });
    // doesn't look like markdown formatting
    if (!mdast) return next();

    const faEmoji = getFaEmoji(mdast);
    if (!faEmoji) return next();

    const at = {
      anchor: { path, offset: faEmoji.position.start.offset },
      focus: { path, offset: faEmoji.position.end.offset },
    };

    Transforms.insertNodes(editor, deserialize(faEmoji), { at });

    if (editor.selection && Range.isCollapsed(editor.selection) && Point.equals(editor.selection.anchor, at.anchor)) {
      const after = Path.next(Path.next(at.anchor.path));
      Transforms.insertNodes(editor, { text: '' }, { at: after, select: true });
    }
  };

export default [convertToFaEmoji];
