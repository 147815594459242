import { Transforms } from 'slate';

import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

import { type as codeType } from '../Code/shared';
import { type as CodeTabTypes } from '../CodeTabs/shared';

export const insertMermaid = (editor: ReadmeEditor, { at = editor.selection } = {}) => {
  if (!at) {
    // eslint-disable-next-line no-console
    console.error('No selection!');
    return;
  }

  Transforms.insertNodes(
    editor,
    {
      active: 0,
      type: CodeTabTypes,
      tabs: [
        {
          lang: 'mermaid',
          meta: '',
          type: codeType,
          value: 'flowchart LR \n A --- B[fa:fa-spinner B] \n B --> C[fa:fa-check C] \n B --> D[fa:fa-ban D]',
        },
      ],
      children: [{ text: '' }],
    },
    { at, select: true },
  );
};

export default insertMermaid;
