import React, { memo, useEffect, useCallback, useRef } from 'react';
import { useSlateStatic, ReactEditor } from 'slate-react';

import Box from '@ui/Box';
import { Variable } from '@ui/MarkdownEditor/editor/blocks';
import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import Menu from '@ui/Menu';

import classes from './style.module.scss';
import useVariableMenu from './useVariableMenu';
import VariableMenuItems from './VariableMenuItems';

const VariableMenu = () => {
  const editor = useSlateStatic();
  const [{ selected, filtered, target, open, rangeRef }, dispatch] = useVariableMenu();

  const selectedName = filtered[selected]?.name;
  const results = filtered;
  const menuRef = useRef();

  const onClick = useCallback(
    ({ name, prefix }) => {
      ReactEditor.focus(editor);

      if (rangeRef) {
        Variable.operations.insertVariable(editor, `${prefix}${name}`, { at: rangeRef.current });
      }

      dispatch({ type: MenuActionTypes.close });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, editor, rangeRef?.current],
  );

  useEffect(() => {
    if (!open || !menuRef?.current) return;

    const menuItem = [...menuRef.current.children][selected];
    if (!menuItem) return;
    if (!menuItem.scrollIntoView) return;

    menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [open, selected]);

  return (
    <MenuDropdown className={`${classes.VariableMenu} VariableMenu`} open={open} target={target}>
      <Box className={classes['VariableMenu-Container']} kind="pop">
        <Menu ref={menuRef} className={classes['VariableMenu-Menu']} data-testid="variable-menu" role="menu">
          <VariableMenuItems onClick={onClick} results={results} selectedName={selectedName} />
        </Menu>
      </Box>
    </MenuDropdown>
  );
};

export default memo(VariableMenu);
