import { Path, Editor, Range, Transforms } from 'slate';

import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';

import { isTable, isTableRow } from './shared';

// @todo: We'd like to keep this behavior, but we need a way to delete tables,
// so for the time being, we're going to delete tables and rows on select.
// const clearTableCells = (
// editor: Editor,
// tablePath: Path,
// { startRowIndex, endRowIndex, next }: { startRowIndex: number; endRowIndex: number; next: () => void }
// ) => {
/// / eslint-disable-next-line consistent-return
// Editor.withoutNormalizing(editor, () => {
/// / eslint-disable-next-line no-plusplus
// for (let i = startRowIndex; i <= endRowIndex; i++) {
// const rowPath = [...tablePath, i];
// const row = Node.get(editor, rowPath);

// if (!isTableRow(row)) {
/// / eslint-disable-next-line no-console
// console.warn("Can't find a row at a row path?!", { rowPath, row });
// return next();
// }

/// / eslint-disable-next-line no-plusplus
// for (let j = 0; j <= row.children.length - 1; j++) {
// const cellPath = [...rowPath, j];
// if (Node.string(Node.get(editor, cellPath)).length === 0) {
/// / eslint-disable-next-line no-continue
// continue;
// }

// const at = {
// anchor: Editor.start(editor, cellPath),
// focus: Editor.end(editor, cellPath),
// };

// Transforms.delete(editor, { at });
// }
// }
// });
// };

// @note: We want to accomplish 2 things:
// 1. If we select within a table, lets only clear the cell values
// 2. If we select outside of a table, slate has a problem deleting the whole
// table. It tends to leave behind a singe table cell.
const deleteFragment = (next: () => void) => (editor: Editor) => {
  const { selection } = editor;
  if (!(selection && Range.isExpanded(selection) && acrossBlocks(editor))) return next();

  const startRowEntry = Editor.above(editor, { at: Range.start(selection), match: isTableRow });
  const endRowEntry = Editor.above(editor, { at: Range.end(selection), match: isTableRow });

  if (startRowEntry && endRowEntry && Path.common(startRowEntry[1], endRowEntry[1]).length !== 0) {
    const tableEntry = Editor.above(editor, { at: startRowEntry[1], match: isTable });
    if (!tableEntry) {
      // eslint-disable-next-line no-console
      console.warn("Can't find a table above a row?!");
      return next();
    }

    const [table, tablePath] = tableEntry;
    const startRowIndex = startRowEntry[1][startRowEntry[1].length - 1];
    const endRowIndex = endRowEntry[1][endRowEntry[1].length - 1];

    // clearTableCells(editor, tablePath, { startRowIndex, endRowIndex, next });

    if (startRowIndex === 0 && endRowIndex === table.children.length - 1) {
      Transforms.removeNodes(editor, { at: tablePath });
    } else {
      // eslint-disable-next-line no-plusplus
      for (let rowIndex = endRowIndex; rowIndex >= startRowIndex; rowIndex--) {
        Transforms.removeNodes(editor, { at: [...tablePath, rowIndex] });
      }
    }

    // eslint-disable-next-line consistent-return
    return;
  }

  // @note: If there are two tables, we delete the end table first so we don't
  // have to worry about updating startRowEntry
  if (endRowEntry) {
    const tableEntry = Editor.above(editor, { at: endRowEntry[1], match: isTable });
    if (tableEntry) {
      Transforms.delete(editor, { at: tableEntry[1] });
    }
  }

  if (startRowEntry) {
    const tableEntry = Editor.above(editor, { at: startRowEntry[1], match: isTable });
    if (tableEntry) {
      Transforms.delete(editor, { at: tableEntry[1] });
    }
  }

  return next();
};

export default deleteFragment;
