import PropTypes from 'prop-types';
import React from 'react';

const Div = ({ attributes, children, element }) => {
  const { data } = element;

  return (
    <pre {...attributes} contentEditable={false}>
      [block:${data.hName}]{'\n'}
      {JSON.stringify(data.hProperties, null, 2)}
      {'\n'}[/block]
      {children}
    </pre>
  );
};

Div.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.shape({
    children: PropTypes.array,
    data: PropTypes.shape({
      hName: PropTypes.string,
      hProperties: PropTypes.object,
    }),
    type: PropTypes.string,
  }),
};

export default Div;
