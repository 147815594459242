import {
  insertGistEmbed,
  insertIframeEmbed,
  insertJSFiddleEmbed,
  insertPDFEmbed,
  insertYouTubeEmbed,
} from './operations';

const slashMenu = {
  'YouTube Embed': { icon: 'youtube', label: 'Youtube', transform: insertYouTubeEmbed },
  'GitHub Gist Embed': { icon: 'github', label: 'GitHub Gist', transform: insertGistEmbed },
  'PDF Embed': { icon: 'file', label: 'PDF', transform: insertPDFEmbed },
  'JSFiddle Embed': { icon: 'jsfiddle', label: 'JSFiddle', transform: insertJSFiddleEmbed },
  'Iframe Embed': { icon: 'maximize', label: 'Iframe', transform: insertIframeEmbed },
};

export default slashMenu;
