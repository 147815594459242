import type { RenderElementProps } from 'slate-react';

import React from 'react';

import type { JsxCommentElement } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: JsxCommentElement;
}

const JsxComment = ({ attributes, children }: Props) => {
  return (
    <span className={classes.JsxComment} {...attributes}>
      {children}
    </span>
  );
};

export default JsxComment;
