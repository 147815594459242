import type { RenderElementProps } from 'slate-react';

import React, { useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import { useEmojiMenu } from '@ui/MarkdownEditor/editor/EmojiMenu';
import { usePageMenu } from '@ui/MarkdownEditor/editor/PageMenu';
import { useReusableContentMenu } from '@ui/MarkdownEditor/editor/ReusableContentMenu';
import { useVariableMenu } from '@ui/MarkdownEditor/editor/VariableMenu';
import { MenuActionTypes, MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { MenuHandle } from '@ui/MarkdownEditor/types';

type Props = RenderElementProps & {
  element: MenuHandle;
};

type MenuProps = Props & {
  menuState:
    | ReturnType<typeof useEmojiMenu>
    | ReturnType<typeof usePageMenu>
    | ReturnType<typeof useReusableContentMenu>
    | ReturnType<typeof useVariableMenu>;
};

const MenuHandle = ({ attributes, children, element, menuState }: MenuProps) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const [{ open, target }, dispatch] = menuState;
  const ref = attributes.ref;

  useEffect(() => {
    if (!open && !target && ref.current) {
      const rangeRef = Editor.rangeRef(editor, Editor.range(editor, ReactEditor.findPath(editor, element)));

      dispatch({ type: MenuActionTypes.init, payload: { rangeRef } });
      dispatch({ type: MenuActionTypes.open, payload: { target: ref } });
    }
  }, [dispatch, editor, element, open, ref, target]);

  useEffect(() => {
    dispatch({ type: MenuActionTypes.search, payload: element.search });
  }, [dispatch, element.menuType, element.search]);

  useEffect(() => {
    if (open && !selected && target === ref) {
      dispatch({ type: MenuActionTypes.close });
      const path = ReactEditor.findPath(editor, element);
      Transforms.unwrapNodes(editor, { at: path });
    }
  }, [dispatch, editor, element, open, ref, selected, target]);

  useEffect(() => {
    return () => dispatch({ type: MenuActionTypes.close });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span {...attributes} role="button">
      {children}
    </span>
  );
};

const makeMenuHandle =
  (menuHook: typeof useEmojiMenu | typeof usePageMenu | typeof useReusableContentMenu | typeof useVariableMenu) =>
  // eslint-disable-next-line react/display-name
  (props: Props) => {
    const menuState = menuHook();
    return <MenuHandle menuState={menuState} {...props} />;
  };

const VariableMenuHandle = makeMenuHandle(useVariableMenu);
const LinkMenuHandle = makeMenuHandle(usePageMenu);
const EmojiMenuHandle = makeMenuHandle(useEmojiMenu);
const ReusableContentMenuHandle = makeMenuHandle(useReusableContentMenu);

const components = {
  [MenuHandleTypes.variable]: VariableMenuHandle,
  [MenuHandleTypes.mdxVariable]: VariableMenuHandle,
  [MenuHandleTypes.reusableContent]: ReusableContentMenuHandle,
  [MenuHandleTypes.link]: LinkMenuHandle,
  [MenuHandleTypes.emoji]: EmojiMenuHandle,
  [MenuHandleTypes.glossary]: ReusableContentMenuHandle,
};

const MenuHandleContainer = (props: Props) => {
  const Component = components[props.element.menuType];

  return <Component {...props} />;
};

export default MenuHandleContainer;
