import { insertGist, insertIframe, insertJSFiddle, insertPDF, insertYouTube } from './operations';

const slashMenu = {
  YouTube: { icon: 'youtube', transform: insertYouTube },
  'GitHub Gist': { icon: 'github', transform: insertGist },
  PDF: { icon: 'file', transform: insertPDF },
  JSFiddle: { icon: 'jsfiddle', transform: insertJSFiddle },
  Iframe: { icon: 'maximize', transform: insertIframe },
};

export default slashMenu;
