import React, { useCallback } from 'react';
import { Editor, Node, Path } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import { Table } from '@ui/MarkdownEditor/editor/blocks';
import { isTableHeaderCell } from '@ui/MarkdownEditor/editor/blocks/TableCell/shared';

import IconMenu, { Divider } from '../IconMenu';

import AddButton from './AddButton';
import MoveButton from './MoveButton';
import classes from './style.module.scss';
import { useTableToolbar } from './useTableToolbar';

const gap = 0.25;
const gapPlus = gap + 0.05;

const hoverBoardStyle = {
  height: `calc(100% + ${gapPlus * 2}em)`,
  width: `calc(100% + ${gapPlus * 2}em)`,
  top: `-${gapPlus}em`,
  left: `-${gapPlus}em`,
};

const TableToolbar = () => {
  const editor = useSlateStatic();
  const [state, dispatch] = useTableToolbar();
  const path = state.path && Node.has(editor, state.path) ? state.path : undefined;
  const node = path && Node.has(editor, path) ? Node.get(editor, path) : null;
  const isColumn = isTableHeaderCell(node);
  const tableEntry = path && Editor.above(editor, { at: path, match: Table.isTable });
  const table = tableEntry ? tableEntry[0] : null;

  const setPosition = useCallback(
    ref => {
      if (!node || !ref.current || !state.open) return;

      const toolbarRect = ref.current.getBoundingClientRect();

      const rowNode = Node.get(editor, Path.parent(path));
      const rowEl = ReactEditor.toDOMNode(editor, rowNode);
      const rowRect = rowEl.getBoundingClientRect();

      const possibleRowLeft = rowRect.left - toolbarRect.width;

      let top;
      let left;

      if (isColumn || possibleRowLeft < 0) {
        const cellEl = ReactEditor.toDOMNode(editor, node);
        const cellRect = cellEl.getBoundingClientRect();

        top = `calc(${cellRect.top - toolbarRect.height}px - ${gap}em)`;
        left = `calc(${cellRect.left + cellRect.width - toolbarRect.width}px)`;
      } else {
        const topPx = rowRect.top - (toolbarRect.height - rowRect.height) / 2;
        top = `calc(${topPx}px)`;
        left = `calc(${possibleRowLeft}px - ${gap}em)`;
      }

      ref.current.style.top = top;
      ref.current.style.left = left;
    },
    [editor, isColumn, node, path, state],
  );

  const onPointerLeave = useCallback(() => {
    dispatch({ type: 'close' });
  }, [dispatch]);

  return (
    <IconMenu
      className={classes.TableToolbar}
      data-path={state.path}
      data-testid="table-toolbar"
      onPointerLeave={onPointerLeave}
      open={state.open}
      setPosition={setPosition}
    >
      <MoveButton isColumn={isColumn} path={path} table={table} />
      <MoveButton forward isColumn={isColumn} path={path} table={table} />
      <Divider />
      <AddButton isColumn={isColumn} path={path} table={table} />
      <AddButton isColumn={isColumn} path={path} remove table={table} />
      <div className={classes.TableToolbarHoverBoard} data-table-toolbar style={hoverBoardStyle} />
    </IconMenu>
  );
};

export { default as useTableToolbar, TableToolbarProvider } from './useTableToolbar';

export default TableToolbar;
