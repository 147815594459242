import type { Node } from 'slate';

import { Element } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { ImageBlock, ParagraphElement } from '@ui/MarkdownEditor/types';

export const type = 'image-block';
export const isImageBlock = (node: Node): node is ImageBlock => Element.isElementType(node, type);

export const defaultImage = ({
  alt = '',
  title = '',
  url = undefined,
  ...props
}: {
  alt?: string;
  title?: string;
  url?: string;
}): ImageBlock => ({
  alt,
  children: [emptyNode() as ParagraphElement],
  title,
  type,
  url,
  ...props,
});

export const toString = (image: ImageBlock) => {
  const { alt, title, url } = image;

  return `![${alt || ''}](${url}${title ? ` "${title}"` : ''})`;
};

export const toInline = (image: ImageBlock) => {
  const { alt, title, url } = image;

  return defaultImage({ alt, title, url });
};
