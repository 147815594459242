export const sampleData = [
  {
    id: '1',
    name: 'testVariable',
    definition: 'test var',
    prefix: '',
    type: 'Variable',
  },
  {
    id: '2',
    name: 'id',
    definition: 'id',
    prefix: '',
    type: 'Variable',
  },
  {
    id: '3',
    name: 'name',
    definition: 'name',
    prefix: '',
    type: 'Variable',
  },
  {
    id: '3.5',
    name: 'email',
    definition: 'kelly@readme.io',
    prefix: '',
    type: 'Variable',
  },
  {
    id: '4',
    name: 'Parliament',
    definition: "Owls are generally solitary, but when seen together the group is called a 'parliament'!",
    prefix: 'glossary:',
    type: 'Glossary Term',
  },
  {
    id: '5',
    name: 'Owlbert',
    definition: "ReadMe's Mascot",
    prefix: 'glossary:',
    type: 'Glossary Term',
  },
  {
    id: '6',
    name: 'A long glossary term',
    definition:
      'One cannot separate icicles from unmet crickets. The zeitgeist contends that those carp are nothing more than seas. Some posit the untressed stepson to be less than taming. The literature would have us believe that a pongid destruction is not but a dream.',
    prefix: 'glossary:',
    type: 'Glossary Term',
  },
  {
    id: '7',
    name: 'Markdown',
    definition: 'A lightweight markup language for creating formatted text using a plain-text editor.',
    prefix: 'glossary:',
    type: 'Glossary Term',
  },
];
