import * as RDMD from '@readme/markdown';
import * as RMDX from '@readme/mdx';
import { Editor, Node, Path, Range, Text } from 'slate';

import { CodeTabs, Link, Heading } from '@ui/MarkdownEditor/editor/blocks';
import { leavesByType as leaves } from '@ui/MarkdownEditor/editor/byType';
import Leaves from '@ui/MarkdownEditor/editor/leaves';
import { decorator, getNode } from '@ui/MarkdownEditor/editor/parser';
import { bounded, offsetToPoint, remap } from '@ui/MarkdownEditor/editor/utils';

import memoize from './memoize';

const decoratorLength = node => leaves[node.type]?.decorator?.length || 0;

const MdastNode = {
  start: node => node.position.start.offset,
  end: node => node.position.end.offset,
  contentStart: node => {
    if (node.type === 'inlineCode') {
      return (
        (node.position.end.offset - node.position.start.offset - node.value.length) / 2 + node.position.start.offset
      );
    }

    return node.children?.length
      ? node.children[0].position.start.offset
      : node.position.start.offset + decoratorLength(node);
  },
  contentEnd: node => {
    if (node.type === 'inlineCode') {
      return node.position.end.offset - (node.position.end.offset - node.position.start.offset - node.value.length) / 2;
    }

    return node.children?.length
      ? node.children[node.children.length - 1].position.end.offset
      : node.position.end.offset - decoratorLength(node);
  },
};

const getOffsets = node => ({
  start: MdastNode.start(node),
  end: MdastNode.end(node),
  contentStart: MdastNode.contentStart(node),
  contentEnd: MdastNode.contentEnd(node),
});

const splitIntoLeaves = (editor, _, decoration) => {
  const { anchor, focus, ...rest } = decoration;
  const splits = [];

  for (const [, leafPath] of Editor.nodes(editor, { at: { anchor, focus }, mode: 'lowest' })) {
    const range = {
      anchor: Path.equals(leafPath, anchor.path) ? anchor : Editor.start(editor, leafPath),
      focus: Path.equals(leafPath, focus.path) ? focus : Editor.end(editor, leafPath),
      ...rest,
    };
    splits.push(range);
  }

  return splits;
};

const slashMenuDecorator = (editor, path) => {
  const { rangeRef } = editor.slashMenu[0];
  const range = rangeRef?.current;

  return range && Range.includes(range, path)
    ? [
        {
          ...range,
          slashMenu: true,
        },
      ]
    : [];
};

/* @note: We filter out delete because for some reason, remark allows empty
 * delete's but nothing else.
 */
const decorators = Object.fromEntries(
  Object.entries({
    ...remap(Leaves, 'decorator'),
    ...remap(Leaves, 'decoratorAlternate'),
  }).filter(([, leaf]) => leaf.type !== 'delete'),
);

const expandRange = (editor, range, { start = 0, end = start, bound }) => {
  const anchor = Editor.start(editor, range);
  const focus = Editor.end(editor, range);

  return {
    anchor: {
      path: anchor.path,
      offset: bounded(anchor.offset - start),
    },
    focus: {
      path: focus.path,
      offset: bounded(focus.offset + end, bound),
    },
  };
};

const decorateText = (editor, [node, path]) => {
  if (!editor.selection) return [];
  if (!(Range.isCollapsed(editor.selection) && Path.equals(path, editor.selection.anchor.path))) return [];

  return Object.entries(decorators).reduce((acc, [chars, leaf]) => {
    const at = expandRange(editor, editor.selection, { start: chars.length, bound: Node.string(node).length });
    const decoration = `${chars}${chars}`;

    if (Editor.string(editor, at) === decoration) {
      acc.push(
        {
          ...expandRange(editor, editor.selection, { start: chars.length, end: 0 }),
          [leaf.type]: true,
          decoration: true,
        },
        {
          ...editor.selection,
          [leaf.type]: true,
        },
        {
          ...expandRange(editor, editor.selection, { start: 0, end: chars.length }),
          [leaf.type]: true,
          decoration: true,
        },
      );
    }

    return acc;
  }, []);
};

// @xxx: This is a convenience for our users. The editor does something sorta
// non-standard and autolinks html props, eq:
//
// <img src="http://placekitten.com/500/500">
//
// We don't (currently) decorate the html, but we do autolink the url in the
// src prop. Even though `">` are not allowed URL characters, it appears most
// markdown implementations that support autolinking, will include those in the
// url, eg:
//
// http://http://placekitten.com/500/500">
//
// Is probably broken. So, as a convenience to our users, we're going to ignore
// those characters at the end of an link? Maybe this will cause problems
// later, I don't know. :shrug:
const linkCharacterConvenience = (mdNode, text, offsets, useMDX) => {
  if (mdNode.type !== Leaves.Link.type) return offsets;
  const match = mdNode.url.match(/"\/?>?$/);
  if (!match) return offsets;

  const html = getNode(
    (useMDX ? RMDX : RDMD).mdast(text),
    node => node.position.start.offset <= offsets.start && offsets.end <= node.position.end.offset,
  );
  if (!html) return offsets;

  return {
    ...offsets,
    end: offsets.end - match[0].length,
    contentEnd: offsets.contentEnd - match[0].length,
  };
};

const decorateBlock = (editor, nodeEntry, text, { at }) => {
  const tree = decorator(text, editor.props?.useMDX);
  if (!tree) return [];

  const mdastToDecorations = mdNode => {
    const { type } = mdNode;

    const deeper = () => mdNode?.children?.flatMap(mdastToDecorations).filter(r => r) || [];

    const leaf = leaves[type];
    if (!leaf || (type === 'link' && mdNode.label) || type === 'break') {
      return type === 'text' ? [] : deeper();
    }

    const decoration = { [leaf.type]: true };
    const { start, end, contentStart, contentEnd } = linkCharacterConvenience(
      mdNode,
      text,
      getOffsets(mdNode),
      editor.props?.useMDX,
    );
    const anchor = offsetToPoint(nodeEntry, start, { affinity: 'forward' });
    const focus = offsetToPoint(nodeEntry, end);

    if (at) {
      if (!Range.intersection(at, { anchor, focus })) return [];
    }

    const contentDecoration = splitIntoLeaves(editor, nodeEntry, {
      anchor,
      focus,
      ...(Leaves.Link.type === leaf.type && { range: { anchor, focus } }),
      ...decoration,
    });

    if (leaf.type === Leaves.MdxJsxTextElement.type) {
      return contentDecoration;
    }

    const startDecoration = start !== contentStart && {
      anchor,
      focus: offsetToPoint(nodeEntry, contentStart),
      decoration: true,
      ...decoration,
    };

    const endDecoration = end !== contentEnd && {
      anchor: offsetToPoint(nodeEntry, contentEnd, { affinity: 'forward' }),
      focus,
      decoration: true,
      ...decoration,
    };

    return [startDecoration, ...contentDecoration, ...deeper(), endDecoration].filter(n => n);
  };

  const blockRanges = mdastToDecorations(tree);
  return blockRanges;
};

const decorate = memoize((editor, nodeEntry, { at = null } = {}) => {
  const [node, path] = nodeEntry;

  if (Editor.isEditor(node)) return [];

  if (Text.isText(node)) {
    const textRanges = [...decorateText(editor, nodeEntry), ...slashMenuDecorator(editor, path)];

    return textRanges;
  }

  if (Link.isLink(node)) {
    const str = Node.string(node);
    const start = offsetToPoint([node, path], 0);
    const leftBracket = offsetToPoint([node, path], 1);
    const rightBracket = offsetToPoint([node, path], str.length - 3);
    const end = offsetToPoint([node, path], str.length);

    return [
      {
        anchor: start,
        focus: leftBracket,
        decoration: true,
      },
      { anchor: leftBracket, focus: rightBracket, link: true, skipMenu: true },
      {
        anchor: rightBracket,
        focus: end,
        decoration: true,
      },
    ];
  }

  if (!(Editor.isBlock(editor, node) && !Editor.isBlock(editor, node.children[0]))) {
    return [];
  }

  if (CodeTabs.is(node)) return [];

  let headingDecorator = [];
  if (Heading.isHeading(node)) {
    headingDecorator = [
      {
        anchor: Editor.start(editor, path),
        focus: offsetToPoint(nodeEntry, node.depth),
        decoration: true,
      },
    ];
  }

  return [...decorateBlock(editor, nodeEntry, Node.string(node), { at }), ...headingDecorator];
});

export default decorate;
