import { useContext } from 'react';

import { DragAndDropContext } from './Provider';

/**
 * Convenience hook to retrieve the drag and drop context value.
 * @example
 * const { id } = useDragAndDropContext();
 */
export default function useDragAndDropContext() {
  return useContext(DragAndDropContext);
}

export { default as DragAndDropProvider } from './Provider';
export * from './Provider';
